import React, { Component } from 'react';
import { Input } from "mdbreact";

import moment from 'moment';
import DateTime from 'react-datetime';

import { customStyles } from '../Shared/selectStyle';
import Select from 'react-select';

import 'react-datetime/css/react-datetime.css';
//import 'moment/locale/es';

import { connect } from 'react-redux';
import { fetchGetCatVentas, fetchGetCatCredito } from '../../Reducers/catalogos';
import '../../Css/input-suffix.css';

/*let start = new Date();
start.setHours(8, 0, 0, 0);*/

let optsPago = [];
let optsFrecuencia = [];

/*let optsPago = [
    { 'value': 1, 'label': 'Contado' },
    { 'value': 2, 'label': 'Crédito' }
];

let optsFrecuencia = [
    { 'value': 1, 'label': 'Mensual' },
    { 'value': 2, 'label': 'Quincenal' },
    { 'value': 3, 'label': 'Decenal' },
    { 'value': 4, 'label': 'Semanal' }
];*/

let optsSemana = [
    { 'value': 1, 'label': 'Lunes' },
    { 'value': 2, 'label': 'Martes' },
    { 'value': 3, 'label': 'Miércoles' },
    { 'value': 4, 'label': 'Jueves' },
    { 'value': 5, 'label': 'Viernes' },
    { 'value': 6, 'label': 'Sábado' },
    { 'value': 0, 'label': 'Domingo' },
]

/*const initState = {
    selOptionPago: null,
    selOptionFrecuencia: null,
    anticipo: '0',
    selOptionSemana: null,
    diaMes: 1,
    quincenal: {
        dia1: 1,
        dia2: 16,
    },
    decenal: {
        dia1: 1,
        dia2: 11,
        dia3: 21
    },
    dpStart: moment(start).format("HH:mm"),
}
*/

class DetallesVenta extends Component {
    /*constructor(props) {
        super(props);
        this.state = initState;
    }*/

    componentDidMount() {
        const { getVentas, getCreditos } = this.props;
        getVentas();
        getCreditos();
    }

    handlePagoChange = (selOptionPago) => {
        const { clickDetalles } = this.props;

        clickDetalles('selOptionPago', selOptionPago);

        /*  this.setState({
              ...this.state, selOptionPago
          });
  
          if (selOptionPago) {
              clickDetalles('tipo_pago', {
                  id: selOptionPago.value,
                  nombre: selOptionPago.label
              });
          }
          else {
              clickDetalles('tipo_pago', {
                  id: -1,
                  nombre: ''
              });
          }*/
    }

    handleFrecuenciaChange = (selOptionFrecuencia) => {
        const { clickDetalles, tiposCredito } = this.props;

        let abonos = [];

        if (selOptionFrecuencia) {
            abonos = tiposCredito.rows.find(credito => credito.id === selOptionFrecuencia.value);
        }
        else {
            abonos.pagos = 0;
        }

        clickDetalles('selOptionFrecuencia', { selected: selOptionFrecuencia, numero: abonos.pagos });

        //clickDetalles('pagos', abonos.pagos);
        /*  this.setState({
              ...this.state, selOptionFrecuencia
          });
  
          if (selOptionFrecuencia) {
              clickDetalles('frecuencia', {
                  id: selOptionFrecuencia.value,
                  nombre: selOptionFrecuencia.label
              });
          }
          else {
              clickDetalles('frecuencia', {
                  id: -1,
                  nombre: ''
              });
          }*/
    }

    handleFrecuenciaOptions = () => {
        optsFrecuencia = [];
        this.props.tiposCredito.rows.map((credito) =>
            optsFrecuencia.push({ value: credito.id, label: credito.nombre })
        );
        return optsFrecuencia;
    }

    handleSemanaChange = (selOptionSemana) => {
        const { clickDetalles } = this.props;
        if (selOptionSemana) {
            let weekDay = selOptionSemana.value;
            let isoSelected = '';
            let localSelected = '';
            let now = new Date();
            now.setHours(8, 0, 0, 0);
            if (now.getDay() === weekDay) {
                now.setDate((now.getDate() + 1) + (weekDay + (6 - now.getDay())) % 7);
            }
            else {
                now.setDate(now.getDate() + (weekDay + (7 - now.getDay())) % 7);
            }
            isoSelected = moment(now).toISOString();
            localSelected = moment(now);
            clickDetalles('selOptionSemana', { 'selOptionSemana': selOptionSemana, 'iso': isoSelected, 'local': localSelected });
        }


        //clickDetalles('dpStartISO', selOptionSemana);
        /*this.setState({
            ...this.state, selOptionSemana
        });

        if (selOptionSemana) {
            clickDetalles('diaSemana', {
                id: selOptionSemana.value,
                nombre: selOptionSemana.label
            });
        }
        else {
            clickDetalles('diaSemana', {
                id: -1,
                nombre: ''
            });
        }*/
    }

    handleChange = (e) => {
        const { id, value } = e.target;
        const { clickDetalles } = this.props;
        const baseDate = new Date('2019-09-01');
        switch (id) {
            case 'anticipo':
                let filteredPrice = value.replace(/[^0-9.]+/g, '');
                //this.setState({ [id]: filteredPrice });
                clickDetalles(id, filteredPrice);
                break;
            case 'decenal1':
                let dec2 = value + 10;
                let dec3 = value + 20;
                /* this.setState({
                     decenal: {
                         dia1: value,
                         dia2: dec2,
                         dia3: dec3
                     }
                 });*/
                clickDetalles('decenal', {
                    dia1: value,
                    dia2: dec2,
                    dia3: dec3
                });
            default:
                // this.setState({ [id]: value });
                clickDetalles(id, value);
                break;
        }
    }

    handleChangeDTP = (name, value) => {
        const { clickDetalles } = this.props;
        /*this.setState({ dpStart: moment(value).format("HH:mm") });
        if (name === 'firstDate') {
            clickDetalles('startDate', { local: moment(value).format("DD/MM/YYYY"), iso: moment(value).toISOString() });
        }
        if (name === 'pickUpTime') {
            clickDetalles('dpStart', { dpStart: value, iso: moment(value).toISOString() });
        }*/
        clickDetalles('startDate', { local: value, iso: moment(value).toISOString() });
    }

    getValidDates = (currentDate) => {
        var today = moment();
        return currentDate.isAfter(today);
    }

    render() {

        const { selOptionFrecuencia, startDate, anticipo, selOptionSemana, decenal, quincenal, diaMes, minimoAnticipo } = this.props.detalles;
        const { precioProducto } = this.props;

        return (
            <form autoComplete="off">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4 col-sm-12 custom-prefix" >
                                <Input label="Anticipio" icon="dollar-sign" className="input-margin input-center" value={anticipo} type="number" name="anticipo" onChange={this.handleChange} id="anticipo" min={minimoAnticipo} max={precioProducto} required pattern=".*[^ ].*" />
                                {parseFloat(anticipo) < minimoAnticipo && <div className="invalid-value"> El anticipo no puede ser menor al 10% del costo del producto. </div>}
                                {parseFloat(anticipo) > precioProducto && <div className="invalid-value"> El anticipo no puede ser mayor al costo del producto. </div>}
                            </div>
                            <div className="col-md-4 col-sm-12" >
                                <Select className="select-margin" options={this.handleFrecuenciaOptions()} value={selOptionFrecuencia} placeholder="Frecuencia" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleFrecuenciaChange} isClearable />
                            </div>
                            {selOptionFrecuencia && selOptionFrecuencia.value === 4 ?
                                <div className="col-md-4 col-sm-12">
                                    <Select className="select-margin" options={optsSemana} value={selOptionSemana} placeholder="Día" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSemanaChange} isClearable />
                                </div>
                                :
                                <div className="col-md-4 col-sm-12  hour-input-margin">
                                    <label className="hour-label" >Fecha de cobro</label>
                                    <div>
                                        <i className="far fa-calendar prefijo-input"></i>
                                        <DateTime name="firstDate" locale="es" inputProps={{ className: "dtpicker_center form-control input-modal" }} onChange={moment => this.handleChangeDTP("firstDate", moment)} closeOnSelect={true} timeFormat={false} value={startDate} isValidDate={this.getValidDates} dateFormat="DD/MM/YYYY" />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12  hour-input-margin">
                                <label className="hour-label">Hora sugerida de cobro</label>
                                <div>
                                    <i className="far fa-clock prefijo-input"></i>
                                    <DateTime name="pickUpTime" locale="es" inputProps={{ className: "dtpicker_center form-control input-modal" }} onChange={moment => this.handleChangeDTP("pickUpTime", moment)} closeOnSelect={true} dateFormat={false} value={startDate} />
                                </div>
                            </div>
                        </div>
                        <br />
                        {/*selOptionFrecuencia &&
                            <div className="row">
                                {selOptionFrecuencia.value === 4 &&
                                    <div className="col-md-4 col-sm-12">
                                        <Select className="select-margin" options={optsSemana} value={selOptionSemana} placeholder="Día" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSemanaChange} isClearable />
                                    </div>
                                }

                                {selOptionFrecuencia.value === 3 &&
                                    [<div className="col-md-4 col-sm-12" key="1">
                                        <Input label="Días de cobro" className="input-center" value={decenal.dia1} type="number" name="decenal1" onChange={this.handleChange} id="decenal1" required pattern=".*[^ ].*" />
                                    </div>,
                                    <div className="col-md-4 col-sm-12" key="2">
                                        <Input label="" className="input-center" value={decenal.dia2} type="number" name="decenal2" onChange={this.handleChange} id="decenal2" required pattern=".*[^ ].*" />
                                    </div>,
                                    <div className="col-md-4 col-sm-12" key="3">
                                        <Input label="" className="input-center" value={decenal.dia3} type="number" name="decenal3" onChange={this.handleChange} id="decenal3" required pattern=".*[^ ].*" />
                                    </div>]
                                }

                                {selOptionFrecuencia.value === 2 &&
                                    [<div className="col-md-4 col-sm-12" key="4">
                                        <Input label="Días de cobro" className="input-center" value={quincenal.dia1} type="number" name="quincenal1" onChange={this.handleChange} id="quincenal1" required pattern=".*[^ ].*" />
                                    </div>,
                                    <div className="col-md-4 col-sm-12" key="5">
                                        <Input label="" className="input-center" value={quincenal.dia2} type="number" name="quincenal2" onChange={this.handleChange} id="quincenal2" required pattern=".*[^ ].*" />
                                    </div>]
                                }

                                {selOptionFrecuencia.value === 1 &&
                                    <div className="col-md-4 col-sm-12">
                                        <Input label="Día de cobro" className="input-center" value={diaMes} type="number" name="diaMes" onChange={this.handleChange} id="diaMes" required pattern=".*[^ ].*" />
                                    </div>
                                }

                            </div>
                            */}
                    </div>
                </div>
                <br />
            </form>
        )
    }
}

export default connect(state => ({
    tiposCredito: state.catalogos.creditos,
    tiposVenta: state.catalogos.ventas,
}), {
        getCreditos: fetchGetCatCredito,
        getVentas: fetchGetCatVentas
    })(DetallesVenta);