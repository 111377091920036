import {
    apiAddCobrador,
    apiUpdateCobrador,
    apiAddIngresoCobrador,
    apiGetIngresosCobrador,
    apiGetAcumuladosCobrador,
    apiResetToken,
    apiGetConexionesCobradores,
    apiGetClientesCobrador,
    apiGetAbonosCobrador,
    apiGetEstadisticasCobrador
} from '../Api/apiCobradores';

import { addUsuario, updateUsuarioLista, updateUsuarioDetalles } from './usuarios';


import { NotificationManager } from 'react-notifications';

import { handleLoading } from './general';

const initialState = {
    ingresos: {
        rows: [],
        total: 0,
    },
    acumulados: {
        total: 0,
        rows: [],
    },
    showModalIngreso: false,
    conexiones: {
        rows: [],
        total: 0,
    },
    clientesCobrador: {
        rows: [],
        total: 0,
    },
    editViewCobrador: false,
    abonos: {
        rows: [],
        total: 0,
    },
    estadisticas: {
        rows: [],
        total: 0,
    }
};

const GET_INGRESOS_COBRADOR = "GET_INGRESOS_COBRADOR";
const ADD_INGRESOS_COBRADOR = "ADD_COBRADOR";
const GET_ACUMULADO_COBRADOR = "GET_ACUMULADO_COBRADOR";
const SHOW_MODAL_INGRESOS = "SHOW_MODAL_INGRESOS";
const GET_CONEXIONES_COBRADORES = "GET_CONEXIONES_COBRADORES";
const RESET_CONEXION_COBRADOR = "RESET_CONEXION_COBRADOR";
const GET_CLIENTES_COBRADOR = "GET_CLIENTES_COBRADOR";
const SHOW_COBRADOR_FORM = "SHOW_COBRADOR_FORM";
const GET_ABONOS_COBRADOR = "GET_ABONOS_COBRADOR";
const GET_ESTADISTICAS_COBRADOR = "GET_ESTADISTICAS_COBRADOR";

const getIngresosCobrador = ingresos => ({ type: GET_INGRESOS_COBRADOR, payload: ingresos });
const addIngresosCobrador = ingreso => ({ type: ADD_INGRESOS_COBRADOR, payload: ingreso });
const getAcumuladoCobrador = acumulado => ({ type: GET_ACUMULADO_COBRADOR, payload: acumulado });
const showModalIngresos = () => ({ type: SHOW_MODAL_INGRESOS });
const getConexionesCobradores = conexiones => ({ type: GET_CONEXIONES_COBRADORES, payload: conexiones });
const resetConexionCobrador = id => ({ type: RESET_CONEXION_COBRADOR, payload: id });
const getClientesCobrador = clientes => ({ type: GET_CLIENTES_COBRADOR, payload: clientes });
const verFormEdCobrador = () => ({ type: SHOW_COBRADOR_FORM });
const getAbonosCobrador = abonos => ({ type: GET_ABONOS_COBRADOR, payload: abonos });
const getEstadisticasCobrador = estadisticas => ({ type: GET_ESTADISTICAS_COBRADOR, payload: estadisticas });

export const fetchAddCobrador = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddCobrador(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Cobrador guardado correctamente', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/DetallesCobrador/${res.body.id}`;
                    }, 3000);
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddUsuario", error);
            });
    };
}

export const fetchUpdateCobrador = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateCobrador(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    dispatch(verFormEdCobrador());
                    NotificationManager.success('Cobrador guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeCobrador", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchGetIngresosCobrador = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetIngresosCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getIngresosCobrador(res.body));
                }
                else {
                    NotificationManager.error(res.message, 'Error');
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddIngresos", error);
            });
    };
}

export const fetchAddIngresosCobrador = (body) => {
    return dispatch => {
        apiAddIngresoCobrador(body)
            .then((res) => {
                if (res.status === 201) {
                    let obj = {
                        cobrador: res.body.cobrador.id,
                        limit: 1,
                        offset: '0',
                    }
                    dispatch(addIngresosCobrador(res.body));
                    dispatch(showModalIngresos());
                    dispatch(fetchGetAcumuladosCobrador(obj));
                    NotificationManager.success('Ingreso guardado correctamente', '¡Éxito!');
                }
                else {
                    let mensaje = '';
                    res.body.map(v => {
                        mensaje += v.message + ', ';
                    });
                    NotificationManager.error(mensaje, 'Error');
                }
            }).catch((error) => {
                console.log("errorAddIngresos", error);
            });
    };
}

export const fetchGetAcumuladosCobrador = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetAcumuladosCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getAcumuladoCobrador(res.body));
                }
                else {
                    if (res.message) {
                        NotificationManager.error(res.message, 'Error');
                    }
                    else {
                        NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                    }

                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddIngresos", error);
            });
    };
}

export const fetchResetTokenCobrador = (id) => {
    return dispatch => {
        apiResetToken(id)
            .then((res) => {
                if (res.status === 200) {
                    NotificationManager.success('Usuario liberado correctamente.', '¡Éxito!');
                    dispatch(resetConexionCobrador(id));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
            }).catch((error) => {
                console.log("errorResetToken", error);
            });
    };
}

export const fetchShowModalIngresos = () => {
    return dispatch => {
        dispatch(showModalIngresos());
    }
}

export const fetGetCobradoresConexiones = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetConexionesCobradores(obj)
            .then((conexiones) => {
                dispatch(getConexionesCobradores(conexiones));
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                dispatch(getConexionesCobradores(initialState.conexiones));
                console.log("errorGetCobradoresConexiones", error);
            });
    };
}

export const fetchGetClientesCobrador = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetClientesCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getClientesCobrador(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetlientesCobrador", error);
            });
    };
}

export const fetchEditViewCobradores = () => {
    return dispatch => {
        dispatch(verFormEdCobrador());
    }
}

export const fetchGetAbonosCobrador = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetAbonosCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getAbonosCobrador(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                dispatch(getAbonosCobrador(initialState.abonos));
                console.log("errorGetAbonosCobrador", error);
            });
    };
}

export const fetchGetEstadisticasCobrador = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetEstadisticasCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getEstadisticasCobrador(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                dispatch(getEstadisticasCobrador(initialState.estadisticas));
                console.log("errorGetEstadisticasCobrador", error);
            });
    };
}


const cobradoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INGRESOS_COBRADOR:
            return { ...state, ingresos: action.payload };
        case ADD_INGRESOS_COBRADOR:
            return { ...state, ingresos: { ...state.ingresos, total: state.ingresos.total + 1, rows: [...state.ingresos.rows, action.payload] } };
        case GET_ACUMULADO_COBRADOR:
            return { ...state, acumulados: action.payload };
        case SHOW_MODAL_INGRESOS:
            return { ...state, showModalIngreso: !state.showModalIngreso };
        case GET_CONEXIONES_COBRADORES:
            return { ...state, conexiones: action.payload };
        case RESET_CONEXION_COBRADOR:
            return { ...state, conexiones: { ...state.conexiones, total: state.conexiones.total - 1, rows: state.conexiones.rows.filter(elem => elem.id !== action.payload) } };
        case GET_CLIENTES_COBRADOR:
            return { ...state, clientesCobrador: action.payload };
        case SHOW_COBRADOR_FORM:
            return { ...state, editViewCobrador: !state.editViewCobrador };
        case GET_ABONOS_COBRADOR:
            return { ...state, abonos: action.payload };
        case GET_ESTADISTICAS_COBRADOR:
            return { ...state, estadisticas: action.payload };
        default:
            return { ...state };
    }
};

export default cobradoresReducer;