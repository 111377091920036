import {
    apiGetVentas,
    apiGetVentaSeleccionada,
    apiGetNotasVenta,
    apiGetFechasVenta,
    apiGetAbonosVenta
} from '../Api/apiVentas';

import { handleLoading } from './general';

const initialState = {
    ventas: {
        rows: [],
        total: 0,
    },
    ventaSeleccionada: {},
    notasVenta: {
        rows: [],
        total: 0,
    },
    fechasVenta: {
        rows: [],
        total: 0,
    },
    abonosVenta: {}
};

const GET_VENTAS = "GET_VENTAS";
const GET_VENTA_SELECCIONADA = "GET_VENTA_SELECCIONADA";
const GET_NOTAS_VENTA = "GET_NOTAS_VENTA";
const GET_FECHAS_VENTA = "GET_FECHAS_VENTA";
const GET_ABONOS_VENTA = "GET_ABONOS_VENTA";

const getVentas = ventas => ({ type: GET_VENTAS, payload: ventas });
const getVentaSeleccionada = seleccion => ({ type: GET_VENTA_SELECCIONADA, payload: seleccion });
const getNotasVenta = notas => ({ type: GET_NOTAS_VENTA, payload: notas });
const getFechasVenta = fechas => ({ type: GET_FECHAS_VENTA, payload: fechas });
const getAbonosVenta = abonos => ({ type: GET_ABONOS_VENTA, payload: abonos });

export const fetchGetVentas = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetVentas(obj)
            .then((ventas) => {
                dispatch(getVentas(ventas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetVentas", error);
                dispatch(getVentas(initialState.ventas));
            });
    };
}

export const fetchGetVentaSeleccionada = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetVentaSeleccionada(id)
            .then((seleccion) => {
                dispatch(getVentaSeleccionada(seleccion));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetVentaSeleccionada", error);
                dispatch(getVentaSeleccionada(initialState.ventaSeleccionada));
            });
    };
}

export const fetchGetNotasVenta = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetNotasVenta(id)
            .then((notas) => {
                dispatch(getNotasVenta(notas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetNotasVenta", error);
                dispatch(getNotasVenta(initialState.notasVenta));
            });
    };
}

export const fetchGetFechasVenta = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetFechasVenta(id)
            .then((fechas) => {
                dispatch(getFechasVenta(fechas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetFechasVenta", error);
                dispatch(getFechasVenta(initialState.fechasVenta));
            });
    };
}

export const fetchGetAbonosVenta = (id) => {
    if (id === -1) {
        return dispatch => {
            dispatch(getAbonosVenta(initialState.abonosVenta));
        };
    }
    else {
        return dispatch => {
            dispatch(handleLoading());
            apiGetAbonosVenta(id)
                .then((abonos) => {
                    dispatch(getAbonosVenta(abonos));
                    dispatch(handleLoading());
                })
                .catch((error) => {
                    dispatch(handleLoading());
                    console.log("errorGetFechasVenta", error);
                    dispatch(getAbonosVenta(initialState.abonosVenta));
                });
        };
    }
}

const ventasReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VENTAS:
            return { ...state, ventas: action.payload };
        case GET_VENTA_SELECCIONADA:
            return { ...state, ventaSeleccionada: action.payload };
        case GET_NOTAS_VENTA:
            return { ...state, notasVenta: action.payload };
        case GET_FECHAS_VENTA:
            return { ...state, fechasVenta: action.payload };
        case GET_ABONOS_VENTA:
            return { ...state, abonosVenta: action.payload };
        default:
            return { ...state };
    }
};

export default ventasReducer;