import { Table } from 'mdbreact';
import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import '../../Css/comisiones.css';

const arr = [
    {
        id: 1,
        semana: 'Semana 1 - 4',
        porcentaje: '87.69%',
        alerta: 'warning',
        resumen: [
            { 'num': '1', 'fechas': '01/01/2019 - 06/01/2019', 'porcentaje': '83.33%', 'cantidad': '$ 1,000.00' },
            { 'num': '2', 'fechas': '07/01/2019 - 13/01/2019', 'porcentaje': '92.30%', 'cantidad': '$ 1,200.00' },
            { 'num': '3', 'fechas': '14/01/2019 - 20/01/2019', 'porcentaje': '75%', 'cantidad': '$ 1,500.00' },
            { 'num': '4', 'fechas': '21/01/2019 - 27/01/2019', 'porcentaje': '100%', 'cantidad': '$ 2,000.00' }
        ],
        totales: { 'porcentaje': '87.69%', 'cantidad': '$ 5,700.00' }
    },
    {
        id: 2,
        semana: 'Semana 5 - 8',
        porcentaje: '20%',
        alerta: 'danger',
        resumen: [
            { 'num': '5', 'fechas': '04/02/2019 - 10/02/2019', 'porcentaje': '80%', 'cantidad': '$ 1,000.00' },

        ],
        totales: { 'porcentaje': '20%', 'cantidad': '$ 1,000.00' }
    },
];

export default class RecaudacionesCobrador extends Component {
    state = {
        index: 0,
        selected: arr[0],
        classArrow:'move-right'
    };

    nextWeeks = () =>
        this.setState(prevState => {
            const newIndex = prevState.index < arr.length - 1 ? prevState.index + 1 : 0;
            return {
                index: newIndex,
                selected: arr[newIndex],
                classArrow: 'move-left'
            };
        });

    prevWeeks = () =>
        this.setState(prevState => {
            const newIndex = prevState.index === 0 ? arr.length - 1 : prevState.index - 1;
            return {
                index: newIndex,
                selected: arr[newIndex],
                classArrow: 'move-right'
            };
        });

    render() {
        const { selected, classArrow, index } = this.state;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-1 text-left">
                        <button className="btn-bloque" onClick={this.prevWeeks} onClick={this.prevWeeks} disabled={index===0? true : false}><i className="fas fa-arrow-left fa-2x"></i></button>
                    </div>
                    <div className="col-md-10 text-center">
                        <TransitionGroup>
                            <CSSTransition key={selected.id} timeout={1000} /*classNames="move"*/ classNames={classArrow} >
                                <h5>
                                    <b>{selected.semana}</b> &nbsp;&nbsp; <span className="badge badge-pill badge-warning badge-recaudacion">{selected.porcentaje}</span>
                                </h5>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                    <div className="col-md-1 text-right">
                        <button className="btn-bloque" onClick={this.nextWeeks} disabled={index === (arr.length -1) ? true : false}><i className="fas fa-arrow-right fa-2x"></i></button>
                    </div>
                </div>

                <br />
                <TransitionGroup>
                    <CSSTransition key={selected.id} timeout={1000} /*classNames="move"*/ classNames={classArrow} >
                        <Table className="text-center tbl_totales" responsive>
                            <thead>
                                <tr>
                                    <th className="col-numero">#</th>
                                    <th>Semana</th>
                                    <th>Porcentaje recaudado</th>
                                    <th >Cantidad recaudada</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selected.resumen.map(semana =>
                                        <tr key={semana.num}>
                                            <td className="col-numero">{semana.num}</td>
                                            <td>{semana.fechas}</td>
                                            <td> {semana.porcentaje}</td>
                                            <td> {semana.cantidad} </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2">Total</td>
                                    <td>{selected.totales.porcentaje}</td>
                                    <td>{selected.totales.cantidad}</td>
                                </tr>
                            </tfoot>
                        </Table>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}