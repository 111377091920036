import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaComisiones from './lista';
import Resumen from './resumen';
import { connect } from 'react-redux';
import '../../Css/comisiones.css';


class Comisiones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
        }
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    render() {
        const { tab } = this.state;
        return (
            <Card className="card-css">
                <CardHeader>
                    <Nav header>
                        <NavItem>
                            <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Comisiones</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Lista de comisiones</NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                           {tab === 1 && <Resumen/>}
                           {tab === 2 && <ListaComisiones/>}
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default connect(state => ({}), {})(Comisiones);