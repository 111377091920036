import React, { Component } from 'react';
import { Card } from "mdbreact";
import MapaCobradores from './mapa';
import ListaCobradores from './lista';
import Resumen from './resumen';
import { connect } from 'react-redux';
import { fetchGetEstadisticasMonitor } from '../../Reducers/monitor';
import moment from 'moment';
import '../../Css/monitor.css';

let start = new Date();
start.setHours(0, 0, 0, 0);
//start.setDate(start.getDate() + 1);

let end = new Date();
end.setHours(23, 59, 59, 999);
//end.setDate(end.getDate() + 1);

class Monitor extends Component {

    componentDidMount() {
        const { getEstadisticas } = this.props;

        let obEstadisticas = {
            fechaInicio: moment(start).toISOString(),
            fechaFin: moment(end).toISOString(),
        }
        getEstadisticas(obEstadisticas);

        try {
            this.interval = setInterval(async () => {
                getEstadisticas(obEstadisticas);
            }, 60000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { estadisticas } = this.props;
        return (
            <div>
                <Card className="card-body card-monitor">
                    <Resumen infoEstadisticas={estadisticas} />
                    <br />
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="titulo-tarjeta">Mapa de clientes</span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <MapaCobradores clientesMapa={estadisticas} />
                            </div>
                        </div>
                    </div>
                </Card>
                <br />
              { /* <Card className="card-body card-monitor">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="titulo-tarjeta">Cobradores</span>
                        </div>
                    </div>
                    <hr className="hr-cobradores" />
                    <ListaCobradores fnEditar={this.editarCobrador} />
        </Card >*/}
            </div>
        )
    }
}

export default connect(state => ({
    estadisticas: state.monitor.estadisticasMonitor
}), {
        getEstadisticas: fetchGetEstadisticasMonitor
    })(Monitor);
