import React, { Component } from 'react';
import { Card, CardBody } from "mdbreact";
import Stepper from './stepper-form';
import moment from 'moment';
import MultipleSeleccion from '../Ventas/productos-seleccionados';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchAddPreventaCredito, fetchAddPreventaContado, fetchCancelPresale, fetchGetFechasPagosPreview, fetchResetFechas } from '../../Reducers/solicitudes';
import { fetchGetCatVentas } from '../../Reducers/catalogos';

let optsPago = [];

let start = new Date();
start.setHours(8, 0, 0, 0);

function productExists(product, object) {
    return object.some(function (elem) {
        return elem.id === product;
    });
}

class AltaPreVenta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabSelected: 1,
            cliente: {
                nombre: '',
                apellidoP: '',
                apellidoM: '',
                id: -1,
                direccion: ''
            },
            producto: {
                detalles: {
                    nombre: '',
                    id: -1,
                    cantidad: 0,
                    precioContado: 0,
                    precioCredito: 0,
                },
                productos: [],
                selLineaOption: null,
                selSublineaOption: null
            },
            detalles_pago: {
                minimoAnticipo: 0,
                selOptionPago: null,
                selOptionFrecuencia: null,
                anticipo: '0',
                selOptionSemana: null,
                diaMes: 1,
                quincenal: {
                    dia1: 1,
                    dia2: 16,
                },
                decenal: {
                    dia1: 1,
                    dia2: 11,
                    dia3: 21
                },
                dpStart: moment(start),
                dpStartISO: moment(start).toISOString(),
                startDate: moment(start).add(1, 'days').format("DD/MM/YYYY"),
                hora: '',
                pagos: 0
            }
        }
    }

    componentDidMount() {
        const { preCliente, getVentas } = this.props;
        this.setState({ cliente: preCliente });
        getVentas();
    }

    handlePagoChange = (selOptionPago) => {
        this.setState({
            producto: {
                ...this.state.producto,
                detalles: {
                    nombre: '',
                    id: -1,
                    cantidad: 0,
                    precioContado: 0,
                    precioCredito: 0,
                },
                productos: []
            }
        });
        this.selectDetalles('selOptionPago', selOptionPago);
    }

    handlePagoOptions = () => {
        optsPago = [];
        this.props.tiposVenta.rows.map((pago) =>
            optsPago.push({ value: pago.id, label: pago.nombre })
        );
        return optsPago;
    }

    selectTab = (id) => {
        this.setState({ tabSelected: id });
    }

    selectCliente = (cliente) => {
        this.setState({ ...this.state, cliente });
    }

    selectProducto = (id, valor) => {
        const { selOptionPago } = this.state.detalles_pago;
        const { productos } = this.state.producto;
        let anticipo = 0;

        if (id === 'productoSeleccionado') {
            switch (selOptionPago.value) {
                case 1:
                    anticipo = Math.round((valor.precioCredito * .10));
                    this.setState({
                        producto: {
                            ...this.state.producto, detalles: valor
                        },
                        detalles_pago: {
                            ...this.state.detalles_pago,
                            anticipo: anticipo.toString(),
                            minimoAnticipo: anticipo,
                        }
                    });
                    break;
                case 2:
                    if (!productExists(valor.id, productos)) {
                        valor.cantidad = 1;
                        this.setState({
                            producto: {
                                ...this.state.producto, productos: [...this.state.producto.productos, valor]
                            }
                        });
                    }
                    else {
                        NotificationManager.warning('El producto ya está agregado en la lista', '');
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            this.setState({
                producto: {
                    ...this.state.producto, [id]: valor
                }
            });
        }
    }

    changeCantidad = (e) => {
        const { name, value } = e.target;
        let numero = (value !== '' ? value : 1);
        const { productos } = this.state.producto;

        this.setState({
            producto: {
                ...this.state.producto, productos: productos.map(elem => elem.id === parseInt(name) ? { ...elem, cantidad: parseInt(numero) } : elem)
            }
        });
    }

    eliminarMueble = (id) => {
        const { productos } = this.state.producto;
        this.setState({
            producto: {
                ...this.state.producto, productos: productos.filter(elem => elem.id !== id)
            }
        });
    }


    selectDetalles = (id, valor) => {
        const { selOptionFrecuencia, startDate } = this.state.detalles_pago;
        const { getFechas, resetFechas } = this.props;
        if (id === 'startDate') {
            this.setState({
                detalles_pago: {
                    ...this.state.detalles_pago,
                    startDate: valor.local,
                    dpStartISO: valor.iso
                }
            });
        }
        else if (id === 'dpStart') {
            this.setState({
                detalles_pago: {
                    ...this.state.detalles_pago,
                    dpStart: valor.dpStart,
                    dpStartISO: valor.iso
                }
            });
        }
        else if (id === 'selOptionSemana') {
            this.setState({
                detalles_pago: {
                    ...this.state.detalles_pago,
                    selOptionSemana: valor.selOptionSemana,
                    dpStartISO: valor.iso,
                    startDate: valor.local
                }
            });
        }
        else if (id === 'selOptionFrecuencia') {
            this.setState({
                detalles_pago: {
                    ...this.state.detalles_pago,
                    selOptionFrecuencia: valor.selected,
                    pagos: valor.numero,
                }
            });
        }
        else {
            this.setState({
                detalles_pago: {
                    ...this.state.detalles_pago, [id]: valor
                }
            });
        }
        let dateString = [];
        if ((id === 'startDate' && selOptionFrecuencia) || (id === 'selOptionSemana' && selOptionFrecuencia)) {
            dateString = moment(valor.local).format('DD/MM/YYYY').split('/');
            getFechas(selOptionFrecuencia.value, { fechaTentativa: dateString[2] + '-' + dateString[1] + '-' + dateString[0] })
        }
        if (id === 'selOptionFrecuencia') {
            dateString = moment(startDate).format('DD/MM/YYYY').split('/');
            if (valor.selected) {
                getFechas(valor.selected.value, { fechaTentativa: dateString[2] + '-' + dateString[1] + '-' + dateString[0] })
            }
            else {
                resetFechas();
            }

        }
    }

    guardarVenta = () => {
        const { saveCredito, saveContado } = this.props;
        const { cliente, producto, detalles_pago } = this.state;
        let body = {};
        if (!detalles_pago.selOptionPago) {
            NotificationManager.warning('Debe seleccionar la forma de pago', 'Información incompleta');
        }
        else if (cliente.id === -1) {
            NotificationManager.warning('Debe seleccionar un cliente', 'Información incompleta');
        }
        else if (detalles_pago.selOptionPago.value === 1 && (!detalles_pago.selOptionFrecuencia || detalles_pago.anticipo === '0' || detalles_pago.dpStart === '' || detalles_pago.dpStart === 'Invalid date' || detalles_pago.startDate === 'Invalid date')) {
            NotificationManager.warning('Falta información importante en detalles de pago.', 'Información incompleta');
        }
        else if (detalles_pago.selOptionPago.value === 1 && (parseFloat(detalles_pago.anticipo) < detalles_pago.minimoAnticipo)) {
            NotificationManager.warning('El anticipo no puede ser menor al 10% del costo del producto. ', 'Información incompleta');
        }
        else if (detalles_pago.selOptionPago.value === 1 && (parseFloat(detalles_pago.anticipo) > producto.detalles.precioCredito)) {
            NotificationManager.warning('El anticipo no puede ser mayor al costo del producto. ', 'Información incompleta');
        }
        else if (detalles_pago.selOptionPago.value === 2 && producto.productos.length === 0) {
            NotificationManager.warning('No se han seleccionado productos. ', 'Información incompleta');
        }
        else if (detalles_pago.selOptionPago.value === 1 && producto.detalles.id === -1) {
            NotificationManager.warning('No se ha seleccionado ningún producto. ', 'Información incompleta');
        }
        else {
            switch (detalles_pago.selOptionPago.value) {
                case 1:
                    body =
                        {
                            "producto": {
                                "id": producto.detalles.id,
                                "cantidad": 1
                            },
                            "TipoCreditoId": detalles_pago.selOptionFrecuencia.value,
                            "anticipo": detalles_pago.anticipo,
                            "fechaTentativa": detalles_pago.dpStartISO,
                            "PreClienteId": cliente.id,
                        };
                    saveCredito(body, 1);
                    break;
                case 2:
                    let productos = [];
                    producto.productos.map(producto => productos.push({ "id": producto.id, "cantidad": producto.cantidad }));
                    body =
                        {
                            "productos": productos,
                            "PreClienteId": cliente.id
                        };
                    saveContado(body, 1);
                    break;
            }
        }
    }

    render() {
        const { cliente, producto, detalles_pago, tabSelected } = this.state;
        const { fechasPagos } = this.props;

        return (
            <div className="col-md-12">
                {tabSelected !== 3 &&
                    <Card className="card-css">
                        <CardBody>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12"><h5>Información de venta</h5></div>
                                </div>
                                <div className="body-generales" >
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin">Tipo de venta</div>
                                        <div className="col-md-3 col-sm-12 descripcion text-right-md"> <Select options={this.handlePagoOptions()} value={detalles_pago.selOptionPago} placeholder="- Seleccione -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handlePagoChange} isClearable /></div>
                                        <div className="col-md-2 col-sm-12 input-margin">Cliente</div>
                                        <div className="col-md-4 col-sm-12 descripcion input-margin text-right-md">{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</div>
                                    </div>
                                    <br />
                                    {detalles_pago.selOptionPago && detalles_pago.selOptionPago.value === 1 && <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin">Producto</div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{producto.detalles.nombre}</div>
                                        <div className="col-md-3 col-sm-12 input-margin">Costo</div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{producto.detalles.precioCredito !== 0 ? '$' + producto.detalles.precioCredito.toLocaleString() : ''}</div>
                                    </div>}
                                    {detalles_pago.selOptionPago && detalles_pago.selOptionPago.value === 2 && <MultipleSeleccion vista={1} objEditar={producto.productos} changeCantidad={this.changeCantidad} eliminarMueble={this.eliminarMueble} />}
                                </div>
                            </div>
                        </CardBody>
                    </Card>}

                <br />
                <br />

                {detalles_pago.selOptionPago && <Stepper
                    changeTab={this.selectTab}
                    selProducto={this.selectProducto}
                    selDetalles={this.selectDetalles}
                    infoDetalles={detalles_pago}
                    infoCliente={cliente}
                    infoProducto={producto}
                    guardarVenta={this.guardarVenta}
                    infoFechas={fechasPagos}
                />}
            </div>
        )
    }
}

export default connect(state => ({
    tiposVenta: state.catalogos.ventas,
    fechasPagos: state.preregistros.fechasPagos
}), {
        saveCredito: fetchAddPreventaCredito,
        saveContado: fetchAddPreventaContado,
        resetSelected: fetchCancelPresale,
        getVentas: fetchGetCatVentas,
        getFechas: fetchGetFechasPagosPreview,
        resetFechas: fetchResetFechas
    })(AltaPreVenta);
