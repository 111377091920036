import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaAdministradores from './lista';
import AltaAdministrador from './alta';
import EditarAdministrador from './editar';
import { connect } from 'react-redux';
import { fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditView } from '../../Reducers/general';

class Administradores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
        }
    }

    componentWillUnmount() {
        const { editar, editarAdministrador, seleccionar } = this.props;
        if (editar) {
            seleccionar(-1);
            editarAdministrador();
        }
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    editarAdministrador = (id) => {
        const { seleccionar, editarAdministrador } = this.props;
        seleccionar(id);
        editarAdministrador();
    }

    cancelarEditar = (e) => {
        e.preventDefault();
        const { seleccionar, editarAdministrador } = this.props;
        seleccionar(-1);
        editarAdministrador();
    }

    render() {
        const { tab } = this.state;
        const { editar } = this.props;

        if (editar) {
            return (
                <EditarAdministrador fnCancelar={this.cancelarEditar} vista='lista' />
            )
        }
        else {
            return (
                <Card className="card-css">
                    <CardHeader>
                        <Nav header>
                            <NavItem>
                                <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de administradores</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nuevo administrador</NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <div className="col-md-12">
                            <div className="row">
                                {tab === 1 && <ListaAdministradores fnEditar={this.editarAdministrador} />}
                                {tab === 2 && <AltaAdministrador />}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }
}

export default connect(state => ({
    editar: state.general.editView,
}), {
        seleccionar: fetchSeleccionarUsuario,
        editarAdministrador: fetchEditView,
    })(Administradores);