import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loaders';
import { Modal, ModalBody } from 'mdbreact';

import { fetchHideAlert } from '../../Reducers/general';
import SweetAlert from 'sweetalert-react';

import { NotificationContainer } from 'react-notifications';

import '../../Css/pagination.css';
import '../../Css/custom.css';
import '../../Css/fileInput.css';
import '../../Css/generales.css';
import '../../Css/loader.scss';
import 'react-notifications/lib/notifications.css';

class Content extends Component {

    hideAlert = () => {
        const { hideMessage } = this.props;
        hideMessage();
    }

    render() {
        const { body, showLoading, alerta } = this.props;

        return (
            <div className="Content">
                <NotificationContainer />
                <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText={alerta.confirmButtonText} cancelButtonText={alerta.cancelButtonText || "Cancelar"}
                    title={alerta.title} text={alerta.text} onConfirm={alerta.onConfirm || this.hideAlert} showCancelButton={alerta.showCancel}
                    onCancel={alerta.onCancel || this.hideAlert} />
                
                {body}

                <Modal isOpen={showLoading} size="sm" className="Modal-loader" centered>
                    <ModalBody>
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-lg-12 text-center" style={{ display: "flex" }} >
                                    <Loader type="line-spin-fade-loader" style={{ transform: "scale(2)", margin: "auto" }} />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        alerta: state.general.alerta,
        showLoading: state.general.showLoading,
    }), {
        hideMessage: fetchHideAlert,
    })(Content);