import React, { Component } from 'react';
import { Card, CardBody, Button, CardHeader, Nav, NavItem, NavLink } from "mdbreact";
import {Link} from 'react-router-dom';

import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';

import Productos from './productos';
import Entradas from './entradas';
import Salidas from './salidas';

import '../../Css/almacen.css';

class DetallesAlmacen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            idAlmacen:-1
        }
       /* this.handleTabChange = this.handleTabChange.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
        this.hideDeleteAlert = this.hideDeleteAlert.bind(this);
        this.deleteAlmacen = this.deleteAlmacen.bind(this);*/
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idAlmacen: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Eliminar almacén',
            text: '¿Desea eliminar el almacén seleccionado?',
            confirmButtonText: 'Eliminar',
            onConfirm:this.deleteAlmacen,
            showCancel: true
        });
    }

    hideDeleteAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idAlmacen: -1 });
        hideMessage();
    }

    deleteAlmacen = () => {
        console.log('borrar almacén');
        const { hideMessage } = this.props;
        this.setState({ idProdutcto: -1 });
        hideMessage();
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    render() {
        const { tab } = this.state;
      //  const { alerta } = this.props;

        return (

            <div className="col-md-12">
               { /* <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText={alerta.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alerta.title} text={alerta.text}
        onConfirm={this.deleteAlmacen} onCancel={this.hideDeleteAlert} />*/}
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <Card className="card-almacen">
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-1 col-sm-2" style={{paddingTop: "5px"}}>
                                        <Link className="btn-back" to={'/Almacenes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                                    </div>
                                    <div className="col-md-5 col-sm-10">
                                        <h4 style={{color:"#4F4F4F"}}>Almacén Celso</h4>
                                    </div>
                                    <div className="col-md-6 col-sm-12 text-right-md">                                        
                                        <Button className="btn-editar btn-detalles" ><i className="fas fa-edit"></i>Editar</Button>
                                        <Button className="btn-eliminar btn-detalles" onClick={()=>this.handleDeleteAlert()}><i className="far fa-trash-alt"></i>Eliminar</Button>
                                    </div>
                                </div>
                                <hr className="hr-almacen" />
                                <div className="body-generales" >
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin">Nombre del almacén</div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"> Almacén Celso</div>
                                        <div className="col-md-3 col-sm-12 input-margin">Almacenista</div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin  text-right-md">Carlos Macarena Martínez</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 input-margin">Dirección</div>
                                        <div className="col-md-10 col-sm-12 descripcion input-margin">Féliz Soria #22, Hermosillo, Sonora</div>                
                                    </div>                                   
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>


                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-css">
                            <CardHeader>
                                <Nav header>
                                    <NavItem>
                                        <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Productos</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Entradas</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 3 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(3)}>Salidas</NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <div className="col-md-12">
                                    {tab === 1 && <Productos />}
                                    {tab === 2 && <Entradas />}
                                    {tab === 3 && <Salidas />}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert
    })(DetallesAlmacen);
