import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiGetUsuarios = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'usuarios/?' + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiCreateUsuario = async (body) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + 'usuarios/';
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateUsuario = async (id, body) => {
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `usuarios/${id}/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetDetallesUsuario = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `usuarios/${id}/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiActivateUsuario = async (id) => {
    var request = await CreateRequest(PUT);
    let url = baseUrl + `usuarios/${id}/activar/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiDeleteUsuario = async (id) => {
    var request = await CreateRequest(PUT);
    let url = baseUrl + `usuarios/${id}/desactivar/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};
