import React, { Component } from 'react';
import { connect } from 'react-redux';

import { signIn } from '../../Reducers/auth';
import { fetchHideAlert } from '../../Reducers/general';

import SweetAlert from 'sweetalert-react';

import { Input, Button } from 'mdbreact';

import './login.css';

const initialState = {
  username: {
    value: '',
    valid: false,
    touched: false,
  },
  password: {
    value: '',
    valid: false,
    touched: false,
  },
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = initialState;
  }

  submit() {
    const { username, password } = this.state;
    if (username.valid && password.valid) {
      this.props.signIn(username.value, password.value);
    } else {
      this.setState({
        username: {
          ...this.state.username,
          touched: true,
        },
        password: {
          ...this.state.password,
          touched: true,
        }
      });
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: {
        value: value.toLowerCase().trim(),
        valid: this.inputValidation(name, value),
        touched: true,
      }
    });
  }

  inputValidation(name, value) {
    switch (name) {
      case 'username':
        // eslint-disable-next-line
        return RegExp(/^.{4,}$/).test(value);
      case 'password':
        return RegExp(/^.{6,40}$/).test(value);
      default:
        return false;
    }
  }

  render() {
    const { username, password } = this.state;
    const { alerta, resetAlert } = this.props;

    return (
      <div className="logindiv">
        <SweetAlert show={alerta.show} type={alerta.type} confirmButtonText="Aceptar" title={alerta.title}
          text={alerta.text} onConfirm={() => resetAlert()} />
        <div className="login">
          <form className="login-form" autoComplete="off">
            <p className="txt_titulo ">¡Bienvenido!</p>
            <p className="txt-greeting"> Para ingresar al sistema de cobranza Celso, por favor llena tu información de usuario</p>
            <div className="grey-text margin-login">
              <Input label="Usuario" type="text" name="username" id="user-name" value={username.value} onChange={this.handleChange} required tabIndex="1" />
              {!username.valid && username.touched && <p className="form-alert">*El usuario debe de ser al menos 4 carácteres</p>}
              <Input label="Contraseña" name="password" type="password" maxLength="40" value={password.value} onChange={this.handleChange} required tabIndex="2" minLength="6" />
              {!password.valid && password.touched && <p className="form-alert">*La contraseña debe de ser al menos 6 carácteres</p>}
            </div>
            <br />
            <div className="text-center py-4 ">
              <Button id="btn_login" className="btn_azul" onClick={this.submit} tabIndex="3">Iniciar Sesión</Button>
            </div>
          </form>
        </div>      
      </div>
    );
  }
}

export default connect((state) =>
  ({
    alerta: state.general.alerta,
  }), { signIn, resetAlert: fetchHideAlert })(Login);