import React, { Component } from 'react';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import EditControl from "./poligonos";
import { Card } from "mdbreact";

import '../../Css/zonas.css';

export default class EditControlExample extends Component {

    // see http://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw-event for leaflet-draw events doc

    _onEdited = (e) => {

        let numEdited = 0;
        e.layers.eachLayer((layer) => {
            numEdited += 1;
        });
        console.log(`_onEdited: edited ${numEdited} layers`, e);

        this._onChange();
    }

    _onCreated = (e) => {
        let type = e.layerType;
        let layer = e.layer;
        if (type === 'marker') {
            // Do marker specific actions
            console.log("_onCreated: marker created", e);
        }
        else {
            console.log("_onCreated: something else created:", type, e);
        }

        console.log(e);
        // Do whatever else you need to. (save to db; etc)

        this._onChange();
    }

    _onDeleted = (e) => {
        let numDeleted = 0;
        e.layers.eachLayer((layer) => {
            numDeleted += 1;
            console.log(layer);
        });
        console.log(`onDeleted: removed ${numDeleted} layers`, e);

        this._onChange();
    }

    _onMounted = (drawControl) => {
        console.log('_onMounted', drawControl);
    }

    _onEditStart = (e) => {
        console.log('_onEditStart', e);
    }

    _onEditStop = (e) => {
        console.log('_onEditStop', e);
    }

    _onDeleteStart = (e) => {
        console.log('_onDeleteStart', e);
    }

    _onDeleteStop = (e) => {
        console.log('_onDeleteStop', e);
    }

    render() {
        return (
            <Card className="card-body card-monitor">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <Map center={[29.0667, -110.9667]} zoom={11} zoomControl={false} id="mapaZonas">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                />
                                <FeatureGroup ref={(reactFGref) => { this._onFeatureGroupReady(reactFGref); }}>
                                    <EditControl
                                        position='topleft'
                                        onEdited={this._onEdited}
                                        onCreated={this._onCreated}
                                        onDeleted={this._onDeleted} ghj
                                        onMounted={this._onMounted}
                                        onEditStart={this._onEditStart}
                                        onEditStop={this._onEditStop}
                                        onDeleteStart={this._onDeleteStart}
                                        onDeleteStop={this._onDeleteStop}
                                        draw={{
                                            rectangle: false,
                                            circle: false,
                                            polyline: false,
                                            circlemarker: false,
                                            marker: false,
                                            polygon: {
                                                allowIntersection: false
                                            }
                                        }}
                                        edit={{
                                            allowIntersection: false
                                        }}
                                    />
                                </FeatureGroup>
                            </Map>

                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    _editableFG = null

    _onFeatureGroupReady = (reactFGref) => {

        if (reactFGref) {
            // populate the leaflet FeatureGroup with the geoJson layers

            let leafletGeoJSON = new L.GeoJSON(getGeoJson());
            let leafletFG = reactFGref.leafletElement;

            leafletGeoJSON.eachLayer((layer) => {

                leafletFG.addLayer(layer).bindTooltip('2', { permanent: false, opacity: 1 });
               

                /*  leafletFG.addLayer(layer);
    
                var labelIcon = L.divIcon({
                    iconSize: null,
                    html: '<div class="map-label "><div class="map-label-content">' + layer.feature.properties.name + '</div></div>'
                    // html:'<div class="map-label "><div class="map-label-content">'+layer.feature.properties.name+'</div><div class="map-label-arrow"></div></div>'
                });
                
                let polyCenter = layer.getBounds().getCenter();
                 let divIcon = L.divIcon({
                    className: "labelClass",
                    html: "textToDisplay"
                })
    
                L.marker(polyCenter, {icon:divIcon}).addTo(leafletFG);
                */
            });

            // store the ref for future access to content

            this._editableFG = reactFGref;
        }
    }

    _onChange = () => {

        // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API

        const { onChange } = this.props;

        if (!this._editableFG || !onChange) {
            return;
        }

        const geojsonData = this._editableFG.leafletElement.toGeoJSON();
        onChange(geojsonData);
    }
}

// data taken from the example in https://github.com/PaulLeCam/react-leaflet/issues/176

var onPolyClick = function(event){
    //callFancyboxIframe('flrs.html')
    var label = event.target.options.label;
    var content = event.target.options.popup;
    var otherStuff = event.target.options.otherStuff;
    alert("Clicked on polygon with label:" +label +" and content:" +content +". Also otherStuff set to:" +otherStuff);
};

function getGeoJson() {
    return {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "properties": {
                    "name": "prueba 1",
                    "id": "1"
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -110.92304994352165,
                                29.120455359885245
                            ],
                            [
                                -110.97688292618842,
                                29.0921588525827
                            ],
                            [
                                -110.98677063826474,
                                29.118057653170563
                            ],
                            [
                                -110.92304994352165,
                                29.120455359885245
                            ]
                        ]
                    ]
                }
            },
            {
                "type": "Feature",
                "properties": {
                    "name": "prueba 2",
                    "id": "2"
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -111.02431297302248,
                                29.141141801886103
                            ],
                            [
                                -111.02396965026857,
                                29.134319625920437
                            ],
                            [
                                -111.02345466613771,
                                29.130720933108325
                            ],
                            [
                                -111.02362632751466,
                                29.12269839367387
                            ],
                            [
                                -111.01126670837404,
                                29.124947766938487
                            ],
                            [
                                -111.02431297302248,
                                29.141141801886103
                            ]
                        ]
                    ]
                }
            }
        ]
    }

}