import React, { Component } from 'react';
import { Input, Card, CardBody, CardHeader, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { baseUrl } from '../../Api/general';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchUpdateSupervisor } from '../../Reducers/supervisores';

class EditarSupervisor extends Component {
    constructor(props) {
        super(props);
        const { supervisor } = this.props;
  
        this.state = {
            image: [],
            perfilImg: null,
            imageValid: true,
            nombre: supervisor.nombre || '',
            apellidoP: supervisor.apellidoP || '',
            apellidoM: supervisor.apellidoM || '',
            telefono: {
                value: supervisor.telefono || '',
                valid: true,
            },
            domicilio: {
                value: supervisor.direccion || '',
                valid: true,
            },
            correo: {
                value: supervisor.email || '',
                valid: true,
            },
            usuario: {
                value: supervisor.username || '',
                valid: true
            },
            contrasena: {
                value: '',
                valid: true,
            },          
            repetirContrasena: '',           
            loadUrl: true,
        }
    }

    onImageChange = (event) => {
        event.persist();
        const { files } = event.target;

        if (files && files[0]) {
            if (files[0].size > 5000000) {
                NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
                this.setState({ perfilImg: files[0], imageValid: false });
            }
            else {
                this.setState({ perfilImg: files[0], imageValid: true });
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ image: [e.target.result], loadUrl: false })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    handleChange = (event) => {
        const { supervisor } = this.props;

        const { id, value } = event.target;
        switch (id) {
            case 'usuario':
                let filteredUsername = value.replace(/[^a-zA-z._0-9]+/g, '');
                this.setState({
                    [id]: {
                        value: filteredUsername.toLowerCase().trim(),
                        valid: RegExp(/^(?=.{4,}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+[a-zA-Z0-9]+$/).test(filteredUsername),
                    }
                });
                break;
            case 'correo':
                let filteredEmail = value.replace(/[^a-zA-z._0-9@-]+/g, '').toLowerCase().trim();
                this.setState({
                    [id]: {
                        value: filteredEmail,
                        valid: filteredEmail === '' && !supervisor.email ? true : RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(filteredEmail),
                    }
                });
                break;
            case 'contrasena':
                this.setState({
                    [id]: {
                        value: value,
                        valid: value === '' ? true : RegExp(/^.{6,}$/).test(value),
                    }
                });
                break;
            case 'telefono':
                let filteredPhone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    [id]: {
                        value: filteredPhone,
                        valid: filteredPhone === '' && !supervisor.telefono ? true : RegExp(/^.{10,}$/).test(filteredPhone),
                    }
                });
                break;
            case 'domicilio':
                this.setState({
                    [id]: {
                        value: value,
                        valid: value === '' && !supervisor.direccion ? true : RegExp(/.*[^ ]{2,}.*/).test(value),
                    }
                });
                break;
            default:
                this.setState({ [id]: value });
                break;
        }
    }

    submit = (e) => {
        e.preventDefault();
        const { update, supervisor, vista } = this.props;
        const { imageValid, loadUrl, perfilImg, nombre, apellidoP, apellidoM, telefono, domicilio, correo,
            usuario, contrasena, repetirContrasena } = this.state;

        if (contrasena.value !== repetirContrasena) {
            NotificationManager.warning('Las contraseñas ingresadas no coinciden', 'Error en contraseña');
        }
        else if (!telefono.valid || !domicilio.valid || !correo.valid || !usuario.valid || !contrasena.valid) {
            NotificationManager.warning('La información del formulario contiene errores.', 'Información con errores');
        }
        else if (!imageValid) {
            NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
          }
        else {
            const datos = {};
            if (nombre !== supervisor.nombre) {
                datos.nombre = nombre;
            }
            if (apellidoP !== supervisor.apellidoP) {
                datos.apellidoP = apellidoP;
            }
            if (apellidoM !== supervisor.apellidoM) {
                datos.apellidoM = apellidoM;
            }
            if (usuario.value !== supervisor.username) {
                datos.username = usuario.value;
            }
            if (contrasena.value !== '') {
                datos.password = contrasena.value;
            }
            if (telefono.value !== supervisor.telefono && telefono.value !== '') {
                datos.telefono = telefono.value;
            }
            if (domicilio.value !== supervisor.direccion && domicilio.value.trim() !== '') {
                datos.direccion = domicilio.value;
            }
            if (correo.value !== supervisor.email && correo.value !== '') {
                datos.email = correo.value;
            }
            if (perfilImg) {
                datos.perfilImg = perfilImg;
            }
            if (!loadUrl && !perfilImg) {
                NotificationManager.warning('La imagen de perfil es obligatoria.', 'Campos obligatorios');
            }
            else if (Object.entries(datos).length === 0 && datos.constructor === Object && !perfilImg) {
                NotificationManager.warning('La información del supervisor no ha sido modificada.', 'Supervisor no editado');
            }
            else {
                update(supervisor.id, datos, vista);
            }
        }
    }

    clearFileInput = () => {
        this.fileInput.value = "";
        this.setState({ image: [], loadUrl: false, perfilImg: null });
    }

    renderImage = () => {
        const { image, loadUrl } = this.state;
        const { supervisor } = this.props;
        let imageUrl = null;

        if (Object.entries(supervisor).length === 0 && supervisor.constructor === Object) {
            /*console.log('usuario sin imagen');*/
        }
        else if (supervisor.imagenes.length > 0) {
            imageUrl = baseUrl + supervisor.imagenes[supervisor.imagenes.length - 1].url;
        }
        else {
            /*console.log('usuario sin imagen');*/
        }

        if (typeof image !== 'undefined' && image.length > 0) {
            return (
                <div className="container-img">
                    <img id="thumb_img" src={image} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else if (imageUrl && loadUrl) {
            return (
                <div className="container-img">
                    <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
    }

    render() {

        const { nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena, repetirContrasena } = this.state;
        const { fnCancelar } = this.props;

        return (
            <Card className="card-css">
                <CardHeader>

                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <form autoComplete="off" id="frm_supervisor" onSubmit={this.submit}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5>Información de supervisor</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Nombre(s)" value={nombre} type="text" name="nombre" onChange={this.handleChange} id="nombre" required pattern=".*[^ ].*" />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Primer apellido" value={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} id="apellidoP" required pattern=".*[^ ].*" />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Segundo apellido" value={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} id="apellidoM" required pattern=".*[^ ].*" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Teléfono" value={telefono.value} type="text" name="telefono" onChange={this.handleChange} id="telefono" minLength="10" />
                                    {!telefono.valid && <p className="form-alert">*El teléfono no puede estar vacío y tiene que ser un número telefónico válido</p>}
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Domicilio" value={domicilio.value} type="text" name="domicilio" onChange={this.handleChange} id="domicilio" minLength="2" />
                                    {!domicilio.valid && <p className="form-alert">*La dirección no puede estar vacía y tiene que ser de 2 caracteres mínimo</p>}
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Correo electrónico" value={correo.value} type="email" name="correo" onChange={this.handleChange} id="correo" />
                                    {!correo.valid && <p className="form-alert">*Formato de correo inválido</p>}
                                    {!correo.valid && <p className="form-alert">*El correo no puede estar vacío</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Usuario" value={usuario.value} type="text" name="usuario" onChange={this.handleChange} id="usuario" required pattern=".*[^ ].*" minLength="4" />
                                    {!usuario.valid && <p className="form-alert">*Formato de usuario inválido</p>}
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Contraseña" value={contrasena.value} type="password" name="contrasena" onChange={this.handleChange} id="contrasena" minLength="6" />
                                    {!contrasena.valid && <p className="form-alert">*La contraseña debe de contener mínimo 6 caracteres</p>}
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Repetir contraseña" value={repetirContrasena} type="password" name="repetirContrasena" onChange={this.handleChange} id="repetirContrasena" minLength="6" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-12 col-sm-12 ">
                                    <p className="div-imagen">Elegir imagen de perfil:</p>
                                    <div className="file-field">
                                        <div className="btn btn-gris">
                                            <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="group_image" ref={ref => this.fileInput = ref} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.renderImage()
                                    }
                                </div>
                            </div>
                            <br />
                            <div className="text-center">
                                <button type="submit" className="btn btn-crear">Actualizar </button>
                                <button type="button" className="btn btn-danger btn-crear" onClick={(e) => fnCancelar(e)}>Cancelar</button>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default connect(state => ({
    supervisor: state.usuarios.usuarioSeleccionado
}), {
        update: fetchUpdateSupervisor
    })(EditarSupervisor);