import { baseUrl, GET, POST, PUT, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiAddCliente = async (body) => {
    var request = await CreateRequestImagen(POST, body);
    let url = baseUrl + `usuarios/cliente/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateCliente = async (id, body) => {
    var request = await CreateRequestImagen(PUT, body);
    let url = baseUrl + `usuarios/${id}/cliente/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetCobradorCliente = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `usuarios/${id}/cliente/cobrador`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateClienteStatus = async (id, body) => {
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `cobrador/clientes/${id}/estado/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateCobradorCliente = async (body) => {
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `cobrador/clientes/`;
    return fetch(url, request).then(res => res.status === 204 ? { status: res.status } : res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetPagosCliente = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `cliente/ventas/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};