import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { connect } from 'react-redux';
import { fetchGetClientesCobrador } from '../../Reducers/cobradores';
import { iconHome } from '../Monitor/Iconos';
import { Alert } from 'mdbreact';
import '../../Css/mapaArea.css'

let position;
let zoomMap;

class AreaCobrador extends Component {
  constructor() {
    super();
    this.state = {
      lat: 29.0667,
      lng: -110.9667,
      zoom: 11,
      showNoClientAlert: false
      /* latlngList: [
         [29.069081, -111.010939], [29.059117, -111.007135], [29.062476, -110.995631], [29.063290, -110.990584], [29.069291, -110.990970], [29.067384, -111.005543]
       ]
       latlngList: [[29.069081, -111.010939], [29.059117, -111.007135]]*/
    };

  }

  componentDidMount() {
    this.map = this.mapInstance.leafletElement;
    const { cobrador, getClientes } = this.props;
    let obj = {
      cobrador: cobrador,
    }
    getClientes(obj);
  }

  componentDidUpdate() {
    const { clientes } = this.props;
    const { showNoClientAlert } = this.state;
    let positionsList = [];
    if (clientes.total > 1) {
      clientes.rows.map(cliente => {
        positionsList.push([cliente.infoCliente.lat, cliente.infoCliente.lng])
      });
      var polygon = L.polygon(positionsList, { color: 'rgba(133, 201, 239, 0.35)', fillColor: 'rgb(133, 201, 239)', fillOpacity: 0.35 }).addTo(this.map);
      this.map.flyToBounds(polygon.getBounds());
      if (showNoClientAlert) {
        this.setState({ showNoClientAlert: false });
      }
    }
    else if (clientes.total === 1) {
      const { infoCliente } = clientes.rows[0];
      position = [infoCliente.lat, infoCliente.lng];
      L.marker(position, { icon: iconHome }).addTo(this.map);
      this.map.flyTo(position, 16);
      if (showNoClientAlert) {
        this.setState({ showNoClientAlert: false });
      }
    }
    else {
      if (!showNoClientAlert) {
        this.setState({ showNoClientAlert: true });
      }
    }
  }

  /*resetCenter() {
    const { lat, lng, zoom } = this.state;
    position = [lat, lng];
    this.map.flyTo(position, zoom)
  }

  findBounds() {
    const { latlngList } = this.state;
    var polygon = L.polygon(latlngList, { color: 'rgba(133, 201, 239, 0.35)', fillColor: 'rgb(133, 201, 239)', fillOpacity: 0.35 }).addTo(this.map);
    this.map.flyToBounds(polygon.getBounds());
  }*/

  render() {
    const { lat, lng, zoom, showNoClientAlert } = this.state;

    position = [lat, lng];
    zoomMap = zoom;

    return (
      <>
        {showNoClientAlert && <Alert color="warning" > El cobrador seleccionado no tiene clientes asignados.</Alert>}

        <Map center={position} zoom={zoomMap} id="mapid" ref={e => { this.mapInstance = e }}>
          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </Map>
      </>
    );
  }
}

export default connect(state => ({
  clientes: state.cobradores.clientesCobrador,
}), {
    getClientes: fetchGetClientesCobrador
  })(AreaCobrador);