import React, { Component } from 'react';
import { Input, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchAddUsuario } from '../../Reducers/usuarios';

class AltaAdministrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
      perfilImg: null,
      nombre: '',
      apellidoP: '',
      apellidoM: '',
      telefono: {
        value: '',
        valid: true,
      },
      domicilio: {
        value: '',
        valid: true,
      },
      correo: {
        value: '',
        valid: true,
      },
      usuario: {
        value: '',
        valid: true
      },
      contrasena: {
        value: '',
        valid: true,
      },
      repetirContrasena: '',
    }
  }

  onImageChange = (event) => {
    event.persist();
    const { files, id } = event.target;

    if (files && files[0]) {
      this.setState({ perfilImg: files[0] });
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: [e.target.result] })
      };
      reader.readAsDataURL(files[0]);
    }
  }

  handleChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      case 'usuario':
        let filteredUsername = value.replace(/[^a-zA-z._0-9]+/g, '');
        this.setState({
          [id]: {
            value: filteredUsername.toLowerCase().trim(),
            valid: RegExp(/^(?=.{4,}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+[a-zA-Z0-9]+$/).test(filteredUsername),
          }
        });
        break;
      case 'correo':
        let filteredEmail = value.replace(/[^a-zA-z._0-9@-]+/g, '').toLowerCase().trim();
        this.setState({
          [id]: {
            value: filteredEmail,
            valid: filteredEmail === '' ? true : RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(filteredEmail),
          }
        });
        break;
      case 'contrasena':
        this.setState({
          [id]: {
            value: value,
            valid: RegExp(/^.{6,}$/).test(value),
          }
        });
        break;
      case 'telefono':
        let filteredPhone = value.replace(/[^0-9]+/g, '');
        this.setState({
          [id]: {
            value: filteredPhone,
            valid: filteredPhone === '' ? true : RegExp(/^.{10,}$/).test(filteredPhone)
          }
        });
        break;
      case 'domicilio':
        this.setState({
          [id]: {
            value: value,
            valid: value === '' ? true : RegExp(/.*[^ ]{2,}.*/).test(value),
          }
        });
        break;
      default:
        this.setState({ [id]: value });
        break;
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { save } = this.props;
    const { perfilImg, nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena, repetirContrasena } = this.state;

    if (contrasena.value !== repetirContrasena) {
      NotificationManager.warning('Las contraseñas ingresadas no coinciden', 'Error en contraseña');
    }
    /* else if (!perfilImg) {
       NotificationManager.warning('La imagen de perfil es obligatoria.', 'Imagen de perfil');
     }*/
    else if (!telefono.valid || !domicilio.valid || !correo.valid || !usuario.valid || !contrasena.valid) {
      NotificationManager.warning('La información del formulario contiene errores.', 'Información con errores');
    }
    else {
      const datos = {
        'username': usuario.value,
        'password': contrasena.value,
        'nombre': nombre,
        'apellidoP': apellidoP,
        'apellidoM': apellidoM,
        /*'perfilImg': perfilImg,*/
        'tipo': 2
      }
      if (telefono.value !== '') {
        datos.telefono = telefono.value;
      }
      if (domicilio.value.trim() !== '') {
        datos.direccion = domicilio.value;
      }
      if (correo.value !== '') {
        datos.email = correo.value;
      }
      save(datos);
    }
  }

  clearFileInput = () => {
    this.fileInput.value = "";
    this.setState({ image: [], perfilImg: null });
  }

  render() {

    const { image, nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena, repetirContrasena } = this.state;

    return (

      <div className="col-md-12">
        <form autoComplete="off" id="frm_admin" onSubmit={(e) => this.submit(e)}>
          <div className="row">
            <div className="col-md-12">
              <h5>Información de administrador</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Nombre(s)" value={nombre} type="text" name="nombre" onChange={this.handleChange} id="nombre" required pattern=".*[^ ].*" minLength="2" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Primer apellido" value={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} id="apellidoP" required pattern=".*[^ ].*" minLength="2" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Segundo apellido" value={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} id="apellidoM" required pattern=".*[^ ].*" minLength="2" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Teléfono" value={telefono.value} type="text" name="telefono" onChange={this.handleChange} id="telefono" minLength="10" />
              {!telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Domicilio" value={domicilio.value} type="text" name="domicilio" onChange={this.handleChange} id="domicilio" minLength="2" />
              {!domicilio.valid && <p className="form-alert">*La dirección tiene que ser de 2 caracteres mínimo</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Correo electrónico" value={correo.value} type="email" name="correo" onChange={this.handleChange} id="correo" />
              {!correo.valid && <p className="form-alert">*Formato de correo inválido</p>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Usuario" value={usuario.value} type="text" name="usuario" onChange={this.handleChange} id="usuario" required pattern=".*[^ ].*" minLength="4" />
              {!usuario.valid && <p className="form-alert">*Formato de usuario inválido</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Contraseña" value={contrasena.value} type="password" name="contrasena" onChange={this.handleChange} id="contrasena" required pattern=".*[^ ].*" minLength="6" />
              {!contrasena.valid && <p className="form-alert">*La contraseña debe de contener mínimo 6 caracteres</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Repetir contraseña" value={repetirContrasena} type="password" name="repetirContrasena" onChange={this.handleChange} id="repetirContrasena" required pattern=".*[^ ].*" minLength="6" />
            </div>
          </div>
          <br />
          {/*<div className="row">
            <div className="col-md-12 col-12 col-sm-12 ">
              <p className="div-imagen">Elegir imagen de perfil:</p>
              <div className="file-field">
                <div className="btn btn-gris">
                  <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="group_image" ref={ref => this.fileInput = ref} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {
                typeof image !== 'undefined' && image.length > 0 ?
                  <div className="container-img">
                    <img id="thumb_img" src={image} style={{ maxHeight: "130px" }} alt='' />
                    <button className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
                  </div>
                  :
                  <img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />
              }
            </div>
            </div>*/}

          <div className="text-center">
            <button type="submit" className="btn btn-crear" >Crear administrador</button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(state => ({}), {
  save: fetchAddUsuario
})(AltaAdministrador);