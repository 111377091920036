import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen,  makeUrlGET} from './general';

export const apiAddVendedor = async (body) =>{
    var request = await CreateRequestImagen(POST, body);
    let url = baseUrl + `usuarios/vendedor/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiUpdateVendedor = async (id, body) =>{
    var request = await CreateRequestImagen(PUT, body);
    let url = baseUrl + `usuarios/${id}/vendedor/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};