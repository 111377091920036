import React from 'react';
import Main from '../Components/Shared/Main';
import Sidebar from '../Components/Shared/Sidebar';
import Header from '../Components/Shared/Header';

const MainContent = () =>
    <div id="wrapper" className="toggled">
        <Sidebar />
        <div id="page-content-wrapper" >
            <Header />
            <Main />
        </div>
    </div>;

export default MainContent;