import {
    apiGetUsuarios,
    apiCreateUsuario,
    apiUpdateUsuario,
    apiGetDetallesUsuario,
    apiActivateUsuario,
    apiDeleteUsuario,
} from '../Api/apiUsuarios';
import { NotificationManager } from 'react-notifications';
import { handleLoading, verFormEd } from './general';
import { updateStateMonitor } from './monitor';

const initialState = {
    usuarios: {
        rows: [],
        total: 0,
    },
    usuarioSeleccionado: {},
};

const GET_USUARIOS = "GET_USUARIOS";
const ADD_USUARIO = "ADD_USUARIO";
const UPDATE_USUARIO_LISTA = "UPDATE_USUARIO_LISTA";
const UPDATE_USUARIO_DETALLES = "UPDATE_USUARIO_DETALLES";
const SELECCIONAR_USUARIO = "SELECCIONAR_USUARIO";

const getUsuarios = usuarios => ({ type: GET_USUARIOS, payload: usuarios });
export const addUsuario = usuario => ({ type: ADD_USUARIO, payload: usuario });
export const updateUsuarioLista = usuario => ({ type: UPDATE_USUARIO_LISTA, payload: usuario });
export const updateUsuarioDetalles = usuario => ({ type: UPDATE_USUARIO_DETALLES, payload: usuario });
const seleccionarUsuario = id => ({ type: SELECCIONAR_USUARIO, payload: id });

export const fetchGetUsuarios = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetUsuarios(obj)
            .then((usuarios) => {
                dispatch(getUsuarios(usuarios));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetUsuarios", error);
                dispatch(getUsuarios(initialState.usuarios));
            });
    };
}

export const fetchAddUsuario = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiCreateUsuario(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Usuario guardado correctamente', '¡Éxito!');
                    if (body.tipo === 2) {
                        window.location.href = `/DetallesAdministrador/${res.body.id}`;
                    }
                    else if (body.tipo === 5) {
                        window.location.href = `/DetallesAlmacenista/${res.body.id}`;
                    }
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddUsuario", error);
            });
    };
}

export const fetchUpdateUsuario = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateUsuario(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    dispatch(verFormEd());
                    NotificationManager.success('Usuario guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeUsuario", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchDeleteUsuario = (id, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiDeleteUsuario(id)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else if (vista === 'monitor') {
                        dispatch(updateStateMonitor(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    NotificationManager.success('Usuario desactivado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorDelUsuario", error);
            });
    };
}

export const fetchActivateUsuario = (id, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiActivateUsuario(id)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else if (vista === 'monitor') {
                        dispatch(updateStateMonitor(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    NotificationManager.success('Usuario activado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorActivarUsuario", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchGetSelected = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetDetallesUsuario(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateUsuarioDetalles(res.body));
                }
                else {
                    dispatch(updateUsuarioDetalles(initialState.usuarioSeleccionado));
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorActivarUsuario", error);
            });
    };
}

export const fetchSeleccionarUsuario = (id) => {
    return dispatch => {
        if (id !== -1) {
            dispatch(seleccionarUsuario(id));
        }
        else {
            dispatch(updateUsuarioDetalles(initialState.usuarioSeleccionado));
        }
    }
}

export const fetchResetUsers = () => {
    return dispatch => {
        dispatch(getUsuarios(initialState.usuarios));
    }
}

const usuariosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USUARIOS:
            return { ...state, usuarios: action.payload };
        case ADD_USUARIO:
            return { ...state, usuarios: { ...state.usuarios, total: state.usuarios.total + 1, rows: [...state.usuarios.rows, action.payload] } };
        case UPDATE_USUARIO_LISTA:
            return { ...state, usuarios: { total: state.usuarios.total, rows: state.usuarios.rows.map(usuario => usuario.id === action.payload.id ? action.payload : usuario) } };
        case SELECCIONAR_USUARIO:
            return { ...state, usuarioSeleccionado: state.usuarios.rows.find(elem => elem.id === action.payload) };
        case UPDATE_USUARIO_DETALLES:
            return { ...state, usuarioSeleccionado: action.payload };
        default:
            return { ...state };
    }
};

export default usuariosReducer;