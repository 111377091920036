import React, { Component } from 'react';
import { Table, TableBody, TableHead, Icon, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import Paginacion from '../Shared/pagination';
import { connect } from 'react-redux';
import { fetchGetClientesCobrador } from '../../Reducers/cobradores';

import '../../Css/clientes.css';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    selectedOption: null
};

class ClientesAsignados extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { cobrador, getClientes } = this.props;
        const { size } = this.state;
        let obj = {
            cobrador: cobrador,
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getClientes(obj);
    }


    buscar = (texto) => {
        const { size } = this.state;
        const { cobrador, getClientes } = this.props;

        this.setState({ activePage: 1, buscar: texto.trim() });

        let obj = {
            cobrador: cobrador,
            limit: size,
            offset: '0',
            orderBy: 'desc',
        }
        getClientes(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, buscar } = this.state;
        const { cobrador, getClientes } = this.props;
        let pagina;

        let obj = {
            orderBy: 'desc',
            cobrador: cobrador,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getClientes(obj);
    }

    render() {
        const { clientes } = this.props;
        const { activePage, size } = this.state;

        return (
            <div>
                <br />
                <Table className="tbl-css tbl-clientes" responsive>
                    <TableHead>
                        <tr>
                            <th>Nombre</th>
                            <th>Dirección</th>
                            <th>Teléfono</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {clientes.total > 0 ?
                            clientes.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.nombre + ' ' + v.apellidoP + ' ' + v.apellidoM}</td>
                                    <td>{v.direccion}</td>
                                    <td>{v.telefono}</td>
                                    <td>
                                        <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCliente/${v.id}`}>Ver más</Link>
                                        <Button className="btn-opciones" color="primary" size="sm" onClick={() => window.open("http://www.google.com/maps/place/" + v.infoCliente.lat + "," + v.infoCliente.lng, "_blank")}><i className="fas fa-map-marker-alt"></i></Button>
                                    </td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="4">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={clientes.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    clientes: state.cobradores.clientesCobrador,
}), {
        getClientes: fetchGetClientesCobrador
    })(ClientesAsignados);

