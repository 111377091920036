import React, { Component } from 'react';
import Info from './info';
import Editar from './editar';

import { connect } from 'react-redux';
import { fetchEditViewPerfil } from '../../Reducers/auth';

class DetallesUsuario extends Component {

    render() {
        const { editar, fneditar } = this.props;

        if (editar) {
            return (
                <Editar fnCancelar={() => fneditar()} />
            )
        }
        else {
            return (
                <Info fnEditar={() => fneditar()} />
            )
        }
    }
}

export default connect(state => ({
    editar: state.auth.editViewProfile,
}), {
        fneditar: fetchEditViewPerfil,
    })(DetallesUsuario);
