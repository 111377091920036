import React, { Component } from 'react';
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { iconHome } from '../Monitor/Iconos';
import { Alert } from 'mdbreact';
import { connect } from 'react-redux';
import '../../Css/mapaArea.css'
import { fetchGetClientesCobrador } from '../../Reducers/cobradores';

let position;
let zoomMap;

class CleintesMapaCobrador extends Component {
    constructor() {
        super();
        this.state = {
            lat: 29.0667,
            lng: -110.9667,
            zoom: 11,
            showNoClientAlert: false
        };
    }

    componentDidMount() {
        this.map = this.mapInstance.leafletElement;
        const { cobrador, getClientes } = this.props;
        let obj = {
            cobrador: cobrador,
        }
        getClientes(obj);
    }

    componentDidUpdate() {
        const { clientes } = this.props;
        const { showNoClientAlert } = this.state;
        let positionsList = [];
        if (clientes.total > 1) {
            clientes.rows.map(cliente => {
                positionsList.push([cliente.infoCliente.lat, cliente.infoCliente.lng])
            });
            this.map.fitBounds(positionsList);
            if (showNoClientAlert) {
                this.setState({ showNoClientAlert: false });
            }
        }
        else if (clientes.total === 1) {
            const { infoCliente } = clientes.rows[0];
            position = [infoCliente.lat, infoCliente.lng];
            this.map.flyTo(position, 16);
            if (showNoClientAlert) {
                this.setState({ showNoClientAlert: false });
            }
        }
        else {
            if (!showNoClientAlert) {
                this.setState({ showNoClientAlert: true });
            }
        }
    }

    render() {
        const { lat, lng, zoom, showNoClientAlert } = this.state;
        const { clientes } = this.props;

        position = [lat, lng];
        zoomMap = zoom;

        return (
            <>
                {showNoClientAlert && <Alert color="warning" > El cobrador seleccionado no tiene clientes asignados.</Alert>}

                <Map center={position} zoom={zoomMap} id="mapid" ref={e => { this.mapInstance = e }}>
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {clientes.total > 0 ?
                        clientes.rows.map(cliente =>
                            <Marker key={cliente.id} position={[cliente.infoCliente.lat, cliente.infoCliente.lng]} icon={iconHome} >
                                <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                                    <span> {cliente.nombre + ' ' + cliente.apellidoP + ' ' + cliente.apellidoM} </span>
                                </Tooltip>
                            </Marker>
                        )
                        :
                        ''
                    }
                </Map>
            </>
        );
    }
}

export default connect(state => ({
    clientes: state.cobradores.clientesCobrador,
}), {
        getClientes: fetchGetClientesCobrador
    })(CleintesMapaCobrador);