import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaVendedores from './lista';
import AltaVendedor from './alta';
import EditarVendedor from './editar';

import { connect } from 'react-redux';
import { fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditViewVendedor } from '../../Reducers/vendedores';


class Vendedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
        }
    }

    componentWillUnmount() {
        const { seleccionar, editarVendedor, editar } = this.props;
        if (editar) {
            seleccionar(-1);
            editarVendedor();
        }
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    editarVendedor = (id) => {
        const { seleccionar, editarVendedor } = this.props;
        seleccionar(id);
        editarVendedor();
    }

    cancelarEditar = (e) => {
        e.preventDefault();
        const { seleccionar, editarVendedor } = this.props;
        seleccionar(-1);
        editarVendedor();
    }

    render() {
        const { tab } = this.state;
        const { editar } = this.props;

        if (editar) {
            return (
                <EditarVendedor fnCancelar={this.cancelarEditar} vista='lista' />
            )
        }
        else {
            return (
                <Card className="card-css">
                    <CardHeader>
                        <Nav header>
                            <NavItem>
                                <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de vendedores</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nuevo vendedor</NavLink>
                            </NavItem>
                        </Nav>
                    </CardHeader>
                    <CardBody>
                        <div className="col-md-12">
                            <div className="row">
                                {tab === 1 && <ListaVendedores fnEditar={this.editarVendedor} />}
                                {tab === 2 && <AltaVendedor />}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )
        }
    }
}

export default connect(state => ({
    editar: state.vendedores.editViewVendedor,
}), {
        seleccionar: fetchSeleccionarUsuario,
        editarVendedor: fetchEditViewVendedor,
    })(Vendedores);