import {
    apiAddCliente,
    apiUpdateCliente,
    apiGetCobradorCliente,
    apiUpdateClienteStatus,
    apiUpdateCobradorCliente,
    apiGetPagosCliente
} from '../Api/apiClientes';

import { addUsuario, updateUsuarioLista, updateUsuarioDetalles } from './usuarios';

import { NotificationManager } from 'react-notifications';

import { handleLoading } from './general';

const SHOW_CLIENTE_FORM = "SHOW_CLIENTE_FORM";
const GET_COBRADOR_ASIGNADO = "GET_COBRADOR_ASIGNADO";
const SHOW_MODAL_ESTATUS = "SHOW_MODAL_ESTATUS";
const SHOW_MODAL_COBRADOR_CLIENTE = "SHOW_MODAL_COBRADOR_CLIENTE";
const HANDLE_BOOL_ASIGNADO = "HANDLE_BOOL_ASIGNADO";
const GET_PAGOS_CLIENTE = "GET_PAGOS_CLIENTE";

const verFormEdClientes = () => ({ type: SHOW_CLIENTE_FORM });
const getCobradorAsignado = cobrador => ({ type: GET_COBRADOR_ASIGNADO, payload: cobrador });
const showModalEstatus = () => ({ type: SHOW_MODAL_ESTATUS });
const showModalCobradorCliente = () => ({ type: SHOW_MODAL_COBRADOR_CLIENTE });
const handleBoolAsignado = asignado => ({ type: HANDLE_BOOL_ASIGNADO, payload: asignado });
const getPagosCliente = pago => ({ type: GET_PAGOS_CLIENTE, payload: pago });

const initialState = {
    editViewCliente: false,
    cobradorAsignado: {},
    showModalEstatus: false,
    showModalCC: false,
    isCobradorAsignado: false,
    pagosCliente: {
        total: 0,
        rows: []
    }
};

export const fetchAddCliente = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddCliente(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Cliente guardado correctamente', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/DetallesCliente/${res.body.id}`;
                    }, 3000);
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddCliente", error);
            });
    };
}

export const fetchUpdateCliente = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateCliente(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    dispatch(verFormEdClientes());
                    NotificationManager.success('Cliente guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeCliente", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchEditViewCliente = () => {
    return dispatch => {
        dispatch(verFormEdClientes());
    }
}

export const fetchGetCobradorAsignado = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetCobradorCliente(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getCobradorAsignado(res.body));
                    dispatch(handleBoolAsignado(true));
                }
                else if (res.status === 404 && res.body.message === "cobrador no fue encontrado") {
                    dispatch(handleBoolAsignado(false));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorGetCobradorAsignado", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchResetCobradorAsignado = () => {
    return dispatch => {
        dispatch(getCobradorAsignado(initialState.cobradorAsignado));
    }
}

export const fetchChangeStatusCliente = (id, body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateClienteStatus(id, body)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(showModalEstatus());
                    dispatch(updateUsuarioDetalles(res.body));
                    NotificationManager.success('Estatus cambiado correctamente.', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorChangeStatusCliente", error);
            });
    };
}

export const fetchShowModalEstatus = () => {
    return dispatch => {
        dispatch(showModalEstatus());
    }
}

export const fetchShowModalCobradorCliente = () => {
    return dispatch => {
        dispatch(showModalCobradorCliente());
    }
}

export const fetchChangeCobradorCliente = (body, cobrador) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateCobradorCliente(body)
            .then((res) => {
                if (res.status === 204) {
                    dispatch(showModalCobradorCliente());
                    dispatch(getCobradorAsignado(cobrador));
                    NotificationManager.success('Cobrador cambiado correctamente.', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorChangeCobradorCliente", error);
            });
    };
}

export const fetchGetPagosCliente = (objs) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetPagosCliente(objs)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getPagosCliente(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorGetPagosCliente", error);
                dispatch(getPagosCliente(initialState.pagosCliente));
                dispatch(handleLoading());
            });
    };
}

const clientesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CLIENTE_FORM:
            return { ...state, editViewCliente: !state.editViewCliente };
        case GET_COBRADOR_ASIGNADO:
            return { ...state, cobradorAsignado: action.payload };
        case SHOW_MODAL_ESTATUS:
            return { ...state, showModalEstatus: !state.showModalEstatus };
        case SHOW_MODAL_COBRADOR_CLIENTE:
            return { ...state, showModalCC: !state.showModalCC };
        case HANDLE_BOOL_ASIGNADO:
            return { ...state, isCobradorAsignado: action.payload };
        case GET_PAGOS_CLIENTE:
            return { ...state, pagosCliente: action.payload };
        default:
            return { ...state };
    }
};

export default clientesReducer;