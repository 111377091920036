import React, { Component } from 'react';
import { ListGroup, ListGroupItem } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import jwtDecode from "jwt-decode";

const links = [
    { link: "/Monitor", name: "Monitor", icon: "fas fa-home", permissions: [1, 2] },
    { link: "/Cobradores", name: "Cobradores", icon: "fas fa-motorcycle", permissions: [1, 2] },
    { link: "/Supervisores", name: "Supervisores", icon: "fas fa-user-clock", permissions: [1, 2] },
    { link: "/Vendedores", name: "Vendedores", icon: "fas fa-user-tag", permissions: [1, 2] },
    { link: "/Administradores", name: "Admin", icon: "fas fa-user-cog", permissions: [1, 2] },
    { link: "/Productos", name: "Productos", icon: "fas fa-boxes", permissions: [1, 2] },
    { link: "/Clientes", name: "Clientes", icon: "far fa-id-card", permissions: [1, 2, 3] },
    { link: "/Ventas", name: "Ventas", icon: "fas fa-file-invoice-dollar", permissions: [1, 2, 3] },
    { link: "/Solicitudes", name: "Solicitudes", icon: "fas fa-user-plus", permissions: [1, 2] },
    { link: "/Notificaciones", name: "Notificaciones", icon: "fas fa-exclamation-circle", permissions: [1, 2] },
];

class SideNav extends Component {
    toggleSidenav() {
        document.getElementById('wrapper').classList.toggle('toggled');
    }
    render() {
        const decoded = jwtDecode(localStorage.token);
        return (
            <div className="sidebar-fixed position-fixed">
                <ListGroup className="list-group-flush">
                    {
                        links.map(item => {
                            return (
                                item.permissions.includes(decoded.tipo)
                                    ?
                                    <NavLink to={item.link} activeClassName="activeClass" key={item.link}>
                                        <ListGroupItem className="item-sidebar">
                                            <i className={`${item.icon} mr-3`}></i>
                                            <span className="texto-hide-side">{item.name}</span>
                                        </ListGroupItem>
                                    </NavLink>
                                    :
                                    ''
                            )
                        })
                    }
                </ListGroup>
            </div>
        );
    }
}

export default SideNav;

/*class SideNav extends Component {

                    toggleSidenav() {
                document.getElementById('wrapper').classList.toggle('toggled');
            }

    render() {
        return (
            <div className="sidebar-fixed position-fixed">
                    <ListGroup className="list-group-flush">
                        <NavLink exact={true} to="/Monitor" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <Fa icon="home" className="mr-3" />
                                <span className="texto-hide-side">Monitor</span>
                            </ListGroupItem>
                        </NavLink>
                        <NavLink to="/Cobradores" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <Fa icon="motorcycle" className="mr-3" />
                                <span className="texto-hide-side">Cobradores</span>
                            </ListGroupItem>
                        </NavLink>
                        <NavLink to="/Supervisores" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <i className="fas fa-user-clock mr-3"></i>
                                <span className="texto-hide-side">Supervisores</span>
                            </ListGroupItem>
                        </NavLink>
                        <NavLink to="/Vendedores" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <i className="fas fa-user-tag mr-3"></i>
                                <span className="texto-hide-side">Vendedores</span>
                            </ListGroupItem>
                        </NavLink>
                        <NavLink to="/Administradores" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <i className="fas fa-user-cog mr-3"></i>
                                <span className="texto-hide-side">Admin</span>
                            </ListGroupItem>
                        </NavLink>
                        <NavLink to="/Productos" activeClassName="activeClass">
                            <ListGroupItem className="item-sidebar">
                                <i className="fas fa-boxes mr-3"></i>
                                <span className="texto-hide-side">Productos</span>
                            </ListGroupItem>
                        </NavLink>
                        {/* <NavLink to="/Almacenes" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-warehouse mr-3"></i>
                            <span className="texto-hide-side">Almacenes</span>
                        </ListGroupItem>
            </NavLink>
                    <NavLink to="/Clientes" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="far fa-id-card mr-3"></i>
                            <span className="texto-hide-side">Clientes</span>
                        </ListGroupItem>
                    </NavLink>
                     <NavLink to="/Zonas" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-map-marked-alt mr-3"></i>
                            <span className="texto-hide-side">Zonas</span>
                        </ListGroupItem>
        </NavLink>
                    <NavLink to="/Ventas" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-file-invoice-dollar mr-3"></i>
                            <span className="texto-hide-side">Ventas</span>
                        </ListGroupItem>
                    </NavLink>
                     <NavLink to="/Comisiones" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-hand-holding-usd mr-3"></i>
                            <span className="texto-hide-side">Comisiones</span>
                        </ListGroupItem>
                    </NavLink>
                    <NavLink to="/Ingresos" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-money-bill-wave mr-3"></i>
                            <span className="texto-hide-side">Ingresos</span>
                        </ListGroupItem>
                    </NavLink>
                    <NavLink to="/Notificaciones" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-exclamation-circle mr-3"></i>
                            <span className="texto-hide-side">Notificaciones</span>
                        </ListGroupItem>
                    </NavLink>
                    <NavLink to="/Solicitudes" activeClassName="activeClass">
                        <ListGroupItem className="item-sidebar">
                            <i className="fas fa-user-plus mr-3"></i>
                            <span className="texto-hide-side">Solicitudes</span>
                        </ListGroupItem>
                    </NavLink>
                </ListGroup>
            </div>
        );
    }
}

export default SideNav;*/