import React, { Component } from 'react';
import General from './general';
import EditarSupervisor from '../Supervisores/editar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGetSelected, fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditViewSupervisores } from '../../Reducers/supervisores';

class DetallesSupervisor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idSupervisor: this.props.match.params.uuid
        }
    }

    componentDidMount() {
        const { getSupervisor } = this.props;
        const { uuid } = this.props.match.params;
        getSupervisor(uuid);
    }

    componentWillUnmount() {
        const { editar, editarSupervisor, resetSupervisor } = this.props;
        if (editar) {
            editarSupervisor();
        }
        resetSupervisor(-1);
    }

    render() {
        const { editar, editarSupervisor } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    {editar
                        ?
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <EditarSupervisor fnCancelar={() => editarSupervisor()} vista='Detalles' />
                                </div>
                            </div>
                        </div>

                        :
                        <General fnEditar={() => editarSupervisor()} />
                    }
                </div>
                <br />
            </div>
        )
    }
}

export default withRouter(connect(state => ({
    editar: state.supervisores.editViewSupervisor,
}), {
        getSupervisor: fetchGetSelected,
        editarSupervisor: fetchEditViewSupervisores,
        resetSupervisor: fetchSeleccionarUsuario
    })(DetallesSupervisor));
