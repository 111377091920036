
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Card, CardBody, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';



import Paginacion from '../Shared/pagination';

const initialState = {
    columns: ['Nombre', 'Zona', 'Dinero', 'Recaudación', 'Opciones'],
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    resultados: {
        count: 10,
        results: []
    },
    idCobrador: -1,
    selectedOption: null
};

let options = [];

class TendenciaProductos extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
        this.hideDeleteAlert = this.hideDeleteAlert.bind(this);
        this.deleteCobrador = this.deleteCobrador.bind(this);
        this.handleSelectOptions = this.handleSelectOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            ...this.state, selectedOption
        });
    }

    handleSelectOptions() {
        options = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           options.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return options;
    }

    handleDeleteAlert(id) {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Eliminar cobrador',
            text: '¿Desea eliminar el cobrador seleccionado?',
            confirmButtonText: 'Eliminar',
            onConfirm: this.deleteCobrador,
            showCancel: true
        });
    }

    hideDeleteAlert() {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }

    deleteCobrador() {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }


    buscar(texto) {
        const { size } = this.state;
        const { getClientes } = this.props;

        this.setState({ activePage: 1, buscar: texto.trim() });

        let obj = {
            limit: size,
            offset: '0',
            query: texto.trim()
        }
        //getClientes(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size, buscar } = this.state;
        //const { getClientes } = this.props;
        let pagina;

        let obj = {
            query: buscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        // getClientes(obj);
    }


    mapArray(data) {
        let dataTable = data.map((elm) => {
            return {
                nombre: elm.nombre + ' ' + elm.apellido,
                zona: elm.engomado[0].codigo,
                dinero: elm.celular,
                recaudacion: elm.correo,
            }
        });
        return dataTable;
    }


    render() {

        const { activePage, size, resultados, selectedOption } = this.state;
        const { alerta } = this.props;

        return (
            <div >
                {/*<SweetAlert show={alerta.show} type={alerta.type} confirmButtonText={alerta.confirmButtonText} cancelButtonText="Cancelar"
                    showCancelButton title={alerta.title} text={alerta.text}
        onConfirm={this.deleteCobrador} onCancel={this.hideDeleteAlert} />*/}
                <Card >
                    <CardBody>
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Tendencias</h4>
                            </div>
                        </div>
                        <hr className="hr-cobradores" />
                        <div className="col-md-12">
                            <FormInline className=" mr-auto m-0">
                                <Select className="select-zona" options={this.handleSelectOptions()} value={selectedOption} placeholder="" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectChange} isClearable />
                            </FormInline>
                            <br/>
                            <Table className="tbl-css" responsive>
                                <TableHead>
                                    <tr>
                                        <th>Producto</th>
                                        <th>Última venta</th>
                                        <th>Unidades vendidas</th>
                                        <th>Disponibles</th>
                                        <th>Aviso</th>
                                    </tr>
                                </TableHead>
                                <TableBody>
                                    <tr>
                                        <td>Mueble Fancy</td>
                                        <td>03/01/2018</td>
                                        <td>100</td>
                                        <td>50</td>
                                        <td className="font-orange">Restablcer</td>
                                    </tr>
                                    <tr>
                                        <td>Mueble Cool</td>
                                        <td>03/01/2018</td>
                                        <td>100</td>
                                        <td>50</td>
                                        <td className="font-red">Agotado</td>
                                    </tr>
                                    <tr>
                                        <td>Mueble Retro</td>
                                        <td>03/01/2018</td>
                                        <td>100</td>
                                        <td>50</td>
                                        <td className="font-green">Disponible</td>
                                    </tr>

                                </TableBody>
                            </Table>
                            <Paginacion total={resultados.count} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert
    })(TendenciaProductos);