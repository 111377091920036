import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaClientes from './lista';
//import AltaCliente from './alta';
import AltaCliente from './altaPreclientes';
import EditarCliente from './editar';
import AltaPreVenta from '../Preventa/alta';

import { connect } from 'react-redux';
import { fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchCancelPresale } from '../../Reducers/solicitudes';
import { fetchEditViewCliente } from '../../Reducers/clientes';

class Clientes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1
        }
    }

    componentWillUnmount() {
        const { editar, seleccionar, editarCliente } = this.props;
        if (editar) {
            seleccionar(-1);
            editarCliente();
        }
    }

    handleTabChange = (id) => {
        const { resetSelected } = this.props;
        this.setState({ tab: id });
        if (id === 1) { resetSelected(); }
    }

    editarCliente = (id) => {
        const { seleccionar, editarCliente } = this.props;
        seleccionar(id);
        editarCliente();
    }

    cancelarEditar = (e) => {
        e.preventDefault();
        const { seleccionar, editarCliente } = this.props;
        seleccionar(-1);
        editarCliente();
    }

    render() {
        const { tab } = this.state;
        const { editar, addSaletoClient, preClienteSeleccionado } = this.props;

        if (editar) {
            return (
                <EditarCliente fnCancelar={this.cancelarEditar} vista='lista' />
            )
        }
        else {
            return (
                <div>
                    <Card className="card-css">
                        <CardHeader>
                            <Nav header>
                                <NavItem>
                                    <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de clientes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nuevo cliente</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <div className="col-md-12">
                                {tab === 1 && <ListaClientes fnEditar={this.editarCliente} />}
                                {tab === 2 && !addSaletoClient && <AltaCliente />}
                                {tab === 2 && addSaletoClient && <AltaPreVenta preCliente={preClienteSeleccionado} />}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            )

        }

    }
}

export default connect(state => ({
    editar: state.clientes.editViewCliente,
    addSaletoClient: state.preregistros.PreSaletoPreClient,
    preClienteSeleccionado: state.preregistros.preclienteSeleccionado
}), {
        seleccionar: fetchSeleccionarUsuario,
        editarCliente: fetchEditViewCliente,
        resetSelected: fetchCancelPresale
    })(Clientes);