import React, { Component } from 'react';
import { Table, TableBody, TableHead, Button, Fa, FormInline } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchGetCatLineas, fetchGetCatSublineas, fetchResetSublineas } from '../../Reducers/catalogos';
import { fetchGetProductos } from '../../Reducers/productos';

import Paginacion from '../Shared/pagination';

let optLineas = [];
let optSublineas = [];

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    idProducto: -1,
    selLineaOption: null,
    selSublineaOption: null,
};

class ListaProductos extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getProductos, getLineas } = this.props;

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            limit: size,
            offset: '0',
        }
        getProductos(obj);
        getLineas();
    }

    handleLineaChange = (selLineaOption) => {
        const { getSublineas, resetSublinea } = this.props;

        this.setState({
            ...this.state, selLineaOption
        });

        if (selLineaOption) {
            let datosSublinea = {
                linea: selLineaOption.value
            };
            getSublineas(datosSublinea);
        }
        else {
            this.setState({ selSublineaOption: null });
            resetSublinea();
        }
    }

    handleLineaOptions = () => {
        optLineas = [];
        this.props.linea.rows.map((linea) =>
            optLineas.push({ value: linea.id, label: linea.numeral + ' - ' + linea.nombre })
        );
        return optLineas;
    }

    handleSublineaChange = (selSublineaOption) => {
        this.setState({
            ...this.state, selSublineaOption
        });
    }

    handleSublineaOptions = () => {
        optSublineas = [];
        this.props.sublineas.rows.map((sublinea) =>
            optSublineas.push({ value: sublinea.id, label: sublinea.numeral + ' - ' + sublinea.nombre })
        );
        return optSublineas;
    }

    /* handleDeleteAlert = (id) => {
         const { showMessage } = this.props;
         this.setState({ idProducto: id });
         showMessage({
             show: true,
             type: 'warning',
             title: 'Eliminar producto',
             text: '¿Desea eliminar el producto seleccionado?',
             confirmButtonText: 'Eliminar',
             onConfirm: this.deleteProducto,
             showCancel: true
         });
     }
 
     deleteProducto = () => {
         console.log('borrar producto');
         const { hideMessage } = this.props;
         this.setState({ idProducto: -1 });
         hideMessage();
     }
     */
    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar, selLineaOption, selSublineaOption } = this.state;
        const { getProductos } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            limit: size,
            offset: '0',
            text: txtBuscar
        }

        if(selLineaOption){
            obj.linea = selLineaOption.value;
        }
        if(selSublineaOption){
            obj.sublinea = selSublineaOption.value;
        }
        getProductos(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, txtBuscar, selLineaOption, selSublineaOption } = this.state;
        const { getProductos } = this.props;
        let pagina;

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            text: txtBuscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        if(selLineaOption){
            obj.linea = selLineaOption.value;
        }
        if(selSublineaOption){
            obj.sublinea = selSublineaOption.value;
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getProductos(obj);
    }

    render() {

        const { activePage, size, txtBuscar, selLineaOption, selSublineaOption } = this.state;
        const { fnEditar, productos } = this.props;

        return (
            <div >
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleLineaOptions()} value={selLineaOption} placeholder="Filtrar por línea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleLineaChange} isClearable />
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleSublineaOptions()} value={selSublineaOption} placeholder="Filtrar por sublínea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSublineaChange} isClearable />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input className="form-control form-control-sm ml-3 search-input" type="text" placeholder="Buscar nombre/numeral/código barras/folio" aria-label="Buscar nombre/numeral/codigoBarras/numeralEditable" value={txtBuscar} onChange={this.changeBuscar} />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12  input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de productos</label>&nbsp;
                                <select id="size" className="select-num" value={size} onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Código</th>
                            <th>Producto</th>
                            <th>Precio contado</th>
                            <th>Precio crédito</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {productos.total > 0 ?
                            productos.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.numeralEditable}</td>
                                    <td>{v.nombre}</td>
                                    <td>${v.precioContado.toLocaleString() }</td>
                                    <td>${v.precioCredito.toLocaleString()}</td>
                                    <td>
                                        <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesProducto/${v.id}`}>Ver más</Link>
                                        <Button className="btn-opciones" color="success" size="sm" onClick={() => fnEditar(v.id)}><Fa icon="edit" /></Button>
                                    </td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={productos.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta,
    productos: state.productos.productos,
    linea: state.catalogos.lineas,
    sublineas: state.catalogos.sublineas,
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        getProductos: fetchGetProductos,
        getLineas: fetchGetCatLineas,
        getSublineas: fetchGetCatSublineas,
        resetSublinea: fetchResetSublineas
    })(ListaProductos);