
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { connect } from 'react-redux';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchResetTokenCobrador, fetGetCobradoresConexiones } from '../../Reducers/cobradores';
import { fetchGetCatUsuariosMultiple } from '../../Reducers/catalogos';

import Paginacion from '../Shared/pagination';
import moment from 'moment';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    idCobrador: -1,
    selCobradorOption: null,
};

let optionsCobradores = [];

class ListaCobradores extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getCobradores, getCatCobradores } = this.props;

        let objCat = {
            tipo: 4,
            activo: 'true'
        }
        let objConexiones = {
            limit: size,
            offset: '0',
        }
        getCobradores(objConexiones);
        getCatCobradores(objCat)
    }

    handleCobradorChange = (selCobradorOption) => {
        const { getCobradores } = this.props;
        const { size } = this.state;
        this.setState({
            ...this.state, selCobradorOption,
            activePage: 1
        });
        let objConexiones = {
            limit: size,
            offset: '0',
        }
        if (selCobradorOption) {
            objConexiones.cobrador = selCobradorOption.value;
        }
        getCobradores(objConexiones);
    }

    handleCobradorOptions = () => {
        optionsCobradores = [];
        this.props.catCobradores.rows.map((cobrador) =>
            optionsCobradores.push({ value: cobrador.id, label: cobrador.nombre + ' ' + cobrador.apellidoP + ' ' + cobrador.apellidoM })
        );
        return optionsCobradores;
    }


    handleResetMobileAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Reiniciar acceso',
            text: '¿Desea reiniciar el acceso del cobrador seleccionado?',
            confirmButtonText: 'Reiniciar',
            onConfirm: this.resetMobileToken,
            showCancel: true
        });
    }

    resetMobileToken = () => {
        const { hideMessage, resetToken } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        resetToken(idCobrador);
    }


    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }

    paginationHandler = (event, value) => {
        const { activePage, size, selCobradorOption } = this.state;
        const { getCobradores } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString(),
        }

        if (selCobradorOption) {
            obj.cobrador = selCobradorOption.value;
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getCobradores(obj);
    }

    render() {

        const { activePage, size, selCobradorOption } = this.state;
        const { cobradores } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        <Select options={this.handleCobradorOptions()} value={selCobradorOption} placeholder="Buscar cobrador" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleCobradorChange} isClearable />
                    </div>
                    <div className="col-md-6 col-sm-12  input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de cobradores</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Nombre</th>
                            <th>¿Es sesión válida?</th>
                            <th>Expiración de sesión</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {cobradores.total > 0 ?
                            cobradores.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.nombre} {v.apellidoP} {v.apellidoM}</td>
                                    <td>{v.jwtCobrador.valid ? <Fa icon="check" size="2x" className="green-text pr-3" /> : <Fa icon="times" size="2x" className="red-text pr-3" />}</td>
                                    <td>{moment(v.jwtCobrador.expirationDate).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>
                                        <Button className="btn-opciones" color="warning" size="sm" onClick={() => this.handleResetMobileAlert(v.id)}><i className="fas fa-mobile-alt"></i>&nbsp; Eliminar sesión</Button>
                                    </td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="4">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={cobradores.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    cobradores: state.cobradores.conexiones,
    catCobradores: state.catalogos.cobradoresCat
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        getCobradores: fetGetCobradoresConexiones,
        getCatCobradores: fetchGetCatUsuariosMultiple,
        resetToken: fetchResetTokenCobrador,
    })(ListaCobradores);