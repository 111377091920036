import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiAddCobrador = async (body) => {
    var request = await CreateRequestImagen(POST, body);
    let url = baseUrl + `usuarios/cobrador/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateCobrador = async (id, body) => {
    var request = await CreateRequestImagen(PUT, body);
    let url = baseUrl + `usuarios/${id}/cobrador/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiAddIngresoCobrador = async (body) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `ingresos/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetIngresosCobrador = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `ingresos/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetAcumuladosCobrador = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `acumulados/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiResetToken = async (id) => {
    var request = await CreateRequest(PUT);
    let url = baseUrl + `usuarios/${id}/reiniciar-cobrador/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetConexionesCobradores = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'conexiones/detalles/?' + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiGetClientesCobrador = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `cobrador/clientes/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetAbonosCobrador = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `abonos/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetEstadisticasCobrador = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `estadistica/cobrador/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetEstadisticasMonitor = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `estadistica/monitor/?` + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};