import React, { Component } from 'react';
import {
    Card, CardBody, Button
} from "mdbreact";
import { connect } from 'react-redux';
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { baseUrl } from '../../Api/general';

class ViewMyInfo extends Component {

    renderImage = () => {
        const { usuario } = this.props;
        if (Object.entries(usuario).length === 0 && usuario.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (usuario.imagenes.length > 0) {
                imageUrl = baseUrl + usuario.imagenes.find(image => image.tipo.id === 1).url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    render() {

        const { fnEditar, usuario } = this.props;

        let urlINE1 = '';
        let urlINE2 = '';
        let urlDom = '';
        let perfil = '';
        let aval1 = { nombre: '', telefono: '', direccion: '' };
        let aval2 = { nombre: '', telefono: '', direccion: '' };
        let laboral = { nombre: '', telefono: '', direccion: '' };
        let referencia = { nombre: '', telefono: '' };
        let zona = '';


        if (usuario && usuario.infoCliente) {
            if (usuario.infoCliente.referenciaAval1) {
                aval1 = JSON.parse(usuario.infoCliente.referenciaAval1);
            }
            if (usuario.infoCliente.referenciaAval2) {
                aval2 = JSON.parse(usuario.infoCliente.referenciaAval2);
            }
            if (usuario.infoCliente.referenciaLaboral) {
                laboral = JSON.parse(usuario.infoCliente.referenciaLaboral);
            }

            if (usuario && usuario.imagenes && usuario.imagenes.length > 0) {
                perfil = baseUrl + usuario.imagenes.find(image => image.tipo.id === 1).url;
                urlINE1 = baseUrl + usuario.imagenes.find(image => image.tipo.id === 2).url;
                urlDom = baseUrl + usuario.imagenes.find(image => image.tipo.id === 3).url;
                urlINE2 = baseUrl + usuario.imagenes.find(image => image.tipo.id === 5).url;
            }
        }

        if (usuario && usuario.infoCobrador) {
            referencia = JSON.parse(usuario.infoCobrador.referencia);
            zona = usuario.infoCobrador.zona;
        }

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <Card className="card-css">
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6 col-sm-10 col-10">
                                        <h4><b>{usuario.nombre} {usuario.apellidoP} {usuario.apellidoM}</b></h4>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                        <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                                    </div>
                                </div>
                                <hr className="gral-hr" />
                                <div className="body-generales" >
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Tipo usuario</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{usuario.tipo.nombre}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Nombre usuario</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{usuario.username}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Correo</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{usuario.email}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{usuario.telefono}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Domicilio</b></div>
                                        <div className="col-md-10 col-sm-12 col-12 descripcion input-margin">{usuario.direccion}</div>
                                    </div>
                                    {usuario.infoCliente ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado a)</b></div>
                                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE1} target="_blank" rel="noopener noreferrer" className="link-archivo">Archivo.jpg</a></div>
                                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado b)</b></div>
                                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE2} target="_blank" rel="noopener noreferrer" className="link-archivo">Archivo.jpg</a></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin"><b>Cliente</b></div>
                                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={perfil} target="_blank" rel="noopener noreferrer" className="link-archivo">Archivo.jpg</a></div>
                                                <div className="col-md-3 col-sm-12 input-margin"><b>Comprobante</b></div>
                                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlDom} target="_blank" rel="noopener noreferrer" className="link-archivo">Archivo.jpg</a></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5><b>Información laboral</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Nombre</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.nombre}</div>
                                                <div className="col-md-3 col-sm-12 input-margin">Teléfono</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.telefono}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Dirección</div>
                                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{laboral.direccion}</div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5><b>Información de avales</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Nombre de aval 1</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.nombre}</div>
                                                <div className="col-md-3 col-sm-12 input-margin">Teléfono</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.telefono}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Dirección</div>
                                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval1.direccion}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Nombre de aval 2</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.nombre}</div>
                                                <div className="col-md-3 col-sm-12 input-margin">Teléfono</div>
                                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.telefono}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-sm-12 input-margin">Dirección</div>
                                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval2.direccion}</div>
                                            </div>
                                        </>
                                        :
                                        ''
                                    }
                                    {usuario.infoCobrador ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Zona de operación</b></div>
                                                <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{zona}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-12 col-sm-12 col-12 input-margin">
                                                    <b>Imagen de perfil</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-12 input-margin">
                                                    {
                                                        this.renderImage()
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5><b>Información de referencia</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <span className="mb-2 text-muted">En caso de algún accidente o emergencia contactaremos a la persona en esta referencia</span>
                                                </div>
                                            </div>
                                            <div className="row ">
                                                <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Nombre</b></div>
                                                <div className="col-md-4 col-sm-12 col-12 descripcion input-margin">{referencia ? referencia.nombre : ''}</div>
                                                <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                                <div className="col-md-4 col-sm-12 col-12 descripcion input-margin">{referencia ? referencia.telefono : ''}</div>
                                            </div>
                                        </>
                                        :
                                        ''
                                    }
                                    {usuario.infoVendedor ?
                                        <>
                                            <div className="row">
                                                <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Comisión</b></div>
                                                <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{usuario.infoVendedor.comision} %</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-12 col-sm-12 col-12 input-margin">
                                                    <b>Imagen de perfil</b>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-12 input-margin">
                                                    {
                                                        this.renderImage()
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ''
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    usuario: state.auth.user
}), {})(ViewMyInfo);