import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from '../App';
import Login from '../Components/Inicio/login';
/*import Recuperar from '../components/Inicio/Recuperar';
import Cambiar from '../components/Inicio/Cambiar';*/
import PrivateRoutes from './PrivateRoutes';

const AppRoutes = () =>
    <App>
        <Switch>
           <Route path="/Login" component={Login} />        
            <PrivateRoutes></PrivateRoutes>
        </Switch>
    </App>;

export default AppRoutes;