import React, { Component } from 'react';
import { Table, TableBody, TableHead } from 'mdbreact';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, CardBody } from "mdbreact";
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { fetchGetNotificacionesHistorico } from '../../Reducers/notificaciones';
import { fetchGetCatUsuariosMultiple } from '../../Reducers/catalogos';
import Paginacion from '../Shared/pagination';
import '../../Css/notificaciones.css';

const initialState = {
    activePage: 1,
    size: 10,
    offset: '0',
    selOrdenOption: null,
    selCobradorOpt: null,
    selTipoNotificacion: null,
};

let optionsOrden = [
    { value: 'asc', label: 'Ver más antiguos' },
    { value: 'desc', label: 'Ver más recientes' }
];

let optionsTiponotificacion = [
    { value: 1, label: 'Por definir' },
    { value: 2, label: 'Por definir' },
    { value: 3, label: 'Por definir' },
];

let optionsCobradores = [];

class ListaNotificaciones extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getNotificaciones, getCobradores } = this.props;
        getCobradores({ tipo: 4 });
        let obj = {
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getNotificaciones(obj);
    }

    handleCobradoresOptions = () => {
        optionsCobradores = [];
        this.props.cobradores.rows.map((cobrador) =>
            optionsCobradores.push({ value: cobrador.id, label: cobrador.nombre + ' ' + cobrador.apellidoP + ' ' + cobrador.apellidoM })
        );
        return optionsCobradores;
    }

    handleCobradorChange = (selCobradorOpt) => {
        this.setState({
            ...this.state, selCobradorOpt
        });
        this.filterList(selCobradorOpt, null, 'c');
    }

    handleOrdenChange = (selOrdenOption) => {
        this.setState({
            ...this.state, selOrdenOption
        });
        this.filterList(null, selOrdenOption, 'o');
    }

    handleTipoChange = (selTipoNotificacion) => {
        this.setState({
            ...this.state, selTipoNotificacion
        });
        // this.filterList(null, null, selTipoNotificacion);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, selOrdenOption, selCobradorOpt, selTipoNotificacion } = this.state;
        const { getNotificaciones } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString(),
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }

        if (selOrdenOption) {
            obj.orderBy = selOrdenOption.value;
        }
        if (selCobradorOpt) {
            obj.idCobrador = selCobradorOpt.value;
        }
        if (selTipoNotificacion) {
            obj.idTipoNotificacion = selTipoNotificacion.value;
        }
        getNotificaciones(obj);
    }

    filterList = (cobrador, orden, fnc) => {
        const { size, selOrdenOption, selCobradorOpt } = this.state;
        const { getNotificaciones } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            limit: size,
            offset: '0'
        }
        if (orden) {
            obj.orderBy = orden.value;
        }
        else if (fnc === 'o') {
            obj.orderBy = 'desc';
        }
        else if (selOrdenOption) {
            obj.orderBy = selOrdenOption.value;
        }
        else {
            obj.orderBy = 'desc';
        }

        if (cobrador) {
            obj.cobrador = cobrador.value;
        }
        else if (fnc === 'c') {
            delete obj["cobrador"];
        }
        else if (selCobradorOpt) {
            obj.cobrador = selCobradorOpt.value;
        }
        else {
            delete obj["cobrador"];
        }

        getNotificaciones(obj);
    }

    getTipoClass = (id) => {
        let nombreClase = '';
        switch (id) {
            case 1:
            case 2:
            case 3:
                nombreClase = 'noti-verde';
                break;
            case 4:
                nombreClase = 'noti-azul';
                break;
            case 5:
                nombreClase = 'noti-naranja';
                break;
        }
        return nombreClase;
    }

    getTipo = (id) => {
        let tipoAlerta = '';
        switch (id) {
            case 1:
            case 2:
            case 3:
                tipoAlerta = 'Cobrador';
                break;
            case 4:
                tipoAlerta = 'Cliente';
                break;
            case 5:
                tipoAlerta = '';
                break;
        }
        return tipoAlerta;
    }

    getPrioridad = (id) => {
        let nombreClase = '';
        switch (id) {
            case 1:
            case 4:
                nombreClase = 'noti-error';
                break;
            case 2:
            case 3:
                nombreClase = 'noti-warning';
                break;
            /* case 3:
            nombreClase = 'noti-success';
                 nombreClase = 'noti-warning';
                  nombreClase = 'noti-cobranza';
                 break;*/
        }
        return nombreClase;
    }


    render() {

        const { activePage, size, selOrdenOption, selCobradorOpt } = this.state;
        const { notificaciones } = this.props;

        return (
            <Card className="card-css">
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={optionsOrden} value={selOrdenOption} placeholder="Cambiar orden" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleOrdenChange} isClearable />
                            </div>
                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={this.handleCobradoresOptions()} value={selCobradorOpt} placeholder="Filtrar por cobrador" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleCobradorChange} isClearable />
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <div className="form-inline float-md-right">
                                    <div className="form-group">
                                        <label htmlFor="size">Número de notificaciones</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                            onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={optionsOrden} value={selOrdenOption} placeholder="Cambiar orden" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleOrdenChange} isClearable />
                            </div>
    </div>*/}
                        <br />

                        <Table className="tbl-css tbl-noti" responsive>
                            <TableHead>
                                <tr>
                                    <th>Fecha y Hora</th>
                                    <th>Tipo</th>
                                    <th>Notificación</th>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {notificaciones.total > 0 ?
                                    notificaciones.rows.map((v, i) =>
                                        <tr key={i}>
                                            <td>{moment(v.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                            <td className={this.getTipoClass(v.tipo)}>{this.getTipo(v.tipo)}</td>
                                            <td className={"text-justify px-5 " + this.getPrioridad(v.tipo)}>{/*this.getNotificacion(v.notificacion)*/ v.mensaje}</td>
                                        </tr>
                                    )
                                    :
                                    <tr><td colSpan="3" className="text-center">No se encontraron registros para mostrar</td></tr>
                                }
                            </TableBody>
                        </Table>
                        <Paginacion total={notificaciones.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
                    </div >
                </CardBody>
            </Card>
        )
    }
}

export default connect(state => ({
    notificaciones: state.notificaciones.historico,
    cobradores: state.catalogos.cobradoresCat
}), {
        getNotificaciones: fetchGetNotificacionesHistorico,
        getCobradores: fetchGetCatUsuariosMultiple,
    })(ListaNotificaciones);