import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Monitor from '../Monitor/index';
import Cobradores from '../Cobradores/index';
import DetallesCobrador from '../DetallesCobradores/index';
import Productos from '../Productos/index';
import DetallesProducto from '../Productos/detalles';
import DetallesCliente from '../DetallesCliente/index';
import Clientes from '../Clientes/index';
import Ventas from '../Ventas/index';
import Solicitudes from '../Solicitudes/lista';
import DetallesSolicitud from '../Solicitudes/detalles';
import Vendedores from '../Vendedores/index';
import DetallesVendedor from '../DetallesVendedores/index';
import Supervisores from '../Supervisores/index';
import DetallesSupervisor from '../DetallesSupervisores/index';
import DetallesVenta from '../DetallesVenta/index';
import Administradores from '../Administradores/index';
import DetallesAdministrador from '../DetallesAdmin/index';
import MiPerfil from '../MiInfo/index';
import Notificaciones from '../Notificaciones/lista';
import Ingresos from '../Ingresos/index';
import Comisiones from '../Comisiones/index';
import Prueba from '../Zonas/pruebaEdit';
import Almacen from '../Almacenes/index';
import DetallesAlmacen from '../DetallesAlmacen/index';
import jwtDecode from "jwt-decode";

const route = [
    { path: "/Monitor", exact: true, component: () => <Monitor />, permissions: [1, 2] },
    { path: "/Cobradores", exact: true, component: () => <Cobradores />, permissions: [1, 2] },
    { path: "/DetallesCobrador/:uuid", exact: true, component: () => <DetallesCobrador />, permissions: [1, 2] },
    { path: "/Productos", exact: true, component: () => <Productos />, permissions: [1, 2] },
    { path: "/DetallesProducto/:uuid", exact: true, component: () => <DetallesProducto />, permissions: [1, 2] },
    { path: "/Supervisores", exact: true, component: () => <Supervisores />, permissions: [1, 2] },
    { path: "/DetallesSupervisor/:uuid", exact: true, component: () => <DetallesSupervisor />, permissions: [1, 2] },
    { path: "/Clientes", exact: true, component: () => <Clientes />, permissions: [1, 2, 3] },
    { path: "/DetallesCliente/:uuid", exact: true, component: () => <DetallesCliente />, permissions: [1, 2, 3] },
    { path: "/Solicitudes", exact: true, component: () => <Solicitudes />, permissions: [1, 2] },
    { path: "/DetallesSolicitud/:tipoId/:uuid", exact: true, component: () => <DetallesSolicitud />, permissions: [1, 2, 3] },
    { path: "/Ventas", exact: true, component: () => <Ventas />, permissions: [1, 2, 3] },
    { path: "/DetallesVenta/:uuid", exact: true, component: () => <DetallesVenta />, permissions: [1, 2, 3] },
    { path: "/Vendedores", exact: true, component: () => <Vendedores />, permissions: [1, 2] },
    { path: "/DetallesVendedor/:uuid", exact: true, component: () => <DetallesVendedor />, permissions: [1, 2] },
    { path: "/Administradores", exact: true, component: () => <Administradores />, permissions: [1, 2] },
    { path: "/DetallesAdministrador/:uuid", exact: true, component: () => <DetallesAdministrador />, permissions: [1, 2] },
    { path: "/MiPerfil", exact: true, component: () => <MiPerfil />, permissions: [1, 2, 3] },
    { path: "/Notificaciones", exact: true, component: () => <Notificaciones />, permissions: [1, 2] },
    { path: "*", exact: true, component: () => <Monitor />, permissions: [1, 2] },
    { path: "*", exact: true, component: () => <Ventas />, permissions: [3] },
];

const MainContent = () => {
    const decoded = jwtDecode(localStorage.token);

    return (
        <div className="content">
            <Switch>
                <Redirect exact={true} from='/' to={decoded.tipo === 3 ? "/Ventas" : "/Monitor"} />
                {
                    route.map(item => {
                        return (
                            item.permissions.includes(decoded.tipo)
                                ?
                                <Route key={item.path} path={item.path} exact={item.exact} component={item.component} />
                                :
                                ''
                        )
                    })
                }
            </Switch>
        </div>
    )
};

export default MainContent;

/*const MainContent = () => {
    return (
        <div className="content">
            <Switch>
                <Redirect exact={true} from='/' to='/Monitor' />
                <Route path='/Monitor' exact component={Monitor} />
                <Route path='/Cobradores' exact component={Cobradores} />
                <Route path='/DetallesCobrador/:uuid' exact component={DetallesCobrador} />
                <Route path='/Productos' exact component={Productos} />
                <Route path='/DetallesProducto/:uuid' exact component={DetallesProducto} />
                <Route path='/Supervisores' exact component={Supervisores} />
                <Route path='/DetallesSupervisor/:uuid' exact component={DetallesSupervisor} />
                <Route path='/Clientes' exact component={Clientes} />
                <Route path='/DetallesCliente/:uuid' exact component={DetallesCliente} />
                <Route path='/Solicitudes' exact component={Solicitudes} />
                <Route path='/DetallesSolicitud/:tipoId/:uuid' exact component={DetallesSolicitud} />
                <Route path='/Ventas' exact component={Ventas} />
                <Route path='/DetallesVenta/:uuid' exact component={DetallesVenta} />
                <Route path='/Vendedores' exact component={Vendedores} />
                <Route path='/DetallesVendedor/:uuid' exact component={DetallesVendedor} />
                <Route path='/Administradores' exact component={Administradores} />
                <Route path='/DetallesAdministrador/:uuid' exact component={DetallesAdministrador} />
                <Route path='/MiPerfil' exact component={MiPerfil} />
                <Route path='/Notificaciones' exact component={Notificaciones} />
                <Route path='*' exact={true} component={Cobradores} />


                <Redirect exact={true} from='/' to='/Monitor' />
                <Route path='/Monitor' exact component={Monitor} />
                <Route path='/Cobradores' exact component={Cobradores} />
                <Route path='/DetallesCobrador/:uuid' exact component={DetallesCobrador} />
                <Route path='/Vendedores' exact component={Vendedores} />
                <Route path='/DetallesVendedor/:uuid' exact component={DetallesVendedor} />
                <Route path='/Supervisores' exact component={Supervisores} />
                <Route path='/DetallesSupervisor/:uuid' exact component={DetallesSupervisor} />
                <Route path='/Productos' exact component={Productos} />
                <Route path='/DetallesProducto/:uuid' exact component={DetallesProducto} />
                <Route path='/DetallesAlmacen' exact component={DetallesAlmacen} />
                <Route path='/Almacenes' exact component={Almacen} />
                <Route path='/Clientes' exact component={Clientes} />
                <Route path='/DetallesCliente/:uuid' exact component={DetallesCliente} />
                <Route path='/Ventas' exact component={Ventas} />
                <Route path='/Zonas' exact component={Prueba} />
                <Route path='/Solicitudes' exact component={Solicitudes} />
                <Route path='/DetallesSolicitud/:tipoId/:uuid' exact component={DetallesSolicitud} />
                <Route path='/Notificaciones' exact component={Notificaciones} />
                <Route path='/Ingresos' exact component={Ingresos} />
                <Route path='/Comisiones' exact component={Comisiones} />
                <Route path='*' exact={true} component={Monitor} />
            </Switch>
        </div>
    )
};

export default MainContent;*/