import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, CardHeader, Nav, NavItem, NavLink } from "mdbreact";
import Alertas from './alertas';
import Area from './area';
import General from './general';
import Clientes from './clientes';
import Abonos from './abonos';
import Ingresos from './ingresos';
import Recaudacion from './recaudacion';
import Comisiones from './comisiones';
import MapaClientes from './clientes-mapa';
import EditarCobrador from '../Cobradores/editar';
import Estadisticas from './estadisticas';
import { connect } from 'react-redux';
import { fetchGetSelected, fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditViewCobradores } from '../../Reducers/cobradores';
import '../../Css/detalles.css';
import '../../Css/switch.css';

class DetallesCobrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            idCobrador: this.props.match.params.uuid,
            mapa: false
        }
    }

    componentDidMount() {
        const { getCobrador } = this.props;
        const { uuid } = this.props.match.params;
        getCobrador(uuid);
    }

    componentWillUnmount() {
        const { editarCobrador, editar, resetCobrador } = this.props;
        if (editar) {
            editarCobrador();
        }
        resetCobrador(-1);
    }

    handleVistaChnage = () => {
        const { mapa } = this.state;
        this.setState({
            mapa: !mapa
        });
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    render() {
        const { tab, idCobrador, mapa } = this.state;
        const { editar, editarCobrador } = this.props;

        return (

            <div className="col-md-12">
                <Estadisticas idCobrador={idCobrador} />
                <br />
                <div className="row">
                    {editar
                        ?
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <EditarCobrador fnCancelar={() => editarCobrador()} vista='Detalles' />
                                </div>
                            </div>
                        </div>
                        :
                        <General fnEditar={() => editarCobrador()} />
                    }
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-css">
                            <CardHeader>
                                <Nav header>
                                    <NavItem>
                                        <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Ingresos</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Abonos recibidos</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 3 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(3)}>Clientes asignados</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 4 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(4)}>Área de trabajo</NavLink>
                                    </NavItem>

                                    {/*<NavItem>
                                        <NavLink className={tab === 4 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(4)}>Recaudación</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 5 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(5)}>Ingresos</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={tab === 6 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(6)}>Comisiones</NavLink>
                                   </NavItem>*/}
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <div className="col-md-12">
                                    {tab === 1 && <Ingresos cobrador={idCobrador} />}
                                    {tab === 2 && <Abonos cobrador={idCobrador} />}
                                    {tab === 3 &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-12 input-margin text-right">
                                                    <div className="span-renta">
                                                        <div className="switch">
                                                            <label>
                                                                Lista
                                    <input type="checkbox" checked={mapa ? "checked" : ''} onChange={this.handleVistaChnage} />
                                                                <span className="lever"></span>
                                                                Mapa
                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!mapa ?
                                                <Clientes cobrador={idCobrador} />
                                                :
                                                <MapaClientes cobrador={idCobrador} />
                                            }
                                        </>

                                    }
                                    {tab === 4 && <Area cobrador={idCobrador} />}

                                    {/* {tab === 4 && <Recaudacion />}
                                    {tab === 5 && <Ingresos cobrador={idCobrador} />}
                                {tab === 6 && <Comisiones />}*/}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                {/* <br />
                <div className="row">
                    <Alertas />
               </div>*/}
            </div>
        )
    }
}

export default withRouter(connect(state => ({
    cobrador: state.usuarios.usuarioSeleccionado,
    editar: state.cobradores.editViewCobrador,
}), {
        getCobrador: fetchGetSelected,
        editarCobrador: fetchEditViewCobradores,
        resetCobrador: fetchSeleccionarUsuario,
    })(DetallesCobrador));
