import React, { Component } from 'react';
import MultipleSeleccion from './productos-seleccionados';
import moment from 'moment';

import { ListGroupItem } from 'mdbreact';

class DetallesVenta extends Component {

    calcPendiente = () => {
        const { detalles, producto } = this.props;
        let pendiente = '';
        if (detalles.selOptionPago && detalles.selOptionPago.value === 1 && producto.detalles.precioCredito) {
            pendiente = '$' + (parseInt(producto.detalles.precioCredito) - parseInt(detalles.anticipo)).toLocaleString();
        }
        return pendiente;
    }

    calcAbonos = () => {
        const { producto, detalles } = this.props;
        let abonos = '';
        /*if (detalles.pagos > 0) {
            let monto = (parseInt(producto.detalles.precioCredito) - parseInt(detalles.anticipo)) / parseInt(detalles.pagos);
            abonos = detalles.pagos + ' pagos de $' + monto.toLocaleString();
        }*/
        if (detalles.pagos > 0) {
            let monto = (parseInt(producto.detalles.precioCredito) - parseInt(detalles.anticipo)) / parseInt(detalles.pagos);
            abonos = detalles.pagos + ' pagos de $' + Math.round(monto).toLocaleString();
        }

        return abonos;
    }

    diasAbonos = () => {
        const { detalles } = this.props;
        let dias = '';
        if (detalles.selOptionFrecuencia) {
            switch (detalles.selOptionFrecuencia.value) {
                case 1:
                    dias = 'Día ' + detalles.diaMes + ' de cada mes.';
                    break;
                case 2:
                    dias = 'Días ' + detalles.quincenal.dia1 + ' y ' + detalles.quincenal.dia2 + ' de cada mes.';
                    break;
                case 3:
                    dias = 'Días ' + detalles.decenal.dia1 + ', ' + detalles.decenal.dia2 + ' y ' + detalles.decenal.dia3 + ' de cada mes.';
                    break;
                case 4:
                    dias = detalles.selOptionSemana ? ('Día ' + detalles.selOptionSemana.label + ' de cada semana.') : '';
                    break;
            }
        }
        return dias;
    }

    returnFechas = () => {
        const { fechasPagos } = this.props;
        let fechas = '';
        if (fechasPagos.total > 0) {
            fechas = fechasPagos.rows.map((fecha, k) =>
                <ListGroupItem className="list-fechas" key={k}>
                    <p className="mb-1">{moment(fecha).format('DD/MM/YYYY')}</p>
                </ListGroupItem>
            );
        }
        return fechas;
    }

    render() {

        const { cliente, producto, detalles } = this.props;

        return (

            <div>
                <hr />
                <h5 className="font-weight-bold pl-0 my-4"><strong>Información del cliente</strong></h5>

                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <p>Nombre</p>
                        <p className="descripcion">{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</p>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <p>Teléfono</p>
                        <p className="descripcion">{cliente.telefono}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 input-margin">
                        <p>Dirección</p>
                        <p className="descripcion">{cliente.direccion}</p>
                    </div>
                </div>

                <h5 className="font-weight-bold pl-0 my-4"><strong>Información del producto</strong></h5>
                {detalles.selOptionPago && detalles.selOptionPago.value === 1 && <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <p>Nombre</p>
                        <p className="descripcion">{producto.detalles.nombre}</p>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <p>Costo contado</p>
                        <p className="descripcion">${producto.detalles.precioContado.toLocaleString()}</p>
                    </div>
                </div>}

                {detalles.selOptionPago && detalles.selOptionPago.value === 2 &&
                    <MultipleSeleccion vista={2} objEditar={producto.productos} />
                }

                <h5 className="font-weight-bold pl-0 my-4"><strong>Detalles de la venta</strong></h5>
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <p>Tipo de pago</p>
                        <p className="descripcion">{detalles.selOptionPago && detalles.selOptionPago.label}</p>
                    </div>
                    {
                        detalles.selOptionPago &&
                        detalles.selOptionPago.value === 1 &&
                        <div className="col-md-6 col-sm-12 input-margin">
                            <p>Frecuencia</p>
                            <p className="descripcion">{detalles.selOptionFrecuencia && detalles.selOptionFrecuencia.label}</p>
                        </div>
                    }
                </div>
                {
                    detalles.selOptionPago &&
                    detalles.selOptionPago.value === 1 &&
                    <>
                        { /*<div className="row" >
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Día(s) de cobro</p>
                                <p className="descripcion">{this.diasAbonos()}</p>
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Hora sugerida de cobro</p>
                                <p className="descripcion">{detalles.dpStart}</p>
                            </div>
                </div>*/}
                        <div className="row">
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Precio crédito</p>
                                <p className="descripcion">${producto.detalles.precioCredito.toLocaleString()}</p>
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Anticipo</p>
                                <p className="descripcion">${parseFloat(detalles.anticipo).toLocaleString()}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Pendiente</p>
                                <p className="descripcion">{this.calcPendiente()}</p>
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Abonos</p>
                                <p className="descripcion">{this.calcAbonos()}</p>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Fecha de cobro</p>
                                <p className="descripcion">{moment(detalles.dpStartISO).format('DD/MM/YYYY')}</p>
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Hora sugerida de cobro</p>
                                <p className="descripcion">{moment(detalles.dpStartISO).format('HH:mm')}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 input-margin">
                                <p>Fechas de pagos:</p>
                            </div>
                            <div className="col-md-12 col-sm-12 input-margin">
                                <ul className="list-inline">
                                    {this.returnFechas()}
                                </ul>
                            </div>
                        </div>
                    </>
                }
                <br />
            </div>

        )
    }
}

export default DetallesVenta;