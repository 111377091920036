import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button, Modal, ModalBody, Container } from 'mdbreact';
import { connect } from 'react-redux';
import Paginacion from '../Shared/pagination';

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    resultados: {
        count: 10,
        results: []
    },
    productoOption: null,
    tipoOption: null,
    precioOption: null,
    showModal: false,
    objEditar: []
};

let optionsProductos = [];
let optionsTipos = [];
let optionsPrecios = [];

function productExists(product, object) {
    return object.some(function (elem) {
        return elem.id === product;
    });
}

class SalidasAlmacen extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
        this.handleProductosOptions = this.handleProductosOptions.bind(this);
        this.handleProductosChange = this.handleProductosChange.bind(this);
        this.handleTipoOptions = this.handleTipoOptions.bind(this);
        this.handleTipoChange = this.handleTipoChange.bind(this);
        this.handlePrecioOptions = this.handlePrecioOptions.bind(this);
        this.handlePrecioChange = this.handlePrecioChange.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.agregarMueble = this.agregarMueble.bind(this);
        this.changeRazon = this.changeRazon.bind(this);
        this.changeCantidad = this.changeCantidad.bind(this);
        this.eliminarMueble = this.eliminarMueble.bind(this);
    }

    handleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal, objEditar: [] });
    }

    handleProductosChange = (productoOption) => {
        this.setState({
            ...this.state, productoOption
        });
    }

    handleProductosOptions() {
        optionsProductos = []
        optionsProductos = [{ "value": "1", "label": "Mueble Fancy" },
        { "value": "2", "label": "Mueble Cool" },
        { "value": "3", "label": "Mueble Retro" }];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           optionsProductos.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return optionsProductos;
    }

    handleTipoChange = (tipoOption) => {
        this.setState({
            ...this.state, tipoOption
        });
    }

    handleTipoOptions() {
        optionsTipos = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           optionsTipos.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return optionsTipos;
    }

    handlePrecioChange = (precioOption) => {
        this.setState({
            ...this.state, precioOption
        });
    }

    handlePrecioOptions() {
        optionsPrecios = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           optionsPrecios.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return optionsPrecios;
    }

    buscar(texto) {
        const { size } = this.state;
        const { getClientes } = this.props;

        this.setState({ activePage: 1, buscar: texto.trim() });

        let obj = {
            limit: size,
            offset: '0',
            query: texto.trim()
        }
        //getClientes(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size, buscar } = this.state;
        //const { getClientes } = this.props;
        let pagina;

        let obj = {
            query: buscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        // getClientes(obj);
    }

    mapArray(data) {
        let dataTable = data.map((elm) => {
            return {
                nombre: elm.nombre + ' ' + elm.apellido,
                zona: elm.engomado[0].codigo,
                dinero: elm.celular,
                recaudacion: elm.correo,
            }
        });
        return dataTable;
    }

    agregarMueble() {
        const { productoOption, objEditar } = this.state;
        if (productoOption) {
            if (!productExists(productoOption.value, objEditar)) {
                objEditar.push({ "id": productoOption.value, "nombre": productoOption.label, "razon": 1, "cantidad": 1 });
            }
            else {
                alert('El producto ya está agregado en la lista');
            }
            this.setState({ productoOption: null, });
        }
    }

    changeCantidad(e) {
        const { name, value } = e.target;
        let numero = (value !== '' ? value : 1);
        const { objEditar } = this.state;
        this.setState({ objEditar: objEditar.map(elem => elem.id === name ? { ...elem, cantidad: parseInt(numero) } : elem) });
    }

    changeRazon(e) {
        const { name, value } = e.target;
        const { objEditar } = this.state;
        this.setState({ objEditar: objEditar.map(elem => elem.id === name ? { ...elem, razon: parseInt(value) } : elem) });
    }

    eliminarMueble(id) {
        const { objEditar } = this.state;

        this.setState({ objEditar: objEditar.filter(elem => elem.id !== id) });
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log('guardar salidas');
    }

    render() {

        const { activePage, size, resultados, productoOption, tipoOption, precioOption, showModal, objEditar } = this.state;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        <FormInline className="md-form m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 input-search"
                                type="text"
                                placeholder="Buscar producto"
                                aria-label="Buscar producto"
                            />
                            <Button className="btn-refresh"><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Button className="btn-movimiento btn-salida float-md-right" onClick={this.handleModal}><i className="fas fa-box mr-3"></i>Generar salida</Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleTipoOptions()} value={tipoOption} placeholder="Tipo de salida" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleTipoChange} isClearable />
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handlePrecioOptions()} value={precioOption} placeholder="Precio de producto" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handlePrecioChange} isClearable />
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de productos</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Producto</th>
                            <th>Razón Salida</th>
                            <th>Fecha</th>
                            <th>Cantidad</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        <tr>
                            <td>Mueble Fancy</td>
                            <td>Almacén</td>
                            <td>16/12/2019</td>
                            <td>00</td>
                        </tr>

                    </TableBody>
                </Table>
                <Paginacion total={resultados.count} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />

                <Modal size="lg" isOpen={showModal} toggle={this.handleModal} centered>
                    <div className="modal-header header-recibir">
                        <p className="modal-title">Generar salida</p>
                        <button type="button" className="close" aria-label="Close" onClick={this.handleModal}><span aria-hidden="true">×</span></button>
                    </div>
                    <ModalBody>
                        <Container fluid >
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12  input-margin">
                                        <FormInline className="md-form m-0">
                                            <Fa icon="search" />
                                            <Select className="select-wide" options={this.handleProductosOptions()} value={productoOption} placeholder="Buscar producto" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleProductosChange} isClearable />
                                            <Button className="btn-refresh float-md-right" onClick={this.agregarMueble}>Agregar</Button>
                                        </FormInline>
                                    </div>
                                </div>
                                <form autoComplete="off" onSubmit={(e) => this.handleSubmit(e)}>
                                    <Table className="tbl-css" responsive>
                                        <TableHead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Tipo de salida</th>
                                                <th>Cantidad</th>
                                                <th>Opciones</th>
                                            </tr>
                                        </TableHead>
                                        <TableBody>
                                            {objEditar.length === 0 ?
                                                <tr><td colSpan="4">No se encontraron registros para mostrar</td></tr>
                                                :
                                                objEditar.map((obj, i) =>
                                                    <tr key={i}>
                                                        <td>{obj.nombre}</td>
                                                        <td>
                                                            <select name={obj.id} className="select-num" value={obj.razon} onChange={this.changeRazon}>
                                                                <option value="1">Almacén</option>
                                                                <option value="2">Garantía</option>
                                                                <option value="3">Cancelación</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input name={obj.id} type="number" min="1" className="input-cant-modal" value={obj.cantidad} onChange={this.changeCantidad} />
                                                        </td>
                                                        <td>
                                                            <Button className="btn-opciones" color="danger" size="sm" onClick={() => this.eliminarMueble(obj.id)} ><Fa icon="trash" /></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-modal-mov">Aceptar</button>
                                    </div>
                                </form>
                            </div>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default connect(state => ({}), {})(SalidasAlmacen);