import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarNav, NavItem, Fa, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { connect } from 'react-redux';
import { signOut, fetchGetMe } from '../../Reducers/auth';
import DropdownSolicitudes from './Solicitudes';
import DropdownNotificaciones from './Notificaciones';
import '../../Css/dropdownInfo.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
        };
    }

    componentDidMount() {
        this.props.fetchGetMe();
    }

    cerrarSesion = () => {
        this.props.signOut();
    }

    toggleSidenav = () => {
        document.getElementById('wrapper').classList.toggle('toggled');
    }

    render() {

        const { usuario } = this.props;

        return (
            <Navbar className="flexible-navbar" light expand="md" >
                <NavbarBrand>
                    <button onClick={this.toggleSidenav} className="button-toggle toggle_side"><Fa icon="bars" size="lg" /></button>
                </NavbarBrand>
                <NavbarNav right>
                    {usuario.tipo && (usuario.tipo.id === 1 || usuario.tipo.id === 2) &&
                        <>
                            <NavItem>
                                <DropdownSolicitudes />
                            </NavItem>
                            <NavItem>
                                <DropdownNotificaciones />
                            </NavItem>
                        </>
                    }
                    <NavItem>
                        <Dropdown>
                            <DropdownToggle nav caret>
                                <div className="d-md-inline div-usr"><Fa icon="user" size="lg" className="icon-verde" /> <span className="texto-hide">{usuario.nombre} {usuario.apellidoP} {usuario.apellidoM ? usuario.apellidoM : ''}</span></div>
                            </DropdownToggle>
                            <DropdownMenu right className="text-center">
                                <DropdownItem header>{usuario.username}</DropdownItem>
                                {usuario.email !== '' && <DropdownItem header>{usuario.email}</DropdownItem>}
                                <DropdownItem href="/MiPerfil"> Mi perfil</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.cerrarSesion()}> <i className="fas fa-sign-out-alt mr-3"></i>Cerrar sesión</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </NavItem>
                </NavbarNav>
            </Navbar>
        );
    }
}

Header.defaultProps = {
    signOut: () => {
        console.log('Cerrar sesión');
    },
    fetchGetMe: () => {
        console.log('Obtener info usuario');
    }
}

export default connect(state =>
    ({
        usuario: state.auth.user
    }), { signOut, fetchGetMe })(Header);

