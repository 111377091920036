import React, { Component } from 'react';
import { Fa, FormInline, Button } from 'mdbreact';
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { connect } from 'react-redux';
import { baseUrl } from '../../Api/general';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import { fetchGetCatLineas, fetchGetCatSublineas, fetchResetSublineas } from '../../Reducers/catalogos';
import { fetchGetProductos } from '../../Reducers/productos';

import Paginacion from '../Shared/pagination';


const initialState = {
    total: 12,
    activePage: 1,
    size: 12,
    offset: '0',
    txtBuscar: '',
    /* productos: {
         count: 20,
         results: [
             { id: 1, nombre: 'Mueble Fancy', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/1.jpg', cantidad: 1 },
             { id: 2, nombre: 'Mueble Cool', costo: '$200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/2.jpg', cantidad: 1 },
             { id: 3, nombre: 'Mueble Bonito', costo: '$2,250.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/3.jpg', cantidad: 1 },
             { id: 4, nombre: 'Mueble Azul', costo: '$550.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/4.jpg', cantidad: 1 },
             { id: 5, nombre: 'Mueble Rojo', costo: '$1,000.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/5.jpg', cantidad: 1 },
             { id: 6, nombre: 'Mueble Morado', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/6.jpg', cantidad: 1 },
             { id: 7, nombre: 'Mueble Gris', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/7.jpg', cantidad: 1 },
             { id: 8, nombre: 'Mueble Retro', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/8.jpg', cantidad: 1 },
             { id: 9, nombre: 'Mueble Clásico', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/9.jpg', cantidad: 1 },
             { id: 10, nombre: 'Mueble Edgy', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/10.jpg', cantidad: 1 },
             { id: 11, nombre: 'Mueble Nuevo', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/11.jpg', cantidad: 1 },
             { id: 12, nombre: 'Mueble Ecológico', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/12.jpg', cantidad: 1 },
             { id: 13, nombre: 'Mueble Práctico', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/13.jpg' },
               { id: 14, nombre: 'Mueble Chilo', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14.jpg' },
               { id: 15, nombre: 'Mueble Antiguo', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/15.jpg' },
               { id: 16, nombre: 'Mueble Amarillo', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/1.jpg' },
               { id: 17, nombre: 'Mueble Verde', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/7.jpg' },
               { id: 18, nombre: 'Mueble Negro', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/8.jpg' },
               { id: 19, nombre: 'Mueble Café', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/9.jpg' },
               { id: 20, nombre: 'Mueble Blanco', costo: '$1,200.00', url: 'https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/2.jpg' },
         ]
 },
     selLineaOption: null,
     selSublineaOption: null,*/
};

let optLineas = [];
let optSublineas = [];

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getProductos, getLineas } = this.props;

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            limit: size,
            offset: '0',
        }
        getProductos(obj);
        getLineas();
    }

    handleLineaChange = (selLineaOption) => {
        const { getSublineas, resetSublinea, clickProducto } = this.props;

        clickProducto('selLineaOption', selLineaOption);

        /*this.setState({
            ...this.state, selLineaOption
        });*/

        if (selLineaOption) {
            let datosSublinea = {
                linea: selLineaOption.value
            };
            getSublineas(datosSublinea);
        }
        else {
            //this.setState({ selSublineaOption: null });
            clickProducto('selSublineaOption', null);
            resetSublinea();
        }
    }

    handleLineaOptions = () => {
        optLineas = [];
        this.props.linea.rows.map((linea) =>
            optLineas.push({ value: linea.id, label: linea.numeral + ' - ' + linea.nombre })
        );
        return optLineas;
    }

    handleSublineaChange = (selSublineaOption) => {
        /*  this.setState({
              ...this.state, selSublineaOption
          });*/
        const { clickProducto } = this.props;
        clickProducto('selSublineaOption', selSublineaOption);
    }

    handleSublineaOptions = () => {
        optSublineas = [];
        this.props.sublineas.rows.map((sublinea) =>
            optSublineas.push({ value: sublinea.id, label: sublinea.numeral + ' - ' + sublinea.nombre })
        );
        return optSublineas;
    }

    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar, selLineaOption, selSublineaOption } = this.state;
        const { getProductos } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            limit: size,
            offset: '0',
            text: txtBuscar
        }

        if (selLineaOption) {
            obj.linea = selLineaOption.value;
        }
        if (selSublineaOption) {
            obj.sublinea = selSublineaOption.value;
        }
        getProductos(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, txtBuscar, selLineaOption, selSublineaOption } = this.state;
        const { getProductos } = this.props;
        let pagina;

        let obj = {
            orderByLinea: 'asc',
            orderBySubLinea: 'asc',
            orderBy: 'asc',
            text: txtBuscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        if (selLineaOption) {
            obj.linea = selLineaOption.value;
        }
        if (selSublineaOption) {
            obj.sublinea = selSublineaOption.value;
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getProductos(obj);
    }





    render() {

        const { clickProducto, productos, selLineaOption, selSublineaOption, tipoVenta } = this.props;

        const { activePage, size, txtBuscar } = this.state;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input className="form-control form-control-sm ml-3 search-input" type="text" placeholder="Buscar producto" aria-label="Buscar producto" value={txtBuscar} onChange={this.changeBuscar} />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de productos</label>&nbsp;
                                <select id="size" className="select-num" value={size} onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleLineaOptions()} value={selLineaOption} placeholder="Filtrar por línea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleLineaChange} isClearable />
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleSublineaOptions()} value={selSublineaOption} placeholder="Filtrar por sublínea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSublineaChange} isClearable />
                    </div>
                </div>

                <br />

                <div className="row">
                    {productos.total > 0 ?
                        productos.rows.map(item =>
                            <div className="col-lg-3 col-md-3 mb-4" key={item.id}>
                                <div className="card collection-card z-depth-1-half" onClick={() => clickProducto('productoSeleccionado', item)}>
                                    <div className="view zoom">
                                        {item.imagenes.length > 0
                                            ?
                                            <img src={baseUrl + item.imagenes[0].url} className="rounded img-fluid" alt={item.nombre} />
                                            :
                                            <img src={imagen_thumbnail} className="rounded img-fluid" alt={item.nombre} />
                                        }
                                        <div className="stripe light">
                                            <a>
                                                <span>{item.nombre}</span>
                                                <p><b>$ {tipoVenta === 1 ? item.precioCredito.toLocaleString() : item.precioContado.toLocaleString()} </b></p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <div className="col-md-12">
                            <h6 className="text-center">No se encontraron elementos para mostrar</h6>
                        </div>
                    }
                </div>

                <Paginacion total={productos.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        );
    }
}

export default connect(state => ({
    productos: state.productos.productos,
    linea: state.catalogos.lineas,
    sublineas: state.catalogos.sublineas,

}), {
        getProductos: fetchGetProductos,
        getLineas: fetchGetCatLineas,
        getSublineas: fetchGetCatSublineas,
        resetSublinea: fetchResetSublineas
    })(Productos);