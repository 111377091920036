
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Button, Fa } from 'mdbreact';
import Paginacion from '../Shared/pagination';
import moment from 'moment';
import DateTime from 'react-datetime';
import { connect } from 'react-redux';
import { fetchGetAbonosCobrador } from '../../Reducers/cobradores';
import 'react-datetime/css/react-datetime.css';
import { NotificationManager } from 'react-notifications';

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    text: '',
    selectedOption: null,
    dpStart: moment(start),
    dpEnd: moment(end),
    startDate: moment(start).toISOString(),
    endDate: moment(end).toISOString(),
};

class ListaAbonos extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { getAbonos, cobrador } = this.props;
        const { startDate, endDate } = this.state;

        let obj = {
            cobrador: cobrador,
            limit: 10,
            offset: '0',
            fechaInicio: startDate,
            fechaFin: endDate
        }
        getAbonos(obj);
        this.statsInterval(startDate, endDate, '0');
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    statsInterval = (start, end, offsetList) => {
        const { getAbonos, cobrador } = this.props;

        let obj = {
            cobrador: cobrador,
            limit: 10,
            offset: offsetList,
            fechaInicio: start,
            fechaFin: end
        }

        try {
            this.interval = setInterval(async () => {
                getAbonos(obj);
            }, 45000);
        } catch (e) {
            console.log(e);
        }

    }

    buscar = () => {
        const { size, startDate, endDate } = this.state;
        const { getAbonos, cobrador } = this.props;
        clearInterval(this.interval);

        this.setState({ activePage: 1 });

        let obj = {
            cobrador: cobrador,
            limit: size,
            offset: '0',
            fechaInicio: startDate,
            fechaFin: endDate
        }
        getAbonos(obj);
        this.statsInterval(startDate, endDate, '0');
    }

    paginationHandler = (event, value) => {
        const { activePage, size, startDate, endDate } = this.state;
        const { getAbonos, cobrador } = this.props;
        let pagina;
        clearInterval(this.interval);

        let obj = {
            cobrador: cobrador,
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            fechaInicio: startDate,
            fechaFin: endDate
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getAbonos(obj);
        this.statsInterval(startDate, endDate, obj.offset);
    }

    handleChangeDTP = (name, value) => {
        const { dpStart } = this.state;
        switch (name) {
            case 'fromDate':
                this.setState({ dpStart: value, startDate: moment(value).toISOString(), });
                break;
            case 'toDate':
                if (!((value).isBefore(dpStart))) {
                    this.setState({ dpEnd: value, endDate: moment(value).toISOString() });
                }
                else {
                    NotificationManager.error('La fecha de fin no puede ser menor a la fecha de inicio.', 'Error en fechas');
                }
                break;
            default:
                break;
        }
    }

    render() {

        const { activePage, size, dpStart, dpEnd } = this.state;
        const { abonos } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">Del </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input"></i>
                            <DateTime name="fromDate" locale="es" inputProps={{ className: "dtpicker_center form-control input-modal" }} onChange={moment => this.handleChangeDTP("fromDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={dpStart} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">al </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input"></i>
                            <DateTime name="toDate" inputProps={{ className: "dtpicker_center form-control input-modal" }} locale="es" onChange={moment => this.handleChangeDTP("toDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" placeholder="Fecha final" value={dpEnd} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="form-inline float-md-right flex-nowrap">
                            <div className="form-group">
                                <label htmlFor="size">Número de registros</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Fecha</th>
                            <th>Cliente</th>
                            <th>Producto</th>
                            <th>Pago</th>
                            <th>Abono</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {
                            abonos.total > 0 ?
                                abonos.rows.map((abono, i) =>
                                    <tr key={i}>
                                        <td>{moment(abono.fechaVisita).format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{abono.cliente.nombre + ' ' + abono.cliente.apellidoP + ' ' + abono.cliente.apellidoM}</td>
                                        <td>{abono.venta.productos[0].nombre}</td>
                                        <td>{abono.venta.tipo.nombre + ' - ' + abono.venta.tipoCredito.nombre}</td>
                                        <td>${abono.total.toFixed(2).toLocaleString()}</td>
                                    </tr>
                                )
                                :
                                <tr><td colSpan="5">No se encontraron registros para mostrar.</td></tr>
                        }
                        {/* <tr>
                            <td>Carlos Macarena Martinez</td>
                            <td>Mueble Fancy</td>
                            <td>Mensual</td>
                            <td>$2000.00</td>
                       </tr>*/}
                    </TableBody>
                </Table>
                <Paginacion total={abonos.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </>
        )
    }
}


export default connect(state => ({
    abonos: state.cobradores.abonos,
}), {
        getAbonos: fetchGetAbonosCobrador,
    })(ListaAbonos);