import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, Button } from 'mdbreact';

class ProductosSeleccionados extends Component {
    constructor(props) {
        super(props)
    }

    getSaleTotal = () => {
        const { objEditar, vista } = this.props;
        let total = 0;
        if (vista === 1 || vista === 2) {
            objEditar.map(producto => total = total + (producto.precioContado * producto.cantidad));
        }
        else if (vista === 3) {
            objEditar.map(producto => total = total + (producto.precioContado * producto.RelVentaProducto.cantidad));
        }
        else {

        }

        return total;
    }

    render() {

        const { objEditar, changeCantidad, eliminarMueble, vista } = this.props;

        if (vista === 1) {
            return (

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Total</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {objEditar.length === 0 ?
                            <tr><td colSpan="5">No se han seleccionado productos</td></tr>
                            :
                            objEditar.map((obj, i) =>
                                <tr key={i}>
                                    <td>{obj.nombre}</td>
                                    <td>
                                        <input name={obj.id} type="number" min="1" className="input-cant-modal" value={obj.cantidad} onChange={(e) => changeCantidad(e)} />
                                    </td>
                                    <td>${obj.precioContado.toLocaleString()}</td>
                                    <td>${(obj.precioContado * obj.cantidad).toLocaleString()}</td>
                                    <td>
                                        <Button className="btn-opciones" color="danger" size="sm" onClick={() => eliminarMueble(obj.id)} ><Fa icon="trash" /></Button>
                                    </td>
                                </tr>
                            )
                        }
                    </TableBody>
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="text-right"></td>
                            <td>Total</td>
                            <td>${(this.getSaleTotal()).toLocaleString()}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </Table>
            )
        }
        else if (vista === 2) {
            return (

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Total</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {objEditar.length === 0 ?
                            <tr><td colSpan="4">No se han seleccionado productos</td></tr>
                            :
                            objEditar.map((obj, i) =>
                                <tr key={i}>
                                    <td>{obj.nombre}</td>
                                    <td>{obj.cantidad}</td>
                                    <td>${obj.precioContado.toLocaleString()}</td>
                                    <td>${(obj.precioContado * obj.cantidad).toLocaleString()}</td>
                                </tr>
                            )
                        }
                    </TableBody>
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="text-right"></td>
                            <td>Total</td>
                            <td>${(this.getSaleTotal()).toLocaleString()}</td>
                        </tr>
                    </tfoot>
                </Table>
            )
        }
        else if (vista === 3) {
            return (

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Total</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {objEditar.length === 0 ?
                            <tr><td colSpan="4">No se han seleccionado productos</td></tr>
                            :
                            objEditar.map((obj, i) =>
                                <tr key={i}>
                                    <td>{obj.nombre}</td>
                                    <td>{obj.RelVentaProducto.cantidad}</td>
                                    <td>${obj.precioContado.toLocaleString()}</td>
                                    <td>${(obj.precioContado * obj.RelVentaProducto.cantidad).toLocaleString()}</td>
                                </tr>
                            )
                        }
                    </TableBody>
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="text-right"></td>
                            <td>Total</td>
                            <td>${(this.getSaleTotal()).toLocaleString()}</td>
                        </tr>
                    </tfoot>
                </Table>
            )
        }
        else {
            return (<div></div>)
        }

    }
}

export default ProductosSeleccionados;