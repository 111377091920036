
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Alert } from 'mdbreact';
import { connect } from 'react-redux';
import '../../Css/pagos.css';
import { fetchGetPagosCliente } from '../../Reducers/clientes';
import moment from 'moment';

let adeudo = 0;

const initialState = {
    collapseID: -1
};

class HistorialCompras extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { getPagos, idCliente } = this.props;
        getPagos({ cliente: idCliente });
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    createTableBody = () => {
        const { pagos } = this.props;
        const { collapseID, adeudoTotal } = this.state;
        let tablaPagos = [];
        let pagosVenta = '';
        let rowPagos = '';
        let deuda = 0;
        let clase = '';

        pagos.rows.map((venta, k) => {
            let abonoEsperado = venta.abonoEstimado !== 0 ? venta.abonoEstimado : ((venta.total - venta.recaudado) / venta.tipoCredito.pagos);
            let restantePorPagar = venta.total - venta.recaudado;
            abonoEsperado = (restantePorPagar >= abonoEsperado) ? abonoEsperado : restantePorPagar;
            deuda += restantePorPagar;
            pagosVenta = venta.fechas.map((fecha, i) => {
                switch (fecha.evento.id) {
                    case 1:
                    case 6:
                        clase = 'lbl-pendiente';
                        break;
                    case 2:
                    case 5:
                    case 7:
                        clase = 'lbl-multi';
                        break;
                    case 3:
                        clase = 'lbl-atrasado';
                        break;
                    case 4:
                        clase = 'lbl-pagado';
                        break;
                }
                if (fecha.evento.id === 4) {
                    let evento = venta.abonos.find(abono => abono.FechaId === fecha.id);
                    rowPagos = { 'fechaISO': fecha.fechaOriginal ? fecha.fechaOriginal : fecha.fecha, 'fecha': moment(fecha.fechaOriginal ? fecha.fechaOriginal : fecha.fecha).format("DD/MM/YYYY HH:mm"), 'estado': fecha.evento.nombre, 'abono': evento.total };
                }
                else {
                    if (fecha.fechaOriginal) {
                        rowPagos = { 'fechaISO': fecha.fechaOriginal, 'fecha': moment(fecha.fechaOriginal).format("DD/MM/YYYY HH:mm"), 'estado': fecha.evento.nombre, 'abono': Math.ceil(abonoEsperado) };
                    }
                    else {
                        rowPagos = { 'fechaISO': fecha.fecha, 'fecha': moment(fecha.fecha).format("DD/MM/YYYY HH:mm"), 'estado': fecha.evento.nombre, 'abono': Math.ceil(abonoEsperado) };
                    }
                    restantePorPagar = restantePorPagar - abonoEsperado;
                    abonoEsperado = (restantePorPagar >= abonoEsperado) ? abonoEsperado : restantePorPagar;
                }
                let eventosFecha = [];
                if (fecha.fechasEventos.length > 0) {
                    eventosFecha = fecha.fechasEventos.filter(fecha => fecha.evento.id !== 6);
                }
                if (rowPagos.abono > 0) {
                    if (eventosFecha.length > 0) {
                        let cont = 0;
                        return (
                            <React.Fragment key={(k * 52 * pagos.total) + i}>
                                <tr onClick={this.toggleCollapse((k * 52 * pagos.total) + i)}>
                                    <td style={{ display: 'none' }}>{rowPagos.fechaISO}</td>
                                    <td>{rowPagos.fecha}</td>
                                    <td>${rowPagos.abono.toFixed(2).toLocaleString()}</td>
                                    <td className={clase}>{rowPagos.estado}</td>
                                    <td>{collapseID === ((k * 52 * pagos.total) + i) ? <i className="fas fa-angle-up fa-2x"></i> : <i className="fas fa-angle-down fa-2x"></i>}</td>
                                </tr>
                                {collapseID === (k * 52 * pagos.total) + i
                                    ?
                                    eventosFecha.map((event, j) => {
                                        if (event.evento.id !== 3) {
                                            cont++;
                                            return (
                                                <tr className="tr-detalles" key={(k * 52 * pagos.total) + i + j}>
                                                    <td className="monto-detalles">{moment(event.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                                    <td className="monto-detalles">Visita {cont}</td>
                                                    <td className="monto-detalles">{event.evento.nombre}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                <tr className="tr-detalles" key={(k * 52 * pagos.total) + i + j}>
                                                    <td className="monto-detalles">{moment(event.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                                    <td className="monto-detalles"></td>
                                                    <td className="monto-detalles">{event.evento.nombre}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        }
                                    })
                                    :
                                    null
                                }
                            </React.Fragment>
                        );
                    }
                    else {
                        return (
                            <tr key={(k * 52 * pagos.total) + i}>
                                <td style={{ display: 'none' }}>{rowPagos.fechaISO}</td>
                                <td>{rowPagos.fecha}</td>
                                <td>${rowPagos.abono.toFixed(2).toLocaleString()}</td>
                                <td className={clase}>{rowPagos.estado}</td>
                                <td></td>
                            </tr>
                        );
                    }
                }
            });
            Array.prototype.push.apply(tablaPagos, pagosVenta);
        });

        tablaPagos = tablaPagos.filter(elem => elem !== undefined);
        adeudo = deuda;
        tablaPagos.sort(function (a, b) {
            if (a.type === 'tr' && b.type === 'tr') {
                var dateA = moment(a.props.children[0].props.children).valueOf(), dateB = moment(b.props.children[0].props.children).valueOf();
                return dateA - dateB;
            }
            else if (a.type !== 'tr' && b.type === 'tr') {
                var dateA = moment(a.props.children[0].props.children[0].props.children).valueOf(), dateB = moment(b.props.children[0].props.children).valueOf();
                return dateA - dateB;
            }
            else if (a.type === 'tr' && b.type !== 'tr') {
                var dateA = moment(a.props.children[0].props.children).valueOf(), dateB = moment(b.props.children[0].props.children[0].props.children).valueOf();
                return dateA - dateB;
            }
            else if (a.type !== 'tr' && b.type !== 'tr') {
                var dateA = moment(a.props.children[0].props.children[0].props.children).valueOf(), dateB = moment(b.props.children[0].props.children[0].props.children).valueOf();
                return dateA - dateB;
            }
        });
        return tablaPagos;
    }

    render() {
        const { pagos } = this.props;

        if (pagos.total > 0) {
            return (
                <div className="col-md-12">
                    <Table className="tbl-css tbl-pagos" responsive>
                        <TableHead>
                            <tr>
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {this.createTableBody()}
                        </TableBody>
                    </Table>
                    <p className="adeudo-total">Adeudo total: ${adeudo.toFixed(2).toLocaleString()}</p>
                </div>
            )
        }
        else {
            return (
                <Alert color="warning" className="text-center"> No se encontraron registros para mostrar.</Alert>
            )
        }
    }
}

export default connect(state => ({
    pagos: state.clientes.pagosCliente
}), {
        getPagos: fetchGetPagosCliente
    })(HistorialCompras);