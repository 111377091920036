import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiGetPreclientes = async (obj) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'preclientes/?' + makeUrlGET(obj);
    return fetch(url, request).then(res => res.json());
};

export const apiAddPrecliente = async (body) => {
    var request = await CreateRequestImagen(POST, body);
    let url = baseUrl + `preclientes/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetPreclienteSeleccionado = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `preclientes/${id}/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiPutPrecliente = async (id) => {
    var request = await CreateRequest(PUT);
    let url = baseUrl + `preclientes/${id}/`;
    return fetch(url, request).then(res => res.status);
};

export const apiDeletePrecliente = async (id) => {
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `preclientes/${id}/`;
    return fetch(url, request).then(res => res.status);
};

export const apiGetPreventas = async (obj) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'preventas/?' + makeUrlGET(obj);
    return fetch(url, request).then(res => res.json());
};

export const apiAddPreventaCredito = async (body) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `preventas/credito/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiAddPreventaContado = async (body) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `preventas/contado/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetPreventaSeleccionada = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `preventas/${id}/`;
    return fetch(url, request).then(res => res.json());
};

export const apiPutPreventa = async (id) => {
    var request = await CreateRequest(PUT);
    let url = baseUrl + `preventas/${id}/`;
    return fetch(url, request).then(res => res.status);
};

export const apiDeletePreventas = async (id) => {
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `preventas/${id}/`;
    return fetch(url, request).then(res => res.status);
};

export const apiGetNotasPrecliente = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `preclientes/${id}/notas/`;
    return fetch(url, request).then(res => res.json());
};

export const apiAddNotaPrecliente = async (body, id) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `preclientes/${id}/notas/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiGetNotasPreventa = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `preventas/${id}/notas/`;
    return fetch(url, request).then(res => res.json());
};

export const apiAddNotaPreventa = async (body, id) => {
    var request = await CreateRequest(POST, body);
    let url = baseUrl + `preventas/${id}/notas/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiAddSupervisor = async (body, id, tipoSolicitud) => {
    var request = await CreateRequest(POST, body);
    let url = '';
    if (tipoSolicitud === 1 || tipoSolicitud === '1') {
        url = baseUrl + `preclientes/${id}/asignar/`;
    }
    if (tipoSolicitud === 2 || tipoSolicitud === '2') {
        url = baseUrl + `preventas/${id}/asignar`;
    }
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiTransferirVenta = async (body, id) => {
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + `preventas/${id}/transferir/`;
    return fetch(url, request).then(res => res.status);
};

export const apiGetFechasPagosPreview = async(id, obj) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `tipos/creditos/${id}/fechas/?` + makeUrlGET(obj);
    return fetch(url, request).then(res => res.json());
};

export const apiGetFechasPagosPreventa = async(id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `preventas/${id}/fechas/`;
    return fetch(url, request).then(res => res.json());
};