import {
    apiGetUsuarios,
} from '../Api/apiUsuarios';

import {
    apiGetLineas,
    apiGetSublineas
} from '../Api/apiProductos';

import {
    apiGetTipoVentas,
    apiGetTipoCredito
} from '../Api/apiCatalogos';

import { handleLoading } from './general';

const initialState = {
    usuariosCat: {
        rows: [],
        total: 0,
    },
    vendedoresCat: {
        rows: [],
        total: 0,
    },
    cobradoresCat: {
        rows: [],
        total: 0,
    },
    almacenistaCat: {
        rows: [],
        total: 0,
    },
    clientesCat: {
        rows: [],
        total: 0,
    },
    supervisoresCat: {
        rows: [],
        total: 0,
    },
    lineas: {
        rows: [],
        total: 0,
    },
    sublineas: {
        rows: [],
        total: 0,
    },
    ventas: {
        rows: [],
        total: 0,
    },
    creditos: {
        rows: [],
        total: 0,
    },
};

const GET_CAT_USUARIOS = "GET_CAT_USUARIOS";
const GET_CAT_VENDEDORES = "GET_CAT_VENDEDORES";
const GET_CAT_COBRADORES = "GET_CAT_COBRADORES";
const GET_CAT_ALMACENISTAS = "GET_CAT_ALMACENISTAS";
const GET_CAT_CLIENTES = "GET_CAT_CLIENTES";
const GET_CAT_SUPERVISORES = "GET_CAT_SUPERVISORES";
const GET_CAT_LINEAS = "GET_CAT_LINEAS";
const GET_CAT_SUBLINEAS = "GET_CAT_SUBLINEAS";
const GET_CAT_VENTAS = "GET_CAT_VENTAS";
const GET_CAT_CREDITOS = "GET_CAT_CREDITOS";

const getCatUsuarios = usuarios => ({ type: GET_CAT_USUARIOS, payload: usuarios });
const getCatVendedores = usuarios => ({ type: GET_CAT_VENDEDORES, payload: usuarios });
const getCatCobradores = usuarios => ({ type: GET_CAT_COBRADORES, payload: usuarios });
const getCatAlmacenista = usuarios => ({ type: GET_CAT_ALMACENISTAS, payload: usuarios });
const getCatCliente = usuarios => ({ type: GET_CAT_CLIENTES, payload: usuarios });
const getCatSupervisor = usuarios => ({ type: GET_CAT_SUPERVISORES, payload: usuarios });
const getCatLineas = lineas => ({ type: GET_CAT_LINEAS, payload: lineas });
const getCatSublineas = sublinea => ({ type: GET_CAT_SUBLINEAS, payload: sublinea });
const getCatVentas = ventas => ({ type: GET_CAT_VENTAS, payload: ventas });
const getCatCreditos = creditos => ({ type: GET_CAT_CREDITOS, payload: creditos });

export const fetchGetCatUsuarios = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetUsuarios(obj)
            .then((usuarios) => {
                dispatch(getCatUsuarios(usuarios));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatUsuarios", error);
                dispatch(getCatUsuarios(initialState.usuariosCat));
            });
    };
}

export const fetchGetCatUsuariosMultiple = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetUsuarios(obj)
            .then((usuarios) => {
                if (obj.tipo === 3) {
                    dispatch(getCatVendedores(usuarios));
                }
                if (obj.tipo === 4) {
                    dispatch(getCatCobradores(usuarios));
                }
                if (obj.tipo === 5) {
                    dispatch(getCatAlmacenista(usuarios));
                }
                if (obj.tipo === 6) {
                    dispatch(getCatCliente(usuarios));
                }
                if (obj.tipo === 7) {
                    dispatch(getCatSupervisor(usuarios));
                }

                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatUsuarios", error);
                dispatch(getCatUsuarios(initialState.usuariosCat));
            });
    };
}

export const fetchGetCatLineas = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetLineas(obj)
            .then((lineas) => {
                dispatch(getCatLineas(lineas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatLineas", error);
                dispatch(getCatLineas(initialState.lineas));
            });
    };
}

export const fetchGetCatSublineas = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetSublineas(obj)
            .then((sublinea) => {
                dispatch(getCatSublineas(sublinea));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatSublinea", error);
                dispatch(getCatSublineas(initialState.sublineas));
            });
    };
}

export const fetchGetCatVentas = () => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetTipoVentas()
            .then((ventas) => {
                dispatch(getCatVentas(ventas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatVentas", error);
                dispatch(getCatVentas(initialState.ventas));
            });
    };
}

export const fetchGetCatCredito = () => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetTipoCredito()
            .then((creditos) => {
                dispatch(getCatCreditos(creditos));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetCatVentas", error);
                dispatch(getCatCreditos(initialState.creditos));
            });
    };
}

export const fetchResetSublineas = () => {
    return dispatch => {
        dispatch(getCatSublineas(initialState.sublineas));
    };
}

const catalogosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAT_USUARIOS:
            return { ...state, usuariosCat: action.payload };
        case GET_CAT_VENDEDORES:
            return { ...state, vendedoresCat: action.payload };
        case GET_CAT_COBRADORES:
            return { ...state, cobradoresCat: action.payload };
        case GET_CAT_ALMACENISTAS:
            return { ...state, almacenistaCat: action.payload };
        case GET_CAT_CLIENTES:
            return { ...state, clientesCat: action.payload };
        case GET_CAT_SUPERVISORES:
            return { ...state, supervisoresCat: action.payload };
        case GET_CAT_LINEAS:
            return { ...state, lineas: action.payload };
        case GET_CAT_SUBLINEAS:
            return { ...state, sublineas: action.payload };
        case GET_CAT_VENTAS:
            return { ...state, ventas: action.payload };
        case GET_CAT_CREDITOS:
            return { ...state, creditos: action.payload };
        default:
            return { ...state };
    }
};

export default catalogosReducer;