import React, { Component } from 'react';
import { Map, TileLayer, Marker, Tooltip, LayersControl } from 'react-leaflet';
import { iconPagado, iconAtrasado, iconMultiple, iconPendiente } from './Iconos';
import { Alert } from 'mdbreact';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import pinVerde from '../../Img/pin-pagado.png';
import pinRojo from '../../Img/pin-atrasado.png';
import pinAmarillo from '../../Img/pin-multi.png';
import pinNaranja from '../../Img/pin-pendiente.png';
require('react-leaflet-markercluster/dist/styles.min.css');

let position;
let zoomMap;

class MapaMonitor extends Component {
  constructor() {
    super();
    this.state = {
      lat: 29.0667,
      lng: -110.9667,
      zoom: 11,
      showNoClientAlert: false
    };
  }

  componentDidMount() {
    this.map = this.mapInstance.leafletElement;
    this.addLegend();
  }

  componentDidUpdate() {
    const { pendientes, abonos, pospuestos } = this.props.clientesMapa;
    const { showNoClientAlert } = this.state;
    let totalClientes = {
      total: pendientes.total + abonos.total + pospuestos.total,
      rows: pendientes.rows.concat(abonos.rows, pospuestos.rows)
    };
    let positionsList = [];
    if (totalClientes.total > 1) {
      totalClientes.rows.map(v => {
        positionsList.push([v.venta.cliente.infoCliente.lat, v.venta.cliente.infoCliente.lng])
      });
      let mapBounds = L.latLngBounds(positionsList);
      this.map.fitBounds(mapBounds.pad(0));
      if (showNoClientAlert) {
        this.setState({ showNoClientAlert: false });
      }
    }
    else if (totalClientes.total === 1) {
      const { infoCliente } = totalClientes.rows[0].venta.cliente;
      position = [infoCliente.lat, infoCliente.lng];
      this.map.flyTo(position, 16);
      if (showNoClientAlert) {
        this.setState({ showNoClientAlert: false });
      }
    }
    else {
      if (!showNoClientAlert) {
        this.setState({ showNoClientAlert: true });
      }
    }
  }

  addLegend = () => {
    var Legend = L.Control.extend({
      options: {
        position: 'bottomright'
      },

      onAdd: function (map) {
        var legend = L.DomUtil.create('div', 'info legend');

        legend.innerHTML +=
          `<img src=${pinVerde} alt="Pendiente" style="width:20px;height:25px;"> Pagado <br/> 
          <img src=${pinAmarillo} alt="Multiple" style="width:20px;height:25px;"> Pospuesto/Cliente ausente <br/> 
          <img src=${pinNaranja} alt="Pendiente" style="width:20px;height:25px;"> Pendiente/Cobrador ausente <br/> 
          <img src=${pinRojo} alt="Atrasado" style="width:20px;height:25px;"> Atrasado <br/> 
          `;

        return legend;
      }
    });

    this.map.addControl(new Legend());
  }

  getIcon = (id) => {
    switch (id) {
      case 1:
      case 6:
        return iconPendiente;
      case 2:
      case 5:
      case 7:
        return iconMultiple;
      case 3:
        return iconAtrasado;
      case 4:
        return iconPagado;
    }
  }

  render() {
    const { lat, lng, zoom, showNoClientAlert } = this.state;
    const { pendientes, abonos, pospuestos } = this.props.clientesMapa;

    position = [lat, lng];
    zoomMap = zoom;

    return (
      <>
        {showNoClientAlert && <Alert color="warning" className="text-center"> No hay clientes por visitar en la fecha seleccionada.</Alert>}

        <Map center={position} zoom={zoomMap} id="mapaMonitor" ref={e => { this.mapInstance = e }} maxZoom={18}>

          <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {pendientes.rows.map((v, i) =>
              <Marker position={[v.venta.cliente.infoCliente.lat, v.venta.cliente.infoCliente.lng]} icon={this.getIcon(v.evento.id)} key={i}>
                <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                  <span>{v.venta.cliente.nombre + ' ' + v.venta.cliente.apellidoP + ' ' + v.venta.cliente.apellidoM} <br /> {v.venta.cliente.direccion} </span>
                </Tooltip>
              </Marker>
            )}
            {abonos.rows.map((v, i) =>
              <Marker position={[v.venta.cliente.infoCliente.lat, v.venta.cliente.infoCliente.lng]} icon={this.getIcon(v.evento.id)} key={i}>
                <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                  <span>{v.venta.cliente.nombre + ' ' + v.venta.cliente.apellidoP + ' ' + v.venta.cliente.apellidoM} <br /> {v.venta.cliente.direccion} </span>
                </Tooltip>
              </Marker>
            )}
            {pospuestos.rows.map((v, i) =>
              <Marker position={[v.venta.cliente.infoCliente.lat, v.venta.cliente.infoCliente.lng]} icon={this.getIcon(v.evento.id)} key={i}>
                <Tooltip direction='top' offset={[0, -40]} opacity={1} >
                  <span>{v.venta.cliente.nombre + ' ' + v.venta.cliente.apellidoP + ' ' + v.venta.cliente.apellidoM} <br /> {v.venta.cliente.direccion} </span>
                </Tooltip>
              </Marker>
            )}

          </MarkerClusterGroup>
        </Map>
      </>
    );

  }
}

export default MapaMonitor;