import jwtDecode from "jwt-decode";

import {
    apiHandleAuth,
    apiGetMe,
    apiUpdateMe
} from '../Api/apiAuth';

import {
    handleLoading,
    fetchHandleAlert,
} from './general';

import { NotificationManager } from 'react-notifications';

const initState = {
    user: {
        token: '',
        tipo: {}
    },
    editViewProfile: false
}

const SIGN_IN_USER = 'SIGN_IN_USER';
const SIGN_OUT_USER = 'SIGN_OUT_USER';
const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
const SHOW_PERFIL_FORM = "SHOW_PERFIL_FORM";

const signInUser = user => ({ type: SIGN_IN_USER, payload: user });
const signOutUser = () => ({ type: SIGN_OUT_USER });
const updateMyInfo = info => ({ type: UPDATE_USER_INFO, payload: info });
const verFormEdPerfil = () => ({ type: SHOW_PERFIL_FORM });

export const signIn = (username, password) => {
    return dispatch => {
        dispatch(handleLoading());
        apiHandleAuth(username, password, 'POST')
            .then((resp) => {
                dispatch(handleLoading());
                if (resp.token) {
                    const decoded = jwtDecode(resp.token);
                    if (decoded.tipo < 4) {
                        window.localStorage.setItem("token", resp.token);
                        window.location.href = "/";
                    }
                    else {
                        dispatch(fetchHandleAlert({ show: true, type: 'error', title: 'Acceso restringido', text: 'El usuario no tiene permiso para ingresar al sistema.' }));
                    }
                }
                else if (resp.message) {
                    let mensaje = '';
                    if (resp.message === "password incorrecto") {
                        mensaje = 'Correo/contraseña incorrectos'
                    }
                    else if (resp.message === "Has exceeded max number of login tries. Retry after 10 mins.") {
                        mensaje = "Ha excedido el número de intentos permitidos. Intente después de 10 mins.";
                    }
                    else {
                        mensaje = resp.message;
                    }
                    dispatch(fetchHandleAlert({ show: true, type: 'warning', title: 'Error', text: mensaje }));
                }
                else {
                    dispatch(fetchHandleAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                }
            })
            .catch((error) => {
                dispatch(handleLoading());
                dispatch(fetchHandleAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                console.log("errorLogin", error);
            });
    };
}

export const fetchGetMe = () => {
    return dispatch => {
        apiGetMe()
            .then((res) => {
                if (res.status === 200) {
                    dispatch(signInUser(res.body));
                }
                else {
                    dispatch(fetchHandleAlert({ show: true, type: 'error', title: 'Error', text: 'Ha ocurrido un error, por favor intente más tarde.' }));
                    dispatch(signInUser(initState.user));
                }
            })
            .catch((error) => {
                console.log("errorGetMe", error);
                dispatch(signInUser(initState.user));
            });
    };
}

export const fetchUpdateMe = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateMe(body)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateMyInfo(res.body));
                    dispatch(verFormEdPerfil());
                    NotificationManager.success('Información guardada correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeUsuario", error);
                NotificationManager.error(error, 'Error');
                dispatch(handleLoading());
            });
    };
}

export const signOut = () => {
    return (dispatch) => {
        dispatch(signOutUser());
        window.localStorage.removeItem("token");
        window.location.href = "/Login";
    }
};

export const fetchEditViewPerfil= () => {
    return dispatch => {
        dispatch(verFormEdPerfil());
    }
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case SIGN_IN_USER:
            return { ...state, user: { token: localStorage.token, ...action.payload } };
        case SIGN_OUT_USER:
            return { ...state, user: {} };
        case UPDATE_USER_INFO:
            return { ...state, user: { token: localStorage.token, ...action.payload } };
        case SHOW_PERFIL_FORM:
            return { ...state, editViewProfile: !state.editViewProfile };
        default:
            return state;
    }
};

export default authReducer;