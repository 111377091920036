import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaProductos from './lista';
import AltaProducto from './alta';
import Tendencias from './tendencias';
import EditarProducto from './editar';
import { connect } from 'react-redux';
import { fetchSeleccionarProducto, fetchEditViewProductos } from '../../Reducers/productos';

import '../../Css/productos.css';

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1
        }
    }

    componentWillUnmount() {
        const { seleccionar, editarProducto, editar } = this.props;        
        if (editar) {
            editarProducto();
            seleccionar(-1);
        }
    }

    handleTabChange = (id) => {
        this.setState({ tab: id });
    }

    editarProducto = (id) => {
        const { seleccionar, editarProducto } = this.props;
        seleccionar(id);
        editarProducto();
    }

    cancelarEditar = (e) => {
        e.preventDefault();
        const { seleccionar, editarProducto } = this.props;
        seleccionar(-1);
        editarProducto();
    }

    render() {
        const { tab } = this.state;
        const { editar } = this.props;

        if (editar) {
            return (
                <EditarProducto fnCancelar={this.cancelarEditar} vista='lista' />
            )
        }
        else {
            return (
                <div>
                    <Card className="card-css">
                        <CardHeader>
                            <Nav header>
                                <NavItem>
                                    <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de productos</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nuevo producto</NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <div className="col-md-12">
                                {tab === 1 && <ListaProductos fnEditar={this.editarProducto} />}
                                {tab === 2 && <AltaProducto />}
                            </div>
                        </CardBody>
                    </Card>
                    { /* <br />
                    {tab === 1 && <Tendencias />}*/}
                </div>
            )

        }
    }
}


export default connect(state => ({
    editar: state.productos.editViewProducto,
}), {
        seleccionar: fetchSeleccionarProducto,
        editarProducto: fetchEditViewProductos,
    })(Productos);
