import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../Css/solicitudes.css';
import { Card, CardBody, Button, ListGroupItem } from "mdbreact";
import { baseUrl } from '../../Api/general';
import moment from 'moment';
import Notas from './notas';
import MultipleSeleccion from '../Ventas/productos-seleccionados';

class PreclientePreventa extends Component {

    calcPendiente = () => {
        const { preVenta } = this.props;
        let pendiente = '';
        let costoCredito = 0;
        let anticipo = 0;
        if (preVenta.tipo.id === 1) {
            costoCredito = parseFloat(preVenta.productos[0].precioCredito);
            anticipo = parseFloat(preVenta.anticipo);
            pendiente = '$' + (costoCredito - anticipo).toLocaleString();
        }
        else {
            pendiente = '$0.00';
        }
        return pendiente;
    }

    calcAbonos = () => {
        const { preVenta } = this.props;
        let abonos = '';
        let pagos = 0;
        let monto = 0;
        let pendiente = 0;

        if (preVenta.tipo.id === 1) {
            pendiente = parseFloat(preVenta.productos[0].precioCredito) - parseFloat(preVenta.anticipo);
            pagos = preVenta.tipoCredito.pagos;
            monto = pendiente / pagos;
            abonos = pagos + ' pagos de $' + monto.toLocaleString();
        }
        return abonos;
    }

    returnFechas = () => {
        const { fechas } = this.props;
        let fechasPagos = '';
        if (fechas.total > 0) {
            fechasPagos = fechas.rows.map((fecha, k) =>
                <ListGroupItem className="list-fechas" key={k}>
                    <p className="mb-1">{moment(fecha.fecha).format('DD/MM/YYYY')}</p>
                </ListGroupItem>
            );
        }
        return fechasPagos;
    }

    render() {

        const { handleModal, handleAcceptAlert, handleDeleteAlert, preVenta, usuario, notasPreventa,
            notasPrecliente, handleModalNota, handleModalSupervisor, fechas } = this.props;

        let urlINE1 = '';
        let urlINE2 = '';
        let urlDom = '';
        let perfil = '';
        let aval1 = { nombre: '', telefono: '', direccion: '' };
        let aval2 = { nombre: '', telefono: '', direccion: '' };
        let laboral = { nombre: '', telefono: '', direccion: '' };

        if (preVenta.preCliente.imagenes && preVenta.preCliente.imagenes.length > 0) {
            perfil = baseUrl + preVenta.preCliente.imagenes.find(image => image.tipo.id === 1).url;
            urlINE1 = baseUrl + preVenta.preCliente.imagenes.find(image => image.tipo.id === 2).url;
            urlDom = baseUrl + preVenta.preCliente.imagenes.find(image => image.tipo.id === 3).url;
            urlINE2 = baseUrl + preVenta.preCliente.imagenes.find(image => image.tipo.id === 5).url;
        }

        if (preVenta.preCliente.referenciaAval1) {
            aval1 = JSON.parse(preVenta.preCliente.referenciaAval1);
        }
        if (preVenta.preCliente.referenciaAval2) {
            aval2 = JSON.parse(preVenta.preCliente.referenciaAval2);
        }
        if (preVenta.preCliente.referenciaLaboral) {
            laboral = JSON.parse(preVenta.preCliente.referenciaLaboral);
        }

        return (
            <Card className="card-css">
                <CardBody>
                    <div className="row">
                        <div className="col-md-1 col-sm-2" style={{ paddingTop: "5px" }}>
                            <Link className="btn-back" to={'/Solicitudes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                        </div>
                        {(usuario.tipo.id === 1 || usuario.tipo.id === 2) && <div className="col-md-11 col-sm-12 text-right-md">
                            <Button className="btn-detalles transferir" onClick={() => handleModal()}><i className="fas fa-exchange-alt"></i>Transferir a cliente existente</Button>
                            {!preVenta.supervisor && <Button className="btn-detalles supervisor" onClick={() => handleModalSupervisor()}><i className="fas fa-user"></i>Cambiar supervisor</Button>}
                            <Button className="btn-detalles aceptar" onClick={() => handleAcceptAlert()}><i className="fas fa-check"></i>Aceptar</Button>
                            <Button className="btn-detalles eliminar" onClick={() => handleDeleteAlert()}><i className="far fa-trash-alt"></i>Eliminar</Button>
                        </div>}
                    </div>

                    <hr className="gral-hr" />
                    <div className="body-generales" >
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Tipo solicitud</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">Nuevo cliente y venta</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Fecha y hora</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{moment(preVenta.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Supervisor</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.supervisor ? preVenta.supervisor.nombre + ' ' + preVenta.supervisor.apellidoP + ' ' + preVenta.supervisor.apellidoM : ''}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de cliente</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.preCliente.nombre} {preVenta.preCliente.apellidoP} {preVenta.preCliente.apellidoM}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.preCliente.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin">{preVenta.preCliente.email}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin">{preVenta.preCliente.direccion}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado a)</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE1} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado b)</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE2} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Cliente</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={perfil} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Comprobante</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlDom} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información laboral</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{laboral.direccion}</div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de avales</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 1</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval1.direccion}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 2</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval2.direccion}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de producto</b></h5>
                            </div>
                        </div>
                        {preVenta.tipo.id === 1 &&
                            <>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de producto</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].nombre}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Código de barras</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].codigoBarras}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Precio de contado</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioContado.toFixed(2).toLocaleString()}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Código numeral</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].numeralEditable}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Precio a crédito</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioCredito.toFixed(2).toLocaleString()}</div>
                                </div>
                            </>
                        }
                        {preVenta.tipo.id === 2 && <MultipleSeleccion vista={3} objEditar={preVenta.productos} />}

                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de pago</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Tipo de pago </b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.tipo.nombre}</div>
                            {preVenta.tipo.id === 1 &&
                                <>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Frecuencia</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.tipoCredito.nombre}</div>
                                </>
                            }
                        </div>
                        {preVenta.tipo.id === 1 &&
                            <>
                                {/*<div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Días de cobro</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"></div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Hora sugerida de cobro</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"></div>
                        </div>*/}
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Precio crédito</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioCredito.toFixed(2).toLocaleString()}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Anticipo</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.anticipo.toFixed(2).toLocaleString()}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Pendiente</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{this.calcPendiente()}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Abonos</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{this.calcAbonos()}</div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Fecha de cobro</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('DD/MM/YYYY') : ''}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Hora sugerida de cobro</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('HH:mm') : ''}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 input-margin"><b>Fechas de pagos:</b></div>
                                    <div className="col-md-12 col-sm-12 input-margin">
                                        <ul className="list-inline">
                                            {this.returnFechas()}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de vendedor</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.vendedor.nombre} {preVenta.vendedor.apellidoP} {preVenta.vendedor.apellidoM}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.vendedor.telefono}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h5><b>Notas</b></h5>
                            </div>
                            {(usuario.tipo.id === 1 || usuario.tipo.id === 2) && <div className="col-md-6 col-sm-6 text-right">
                                <Button className="btn-detalles transferir" onClick={() => handleModalNota()}><i className="fas fa-plus"></i>Agregar nota</Button>
                            </div>}
                        </div>
                        <br />
                        <div className="row">
                            <Notas notasPrecliente={notasPrecliente} notasPreventa={notasPreventa} />
                        </div>
                        <br />
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default PreclientePreventa;