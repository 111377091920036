import React, { Component } from 'react';
import { Input, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';

import { NotificationManager } from 'react-notifications';

import { connect } from 'react-redux';
import { fetchAddCobrador } from '../../Reducers/cobradores';

import Search from '../SearchBar/Search';

class AltaCobrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
      perfilImg: null,
      imageValid: true,
      nombre: '',
      apellidoP: '',
      apellidoM: '',
      telefono: {
        value: '',
        valid: true,
      },
      domicilio: {
        value: '',
        valid: true,
      },
      correo: {
        value: '',
        valid: true,
      },
      usuario: {
        value: '',
        valid: true
      },
      contrasena: {
        value: '',
        valid: true,
      },
      lugar: {
        direccion: '',
        lat: 0,
        lng: 0,
        valid: false
      },
      imei: '',
      repetirContrasena: '',
      nombreReferencia: '',
      telReferencia: '',
      mapsReady: false
    }
    /*this.onImageChange = this.onImageChange.bind(this);
    this.clearFileInput = this.clearFileInput.bind(this);
    this.handleSelectOptions = this.handleSelectOptions.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);*/
  }

  componentDidMount() {
    const isLoaded = document.getElementById("maps-url");
    if (!isLoaded) {
      const script = document.createElement('script');
      script.onload = () => {
        this.setState({ mapsReady: true });
      };
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxEwucH4e-Hs6d2waD9gReIJH9VgBSpGI&libraries=places';
      script.id = 'maps-url';

      document.body.appendChild(script);

    }
    else {
      this.setState({ mapsReady: true });
    }
  }

  addressChange = (name, info) => {

    this.geocoder = new window.google.maps.Geocoder;
    let lat = 0;
    let lng = 0;
    let domicilio = "";
    if (info.id !== -1) {
      this.geocoder.geocode({ 'placeId': info.id }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.setState({
            lugar: {
              direccion: info.nombre,
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
              valid: true
            }
          });
        }
      });
    }
    else {
      this.setState({
        lugar: {
          direccion: domicilio,
          lat: lat,
          lng: lng,
          valid: false
        }
      });

    }
  }

  onImageChange = (event) => {
    event.persist();
    const { files } = event.target;

    if (files && files[0]) {
      if (files[0].size > 5000000) {
        NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
        this.setState({ perfilImg: files[0], imageValid: false });
      }
      else {
        this.setState({ perfilImg: files[0], imageValid: true });
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: [e.target.result] })
      };
      reader.readAsDataURL(files[0]);
    }
  }

  handleChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      case 'usuario':
        let filteredUsername = value.replace(/[^a-zA-z._0-9]+/g, '');
        this.setState({
          [id]: {
            value: filteredUsername.toLowerCase().trim(),
            valid: RegExp(/^(?=.{4,}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+[a-zA-Z0-9]+$/).test(filteredUsername),
          }
        });
        break;
      case 'correo':
        let filteredEmail = value.replace(/[^a-zA-z._0-9@-]+/g, '').toLowerCase().trim();
        this.setState({
          [id]: {
            value: filteredEmail,
            valid: filteredEmail === '' ? true : RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(filteredEmail),
          }
        });
        break;
      case 'contrasena':
        this.setState({
          [id]: {
            value: value,
            valid: RegExp(/^.{6,}$/).test(value),
          }
        });
        break;
      case 'telefono':
        let filteredPhone = value.replace(/[^0-9]+/g, '');
        this.setState({
          [id]: {
            value: filteredPhone,
            valid: filteredPhone === '' ? true : RegExp(/^.{10,}$/).test(filteredPhone)
          }
        });
        break;
      case 'telReferencia':
        let filteredRefPhone = value.replace(/[^0-9]+/g, '');
        this.setState({ [id]: filteredRefPhone });
        break;
      case 'domicilio':
        this.setState({
          [id]: {
            value: value,
            valid: value === '' ? true : RegExp(/.*[^ ]{2,}.*/).test(value),
          }
        });
        break;
      default:
        this.setState({ [id]: value });
        break;
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { save } = this.props;
    const { imageValid, perfilImg, lugar, nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena, repetirContrasena, nombreReferencia, telReferencia, imei } = this.state;

    if (contrasena.value !== repetirContrasena) {
      NotificationManager.warning('Las contraseñas ingresadas no coinciden', 'Error en contraseña');
    }
    else if (!perfilImg) {
      NotificationManager.warning('La imagen de perfil es obligatoria.', 'Imagen de perfil');
    }
    else if (!telefono.valid || !domicilio.valid || !correo.valid || !usuario.valid || !contrasena.valid) {
      NotificationManager.warning('La información del formulario contiene errores.', 'Información con errores');
    }
    else if (!lugar.valid) {
      NotificationManager.warning('La zona de operación no ha sido seleccionada.', 'Información incompleta');
    }
    else if (!imageValid) {
      NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
    }
    else {
      const datos = {
        'perfilImg': perfilImg,
        'nombre': nombre,
        'apellidoP': apellidoP,
        'apellidoM': apellidoM,
        'username': usuario.value,
        'password': contrasena.value,
        'referencia': JSON.stringify({ 'nombre': nombreReferencia, 'telefono': telReferencia }),
        'zona': lugar.direccion,
        'lat': lugar.lat,
        'lng': lugar.lng
      }
      if (telefono.value !== '') {
        datos.telefono = telefono.value;
      }
      if (domicilio.value.trim() !== '') {
        datos.direccion = domicilio.value;
      }
      if (correo.value !== '') {
        datos.email = correo.value;
      }
      if (imei.trim() !== '') {
        datos.imei = imei;
      }
      save(datos);
    }
  }

  clearFileInput = () => {
    this.fileInput.value = "";
    this.setState({ image: [], perfilImg: null });
  }

  render() {

    const { mapsReady, image, nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena, repetirContrasena, nombreReferencia, telReferencia, imei } = this.state;

    return (

      <div className="col-md-12">
        <form autoComplete="off" id="frm_cobrador" onSubmit={(e) => this.submit(e)}>
          <div className="row">
            <div className="col-md-12">
              <h5>Información de cobrador</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Nombre(s)" value={nombre} type="text" name="nombre" onChange={this.handleChange} id="nombre" required pattern=".*[^ ].*" minLength="2" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Primer apellido" value={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} id="apellidoP" required pattern=".*[^ ].*" minLength="2" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Segundo apellido" value={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} id="apellidoM" required pattern=".*[^ ].*" minLength="2" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Teléfono" value={telefono.value} type="text" name="telefono" onChange={this.handleChange} id="telefono" minLength="10" />
              {!telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Domicilio" value={domicilio.value} type="text" name="domicilio" onChange={this.handleChange} id="domicilio" minLength="2" />
              {!domicilio.valid && <p className="form-alert">*La dirección tiene que ser de 2 caracteres mínimo</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Correo electrónico" value={correo.value} type="email" name="correo" onChange={this.handleChange} id="correo" />
              {!correo.valid && <p className="form-alert">*Formato de correo inválido</p>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Usuario" value={usuario.value} type="text" name="usuario" onChange={this.handleChange} id="usuario" required pattern=".*[^ ].*" minLength="4" />
              {!usuario.valid && <p className="form-alert">*Formato de usuario inválido</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Contraseña" value={contrasena.value} type="password" name="contrasena" onChange={this.handleChange} id="contrasena" required pattern=".*[^ ].*" minLength="6" />
              {!contrasena.valid && <p className="form-alert">*La contraseña debe de contener mínimo 6 caracteres</p>}
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Repetir contraseña" value={repetirContrasena} type="password" name="repetirContrasena" onChange={this.handleChange} id="repetirContrasena" required pattern=".*[^ ].*" minLength="6" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="form-inline md-form mr-auto m-0 form-direccion">
                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                {mapsReady && <Search id="txt-zona-operacion" handleChange={this.addressChange} labeltxt='Zona de operación' />}
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="IMEI" value={imei} type="text" name="imei" onChange={this.handleChange} id="imei" minLength="2" maxLength="255" />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12 col-12 col-sm-12 ">
              <p className="div-imagen">Elegir imagen de perfil:</p>
              <div className="file-field">
                <div className="btn btn-gris">
                  <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="group_image" ref={ref => this.fileInput = ref} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {
                typeof image !== 'undefined' && image.length > 0 ?
                  <div className="container-img">
                    <img id="thumb_img" src={image} style={{ maxHeight: "130px" }} alt='' />
                    <button className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
                  </div>
                  :
                  <img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />
              }
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <h5>Información de referencia</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <span className="mb-2 text-muted">En caso de algún accidente o emergencia contactaremos a la persona en esta referencia</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Nombre completo" value={nombreReferencia} type="text" name="nombreReferencia" onChange={this.handleChange} id="nombreReferencia" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Teléfono" value={telReferencia} type="text" name="telReferencia" onChange={this.handleChange} id="telReferencia" />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-crear" >Crear cobrador</button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(state => ({}), {
  save: fetchAddCobrador
})(AltaCobrador);