import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../Css/solicitudes.css';
import {
    fetchGetPrecliente, fetchShowModalTransferencias, fetchAceptarPrecliente, fetchAddSupervisor,
    fetchEliminarPrecliente, fetchAceptarPreventa, fetchEliminarPreventa, fetchTransferirVentaCliente,
    fetchGetPreventa, fetchResetSolicitud, fetchGetNotasPreclientes, fetchAddNotasPrecliente,
    fetchGetNotasPreventas, fetchAddNotasPreventa, fetchHandleModalNotas, fetchHandleModalSupervisores,
    fetchUpdateTransfer, fetchGetFechasPagosPreventa
} from '../../Reducers/solicitudes';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchGetCatUsuariosMultiple } from '../../Reducers/catalogos';
import { fetchGetSelected, fetchResetUsers } from '../../Reducers/usuarios';
import { NotificationManager } from 'react-notifications';
import Precliente from './detalles-precliente';
import Preventa from './detalles-preventa';
import PreclientePreventa from './detalles-preventa-precliente';
import ModalClientes from './modal-transferir';
import ModalNotas from './modal-notas';
import ModalSupervisores from './modal-supervisor';
import { withRouter } from 'react-router-dom';

let optsClientes = [];
let optsSupervisores = [];

class DetallesSolicitud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idTipo: 0,
            idSolicitud: 0,
            selCliente: null,
            showClientDetails: false,
            nota: '',
            selSupervisor: null
        }
    }

    componentDidMount() {
        const { getFechas, getPreCliente, getPreVenta, getNotasPrecliente, getNotasPreventa } = this.props;
        const { uuid, tipoId } = this.props.match.params;
        this.setState({ idTipo: tipoId, idSolicitud: uuid });
        if (tipoId === '1') {
            getPreCliente(uuid);
            getNotasPrecliente(uuid);
        }
        else if (tipoId === '2' || tipoId === '3') {
            getPreVenta(uuid);
            getNotasPreventa(uuid);
            getFechas(uuid);
        }
        else {
            NotificationManager.error('Solicitud no encontrada', 'Error');
        }

    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleDeleteAlert = () => {
        const { showMessage, preCliente, preVenta } = this.props;
        const { idTipo } = this.state;

        let nombre = '';
        switch (parseInt(idTipo)) {
            case 1:
                nombre = preCliente.nombre + ' ' + preCliente.apellidoP + ' ' + preCliente.apellidoM;
                break;
            case 2:
                nombre = preVenta.cliente.nombre + ' ' + preVenta.cliente.apellidoP + ' ' + preVenta.cliente.apellidoM;
                break;
            case 3:
                nombre = preVenta.preCliente.nombre + ' ' + preVenta.preCliente.apellidoP + ' ' + preVenta.preCliente.apellidoM;
                break;
        };

        showMessage({
            show: true,
            type: 'warning',
            title: 'Eliminar solicitud',
            text: `¿Desea eliminar la solicitud de ${nombre}?`,
            confirmButtonText: 'Eliminar',
            onConfirm: this.deleteSolicitud,
            showCancel: true
        });
    }

    deleteSolicitud = () => {
        const { hideMessage, eliminarPreCliente, eliminarPreventa, preCliente, preVenta } = this.props;
        const { idTipo } = this.state;

        hideMessage();
        switch (parseInt(idTipo)) {
            case 1:
                eliminarPreCliente(preCliente.id);
                break;
            case 2:
            case 3:
                eliminarPreventa(preVenta.id);
                break;
        };
    }

    handleAcceptAlert = () => {
        const { showMessage, preCliente, preVenta } = this.props;
        const { idTipo } = this.state;
        let nombre = '';
        switch (parseInt(idTipo)) {
            case 1:
                nombre = preCliente.nombre + ' ' + preCliente.apellidoP + ' ' + preCliente.apellidoM;
                break;
            case 2:
                nombre = preVenta.cliente.nombre + ' ' + preVenta.cliente.apellidoP + ' ' + preVenta.cliente.apellidoM;
                break;
            case 3:
                nombre = preVenta.preCliente.nombre + ' ' + preVenta.preCliente.apellidoP + ' ' + preVenta.preCliente.apellidoM;
                break;
        };
        showMessage({
            show: true,
            type: 'warning',
            title: 'Aceptar solicitud',
            text: `¿Desea aceptar la solicitud de  ${nombre}?`,
            confirmButtonText: 'Aceptar',
            onConfirm: this.acceptSolicitud,
            showCancel: true
        });
    }

    acceptSolicitud = () => {
        const { hideMessage, aceptarPreCliente, aceptarPreventa, preCliente, preVenta } = this.props;
        const { idTipo } = this.state;

        hideMessage();
        switch ((parseInt(idTipo))) {
            case 1:
                aceptarPreCliente(preCliente.id);
                break;
            case 2:
            case 3:
                aceptarPreventa(preVenta.id);
                break;
        };
    }

    handleClienteChange = (selCliente) => {
        const { getSelected, resetSelected } = this.props;
        this.setState({
            ...this.state, selCliente
        });
        if (selCliente) {
            this.setState({ showClientDetails: true });
            getSelected(selCliente.value);
        }
        else {
            this.setState({ showClientDetails: false });
            resetSelected();
        }
    }

    handleClientesOptions = () => {
        optsClientes = [];
        this.props.clientes.rows.map((cliente) =>
            optsClientes.push({ value: cliente.id, label: cliente.nombre + ' ' + cliente.apellidoP + ' ' + cliente.apellidoM })
        );
        return optsClientes;
    }

    handleSupervisorChange = (selSupervisor) => {
        this.setState({
            ...this.state, selSupervisor
        });
    }

    handleSupervisoresOptions = () => {
        optsSupervisores = [];
        this.props.supervisores.rows.map((supervisor) =>
            optsSupervisores.push({ value: supervisor.id, label: supervisor.nombre + ' ' + supervisor.apellidoP + ' ' + supervisor.apellidoM })
        );
        return optsSupervisores;
    }

    loadClientes = () => {
        const { getUsuarios } = this.props;
        let obj = {
            tipo: 6,
        };
        getUsuarios(obj);

        this.setState({ selCliente: null, showClientDetails: false });
    }

    loadSupervisores = () => {
        const { getUsuarios } = this.props;
        let obj = {
            tipo: 7,
            activo: 'true'
        };
        getUsuarios(obj);

        this.setState({ selSupervisor: null });
    }

    handleNotasChange = (e) => {
        let value = e.target.value;
        this.setState({ nota: value });
    }

    addNotaSolicitud = (e) => {
        e.preventDefault();
        const { nota, idTipo, idSolicitud } = this.state;
        const { addNotaPrecliente, addNotaPreventa } = this.props;

        if (nota.trim() === '') {
            NotificationManager.warning('El contenido de la nota no puede estar vacío.', 'Nota vacía');
        }
        else {
            let body = {
                "nota": nota
            }
            if (parseInt(idTipo) === 1) {
                addNotaPrecliente(body, idSolicitud);
            }
            else {
                addNotaPreventa(body, idSolicitud);
            }
        }
    }

    transferirVenta = (e, tipoTransfer) => {
        e.preventDefault();
        const { selCliente, idSolicitud } = this.state;
        const { transferirVenta, updateTransfer } = this.props;
        const { preCliente } = this.props.preVenta;

        if (!selCliente) {
            NotificationManager.warning('Debe seleccionar un cliente para continuar.', 'Cliente no seleccionado');
        }
        else {
            let bodyTransfer = {
                "ClienteId": selCliente.value
            };
            let bodyUpdate = {
                nombre: preCliente.nombre,
                apellidoP: preCliente.apellidoP,
                apellidoM: preCliente.apellidoM,
                telefono: preCliente.telefono,
                direccion: preCliente.direccion,
                email: preCliente.email,
                referenciaAval1: preCliente.referenciaAval1,
                referenciaAval2: preCliente.referenciaAval2,
                referenciaLaboral: preCliente.referenciaLaboral,
                lat: preCliente.lat,
                lng: preCliente.lng,
            }
            if (tipoTransfer === 1) {
                transferirVenta(bodyTransfer, idSolicitud);
            }
            else if (tipoTransfer === 2) {
                updateTransfer(bodyUpdate, bodyTransfer, selCliente.value, idSolicitud);
            }
            else { }
        }
    }

    agregarSupervisor = (e) => {
        e.preventDefault();
        const { selSupervisor, idTipo, idSolicitud } = this.state;
        const { addSupervisor } = this.props;

        if (!selSupervisor) {
            NotificationManager.warning('Debe seleccionar un supervisor para continuar.', 'Supervisor no seleccionado');
        }
        else {
            let body = {
                "SupervisorId": selSupervisor.value
            }
            let tipoSolicitud = (parseInt(idTipo) > 1 ? 2 : parseInt(idTipo));
            addSupervisor(body, idSolicitud, tipoSolicitud);
        }
    }

    render() {

        const { showModalTransferencia, handleModalTransferencia, handleModalSupervisor, handleModalNota, preCliente,
            preVenta, usuario, cliente, notasPrecliente, notasPreventa, showModalNotas, showModalSupervisor,
            fechasPagos
        } = this.props;
        const { idTipo, showClientDetails, selSupervisor, selCliente, nota } = this.state;

        if (idTipo === '1' && Object.entries(preCliente).length !== 0) {
            return (
                <div>
                    <Precliente preCliente={preCliente} usuario={usuario} handleAcceptAlert={this.handleAcceptAlert}
                        handleDeleteAlert={this.handleDeleteAlert} notasPrecliente={notasPrecliente} notasPreventa={notasPreventa}
                        handleModalNota={handleModalNota}
                        handleModalSupervisor={handleModalSupervisor}
                    />
                    <ModalNotas nota={nota} handleChange={this.handleNotasChange} agregarNota={this.addNotaSolicitud} handleModal={handleModalNota} showModalNotas={showModalNotas} />
                    <ModalSupervisores selSupervisor={selSupervisor} supervisorAsignado={''} handleChange={this.handleSupervisorChange} handleSupervisoresOptions={this.handleSupervisoresOptions} loadSupervisores={this.loadSupervisores} handleModal={handleModalSupervisor} showModalSupervisor={showModalSupervisor} agregarSupervisor={this.agregarSupervisor} />
                </div>
            )
        }
        else if (idTipo === '2' && Object.entries(preVenta).length !== 0 && preVenta.cliente) {
            return (

                <div>
                    <Preventa preVenta={preVenta} usuario={usuario} handleAcceptAlert={this.handleAcceptAlert}
                        handleDeleteAlert={this.handleDeleteAlert} notasPrecliente={notasPrecliente} notasPreventa={notasPreventa}
                        handleModalNota={handleModalNota}
                        handleModalSupervisor={handleModalSupervisor}
                        fechas={fechasPagos}
                    />
                    <ModalNotas nota={nota} handleChange={this.handleNotasChange} agregarNota={this.addNotaSolicitud} handleModal={handleModalNota} showModalNotas={showModalNotas} />
                    <ModalSupervisores selSupervisor={selSupervisor} supervisorAsignado={''} handleChange={this.handleSupervisorChange} handleSupervisoresOptions={this.handleSupervisoresOptions} loadSupervisores={this.loadSupervisores} handleModal={handleModalSupervisor} showModalSupervisor={showModalSupervisor} agregarSupervisor={this.agregarSupervisor} />
                </div>
            )
        }
        else if (idTipo === '3' && Object.entries(preVenta).length !== 0 && preVenta.preCliente) {
            return (
                <div>
                    <PreclientePreventa handleModal={handleModalTransferencia} preVenta={preVenta} usuario={usuario} handleAcceptAlert={this.handleAcceptAlert}
                        handleDeleteAlert={this.handleDeleteAlert} notasPrecliente={notasPrecliente} notasPreventa={notasPreventa}
                        handleModalNota={handleModalNota}
                        handleModalSupervisor={handleModalSupervisor}
                        showModalNotas={showModalNotas}
                        showModalSupervisor={showModalSupervisor}
                        showModalTransferencia={showModalTransferencia}
                        fechas={fechasPagos}
                    />
                    <ModalClientes showModal={showModalTransferencia} handleModal={handleModalTransferencia}
                        selCliente={selCliente} cliente={cliente} showClientDetails={showClientDetails}
                        handleClientesOptions={this.handleClientesOptions} handleClienteChange={this.handleClienteChange}
                        loadClientes={this.loadClientes}
                        transferirVenta={this.transferirVenta}
                    />
                    <ModalNotas nota={nota} handleChange={this.handleNotasChange} agregarNota={this.addNotaSolicitud} handleModal={handleModalNota} showModalNotas={showModalNotas} />
                    <ModalSupervisores selSupervisor={selSupervisor} supervisorAsignado={''} handleChange={this.handleSupervisorChange} handleSupervisoresOptions={this.handleSupervisoresOptions} loadSupervisores={this.loadSupervisores} handleModal={handleModalSupervisor} showModalSupervisor={showModalSupervisor} agregarSupervisor={this.agregarSupervisor} />
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}

export default withRouter(connect(state => ({
    preCliente: state.preregistros.preclienteSeleccionado,
    preVenta: state.preregistros.preventaSeleccionada,
    showModalTransferencia: state.preregistros.showModalTransferencia,
    showModalNotas: state.preregistros.showModalNotas,
    showModalSupervisor: state.preregistros.showModalSupervisor,
    usuario: state.auth.user,
    clientes: state.catalogos.clientesCat,
    supervisores: state.catalogos.supervisoresCat,
    cliente: state.usuarios.usuarioSeleccionado,
    notasPrecliente: state.preregistros.notasPrecliente,
    notasPreventa: state.preregistros.notasPreventa,
    fechasPagos: state.preregistros.fechasPagos,
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        handleModalTransferencia: fetchShowModalTransferencias,
        handleModalNota: fetchHandleModalNotas,
        handleModalSupervisor: fetchHandleModalSupervisores,
        getPreCliente: fetchGetPrecliente,
        getPreVenta: fetchGetPreventa,
        aceptarPreCliente: fetchAceptarPrecliente,
        eliminarPreCliente: fetchEliminarPrecliente,
        aceptarPreventa: fetchAceptarPreventa,
        eliminarPreventa: fetchEliminarPreventa,
        reset: fetchResetSolicitud,
        getUsuarios: fetchGetCatUsuariosMultiple,
        getSelected: fetchGetSelected,
        resetSelected: fetchResetUsers,
        getNotasPrecliente: fetchGetNotasPreclientes,
        addNotaPrecliente: fetchAddNotasPrecliente,
        getNotasPreventa: fetchGetNotasPreventas,
        addNotaPreventa: fetchAddNotasPreventa,
        addSupervisor: fetchAddSupervisor,
        transferirVenta: fetchTransferirVentaCliente,
        updateTransfer: fetchUpdateTransfer,
        getFechas: fetchGetFechasPagosPreventa
    })(DetallesSolicitud));