import L from 'leaflet';

const iconHome = new L.Icon({
    iconUrl: require('../../Img/PinCliente.png'),
    iconSize:     [40, 45], 
    shadowSize:   [50, 64],
    iconAnchor:   [20, 40], 
    shadowAnchor: [4, 62], 
    popupAnchor:  [0, -40] 
});

const iconPagado = new L.Icon({
    iconUrl: require('../../Img/pin-pagado.png'),
    iconSize:     [40, 45], 
    shadowSize:   [50, 64],
    iconAnchor:   [20, 40], 
    shadowAnchor: [4, 62], 
    popupAnchor:  [0, -40] 
});

const iconAtrasado = new L.Icon({
    iconUrl: require('../../Img/pin-atrasado.png'),
    iconSize:     [40, 45], 
    shadowSize:   [50, 64],
    iconAnchor:   [20, 40], 
    shadowAnchor: [4, 62], 
    popupAnchor:  [0, -40] 
});

const iconMultiple = new L.Icon({
    iconUrl: require('../../Img/pin-multi.png'),
    iconSize:     [40, 45], 
    shadowSize:   [50, 64],
    iconAnchor:   [20, 40], 
    shadowAnchor: [4, 62], 
    popupAnchor:  [0, -40] 
});

const iconPendiente = new L.Icon({
    iconUrl: require('../../Img/pin-pendiente.png'),
    iconSize:     [40, 45], 
    shadowSize:   [50, 64],
    iconAnchor:   [20, 40], 
    shadowAnchor: [4, 62], 
    popupAnchor:  [0, -40] 
});
export { iconHome, iconPagado, iconAtrasado, iconMultiple, iconPendiente  };