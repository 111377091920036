import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen,  makeUrlGET} from './general';

export const apiGetProductos = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'productos/?'+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiGetLineas = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'lineas/?'+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiGetSublineas = async (objs) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'sublineas/?'+ makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiCreateProducto = async (body) =>{
    var request = await CreateRequestImagen( POST, body );
    let url = baseUrl + 'productos/';
   return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiUpdateProducto = async (id, body) =>{
    var request = await CreateRequestImagen(PUT, body);
    let url = baseUrl + `productos/${id}/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiGetDetallesProducto = async (id) =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + `productos/${id}/`;
    return fetch(url, request).then(res =>  res.json().then(data => ({status: res.status, body:data})));
};

export const apiActivateProducto = async (id) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl + `productos/${id}/activar/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiDeleteProducto = async (id) =>{
    var request = await CreateRequest(PUT);
    let url = baseUrl + `productos/${id}/desactivar/`;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};
