import {
    apiAddSupervisor,
    apiUpdateSupervisor,
} from '../Api/apiSupervisores';

import { addUsuario, updateUsuarioLista, updateUsuarioDetalles } from './usuarios';

import { NotificationManager } from 'react-notifications';

import { handleLoading } from './general';

const initialState = {
    editViewSupervisor: false
};

const SHOW_SUPERVISOR_FORM = "SHOW_SUPERVISOR_FORM";

const verFormEdSupervisor = () => ({ type: SHOW_SUPERVISOR_FORM });

export const fetchAddSupervisor = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddSupervisor(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Supervisor guardado correctamente', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/DetallesSupervisor/${res.body.id}`;
                    }, 3000);
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddSupervisor", error);
            });
    };
}

export const fetchUpdateSupervisor = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateSupervisor(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    dispatch(verFormEdSupervisor());
                    NotificationManager.success('Supervisor guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeSupervisor", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchEditViewSupervisores = () => {
    return dispatch => {
        dispatch(verFormEdSupervisor());
    }
}

const supervisoresReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SUPERVISOR_FORM:
            return { ...state, editViewSupervisor: !state.editViewSupervisor };
        default:
            return { ...state };
    }
};

export default supervisoresReducer;