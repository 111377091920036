import React, { Component } from 'react';
import { CardGroup, Card, CardBody, CardText, CardFooter } from 'mdbreact';
import moment from 'moment';

class Notas extends Component {

    render() {

        const { notas } = this.props;
        

        if (notas.total > 0) {
            return (
                <div className="col-12">
                    <CardGroup deck>
                        {notas.rows.map((nota, i) =>
                            <Card className="text-justify col-nota" key={i}>
                                <CardBody>
                                    <CardText>
                                        {nota.nota}
                                    </CardText>
                                </CardBody>
                                <CardFooter small muted>
                                    {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM + ' (' + moment(nota.createdAt).format('DD/MM/YYYY  HH:mm') + ')'}
                                </CardFooter>
                            </Card>
                        )
                        }
                    </CardGroup>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}

export default Notas;