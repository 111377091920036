import { baseUrl, GET, DELETE, CreateRequest, makeUrlGET } from './general';

export const apiGetAlertasSolicitudes = async () => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `alertas/solicitudes/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiDeleteAlertasSolicitudes = async () => {
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `alertas/solicitudes/`;
    return fetch(url, request).then(res => res.status);
};

export const apiGetAlertasNotificaciones = async () => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `alertas/notificaciones/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiDeleteAlertasNotificaciones = async () => {
    var request = await CreateRequest(DELETE);
    let url = baseUrl + `alertas/notificaciones/`;
    return fetch(url, request).then(res => res.status);
};

export const apiGetAlertasNotificacionesHistorico = async (obj) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `alertas/notificaciones/historico/?`+ makeUrlGET(obj);
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};
