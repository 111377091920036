import React, { Component } from 'react';
import { Table, TableBody, TableHead } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { Card, CardBody } from "mdbreact";
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { fetchGetPreclientes, fetchGetPreventas } from '../../Reducers/solicitudes';
import { fetchGetCatUsuarios } from '../../Reducers/catalogos';
import Paginacion from '../Shared/pagination';

let total = 0;

const initialState = {
    activePage: 1,
    size: 10,
    offset: '0',
    selOrdenOption: null,
    selVendedorOpt: null,
    selSolicitudOpt: null,
};

let optionsOrden = [
    { value: 'asc', label: 'Ver más antiguos' },
    { value: 'desc', label: 'Ver más recientes' }
];

let optionsSolicitudes = [
    { value: 1, label: 'Alta Cliente' },
    { value: 2, label: 'Alta Venta' },
    { value: 3, label: 'Alta Cliente + Venta' },
];

let optionsVendedores = [];

class ListaSolicitudes extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getVendedores, getPreclientes, getPreventas } = this.props;

        getVendedores({ tipo: 3 });

        let obj = {
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }

        let objPreclientes = {
            orderBy: 'desc',
            limit: size,
            offset: '0',
            hasPreVenta: 'false'
        }

        getPreventas(obj);
        getPreclientes(objPreclientes);
    }

    handleVendedorChange = (selVendedorOpt) => {
        this.setState({
            ...this.state, selVendedorOpt
        });
        this.filterList(selVendedorOpt, null);
    }

    handleVendedoresOptions = () => {
        optionsVendedores = [];
        this.props.vendedores.rows.map((vendedor) =>
            optionsVendedores.push({ value: vendedor.id, label: vendedor.nombre + ' ' + vendedor.apellidoP + ' ' + vendedor.apellidoM })
        );
        return optionsVendedores;
    }

    handleOrdenChange = (selOrdenOption) => {
        this.setState({
            ...this.state, selOrdenOption
        });
        this.filterList();
    }

    handleSolicitudChange = (selSolicitudOpt) => {
        this.setState({
            ...this.state, selSolicitudOpt
        });
        this.filterList(null, selSolicitudOpt);
    }

    paginationHandler(event, value) {
        const { activePage, size } = this.state;
        const { getPreclientes } = this.props;
        let pagina;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getPreclientes(obj);
    }

    filterList = (vendedor, tipoSolicitud) => {
        const { size, selOrdenOption, selVendedorOpt, selSolicitudOpt } = this.state;
        const { getPreclientes, getPreventas } = this.props;

        this.setState({ activePage: 1 });

        let objPrecliente = {
            limit: size,
            offset: '0',
            hasPreVenta: "false"
        }
        let objPreventa = {
            limit: size,
            offset: '0'
        }
        /*  if (selOrdenOption) {
              objPrecliente.orderBy = selOrdenOption.value;
              objPreventa.orderBy = selOrdenOption.value;
          }
          else {
              objPrecliente.orderBy = 'desc';
              objPreventa.orderBy = 'desc';
          }*/

        if (vendedor) {
            objPreventa.idVendedor = vendedor.value;
        }

        if (tipoSolicitud) {
            switch (tipoSolicitud.value) {
                case 1:
                    getPreclientes(objPrecliente);
                    break;
                case 2:
                    objPreventa.preClienteBool = "false";
                    getPreventas(objPreventa);
                    break;
                case 3:
                    objPreventa.preClienteBool = "true";
                    getPreventas(objPreventa);
                    break;
            }
        }
        else {
            getPreclientes(objPrecliente);
            getPreventas(objPreventa);
        }
    }

    mapArray = (data) => {
        let dataTable = [];
        data.map((elm) => {
            let nombre = '';
            let direccion = '';
            let tipo = '';
            let tipoId = 0;

            if (elm.nombre) {
                nombre = elm.nombre + ' ' + elm.apellidoP + ' ' + elm.apellidoM;
                direccion = elm.direccion;
                tipo = elm.hasPreVenta ? 'Nuevo cliente y nueva venta' : 'Nuevo cliente';
                tipoId = 1;
            }
            else if (elm.cliente) {
                nombre = elm.cliente.nombre + ' ' + elm.cliente.apellidoP + ' ' + elm.cliente.apellidoM;
                direccion = elm.cliente.direccion;
                tipo = 'Nueva venta';
                tipoId = 2;
            }
            else if (elm.preCliente) {
                nombre = elm.preCliente.nombre + ' ' + elm.preCliente.apellidoP + ' ' + elm.preCliente.apellidoM;
                direccion = elm.preCliente.direccion;
                tipo = 'Nuevo cliente y nueva venta';
                tipoId = 3;
            }

            dataTable.push({
                id: elm.id,
                fecha: moment(elm.createdAt).format('DD/MM/YYYY HH:mm'),
                nombre: nombre,
                direccion: direccion,
                tipo: tipo,
                tipoId: tipoId,
            });
        });
        return dataTable;
    }

    createTableRows = () => {

        let rowSolicitudes = [];
        const { selSolicitudOpt } = this.state;
        const { preclientes, preventas } = this.props;

        if (!selSolicitudOpt) {
            total = (preclientes.total ? preclientes.total : 0) + (preventas.total ? preventas.total : 0);
            if (total === 0) {
                return (<tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>);
            }
            else {
                if (preclientes && preclientes.total > 0) {
                    Array.prototype.push.apply(rowSolicitudes, this.mapArray(preclientes.rows));
                }
                if (preventas && preventas.total > 0) {
                    Array.prototype.push.apply(rowSolicitudes, this.mapArray(preventas.rows));
                }
                rowSolicitudes.sort((a, b) => a.fecha < b.fecha ? 1 : -1);
                return (rowSolicitudes.map((v, i) =>
                    <tr key={i}>
                        <td>{v.fecha}</td>
                        <td>{v.nombre}</td>
                        <td>{v.direccion}</td>
                        <td>{v.tipo}</td>
                        <td> <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesSolicitud/${v.tipoId}/${v.id}`} >Ver más</Link></td>
                    </tr>
                ));
            }
        }
        else {
            switch (selSolicitudOpt.value) {
                case 1:
                    total = preclientes.total;
                    if (preclientes.total > 0) {
                        Array.prototype.push.apply(rowSolicitudes, this.mapArray(preclientes.rows));
                        rowSolicitudes.sort((a, b) => a.fecha < b.fecha ? 1 : -1);
                        return (rowSolicitudes.map((v, i) =>
                            <tr key={i}>
                                <td>{v.fecha}</td>
                                <td>{v.nombre}</td>
                                <td>{v.direccion}</td>
                                <td>{v.tipo}</td>
                                <td> <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesSolicitud/${v.tipoId}/${v.id}`}>Ver más</Link></td>
                            </tr>
                        ));
                    }
                    else {
                        return (<tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>);
                    }
                case 2:
                case 3:
                    total = preventas.total;
                    if (preventas.total > 0) {
                        Array.prototype.push.apply(rowSolicitudes, this.mapArray(preventas.rows));
                        rowSolicitudes.sort((a, b) => a.fecha < b.fecha ? 1 : -1);
                        return (rowSolicitudes.map((v, i) =>
                            <tr key={i}>
                                <td>{v.fecha}</td>
                                <td>{v.nombre}</td>
                                <td>{v.direccion}</td>
                                <td>{v.tipo}</td>
                                <td> <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesSolicitud/${v.tipoId}/${v.id}`}>Ver más</Link></td>
                            </tr>
                        ));
                    }
                    else {
                        return (<tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>);
                    }
                default:
                    return (<tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>);

            }
        }
    }

    render() {

        const { activePage, size, selOrdenOption, selVendedorOpt, selSolicitudOpt } = this.state;
        const { preclientes, preventas } = this.props;

        return (
            <Card className="card-css">
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={optionsSolicitudes} value={selSolicitudOpt} placeholder="Tipo de solicitud" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSolicitudChange} isClearable />
                            </div>

                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={this.handleVendedoresOptions()} value={selVendedorOpt} placeholder="Filtrar por vendedor" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleVendedorChange} isClearable />
                            </div>
                            <div className="col-md-6 col-sm-12 input-margin">
                                <div className="form-inline float-md-right">
                                    <div className="form-group">
                                        <label htmlFor="size">Número de solicitudes</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                            onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-md-3 col-sm-12 input-margin">
                                <Select options={optionsOrden} value={selOrdenOption} placeholder="Cambiar orden" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleOrdenChange} isClearable />
        </div>
                        </div>*/}
                        <br />

                        <Table className="tbl-css" responsive>
                            <TableHead>
                                <tr>
                                    <th>Fecha y Hora</th>
                                    <th>Nombre</th>
                                    <th>Dirección</th>
                                    <th>Tipo de solicitud</th>
                                    <th>Opciones</th>
                                </tr>
                            </TableHead>
                            <TableBody>
                                {this.createTableRows()}
                            </TableBody>
                        </Table>
                        <Paginacion total={total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
                    </div >
                </CardBody>
            </Card>
        )
    }
}

export default connect(state => ({
    preventas: state.preregistros.preventas,
    preclientes: state.preregistros.preclientes,
    vendedores: state.catalogos.usuariosCat
}), {
        getPreclientes: fetchGetPreclientes,
        getVendedores: fetchGetCatUsuarios,
        getPreventas: fetchGetPreventas,
    })(ListaSolicitudes);