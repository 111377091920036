import React, { Component } from 'react';
import { Card, CardBody, Table, TableBody } from "mdbreact";
import Paginacion from '../Shared/pagination';

import '../../Css/alertas.css'

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    resultados: {
        count: 0,
        results: []
    }
};

class AlertasCobrador extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
    }

    paginationHandler(event, value) {
        const { activePage, size, buscar } = this.state;
        //const { getClientes } = this.props;
        let pagina;

        let obj = {
            query: buscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        // getClientes(obj);
    }


    mapArray(data) {
        let dataTable = data.map((elm) => {
            return {
                nombre: elm.nombre + ' ' + elm.apellido,
                zona: elm.engomado[0].codigo,
                adeudo: elm.celular
            }
        });
        return dataTable;
    }


    render() {

        const { activePage, size, resultados } = this.state;

        return (
            <div className="col-md-12 col-sm-12">
                <Card >
                    <CardBody >
                        <div className="row">
                            <div className="col-md-12">
                                <h4>Alertas</h4>
                            </div>
                        </div>
                        <hr className="alertas-hr" />
                        <Table className="tbl-alertas" responsive>
                            <TableBody>
                                <tr>
                                    <td>22/12/2018</td>
                                    <td>Alerta: Algo pasó 1</td>
                                </tr>
                                <tr>
                                    <td>22/12/2018</td>
                                    <td>Alerta: Algo pasó 1</td>
                                </tr>
                                <tr>
                                    <td>22/12/2018</td>
                                    <td>Alerta: Algo pasó 1</td>
                                </tr>
                                <tr>
                                    <td>22/12/2018</td>
                                    <td>Alerta: Algo pasó 1</td>
                                </tr>
                            </TableBody>
                        </Table>
                        <Paginacion total={resultados.count} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default AlertasCobrador;