import React, { Component } from 'react';
import { Table, TableBody, TableHead } from 'mdbreact';
import { connect } from 'react-redux';
import { fetchGetIngresos } from '../../Reducers/reportes';
import Paginacion from '../Shared/pagination';
import moment from 'moment';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
//import 'moment/locale/es';
import { NotificationManager } from 'react-notifications';

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    dpStart: moment(start),
    dpEnd: moment(end),
    startDate: moment(start).toISOString(),
    endDate: moment(end).toISOString(),
};

class IngresosVendedores extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getIngresos } = this.props;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getIngresos(obj);
    }

    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar } = this.state;
        const { getIngresos } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
            text: txtBuscar
        }
        getIngresos(obj);
    }


    paginationHandler(event, value) {
        const { activePage, size, txtBuscar } = this.state;
        const { getIngresos } = this.props;
        let pagina;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            text: txtBuscar
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getIngresos(obj);
    }

    handleChangeDTP = (name, value) => {
        const { dpStart } = this.state;
        switch (name) {
            case 'fromDate':
                this.setState({ dpStart: value, startDate: moment(value).toISOString(), });
                break;
            case 'toDate':
                if (!((value).isBefore(dpStart))) {
                    this.setState({ dpEnd: value, endDate: moment(value).toISOString() });
                }
                else {
                    NotificationManager.error('La fecha de fin no puede ser menor a la fecha de inicio.', 'Error en fechas');
                }
                break;
            default:
                break;
        }
    }


    render() {

        const { activePage, size, dpStart, dpEnd } = this.state;
        const { nombreColumna, ingresos } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">Del </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input prefijo-right"></i>
                            <DateTime name="fromDate" locale="es" inputProps={{ className: "dtpicker_center form-control input-modal" }} onChange={moment => this.handleChangeDTP("fromDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={dpStart} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">al </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input prefijo-right"></i>
                            <DateTime name="toDate" inputProps={{ className: "dtpicker_center form-control input-modal" }} locale="es" onChange={moment => this.handleChangeDTP("toDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" placeholder="Fecha final" value={dpEnd} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de registros</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>{nombreColumna}</th>
                            <th>Fecha y Hora</th>
                            <th>Monto</th>
                            <th>Producto</th>
                            <th>Cliente</th>
                            <th>Tipo de ingreso</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {ingresos.total > 0 ?
                            ingresos.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.nombre} {v.apellidoP} {v.apellidoM}</td>
                                    <td>{moment(v.createdAt).format('DD/MM/YYYY  HH:mm')}</td>
                                    <td>{v.direccion}</td>

                                </tr>
                            )
                            :
                            <tr><td colSpan="6">No se encontraron registros para mostrar</td></tr>
                        }

                    </TableBody>
                </Table>
                <Paginacion total={ingresos.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div >
        )
    }
}

export default connect(state => ({
    ingresos: state.reportes.ingresos
}), {
        getIngresos: fetchGetIngresos
    })(IngresosVendedores);