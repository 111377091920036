import React, { Component } from 'react';
import { Button, Alert } from 'mdbreact';
import { connect } from 'react-redux';
import { fetchGetAbonosVenta } from '../../Reducers/ventas';
import '../../Css/timeline.css';
import moment from 'moment';
//import 'moment/locale/es';

class PruebaTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const { getAbonos, venta } = this.props;
        getAbonos(venta);
    }

    componentWillUnmount() {
        const { getAbonos } = this.props;
        getAbonos(-1);
    }

    render() {
        const { fnVerPagos, abonos } = this.props;

        if (Object.entries(abonos).length === 0) {
            return (<Alert color="info" className="text-center"> Cargando información...</Alert>);
        }
        else {
            let abonosTotal = 0;
            return (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="btn-mas" onClick={() => fnVerPagos(-1)}><i className="fas fa-arrow-left"></i> Regresar</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="stepper stepper-vertical timeline pl-0">
                                <li>
                                    <a href="#!">
                                        <span className="circle primary-color z-depth-1-half"><i className="fas fa-dollar-sign" aria-hidden="true"></i></span>
                                    </a>
                                    <div className="step-content z-depth-1 ml-xl-0 p-4">
                                        <p className="text-muted"><i className="far fa-clock" aria-hidden="true"></i> {moment(abonos.createdAt).format('MMMM DD, YYYY HH:mm')} </p>
                                        <p className="mb-0">Compra del mueble <b>{abonos.productos[0].nombre}</b> con un costo a crédito de  <b>${abonos.productos[0].RelVentaProductos.snap.precioCredito.toFixed(2).toLocaleString()}</b>. </p>
                                    </div>
                                </li>
                                <li className="timeline-inverted">
                                    <a href="#!">
                                        <span className="circle warning-color z-depth-1-half"><i className="fas fa-file-invoice" aria-hidden="true"></i></span>
                                    </a>
                                    <div className="step-content z-depth-1 ml-xl-0 p-4">
                                        <p className="text-muted"><i className="far fa-clock" aria-hidden="true"></i> {moment(abonos.createdAt).format('MMMM DD, YYYY HH:mm')} </p>
                                        <p className="mb-0">Anticipo de <b>${abonos.anticipo.toFixed(2).toLocaleString()}</b>. </p>
                                    </div>
                                </li>
                                {
                                    abonos.abonos.length > 0
                                        ?
                                        abonos.abonos.map((abono, i) => {
                                            if (i === abonos.abonos.length - 1 && abonos.total === abonos.recaudado) {
                                                return (
                                                    <li className={i % 2 === 0 ? '' : "timeline-inverted"} key={i}>
                                                        <a href="#!">
                                                            <span className="circle success-color z-depth-1-half"><i className="fas fa-file-invoice" aria-hidden="true"></i></span>
                                                        </a>
                                                        <div className="step-content z-depth-1 ml-xl-0 p-4">
                                                            <p className="text-muted"><i className="far fa-clock" aria-hidden="true"></i> {moment(abono.createdAt).format('MMMM DD, YYYY HH:mm')}</p>
                                                            <p className=" mt-4 mb-0">Se finiquitó la deuda del mueble <b>{abonos.productos[0].nombre}</b> con un abono de <b>${abono.total.toFixed(2).toLocaleString()}</b>.</p>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            else {
                                                return (<li className={i % 2 === 0 ? '' : "timeline-inverted"} key={i}>
                                                    <a href="#!">
                                                        <span className="circle warning-color z-depth-1-half"><i className="fas fa-file-invoice" aria-hidden="true"></i></span>
                                                    </a>
                                                    <div className="step-content z-depth-1 ml-xl-0 p-4">
                                                        <p className="text-muted"><i className="far fa-clock" aria-hidden="true"></i> {moment(abono.createdAt).format('MMMM DD, YYYY HH:mm')}</p>
                                                        <p className=" mt-4 mb-0">Abono de <b>${abono.total.toFixed(2).toLocaleString()}</b>.</p>
                                                    </div>
                                                </li>)
                                            }
                                        })
                                        :
                                        ''
                                }
                            </ul>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default connect(state => ({
    abonos: state.ventas.abonosVenta,
}), {
        getAbonos: fetchGetAbonosVenta,
    })(PruebaTimeline);