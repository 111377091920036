
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { connect } from 'react-redux';

import { fetchGetUsuarios, fetchResetUsers } from '../../Reducers/usuarios';
import '../../Css/clientes.css';
import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    //selectedUsr:-1
};

class ListaClientes extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getClientes } = this.props;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
            activo: 'true',
            rechazado: 'false'
        }
        getClientes(obj);
    }

    componentWillUnmount() {
        const { resetUsers } = this.props;
        resetUsers();
    }

    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar } = this.state;
        const { getClientes } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
            text: txtBuscar,
            activo: 'true',
            rechazado: 'false'
        }
        getClientes(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size, txtBuscar } = this.state;
        const { getClientes } = this.props;
        let pagina;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            text: txtBuscar,
            activo: 'true',
            rechazado: 'false'
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getClientes(obj);
    }

    selectUsr = (user) => {
        const { clickCliente } = this.props;
        //this.setState({ selectedUsr: user.id });
        clickCliente(user);
    }

    getStatus = (id, rechazado, activo) => {
        let estatus = '';
        if (!rechazado) {
            if (activo) {
                switch (id) {
                    case 1:
                        estatus = <div className="font-bien">Bueno <i className="far fa-laugh-wink fa-lg"></i></div>
                        break;
                    case 2:
                        estatus = <div className="font-reg">Regular <i className="far fa-meh fa-lg"></i></div>
                        break;
                    case 3:
                        estatus = <div className="font-malo">Malo <i className="far fa-frown fa-lg"></i> </div>
                        break;
                }
            }
            else {
                estatus = <div className="font-eliminado">Inactivo <i className="far fa-times-circle fa-lg"></i></div>
            }
        }
        else {
            estatus = <div className="font-rechazado">Rechazado <i className="far fa-thumbs-down fa-lg"></i></div>
        }
        return estatus;
    }

    render() {

        const { activePage, size, txtBuscar/*, selectedUsr*/ } = this.state;
        const { clientes, cliente } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 search-cliente"
                                type="text"
                                placeholder="Buscar cliente"
                                aria-label="Buscar cliente"
                                onChange={this.changeBuscar}
                                value={txtBuscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de clientes</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Dirección</th>
                            <th>Estado</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {clientes.total > 0 ?
                            clientes.rows.map((v, i) =>
                                <tr key={i} className={cliente.id === v.id ? "rowSelected" : ""} onClick={() => this.selectUsr(v)}>
                                    <td>{v.nombre} {v.apellidoP} {v.apellidoM}</td>
                                    <td>{v.telefono}</td>
                                    <td>{v.direccion}</td>
                                    <td>{v.infoCliente ? this.getStatus(v.infoCliente.estado, v.rechazado, v.activo) : ''}</td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="4">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={clientes.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div >
        )
    }
}

export default connect(state => ({
    clientes: state.usuarios.usuarios
}), {
        getClientes: fetchGetUsuarios,
        resetUsers: fetchResetUsers
    })(ListaClientes);