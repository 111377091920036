import React, { Component } from 'react';
import moment from 'moment';

let start = new Date();
//let start = new Date('2019-06-03');
//start.setDate(start.getDate() + 1);

class Resumen extends Component {
  constructor() {
    super();
    this.state = {
      today: start,//new Date(),
      estadisticasCalculadas: {
        visitar: 0,
        visitados: 0,
        pagos: 0,
        sinPagos: 0,
        pospuestos: 0,
        garantias: 0,
        abonosEsperadosHoy: 0,
        abonosEsperadosGral: 0,
        dineroPendienteCobrar: 0,
        dineroPagado: 0,
        dineroNoRecibido: 0,
        clienteAusente: 0,
        porcentajeHoy: 0,
        porcentajeGral: 0,
      },
      estadisticasJSON: []
    };
  }

  componentDidUpdate() {
    const { infoEstadisticas } = this.props;
    const { estadisticasJSON } = this.state;

    if (estadisticasJSON !== infoEstadisticas) {
      this.updateComponentStats();
    }
  }

  updateComponentStats = () => {
    const { infoEstadisticas } = this.props;
    const { abonos, pendientes, pospuestos } = infoEstadisticas;
    const { estadisticasCalculadas, today, estadisticasJSON } = this.state;

    let hoy = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate();
    let dateEval = '';

    let actualObj = {
      visitar: pendientes.total,
      visitados: abonos.total + pospuestos.total,
      pagos: abonos.total,
      sinPagos: pospuestos.total,
      pospuestos: pospuestos.total,
      garantias: 0,
      abonosEsperadosHoy: 0,
      abonosEsperadosGral: 0,
      dineroPendienteCobrar: 0,
      dineroPagado: 0,
      dineroNoRecibido: 0,
      clienteAusente: 0,
      porcentajeHoy: 0,
      porcentajeGral: 0,
    }

    abonos.rows.map(abono => {
      dateEval = moment(abono.abonos[0].fechaVisita).format('YYYY-MM-DD');
      if (moment(hoy).isSame(dateEval)) {
        actualObj['dineroPagado'] += abono.abonos[0].total;
        actualObj['abonosEsperadosHoy'] += Math.ceil(abono.venta.abonoEstimado);
        actualObj['abonosEsperadosGral'] += Math.ceil(abono.venta.abonoEstimado);
      }
      else if (moment(dateEval).isBefore(hoy)) {
        actualObj['abonosEsperadosGral'] += Math.ceil(abono.abonos[0].total);
        actualObj['dineroPagado'] += abono.abonos[0].total;
      }
      else {
        actualObj['dineroPagado'] += abono.abonos[0].total;
      }
    });

    pospuestos.rows.map(pospuesto => {
      dateEval = moment(pospuesto.fechaOriginal).format('YYYY-MM-DD');
      if (moment(hoy).isSame(dateEval)) {
        actualObj['dineroNoRecibido'] += Math.ceil(pospuesto.venta.abonoEstimado);
        actualObj['abonosEsperadosHoy'] += Math.ceil(pospuesto.venta.abonoEstimado);
        actualObj['abonosEsperadosGral'] += Math.ceil(pospuesto.venta.abonoEstimado);
      }
      else if (moment(dateEval).isBefore(hoy)) {
        actualObj['dineroNoRecibido'] += Math.ceil(pospuesto.venta.abonoEstimado);
        actualObj['abonosEsperadosGral'] += Math.ceil(pospuesto.venta.abonoEstimado);
      }
      else {

      }
    });

    pendientes.rows.map(pendiente => {
      dateEval = moment(pendiente.fecha).format('YYYY-MM-DD');
      if (moment(hoy).isSame(dateEval)) {
        actualObj['abonosEsperadosHoy'] += Math.ceil(pendiente.venta.abonoEstimado);
        actualObj['abonosEsperadosGral'] += Math.ceil(pendiente.venta.abonoEstimado);
        switch (pendiente.EventoId) {
          case 3:
          case 6:
            actualObj.sinPagos++;
            break;
          case 5:
            actualObj.visitados++;
            actualObj.sinPagos++;
            actualObj.clienteAusente++;
            break;
        }
      }
      else if (moment(dateEval).isBefore(hoy)) {
        actualObj['abonosEsperadosGral'] += Math.ceil(pendiente.venta.abonoEstimado);
      }
      else {
        /*console.log(hoy, dateEval, '?');*/
      }
    });

    if (actualObj.abonosEsperadosHoy === 0) {
      actualObj.porcentajeHoy = 100;
    }
    else {
      actualObj.porcentajeHoy = ((actualObj.dineroPagado * 100) / actualObj.abonosEsperadosHoy);
    }

    if (actualObj.abonosEsperadosGral === 0) {
      actualObj.porcentajeGral = 100;
    }
    else {
      actualObj.porcentajeGral = ((actualObj.dineroPagado * 100) / actualObj.abonosEsperadosGral);
    }

    if (actualObj !== estadisticasCalculadas) {
      this.setState({ estadisticasCalculadas: actualObj, estadisticasJSON: infoEstadisticas });
    }
  }


  render() {
    const { today } = this.state;
    const { estadisticasCalculadas } = this.state;

    return (
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-3 col-sm-12">
            <div className="row input-margin">
              <div className="col-md-12 desc-monitor">
                <b>{moment(today).format('DD') + ' de ' + moment(today).locale('es').format('MMMM YYYY')}</b>
              </div>
            </div>
            <div className="row input-margin">
              <div className="col-md-12 ">
                <span className="desc-monitor">Monto a recaudar:</span><br />
                <span className="total-monitor">${estadisticasCalculadas.abonosEsperadosHoy.toFixed(2).toLocaleString()}</span>
              </div>
            </div>
            <div className="row input-margin">
              <div className="col-md-12">
                <span className="desc-monitor">Porcentaje recaudado hoy:</span><br />
                <span className="total-monitor">{estadisticasCalculadas.porcentajeHoy.toFixed(2)}%</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="row input-margin">
              <div className="col-md-12 desc-monitor">
                <b>General</b>
              </div>
            </div>
            <div className="row input-margin">
              <div className="col-md-12 ">
                <span className="desc-monitor">Monto total a recaudar:</span><br />
                <span className="total-monitor">${estadisticasCalculadas.abonosEsperadosGral.toFixed(2).toLocaleString()}</span>
              </div>
            </div>
            <div className="row input-margin">
              <div className="col-md-12">
                <span className="desc-monitor">Porcentaje total recaudado:</span><br />
                <span className="total-monitor">{estadisticasCalculadas.porcentajeGral.toFixed(2)}%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 border-green">
            <div className="row input-margin">
              <div className="col-md-12 titulo-resumen">
                Clientes
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 input-margin">
                Por visitar:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.visitar}</b>
              </div>
              <div className="col-md-4 col-sm-6 input-margin">
                Pago realizado:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.pagos}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 input-margin">
                Visitados:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.visitados}</b>
              </div>
              <div className="col-md-4 col-sm-6 input-margin">
                Pago pospuesto:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.pospuestos}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-6 input-margin">
                Sin pagar:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.sinPagos}</b>
              </div>
              <div className="col-md-4 col-sm-6 input-margin">
                Cliente ausente:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.clienteAusente}</b>
              </div>
            </div>
            {/*<div className="row">
              <div className="col-md-4 col-sm-6 input-margin">
                Garantía solicitada:
              </div>
              <div className="col-md-2 col-sm-6 input-margin">
                <b>{estadisticasCalculadas.garantias}</b>
              </div>
    </div>*/}
          </div>
        </div>
      </div >
    );
  }
}

export default Resumen;