import React, { Component } from 'react';
import General from './general';
import EditarVendedor from '../Vendedores/editar';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGetSelected, fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditViewVendedor } from '../../Reducers/vendedores';

class DetallesVendedor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idVendedor: this.props.match.params.uuid
        }
    }

    componentDidMount() {
        const { getVendedor } = this.props;
        const { uuid } = this.props.match.params;
        getVendedor(uuid);
    }

    componentWillUnmount() {
        const { editar, editarVendedor, resetVendedor } = this.props;
        if (editar) {
            editarVendedor();
        }
        resetVendedor(-1);
    }

    render() {
        const { editar, editarVendedor } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    {editar
                        ?
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <EditarVendedor fnCancelar={() => editarVendedor()} vista='Detalles' />
                                </div>
                            </div>
                        </div>

                        :
                        <General fnEditar={() => editarVendedor()} />
                    }
                </div>
                <br />
            </div>
        )
    }
}

export default withRouter(connect(state => ({
    vendedor: state.usuarios.usuarioSeleccionado,
    editar: state.vendedores.editViewVendedor,
}), {
        getVendedor: fetchGetSelected,
        editarVendedor: fetchEditViewVendedor,
        resetVendedor: fetchSeleccionarUsuario
    })(DetallesVendedor));
