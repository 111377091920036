import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle } from "mdbreact";
import { connect } from 'react-redux';
import { fetchGetAcumuladosCobrador, fetchGetEstadisticasCobrador } from '../../Reducers/cobradores';
import { fetchGetEstadisticasMonitor } from '../../Reducers/monitor';
import moment from 'moment';

class EstadisticasCobrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCobrador: parseInt(this.props.idCobrador),
            fechaInicio: moment().startOf("isoWeek").toDate().toISOString(),
            fechaFin: moment().endOf("isoWeek").toDate().toISOString(),
            /* estadisticasCalculadas: {
                 abonosEsperados: 0,
                 dineroPendienteCobrar: 0,
                 dineroPagado: 0,
                 dineroPospuesto: 0,
                 dineroNoRecibido: 0,
                 porcentaje: 0,
                 fechaInicio: moment().startOf("isoWeek").toDate().toISOString(),
                 fechaFin: moment().endOf("isoWeek").toDate().toISOString()
             },*/
            estadisticasCalculadas: {
                abonosPendientes: 0,
                abonosEsperados: 0,
                dineroPospuesto: 0,
                dineroPagado: 0,
                dineroNoRecibido: 0,
                porcentajeHoy: 0,
            },
            estadisticasJSON: []
        }
    }

    componentDidMount() {
        const { getAcumulado, idCobrador, getEstadisticas } = this.props;

        let objAcumulado = {
            cobrador: idCobrador,
            limit: 1,
            offset: '0',
        }
        getAcumulado(objAcumulado);

        let obEstadisticas = {
            // cobrador: idCobrador,
            fechaInicio: moment().startOf("isoWeek").toDate().toISOString(),
            fechaFin: moment().endOf("isoWeek").toDate().toISOString(),
            /*fechaInicio: '2019-05-27T07:00:00.000Z',
            fechaFin: '2019-06-03T06:59:59.999Z'*/
        }
        getEstadisticas(obEstadisticas);

        try {
            this.interval = setInterval(async () => {
                getAcumulado(objAcumulado);
                getEstadisticas(obEstadisticas);
            }, 60000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    componentDidUpdate() {
        const { infoEstadisticas } = this.props;
        const { estadisticasJSON } = this.state;

        if (estadisticasJSON !== infoEstadisticas) {
            this.updateComponentStats();
        }
    }

    updateComponentStats = () => {
        const { infoEstadisticas } = this.props;
        const { abonos, pendientes, pospuestos } = infoEstadisticas;
        const { estadisticasCalculadas, idCobrador, fechaInicio, fechaFin } = this.state;

        let dateEval = '';
        let abonosCobrador = abonos.rows.filter(abono => abono.CobradorId === idCobrador);
        let pendientesCobrador = pendientes.rows.filter(pendiente => pendiente.CobradorId === idCobrador);
        let pospuestosCobrador = pospuestos.rows.filter(pospuesto => pospuesto.CobradorId === idCobrador);

        let actualObj = {
            abonosPendientes: 0,
            abonosEsperados: 0,
            dineroPospuesto: 0,
            dineroPagado: 0,
            dineroNoRecibido: 0,
            porcentajeHoy: 0,
        }

        abonosCobrador.map(abono => {
            dateEval = moment(abono.abonos[0].fechaVisita).format('YYYY-MM-DD');
            if (moment(dateEval).isBetween(fechaInicio, fechaFin)) {
                const abonoEsperado = Math.ceil(abono.venta.abonoEstimado);
                const totalAbono = abono.abonos[0].total;
                const diferenciaEsperado = abonoEsperado - totalAbono;
                actualObj['dineroPagado'] += totalAbono;
                actualObj['abonosEsperados'] += abonoEsperado;
                if (diferenciaEsperado > 0) {
                    actualObj['dineroNoRecibido'] += diferenciaEsperado;
                }
            }
            else {
                actualObj['dineroPagado'] += abono.abonos[0].total;
            }
        });

        pospuestosCobrador.map(pospuesto => {
            dateEval = moment(pospuesto.fechaOriginal).format('YYYY-MM-DD');
            if (moment(dateEval).isBetween(fechaInicio, fechaFin)) {
                actualObj['abonosEsperados'] += Math.ceil(pospuesto.venta.abonoEstimado);
                actualObj['dineroPospuesto'] += Math.ceil(pospuesto.venta.abonoEstimado);
            }
        });

        pendientesCobrador.map(pendiente => {
            dateEval = moment(pendiente.fecha).format('YYYY-MM-DD');
            if (moment(dateEval).isBetween(fechaInicio, fechaFin)) {
                if (pendiente.EventoId === 1) {
                    actualObj['abonosPendientes'] += Math.ceil(pendiente.venta.abonoEstimado);
                    actualObj['abonosEsperados'] += Math.ceil(pendiente.venta.abonoEstimado);
                }
                if (pendiente.EventoId === 5) {
                    actualObj['dineroNoRecibido'] += Math.ceil(pendiente.venta.abonoEstimado);
                    actualObj['abonosEsperados'] += Math.ceil(pendiente.venta.abonoEstimado);
                }
            }
            else {
                /*console.log('pendiente else')*/
            }
        });

        if (actualObj.abonosEsperados === 0) {
            actualObj.porcentajeHoy = 100;
        }
        else {
            actualObj.porcentajeHoy = ((actualObj.dineroPagado * 100) / actualObj.abonosEsperados);
        }


        if (actualObj !== estadisticasCalculadas) {
            this.setState({ estadisticasCalculadas: actualObj, estadisticasJSON: infoEstadisticas });
        }
        else {
            this.setState({ estadisticasJSON: infoEstadisticas });
        }

    }



    /* updateComponentStats = () => {
         const { rows } = this.props.estadisticas;
         const { estadisticasCalculadas } = this.state;
 
         const actualObj = rows.reduce((current, r) => {
             const { venta, EventoId, abonos, id } = r;
             let evento = {};
             let abonoEsperado = Math.ceil(venta.abonoEstimado);
             const restantePorPagar = venta.total - venta.recaudado;
             abonoEsperado = (restantePorPagar >= abonoEsperado) ? abonoEsperado : restantePorPagar;
             current.abonosEsperados += abonoEsperado;
             switch (EventoId) {
                 case 1:
                 case 5:
                 case 6:
                     {
                         console.log('pendiente:', abonoEsperado);
                         current.dineroPendienteCobrar += abonoEsperado;
                         break;
                     }
                 case 2: { current.dineroPospuesto += abonoEsperado; break; }
                 case 3: { current.dineroNoRecibido += abonoEsperado; break; }
                 case 4: {
                     evento = abonos.find(abono => abono.FechaId === id)
                     console.log('pagado:', evento.total);
                     current.dineroPagado += evento.total;
                     break;
                 }
                 default: { break; }
             }
             return current;
         }, {
                 abonosEsperados: 0,
                 dineroPendienteCobrar: 0,
                 dineroPagado: 0,
                 dineroPospuesto: 0,
                 dineroNoRecibido: 0,
                 porcentaje: 0,
             });
         let porcentaje = (actualObj.dineroPagado * 100) / actualObj.abonosEsperados;
         actualObj.porcentaje = !isNaN(porcentaje) ? porcentaje : 0;
         if (actualObj !== estadisticasCalculadas) {
             this.setState({ estadisticasCalculadas: actualObj, estadisticasJSON: rows });
         }
     }
 */
    render() {

        const { acumulados } = this.props;
        const { estadisticasCalculadas } = this.state;

        return (
            <div className="row">
                <div className="col-md-2 col-sm-12 input-margin">
                    <Card className="text-center tarjeta-detalles azul">
                        <CardBody>
                            <CardText className="blanco">
                                % recaudado
                                </CardText>
                            <CardTitle className="blanco">{/*estadisticasCalculadas.porcentaje*/estadisticasCalculadas.porcentajeHoy.toFixed(2)}%</CardTitle>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-2 col-sm-12 input-margin">
                    <Card className="text-center tarjeta-detalles verde">
                        <CardBody>
                            <CardText className="blanco">
                                Dinero con cobrador
                                </CardText>
                            <CardTitle className="blanco">${acumulados.total > 0 ? Math.ceil(acumulados.rows[0].total).toFixed(2).toLocaleString() : '000.00'}</CardTitle>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-2 col-sm-12 input-margin">
                    <Card className="text-center tarjeta-detalles amarillo">
                        <CardBody>
                            <CardText className="blanco">
                                Dinero pospuesto
                                </CardText>
                            <CardTitle className="blanco">${Math.ceil(/*estadisticasCalculadas.dineroPospuesto*/estadisticasCalculadas.dineroPospuesto).toFixed(2).toLocaleString()}</CardTitle>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-2 col-sm-12 input-margin">
                    <Card className="text-center tarjeta-detalles naranja">
                        <CardBody>
                            <CardText className="blanco">
                                Pendiente a cobrar
                                </CardText>
                            <CardTitle className="blanco">${Math.ceil(/*estadisticasCalculadas.dineroPendienteCobrar*/estadisticasCalculadas.abonosPendientes).toFixed(2).toLocaleString()}</CardTitle>
                        </CardBody>
                    </Card>
                </div>

                <div className="col-md-2 col-sm-12 input-margin">
                    <Card className="text-center tarjeta-detalles rojo">
                        <CardBody>
                            <CardText className="blanco">
                                Dinero no recibido
                                </CardText>
                            <CardTitle className="blanco">${Math.ceil(estadisticasCalculadas.dineroNoRecibido).toFixed(2).toLocaleString()}</CardTitle>
                        </CardBody>
                    </Card>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    acumulados: state.cobradores.acumulados,
    estadisticas: state.cobradores.estadisticas,
    infoEstadisticas: state.monitor.estadisticasMonitor
}), {
        getAcumulado: fetchGetAcumuladosCobrador,
        // getEstadisticas: fetchGetEstadisticasCobrador
        getEstadisticas: fetchGetEstadisticasMonitor
    })(EstadisticasCobrador);
