import { combineReducers } from "redux";
import generalReducer from '../Reducers/general';
import authReducer from '../Reducers/auth';
import usuariosReducer from '../Reducers/usuarios';
import cobradoresReducer from '../Reducers/cobradores';
import catalogosReducer from "../Reducers/catalogos";
import clientesReducer from "../Reducers/clientes";
import monitorReducer from "../Reducers/monitor";
import productosReducer from "../Reducers/productos";
import preregistrosReducer from "../Reducers/solicitudes";
import notificacionesReducer from "../Reducers/notificaciones";
import reportesReducer from "../Reducers/reportes";
import supervisoresReducer from "./supervisores";
import vendedoresReducer from "./vendedores";
import ventasReducer from "./ventas";

const mainStore = combineReducers(
  {
    auth: authReducer,
    catalogos: catalogosReducer,
    clientes: clientesReducer,
    cobradores: cobradoresReducer,
    general: generalReducer,
    monitor: monitorReducer,
    notificaciones: notificacionesReducer,
    productos: productosReducer,
    preregistros: preregistrosReducer,
    usuarios: usuariosReducer,
    reportes: reportesReducer,
    supervisores: supervisoresReducer,
    vendedores: vendedoresReducer,
    ventas: ventasReducer
  }
);

export default mainStore;