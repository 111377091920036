import {
    apiGetPreclientes,
    apiAddPrecliente,
    apiGetPreclienteSeleccionado,
    apiPutPrecliente,
    apiDeletePrecliente,
    apiGetPreventas,
    apiAddPreventaCredito,
    apiAddPreventaContado,
    apiGetPreventaSeleccionada,
    apiPutPreventa,
    apiDeletePreventas,
    apiGetNotasPrecliente,
    apiAddNotaPrecliente,
    apiGetNotasPreventa,
    apiAddNotaPreventa,
    apiAddSupervisor,
    apiTransferirVenta,
    apiGetFechasPagosPreview,
    apiGetFechasPagosPreventa
} from '../Api/apiSolicitudes';
import {
    apiUpdateCliente,
} from '../Api/apiClientes';
import { NotificationManager } from 'react-notifications';
import { handleLoading, handleAlert } from './general';
import jwtDecode from "jwt-decode";

const initialState = {
    preclientes: {
        rows: [],
        total: 0,
    },
    preventas: {
        rows: [],
        total: 0,
    },
    preclienteSeleccionado: {},
    preventaSeleccionada: {},
    showModalTransferencia: false,
    showModalNotas: false,
    showModalSupervisor: false,
    PreSaletoPreClient: false,
    notasPrecliente: {
        rows: [],
        total: 0,
    },
    notasPreventa: {
        rows: [],
        total: 0,
    },
    fechasPagos: {
        rows: [],
        total: 0,
    }
};

const GET_PRECLIENTES = "GET_PRECLIENTES";
const ADD_PRECLIENTE = "ADD_PRECLIENTE";
const GET_PRECLIENTE_SELECCIONADO = "GET_PRECLIENTE_SELECCIONADO";
const ACEPTAR_PRECLIENTE = "ACEPTAR_PRECLIENTE";
const ELIMINAR_PRECLIENTE = "ELIMINAR_PRECLIENTE";
const GET_PREVENTAS = "GET_PREVENTAS";
const ADD_PREVENTA_CREDITO = "ADD_PREVENTA_CREDITO";
const ADD_PREVENTA_CONTADO = "ADD_PREVENTA_CONTADO";
const GET_PREVENTA_SELECCIONADA = "GET_PREVENTA_SELECCIONADA";
const ELIMINAR_PREVENTA = "ELIMINAR_PREVENTA";
const SHOW_MODAL_TRANSFERENCIA = "SHOW_MODAL_TRANSFERENCIA";
const HANDLE_MODAL_NOTAS = "HANDLE_MODAL_NOTAS";
const HANDLE_MODAL_SUPERVISOR = "HANDLE_MODAL_SUPERVISOR";
const ADD_PRESALE_PRECLIENT = "ADD_PRESALE_PRECLIENT";
const ADD_NOTE_PRECLIENTE = "ADD_NOTE_PRECLIENTE";
const ADD_NOTE_PREVENTA = "ADD_NOTE_PREVENTA";
const GET_NOTES_PRECLIENTE = "GET_NOTES_PRECLIENTE";
const GET_NOTES_PREVENTA = "GET_NOTES_PREVENTA";
const GET_FECHAS_PAGOS = "GET_FECHAS_PAGOS";

const getPreclientes = preclientes => ({ type: GET_PRECLIENTES, payload: preclientes });
const getPreclienteSeleccionado = precliente => ({ type: GET_PRECLIENTE_SELECCIONADO, payload: precliente });
const getPreventas = preventas => ({ type: GET_PREVENTAS, payload: preventas });
const getPreventaSeleccionado = preventa => ({ type: GET_PREVENTA_SELECCIONADA, payload: preventa });
const showModalTransferencia = () => ({ type: SHOW_MODAL_TRANSFERENCIA });
const handleModalNotas = () => ({ type: HANDLE_MODAL_NOTAS });
const handleModalSupervisor = () => ({ type: HANDLE_MODAL_SUPERVISOR });
const addPreSale = item => ({ type: ADD_PRESALE_PRECLIENT, payload: item });
const addNotePrecliente = nota => ({ type: ADD_NOTE_PRECLIENTE, payload: nota });
const addNotePreventa = nota => ({ type: ADD_NOTE_PREVENTA, payload: nota });
const getNotesPrecliente = notas => ({ type: GET_NOTES_PRECLIENTE, payload: notas });
const getNotesPreventa = notas => ({ type: GET_NOTES_PREVENTA, payload: notas });
const getFechasPagos = fechas => ({ type: GET_FECHAS_PAGOS, payload: fechas });

export const fetchGetPreclientes = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetPreclientes(obj)
            .then((preclientes) => {
                dispatch(getPreclientes(preclientes));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetPreclientes", error);
                dispatch(getPreclientes(initialState.preclientes));
            });
    };
}

export const fetchAddPrecliente = (body, fnAdd) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddPrecliente(body)
            .then((res) => {
                if (res.status === 201) {
                    NotificationManager.success('Solicitud de cliente enviada.', '¡Éxito!');
                    let nombre = res.body.nombre + ' ' + res.body.apellidoP + ' ' + res.body.apellidoM;
                    let id = res.body.id;
                    dispatch(getPreclienteSeleccionado(res.body));
                    const decoded = jwtDecode(localStorage.token);
                    if (decoded.tipo === 3) {
                        dispatch(handleAlert({
                            'show': true,
                            'type': 'warning',
                            'title': 'Agregar venta',
                            'text': `¿Desea agregar una venta a la solicitud de ${nombre}?`,
                            'confirmButtonText': 'Sí',
                            'cancelButtonText': 'No',
                            'onConfirm': () => fnAdd(),
                            'onCancel': () => redirectPrecliente(id),
                            'showCancel': true
                        }));
                    }
                    else {
                        window.location.href = `/DetallesSolicitud/1/${id}`;
                    }
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddPrecliente", error);
            });
    };
}

export const fetchGetPrecliente = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetPreclienteSeleccionado(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getPreclienteSeleccionado(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetPreclienteSeleccionado", error);
                dispatch(getPreclienteSeleccionado(initialState.preclienteSeleccionado));
            });
    };
}

export const fetchAceptarPrecliente = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiPutPrecliente(id)
            .then((resp) => {
                if (resp === 204) {
                    NotificationManager.success('Cliente guardado correctamente.', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/Solicitudes/`;
                    }, 3000);
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorAcpetarPrecliente", error);
            });
    };
}

export const fetchEliminarPrecliente = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiDeletePrecliente(id)
            .then((resp) => {
                if (resp === 204) {
                    NotificationManager.success('Solicitud eliminada correctamente.', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/Solicitudes/`;
                    }, 3000);
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorEliminarPrecliente", error);
            });
    };
}

export const fetchGetNotasPreclientes = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetNotasPrecliente(id)
            .then((notas) => {
                dispatch(getNotesPrecliente(notas));
                dispatch(getNotesPreventa(initialState.notasPreventa));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetNotasPreclientes", error);
                dispatch(getNotesPrecliente(initialState.notasPrecliente));
            });
    };
}

export const fetchAddNotasPrecliente = (body, id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddNotaPrecliente(body, id)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addNotePrecliente(res.body));
                    dispatch(handleModalNotas());
                    NotificationManager.success('Nota agregada correctamente.', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddNotasPrecliente", error);
            });
    };
}

export const fetchAddSupervisor = (body, id, tipoSolicitud) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddSupervisor(body, id, tipoSolicitud)
            .then((res) => {
                if (res.status === 200) {
                    if (tipoSolicitud === 1) {
                        dispatch(getPreclienteSeleccionado(res.body));
                    }
                    else {
                        dispatch(getPreventaSeleccionado(res.body));
                    }
                    dispatch(handleModalSupervisor());
                    NotificationManager.success('Supervisor asignado correctamente.', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddNotasPrecliente", error);
            });
    };
}

export const fetchGetPreventas = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetPreventas(obj)
            .then((preventas) => {
                dispatch(getPreventas(preventas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetPreventas", error);
                dispatch(getPreventas(initialState.preventas));
            });
    };
}

export const fetchAddPreventaCredito = (body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddPreventaCredito(body)
            .then((res) => {
                if (res.status === 201) {
                    //dispatch(addPreventaCredito(res.body));
                    // NotificationManager.success('Solicitud de venta enviada.', '¡Éxito!');
                    if (vista === 1) {
                        NotificationManager.success('Solicitud de venta enviada.', '¡Éxito!');
                        setTimeout(() => {
                            window.location.href = `/DetallesSolicitud/3/${res.body.id}`;
                        }, 3000);
                    }
                    else if (vista === 2) {
                        NotificationManager.success('Registro de venta enviada.', '¡Éxito!');
                        setTimeout(() => {
                            window.location.href = `/DetallesSolicitud/2/${res.body.id}`;
                        }, 3000);
                    }
                    else {
                        NotificationManager.success('Registro de venta enviada.', '¡Éxito!');
                    }
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddPreventaCredito", error);
            });
    };
}

export const fetchAddPreventaContado = (body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddPreventaContado(body)
            .then((res) => {
                if (res.status === 201) {
                    //dispatch(addPreventaContado(res.body));
                    if (vista === 1) {
                        NotificationManager.success('Registro de venta enviado.', '¡Éxito!');
                        setTimeout(() => {
                            window.location.href = `/DetallesSolicitud/3/${res.body.id}`;
                        }, 3000);
                    }
                    else if (vista === 2) {
                        NotificationManager.success('Registro de venta enviado.', '¡Éxito!');
                        setTimeout(() => {
                            window.location.href = `/DetallesSolicitud/2/${res.body.id}`;
                        }, 3000);

                    }
                    else {
                        NotificationManager.success('Registro de venta enviado.', '¡Éxito!');
                    }
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddPreventaContado", error);
            });
    };
}

export const fetchGetPreventa = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetPreventaSeleccionada(id)
            .then((solicitud) => {
                dispatch(getPreventaSeleccionado(solicitud));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetPreventaSeleccionada", error);
                dispatch(getPreventaSeleccionado(initialState.preventaSeleccionada));
            });
    };
}

export const fetchAceptarPreventa = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiPutPreventa(id)
            .then((resp) => {
                if (resp === 204) {
                    NotificationManager.success('Venta guardada correctamente.', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/Solicitudes/`;
                    }, 3000);
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorAcpetarPrecliente", error);
            });
    };
}

export const fetchEliminarPreventa = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiDeletePreventas(id)
            .then((resp) => {
                if (resp === 204) {
                    NotificationManager.success('Solicitud eliminada correctamente.', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/Solicitudes/`;
                    }, 3000);
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorEliminarPreventa", error);
            });
    };
}

export const fetchShowModalTransferencias = () => {
    return dispatch => {
        dispatch(showModalTransferencia());
    }
}

export const fetchHandleModalNotas = () => {
    return dispatch => {
        dispatch(handleModalNotas());
    }
}

export const fetchHandleModalSupervisores = () => {
    return dispatch => {
        dispatch(handleModalSupervisor());
    }
}

export const fetchResetSolicitud = () => {
    return dispatch => {
        dispatch(getPreclienteSeleccionado(initialState.preclienteSeleccionado));
    }
}

export const fetchCancelPresale = () => {
    return dispatch => {
        dispatch(addPreSale(false));
        dispatch(getPreclienteSeleccionado(initialState.preclienteSeleccionado));
    }
}

export const fetchAgregarPreventa = () => {
    return dispatch => {
        dispatch(handleAlert({
            show: false,
            type: 'warning',
            title: '',
            text: '',
            confirmButtonText: '',
            cancelButtonText: 'Cancelar',
            onConfirm: null,
            onCancel: null,
            showCancel: false
        }));
        dispatch(addPreSale(true));
    }
}

export const fetchGetNotasPreventas = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetNotasPreventa(id)
            .then((notas) => {
                dispatch(getNotesPreventa(notas));
                dispatch(getNotesPrecliente(initialState.notasPrecliente));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetNotasPreventas", error);
                dispatch(getNotesPreventa(initialState.notasPreventa));
            });
    };
}

export const fetchAddNotasPreventa = (body, id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddNotaPreventa(body, id)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(handleModalNotas());
                    dispatch(addNotePreventa(res.body));
                    NotificationManager.success('Nota agregada correctamente.', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddNotasPreventa", error);
            });
    };
}

export const fetchTransferirVentaCliente = (body, id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiTransferirVenta(body, id)
            .then((res) => {
                if (res === 204) {
                    dispatch(showModalTransferencia());
                    NotificationManager.success('Venta transferida a cliente seleccionado.', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/Solicitudes/`;
                    }, 3000);
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorTransferirVenta", error);
            });
    };
}

export const fetchUpdateTransfer = (bodyCliente, bodyVenta, idCliente, idPreventa) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateCliente(idCliente, bodyCliente)
            .then(res => {
                if (res.status === 200) {
                    NotificationManager.success('Información de cliente actualizada correctamente.', '¡Éxito!');
                    apiTransferirVenta(bodyVenta, idPreventa)
                        .then(response => {
                            if (response === 204) {
                                dispatch(showModalTransferencia());
                                NotificationManager.success('Venta transferida a cliente seleccionado.', '¡Éxito!');
                                setTimeout(() => {
                                    window.location.href = `/Solicitudes/`;
                                }, 3000);
                            }
                            else {
                                NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                            }
                        })
                        .catch(error => {
                            console.log("errorTransferinUpdate&Transfer", error);
                        });
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorUpdateinUpdate&Transfer", error);
            });
    };
}

export const fetchGetFechasPagosPreview = (id, obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetFechasPagosPreview(id, obj)
            .then((fechas) => {
                dispatch(getFechasPagos(fechas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetFechasPagosPreview", error);
                dispatch(getFechasPagos(initialState.fechasPagos));
            });
    };
}

export const fetchGetFechasPagosPreventa = (id, obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetFechasPagosPreventa(id, obj)
            .then((fechas) => {
                dispatch(getFechasPagos(fechas));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetFechasPagosPreventa", error);
                dispatch(getFechasPagos(initialState.fechasPagos));
            });
    };
}

export const fetchResetFechas = () => {
    return dispatch => {
        dispatch(getFechasPagos(initialState.fechasPagos));
    }
}

function redirectPrecliente(id) {
    window.location.href = `/DetallesSolicitud/1/${id}`;
}

const preregistrosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRECLIENTES:
            return { ...state, preclientes: action.payload };
        case ADD_PRECLIENTE:
            return { ...state, preclientes: { ...state.preclientes, total: state.preclientes.total + 1, rows: [...state.preclientes.rows, action.payload] } };
        case GET_PRECLIENTE_SELECCIONADO:
            return { ...state, preclienteSeleccionado: action.payload };
        case GET_NOTES_PRECLIENTE:
            return { ...state, notasPrecliente: action.payload };
        case GET_NOTES_PREVENTA:
            return { ...state, notasPreventa: action.payload };
        case ADD_NOTE_PRECLIENTE:
            return { ...state, notasPrecliente: { ...state.notasPrecliente, total: state.notasPrecliente.total + 1, rows: [...state.notasPrecliente.rows, action.payload] } };
        case ADD_NOTE_PREVENTA:
            return { ...state, notasPreventa: { ...state.notasPreventa, total: state.notasPreventa.total + 1, rows: [...state.notasPreventa.rows, action.payload] } };
        case ACEPTAR_PRECLIENTE:
            return { ...state, preclientes: { ...state.preclientes, total: state.preclientes.total - 1, rows: state.preclientes.rows.filter(elem => elem.id !== action.payload) } };
        case ELIMINAR_PRECLIENTE:
            return { ...state, preclientes: { ...state.preclientes, total: state.preclientes.total - 1, rows: state.preclientes.rows.filter(elem => elem.id !== action.payload) } };
        case GET_PREVENTAS:
            return { ...state, preventas: action.payload };
        case ADD_PREVENTA_CREDITO:
            return { ...state, preventas: { ...state.preventas, total: state.preventas.total + 1, rows: [...state.preventas.rows, action.payload] } };
        case ADD_PREVENTA_CONTADO:
            return { ...state, preventas: { ...state.preventas, total: state.preventas.total + 1, rows: [...state.preventas.rows, action.payload] } };
        case GET_PREVENTA_SELECCIONADA:
            return { ...state, preventaSeleccionada: action.payload };
        case ELIMINAR_PREVENTA:
            return { ...state, preventas: { ...state.preventas, total: state.preventas.total - 1, rows: state.preventas.rows.filter(elem => elem.id !== action.payload) } };
        case SHOW_MODAL_TRANSFERENCIA:
            return { ...state, showModalTransferencia: !state.showModalTransferencia };
        case HANDLE_MODAL_NOTAS:
            return { ...state, showModalNotas: !state.showModalNotas };
        case HANDLE_MODAL_SUPERVISOR:
            return { ...state, showModalSupervisor: !state.showModalSupervisor };
        case ADD_PRESALE_PRECLIENT:
            return { ...state, PreSaletoPreClient: action.payload };
        case GET_FECHAS_PAGOS:
            return { ...state, fechasPagos: action.payload };
        default:
            return { ...state };
    }
};

export default preregistrosReducer;