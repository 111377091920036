import React from 'react';
import ReactDOM from 'react-dom';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

import 'sweetalert/dist/sweetalert.css';

import './index.css';

import { Provider } from 'react-redux';
import store from './Store/index';
import AppRoutes from './Routes/AppRoutes'
import { BrowserRouter as Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Provider store={store}>
    <Router>
        <AppRoutes />
    </Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
