import React, { Component } from 'react';
import { Modal, ModalBody, Container } from 'mdbreact';
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { baseUrl } from '../../Api/general';

class ModalTransferir extends Component {

    renderImage = () => {
        const { cliente } = this.props;
        if (Object.entries(cliente).length === 0 && cliente.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (cliente && cliente.imagenes && cliente.imagenes.length > 0) {
                imageUrl = baseUrl + cliente.imagenes.find(image => image.tipo.id === 1).url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    render() {

        const { showModal, handleModal, selCliente, cliente, showClientDetails, handleClientesOptions, handleClienteChange, loadClientes, transferirVenta } = this.props;

        return (

            <Modal isOpen={showModal} centered size="lg" showModal={() => loadClientes()}>
                <div className="modal-header header-recibir">
                    <p className="modal-title">Transferir a cliente existente</p>
                    <button type="button" className="close" aria-label="Close" onClick={() => handleModal()}><span aria-hidden="true">×</span></button>
                </div>
                <ModalBody>
                    <Container fluid >
                        <form autoComplete="off">
                            <br />
                            <div className="row">
                                <div className="col-md-12 input-margin">
                                    <Select options={handleClientesOptions()} value={selCliente} placeholder="Elegir cliente a transferir venta" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={handleClienteChange} isClearable />
                                </div>
                            </div>
                            <br />
                            {showClientDetails &&
                                <div className="row" >
                                    <div className="col-md-8 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 input-margin">
                                                <p>Nombre</p>
                                                <p className="descripcion">{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</p>
                                            </div>
                                            <div className="col-md-6 col-sm-12 input-margin">
                                                <p>Teléfono</p>
                                                <p className="descripcion">{cliente.telefono}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 input-margin">
                                                <p>Dirección</p>
                                                <p className="descripcion">{cliente.direccion}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 input-margin text-center">
                                                {
                                                    this.renderImage()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <br />
                            <div className="text-center">
                                <button type="button" className="btn btn-modal btn-verde"  onClick={(e) => transferirVenta(e, 1)}>Transferir </button>
                                <button type="button" className="btn btn-modal btn-azul"  onClick={(e) => transferirVenta(e, 2)} >Transferir y actualizar datos</button>
                                <button type="button" className="btn btn-modal btn-rojo" onClick={() => handleModal()}>Cancelar</button>
                            </div>
                        </form>
                    </Container>
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalTransferir;