import {
    apiGetAcumuladosCobrador,
    apiGetEstadisticasMonitor
} from '../Api/apiCobradores';
import { NotificationManager } from 'react-notifications';
import { handleLoading } from './general';

const initialState = {
    acumuladosMonitor: {
        total: 0,
        rows: [],
    },
    estadisticasMonitor: {
        abonos: {
            total: 0,
            rows: []
        },
        pendientes: {
            total: 0,
            rows: []
        },
        pospuestos: {
            total: 0,
            rows: []
        }
    }
};

const GET_LISTA_ACUMULADOS_MONITOR = "GET_LISTA_ACUMULADOS_MONITOR";
const UPDATE_LISTA_MONITOR = "UPDATE_LISTA_MONITOR";
const GET_ESTADISTICAS_MONITOR = "GET_ESTADISTICAS_MONITOR";

const getEstadisticasMonitor = estadisticas => ({ type: GET_ESTADISTICAS_MONITOR, payload: estadisticas });
const getAcumuladoMonitor = acumulado => ({ type: GET_LISTA_ACUMULADOS_MONITOR, payload: acumulado });
export const updateStateMonitor = cobrador => ({ type: UPDATE_LISTA_MONITOR, payload: cobrador });

export const fetchGetAcumuladosMonitor = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetAcumuladosCobrador(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getAcumuladoMonitor(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                dispatch(getAcumuladoMonitor(initialState.acumuladosMonitor));
                NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                console.log("errorGetAcumuladosMonitor", error);
            });
    };
}

export const fetchGetEstadisticasMonitor = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetEstadisticasMonitor(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getEstadisticasMonitor(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                dispatch(getEstadisticasMonitor(initialState.estadisticasMonitor));
                console.log("errorGetEstadisticasMonitor", error);
            });
    };
}

const monitorReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LISTA_ACUMULADOS_MONITOR:
            return { ...state, acumuladosMonitor: action.payload };
        case UPDATE_LISTA_MONITOR:
            return {
                ...state, acumuladosMonitor: {
                    ...state.acumuladosMonitor,
                    rows: state.acumuladosMonitor.rows.map(usuario => usuario.cobrador.id === action.payload.id ? { ...usuario, cobrador: { ...usuario.cobrador, activo: action.payload.activo } } : usuario)
                }
            };
        case GET_ESTADISTICAS_MONITOR:
            return { ...state, estadisticasMonitor: action.payload };
        default:
            return { ...state };
    }
};

export default monitorReducer;