import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchGetVentaSeleccionada, fetchGetNotasVenta, fetchGetFechasVenta } from '../../Reducers/ventas';
import { fetchShowModalCobradorCliente, fetchGetCobradorAsignado, fetchChangeCobradorCliente, fetchResetCobradorAsignado } from '../../Reducers/clientes';
import { fetchGetCatUsuariosMultiple } from '../../Reducers/catalogos';
import Notas from './notas';
import { Card, CardBody, ListGroupItem, Button, Modal, ModalBody, Container } from 'mdbreact';
import moment from 'moment';
import { Link, withRouter } from "react-router-dom";
import MultipleSeleccion from '../Ventas/productos-seleccionados';
import { NotificationManager } from 'react-notifications';
import jwtDecode from "jwt-decode";
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import '../../Css/pagos.css';

let optCobrador = [];

class DetallesVenta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCliente: -1,
            buscar: true,
            selCobradorAsignado: null,
            cobradorSeleccionado: {}
        }
    }

    componentDidMount() {
        const { getVenta, getFechas, getNotas } = this.props;
        const { uuid } = this.props.match.params;
        getVenta(uuid);
        getFechas(uuid);
        getNotas(uuid);
    }

    componentDidUpdate() {
        const { venta, getCobradorAsignado } = this.props;
        const { idCliente, buscar } = this.state;
        if (Object.entries(venta).length !== 0 && buscar) {
            getCobradorAsignado(venta.ClienteId);
            this.setState({ idCliente: venta.ClienteId, buscar: false });
        }
    }

    componentWillUnmount() {
        const { resetCobradorAsignado } = this.props;
        resetCobradorAsignado();
    }

    returnFechas = () => {
        const { fechas } = this.props;
        let fechasPagos = '';
        if (fechas.total > 0) {
            fechasPagos = fechas.rows.map((fecha, k) => {
                let lblClass = '';
                switch (fecha.evento.id) {
                    case 1:
                    case 6:
                        lblClass = 'lbl-pendiente';
                        break;
                    case 2:
                    case 5:
                    case 7:
                        lblClass = 'lbl-multi';
                        break;
                    case 3:
                        lblClass = 'lbl-atrasado';
                        break;
                    case 4:
                        lblClass = 'lbl-pagado';
                        break;
                }
                return (<ListGroupItem className="list-fechas" key={k}>
                    <p className="mb-1" className={lblClass}>{moment(fecha.fecha).format('DD/MM/YYYY')}</p>
                </ListGroupItem>)
            }
            );
        }
        return fechasPagos;
    }

    handleModalCobradorCliente = () => {
        const { cobradorAsignado, handleModalCC, getCobradores, showModalCC } = this.props;
        if (!showModalCC) {
            if (Object.entries(cobradorAsignado).length !== 0) {
                this.setState({ selCobradorAsignado: { value: cobradorAsignado.id, label: cobradorAsignado.nombre + ' ' + cobradorAsignado.apellidoP + ' ' + cobradorAsignado.apellidoM } });
            }
            let obj = {
                tipo: 4,
                activo: 'true'
            };
            getCobradores(obj);
        }
        handleModalCC();
    }

    handleOptsCobrador = () => {
        const { cobradores } = this.props;
        optCobrador = [];
        cobradores.rows.map((user) =>
            optCobrador.push({ value: user.id, label: user.nombre + ' ' + user.apellidoP + ' ' + user.apellidoM })
        );
        return optCobrador;
    }

    handleCobradorChange = (selCobradorAsignado) => {
        const { cobradores } = this.props;
        if (selCobradorAsignado) {
            let cobrador = cobradores.rows.find(cobrador => cobrador.id === selCobradorAsignado.value);
            this.setState({
                ...this.state, selCobradorAsignado,
                cobradorSeleccionado: cobrador
            });
        }
        else {
            this.setState({
                ...this.state, selCobradorAsignado,
                cobradorSeleccionado: {}
            });
        }
    }

    cambiarCobrador = (e) => {
        e.preventDefault();
        const { changeCobrador, cobradorAsignado } = this.props;
        const { selCobradorAsignado, cobradorSeleccionado, idCliente } = this.state;

        if (selCobradorAsignado) {
            if ((Object.entries(cobradorAsignado).length !== 0) && (selCobradorAsignado.value === cobradorAsignado.id)) {
                NotificationManager.warning('La información no ha sido modificada.', 'Información sin cambios');
            }
            else {
                let cambio = {
                    "CobradorId": selCobradorAsignado.value,
                    "clientes": [idCliente]
                };
                changeCobrador(cambio, cobradorSeleccionado);
            }
        }
        else {
            NotificationManager.warning('Debe seleccionar un cobrador para continuar.', 'Información incompleta');
        }

    }

    render() {

        const { venta, notas, fechas, cobradorAsignado, showModalCC, hasCobrador, usuario } = this.props;
        const { selCobradorAsignado, idCliente } = this.state;
        const decoded = jwtDecode(localStorage.token);

        if (Object.entries(venta).length === 0 && venta.constructor === Object) {
            return (<div></div>);
        }
        else {
            let cobrador = { nombre: '', telefono: '' };
            if (Object.entries(cobradorAsignado).length !== 0) {
                cobrador.nombre = cobradorAsignado.nombre + ' ' + cobradorAsignado.apellidoP + ' ' + cobradorAsignado.apellidoM;
                cobrador.telefono = cobradorAsignado.telefono;
            }

            return (
                <>
                    <Card className="card-css">
                        <CardBody>
                            <div className="row">
                                <div className="col-md-1 col-sm-3" style={{ paddingTop: "5px" }}>
                                    <Link className="btn-back" to={'/Ventas'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                                </div>
                                <div className="col-md-9 col-sm-6 "><h4 style={{ color: "#4F4F4F" }}><b>Folio: {venta.id.toString().padStart(7, '0')}</b></h4></div>
                                <div className="col-md-2 col-sm-3 text-right">{moment(venta.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                            </div>
                            <hr className="gral-hr" />
                            <div className="body-generales" >
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5><b>Información de cliente</b></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.cliente.nombre} {venta.cliente.apellidoP} {venta.cliente.apellidoM}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.cliente.telefono}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                                    <div className="col-md-9 col-sm-12 descripcion input-margin">{venta.cliente.email}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                    <div className="col-md-9 col-sm-12 descripcion input-margin">{venta.cliente.direccion}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5><b>Información de producto</b></h5>
                                    </div>
                                </div>
                                {venta.tipo.id === 1 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de producto</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.productos[0].nombre}</div>
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Código de barras</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.productos[0].codigoBarras}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Precio de contado</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${venta.productos[0].precioContado.toFixed(2).toLocaleString()}</div>
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Código numeral</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.productos[0].numeralEditable}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Precio a crédito</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${venta.productos[0].precioCredito.toFixed(2).toLocaleString()}</div>
                                        </div>
                                    </>
                                }
                                {venta.tipo.id === 2 && <MultipleSeleccion vista={3} objEditar={venta.productos} />}
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5><b>Información de pago</b></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Tipo de pago </b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.tipo.nombre}</div>
                                    {venta.tipo.id === 1 &&
                                        <>
                                            <div className="col-md-3 col-sm-12 input-margin" ><b>Frecuencia</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.tipoCredito.nombre}</div>
                                        </>
                                    }
                                </div>
                                {venta.tipo.id === 1 &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Precio crédito</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${venta.productos[0].precioCredito.toFixed(2).toLocaleString()}</div>
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Anticipo</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${venta.anticipo.toFixed(2).toLocaleString()}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Pendiente</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${(venta.total - venta.recaudado).toFixed(2).toLocaleString()}</div>
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Abono</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${Math.ceil(venta.abonoEstimado).toFixed(2).toLocaleString()}</div>
                                        </div>
                                        <div className="row" >
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Fecha inicial de cobro</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('DD/MM/YYYY') : ''}</div>
                                            <div className="col-md-3 col-sm-12 input-margin"><b>Hora sugerida de cobro</b></div>
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('HH:mm') : ''}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 input-margin"> <b>Fechas de pagos:</b></div>
                                            <div className="col-md-12 col-sm-12 input-margin">
                                                <ul className="list-inline">
                                                    {this.returnFechas()}
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                                <hr />
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5><b>Información de vendedor</b></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.vendedor.nombre} {venta.vendedor.apellidoP} {venta.vendedor.apellidoM}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{venta.vendedor.telefono}</div>
                                </div>
                                <hr />


                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <h5><b>Información de cobrador</b></h5>
                                    </div>
                                    <div className="col-md-6 col-sm-6 text-right">
                                        {decoded.tipo !== 3 && <Button className="btn-detalles transferir" onClick={() => this.handleModalCobradorCliente()}><i className="fas fa-motorcycle"></i>Cambiar cobrador</Button>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cobrador.nombre}</div>
                                    <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                    <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cobrador.telefono}</div>
                                </div>
                                <hr />

                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <h5><b>Notas</b></h5>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <Notas notas={notas} />
                                </div>
                                <br />
                            </div>
                        </CardBody>
                    </Card>

                    <Modal isOpen={showModalCC} className="modal-dinero" centered>
                        <div className="modal-header header-recibir">
                            <p className="modal-title">Cobrador asignado</p>
                            <button type="button" className="close" aria-label="Close" onClick={() => this.handleModalCobradorCliente()}><span aria-hidden="true">×</span></button>
                        </div>
                        <ModalBody>
                            <Container fluid >
                                <form autoComplete="off" onSubmit={(e) => this.cambiarCobrador(e)}>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 input-margin">
                                            <Select options={this.handleOptsCobrador()} value={selCobradorAsignado} placeholder="Seleccionar cobrador" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleCobradorChange} isClearable />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-crear" >Confirmar</button>
                                    </div>
                                </form>
                            </Container>
                        </ModalBody>
                    </Modal>
                </>
            )
        }
    }
}

export default withRouter(connect(state => ({
    venta: state.ventas.ventaSeleccionada,
    fechas: state.ventas.fechasVenta,
    notas: state.ventas.notasVenta,
    cobradorAsignado: state.clientes.cobradorAsignado,
    showModalCC: state.clientes.showModalCC,
    cobradores: state.catalogos.cobradoresCat,
    hasCobrador: state.clientes.isCobradorAsignado,
}), {
        getVenta: fetchGetVentaSeleccionada,
        getFechas: fetchGetFechasVenta,
        getNotas: fetchGetNotasVenta,
        changeCobrador: fetchChangeCobradorCliente,
        handleModalCC: fetchShowModalCobradorCliente,
        getCobradores: fetchGetCatUsuariosMultiple,
        resetCobradorAsignado: fetchResetCobradorAsignado,
        getCobradorAsignado: fetchGetCobradorAsignado,
    })(DetallesVenta));