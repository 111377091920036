import React, { Component } from 'react';
import { CardGroup, Card, Container, CardBody, CardTitle, CardText, CardFooter } from 'mdbreact';
import moment from 'moment';

class NotasSolicitud extends Component {

    render() {

        const { notasPrecliente, notasPreventa } = this.props;
        let totalNotas = notasPrecliente.total + notasPreventa.total;

        /*
        <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Fecha</th>
                            <th>Nota</th>
                            <th>Quién la escribió</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {notasPrecliente.total > 0 ?
                            notasPrecliente.rows.map((nota, i) =>
                                <tr key={i}>
                                    <td> {moment(nota.createdAt).format('DD/MM/YYYY  HH:mm')}</td>
                                    <td> {nota.nota}</td>
                                    <td> {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM}</td>
                                </tr>
                            )
                            :
                            null
                        }
                        {notasPreventa.total > 0 ?
                            notasPreventa.rows.map((nota, i) =>
                                <tr key={i}>
                                    <td> {moment(nota.createdAt).format('DD/MM/YYYY  HH:mm')}</td>
                                    <td> {nota.nota}</td>
                                    <td> {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM}</td>
                                </tr>
                            )
                            :
                            null
                        }
                    </TableBody>
                </Table>
        */

        if (totalNotas > 0) {
            return (
                <div className="col-12">
                        <CardGroup deck>
                            {notasPrecliente.total > 0 ?
                                notasPrecliente.rows.map((nota, i) =>
                                    <Card className="text-justify col-nota" key={i}>
                                        <CardBody>
                                            <CardText>
                                                {nota.nota}
                                            </CardText>
                                        </CardBody>
                                        <CardFooter small muted>
                                            {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM + ' (' + moment(nota.createdAt).format('DD/MM/YYYY  HH:mm') + ')'}
                                        </CardFooter>

                                        {/* <blockquote className="blockquote mb-0 card-body">
                                            <p>{nota.nota}</p>
                                            <footer className="blockquote-footer">
                                                <small className="text-muted">
                                                    {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM + ' (' + moment(nota.createdAt).format('DD/MM/YYYY  HH:mm') + ')'}
                                                </small>
                                            </footer>
                            </blockquote>*/}
                                    </Card>

                                )
                                :
                                ''
                            }
                            {notasPreventa.total > 0 ?
                                notasPreventa.rows.map((nota, i) =>
                                    <Card className="text-justify  col-nota" key={i}>
                                        <CardBody>
                                            <CardText>
                                                {nota.nota}
                                            </CardText>
                                        </CardBody>
                                        <CardFooter small muted>
                                            {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM + ' (' + moment(nota.createdAt).format('DD/MM/YYYY  HH:mm') + ')'}
                                        </CardFooter>
                                        { /*<blockquote className="blockquote mb-0 card-body">
                                            <p>{nota.nota}</p>
                                            <footer className="blockquote-footer">
                                                <small className="text-muted">
                                                    {nota.usuario.nombre + ' ' + nota.usuario.apellidoP + ' ' + nota.usuario.apellidoM + ' (' + moment(nota.createdAt).format('DD/MM/YYYY  HH:mm') + ')'}
                                                </small>
                                            </footer>
                            </blockquote>*/}
                                    </Card>
                                )
                                :
                                ''
                            }
                        </CardGroup>
              
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}

export default NotasSolicitud;