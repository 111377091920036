import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaVentas from './lista-ventas';
import AltaVenta from './alta';

import '../../Css/ventas.css';

class Ventas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            editar: false,
            idVenta: -1
        }
        this.handleTabChange = this.handleTabChange.bind(this);
        this.editarVenta = this.editarVenta.bind(this);
        this.cancelarEditar = this.cancelarEditar.bind(this);
    }

    handleTabChange(id) {
        this.setState({ tab: id });
    }

    editarVenta(id) {
        this.setState({ idVenta: id, editar: true })
    }

    cancelarEditar() {
        this.setState({ idVenta: -1, editar: false })
    }

    render() {
        const { tab, editar, idVenta } = this.state;

        return (
            <Card className="card-css">
                <CardHeader>
                    <Nav header>
                        <NavItem>
                            <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de ventas</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nueva venta</NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                            {tab === 1 && <ListaVentas />}
                            {tab === 2 && <AltaVenta />}
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Ventas;