import {
    apiGetProductos,
    apiCreateProducto,
    apiUpdateProducto,
    apiGetDetallesProducto,
    apiActivateProducto,
    apiDeleteProducto,
} from '../Api/apiProductos';

import { NotificationManager } from 'react-notifications';

import { handleLoading } from './general';

const initialState = {
    productos: {
        rows: [],
        total: 0,
    },
    productoSeleccionado: {},
    editViewProducto: false
};

const GET_PRODUCTOS = "GET_PRODUCTOS";
const ADD_PRODUCTO = "ADD_PRODUCTO";
const UPDATE_PRODUCTO_LISTA = "UPDATE_PRODUCTO_LISTA";
const UPDATE_PRODUCTO_DETALLES = "UPDATE_PRODUCTO_DETALLES";
const SELECCIONAR_PRODUCTO = "SELECCIONAR_PRODUCTO";
const SHOW_PRODUCT_FORM = "SHOW_PRODUCT_FORM";

const getProductos = productos => ({ type: GET_PRODUCTOS, payload: productos });
export const addProducto = producto => ({ type: ADD_PRODUCTO, payload: producto });
export const updateProductoLista = producto => ({ type: UPDATE_PRODUCTO_LISTA, payload: producto });
export const updateProductoDetalles = producto => ({ type: UPDATE_PRODUCTO_DETALLES, payload: producto });
const seleccionarProducto = id => ({ type: SELECCIONAR_PRODUCTO, payload: id });
const verFormEdProducto = () => ({ type: SHOW_PRODUCT_FORM });

export const fetchGetProductos = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetProductos(obj)
            .then((productos) => {
                dispatch(getProductos(productos));
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorGetProductos", error);
                dispatch(getProductos(initialState.productos));
            });
    };
}

export const fetchAddProducto = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiCreateProducto(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addProducto(res.body));
                    NotificationManager.success('Producto guardado correctamente', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/DetallesProducto/${res.body.id}`;
                    }, 3000);
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddProducto", error);
            });
    };
}

export const fetchUpdateProducto = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateProducto(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateProductoLista(res.body));
                    }
                    else {
                        dispatch(updateProductoDetalles(res.body));
                    }
                    dispatch(verFormEdProducto());
                    NotificationManager.success('Producto guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeProducto", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchDeleteProducto = (id, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiDeleteProducto(id)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateProductoLista(res.body));
                    }
                    else {
                        dispatch(updateProductoDetalles(res.body));
                    }
                    NotificationManager.success('Producto desactivado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                dispatch(handleLoading());
                console.log("errorDelProducto", error);
            });
    };
}

export const fetchActivateProducto = (id, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiActivateProducto(id)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateProductoLista(res.body));
                    }
                    else {
                        dispatch(updateProductoDetalles(res.body));
                    }
                    NotificationManager.success('Producto activado correctamente', '¡Éxito!');
                }
                else {
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorActivarProducto", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchGetSelectedProduct = (id) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetDetallesProducto(id)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(updateProductoDetalles(res.body));
                }
                else {
                    dispatch(updateProductoDetalles(initialState.productoSeleccionado));
                    NotificationManager.error('Ha ocurrido un error, por favor intente más tarde.', 'Error');
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorGetDetallesProducto", error);
            });
    };
}

export const fetchSeleccionarProducto = (id) => {
    return dispatch => {
        if (id !== -1) {
            dispatch(seleccionarProducto(id));
        }
        else {
            dispatch(updateProductoDetalles(initialState.productoSeleccionado));
        }
    }
}

export const fetchEditViewProductos = () => {
    return dispatch => {
        dispatch(verFormEdProducto());
    }
}

const productosReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTOS:
            return { ...state, productos: action.payload };
        case ADD_PRODUCTO:
            return { ...state, productos: { ...state.productos, total: state.productos.total + 1, rows: [...state.productos.rows, action.payload] } };
        case UPDATE_PRODUCTO_LISTA:
            return { ...state, productos: { total: state.productos.total, rows: state.productos.rows.map(producto => producto.id === action.payload.id ? action.payload : producto) } };
        case UPDATE_PRODUCTO_DETALLES:
            return { ...state, productoSeleccionado: action.payload };
        case SELECCIONAR_PRODUCTO:
            return { ...state, productoSeleccionado: state.productos.rows.find(elem => elem.id === action.payload) };
        case SHOW_PRODUCT_FORM:
            return { ...state, editViewProducto: !state.editViewProducto };
        default:
            return { ...state };
    }
};

export default productosReducer;