import React, { Component } from 'react';
import { Table, TableBody, TableHead, Nav, NavItem, NavLink, Input } from 'mdbreact';
import { connect } from 'react-redux';
import { fetchGetComisiones } from '../../Reducers/reportes';
import Paginacion from '../Shared/pagination';
import { CSSTransition, TransitionGroup } from "react-transition-group";
import '../../Css/custom-chbx.css';

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

const arr = [
    {
        id: 1,
        semana: 'Semana 1 - 4',
        resumen: [
            { 'num': '1', 'fechas': '01/01/2019 - 06/01/2019', 'porcentajeCantidad': '83.33%', 'cantidad': '$ 1,000.00', 'porcentajeComision': '10%', 'comision': '$ 100.00', 'porcentajeAcumulado': '2%', 'acumulado': '$ 20.00', 'pagado': true },
            { 'num': '2', 'fechas': '07/01/2019 - 13/01/2019', 'porcentajeCantidad': '92.30%', 'cantidad': '$ 1,200.00', 'porcentajeComision': '10%', 'comision': '$ 100.00', 'porcentajeAcumulado': '2%', 'acumulado': '$ 20.00', 'pagado': true },
            { 'num': '3', 'fechas': '14/01/2019 - 20/01/2019', 'porcentajeCantidad': '75%', 'cantidad': '$ 1,500.00', 'porcentajeComision': '10%', 'comision': '$ 100.00', 'porcentajeAcumulado': '2%', 'acumulado': '$ 20.00', 'pagado': true },
            { 'num': '4', 'fechas': '21/01/2019 - 27/01/2019', 'porcentajeCantidad': '100%', 'cantidad': '$ 2,000.00', 'porcentajeComision': '10%', 'comision': '$ 100.00', 'porcentajeAcumulado': '2%', 'acumulado': '$ 20.00', 'pagado': false }
        ],
        periodo: '01/01/2019 - 27/01/2019',
        total: '$ 80.00'
    },
    {
        id: 2,
        semana: 'Semana 5 - 8',
        resumen: [
            { 'num': '5', 'fechas': '04/02/2019 - 10/02/2019', 'porcentajeCantidad': '80%', 'cantidad': '$ 1,000.00', 'porcentajeComision': '10%', 'comision': '$ 100.00', 'porcentajeAcumulado': '2%', 'acumulado': '$ 20.00', 'pagado': false },
        ],
        periodo: '04/02/2019 - 03/03/2019',
        total: '$ 20.00'
    },
];

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    anio: '',
    tipoUsuario: 3,
    selectYear: null,
    index: 0,
    selected: arr[0],
    classArrow: 'move-right',
    weekSelected: 1
};

let optionsYears = [
    { value: '2019', label: '2019' }
];

class ListaComisioens extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size, tipoUsuario } = this.state;
        //const { getIngresos } = this.props;

        let obj = {
            tipo: tipoUsuario,
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        //getIngresos(obj);
    }

    handleTipoChange = (id) => {
        this.setState({ tipoUsuario: id });
    }

    handleWeekChange = (e) => {
        e.preventDefault();
        let id = parseInt(e.target.name);
        this.setState({ weekSelected: id });
    }

    handleYearsChange = (selectYear) => {
        this.setState({
            ...this.state, selectYear
        });
    }

    paginationHandler(event, value) {
        const { activePage, size } = this.state;
        //const { getIngresos } = this.props;
        let pagina;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        //getIngresos(obj);
    }

    nextWeeks = () =>
        this.setState(prevState => {
            const newIndex = prevState.index < arr.length - 1 ? prevState.index + 1 : 0;
            return {
                index: newIndex,
                selected: arr[newIndex],
                classArrow: 'move-left'
            };
        });

    prevWeeks = () =>
        this.setState(prevState => {
            const newIndex = prevState.index === 0 ? arr.length - 1 : prevState.index - 1;
            return {
                index: newIndex,
                selected: arr[newIndex],
                classArrow: 'move-right'
            };
        });

    render() {

        const { activePage, size, tipoUsuario, selectYear, selected, classArrow, index, weekSelected } = this.state;
        const { comisiones } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <Nav className="nav-pills" id="navsTipo">
                            <NavItem>
                                <NavLink className={tipoUsuario === 3 ? 'tabs-tipos-css active' : 'tabs-tipos-css'} to="#" onClick={() => this.handleTipoChange(3)}>Vendedores</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tipoUsuario === 4 ? 'tabs-tipos-css active' : 'tabs-tipos-css'} to="#" onClick={() => this.handleTipoChange(4)}>Cobradores</NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de registros</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        <Select options={optionsYears} value={selectYear} placeholder="Filtrar por año" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleYearsChange} isClearable />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-md-1 text-left">
                        <button className="btn-bloque" onClick={this.prevWeeks} disabled={index === 0 ? true : false} ><i className="fas fa-arrow-left fa-2x"></i></button>
                    </div>
                    <div className="col-md-10 text-center">
                        <TransitionGroup>
                            <CSSTransition key={selected.id} timeout={1000} /*classNames="move"*/ classNames={classArrow} >
                                <h5>
                                    <b>{selected.semana}</b><br />
                                    <span className="text-muted small">{selected.periodo}</span>
                                </h5>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                    <div className="col-md-1 text-right">
                        <button className="btn-bloque" onClick={this.nextWeeks} disabled={index === (arr.length - 1) ? true : false}><i className="fas fa-arrow-right fa-2x"></i></button>
                    </div>
                </div>
                <hr className="gral-hr" />
                <div className="row">
                    <div className="col-md-3 tab-alignment">
                        <a className={`tabs-semanas-css ${weekSelected === 1 ? 'active' : ''}`} href="#" name="1" onClick={(e) => this.handleWeekChange(e)}>Semana 1<div className="Ripple is-reppling" style={{ top: "-37px", left: "7px", width: "122px", height: "122px" }}></div></a>
                    </div>
                    <div className="col-md-3 tab-alignment">
                        <a className={`tabs-semanas-css ${weekSelected === 2 ? 'active' : ''}`} href="#" name="2" onClick={(e) => this.handleWeekChange(e)}>Semana 2<div className="Ripple is-reppling" style={{ top: "-37px", left: "7px", width: "122px", height: "122px" }}></div></a>
                    </div>
                    <div className="col-md-3 tab-alignment">
                        <a className={`tabs-semanas-css ${weekSelected === 3 ? 'active' : ''}`} href="#" name="3" onClick={(e) => this.handleWeekChange(e)}>Semana 3<div className="Ripple is-reppling" style={{ top: "-37px", left: "7px", width: "122px", height: "122px" }}></div></a>
                    </div>
                    <div className="col-md-3 tab-alignment">
                        <a className={`tabs-semanas-css ${weekSelected === 4 ? 'active' : ''}`} href="#" name="4" onClick={(e) => this.handleWeekChange(e)}>Semana 4<div className="Ripple is-reppling" style={{ top: "-37px", left: "7px", width: "122px", height: "122px" }}></div></a>
                    </div>
                </div>
                <hr className="gral-hr" />

                <TransitionGroup>
                    <CSSTransition key={selected.id} timeout={1000} /*classNames="move"*/ classNames={classArrow} >
                        <Table className="text-center tbl-css" responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre</th>
                                    <th>% Comisión</th>
                                    <th>Monto</th>
                                    <th>Acumulado</th>
                                    <th>Pagado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selected.resumen.map(semana =>
                                        <tr key={semana.num}>
                                            <td className="col-numero">{semana.num}</td>
                                            <td>{semana.fechas}</td>
                                            <td> {semana.porcentajeCantidad}</td>
                                            <td> {semana.cantidad} </td>
                                            <td> {semana.porcentajeComision} </td>
                                            <td className="td-checkbox">
                                                {semana.pagado ?
                                                    <div className="div-checkbox-checked"></div>
                                                    :
                                                    <div className="div-checkbox"></div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </CSSTransition>
                </TransitionGroup>
            </div >
        )
    }
}

export default connect(state => ({
    comisiones: state.reportes.comisiones
}), {
        getComisiones: fetchGetComisiones
    })(ListaComisioens);