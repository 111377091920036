import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchDeleteAlertasNotificaciones, fetchGetNotificaciones } from '../../Reducers/notificaciones';

class DropdownNotificaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.startInterval();
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    startInterval() {
        const { getNotificaciones } = this.props;
        getNotificaciones();
        try {
            this.interval = setInterval(async () => {
                getNotificaciones();
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    stopInterval() {
        clearInterval(this.interval);
    }

    handleDropdownClick = () => {
        const { show } = this.state;
        const { deleteNotificaciones } = this.props;
        if (show) {
            deleteNotificaciones();
        }
        this.setState({ show: !show });
    }

    render() {
        const { notificaciones } = this.props;
        const { show } = this.state;

        return (
            <div className="dropdown dd-info">
                <a aria-haspopup="true" href="#" className="nav-link" onClick={this.handleDropdownClick}>
                    <div className="d-md-inline div-usr">
                        <i className="fas fa-bell fa-lg icon-verde"></i>
                        <span className="texto-hide">Notificaciones</span>
                        <span className="badge default badge-default badgeInfo">{notificaciones.total}</span>
                    </div>
                </a>
                <div tabIndex="-1" role="menu" aria-hidden="true" className={`dropdown-menu ${show ? 'show' : ''}`} style={{ right: "0", left: "auto" }}>
                    <div className="arrow-up"></div>
                    {notificaciones.total > 0 ?
                        notificaciones.rows.map((item, index) =>
                            <React.Fragment key={index}>
                                <button type="button" tabIndex="0" className="dropdown-item" >
                                    <div className="alerta-fecha">{moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</div>
                                    <div className="alerta-descripcion">{item.mensaje} </div>
                                </button>
                                <div tabIndex="-1" className="dropdown-divider" key={index + notificaciones.total}></div>
                            </React.Fragment>
                        )
                        :
                        <>
                            <button type="button" tabIndex="0" className="dropdown-item">
                                <div className="alerta-descripcion">No se encontraron notificaciones nuevas</div>
                            </button>
                            <div tabIndex="-1" className="dropdown-divider"></div>
                        </>
                    }
                    <a className="dropdown-item ver_todo text-center" href="/Notificaciones">Ver todo</a>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    notificaciones: state.notificaciones.notificaciones
}), {
        getNotificaciones: fetchGetNotificaciones,
        deleteNotificaciones: fetchDeleteAlertasNotificaciones
    })(DropdownNotificaciones);