import { baseUrl, GET, POST, PUT, DELETE, CreateRequest,  makeUrlGET} from './general';

export const apiGetTipoVentas = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'tipos/ventas/';
    return fetch(url, request).then(res => res.json());
};

export const apiGetTipoCredito = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'tipos/creditos/';
    return fetch(url, request).then(res => res.json());
};
