
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import Paginacion from '../Shared/pagination';
import { fetchGetVentas } from '../../Reducers/ventas';
import { fetchGetCatUsuarios, fetchGetCatCredito, fetchGetCatVentas } from '../../Reducers/catalogos';
import { fetchGetProductos } from '../../Reducers/productos';


let optTiposVenta = [];
let optTiposCredito = [];
let optVendedores = [];
let optClientes = [];
let optProductos = [];
let optAnticipo = [
    { value: 'true', label: 'Con anticipo' },
    { value: 'false', label: 'Sin anticipo' }
];

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    anticipo: null,
    estado: null,
    tipoVenta: null,
    tipoCredito: null,
    vendedor: null,
    cliente: null,
    producto: null,
};

class ListaVentas extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { getVentas, getUsuarios, getCreditos, getVentasCat, getProductos } = this.props;
        let objVentas = {
            orderBy: 'desc',
            limit: '10',
            offset: '0'
        }
        let objUsuarios = {
            tipo: '3,6'
        }
        getVentas(objVentas);
        getUsuarios(objUsuarios);
        getCreditos();
        getVentasCat();
        getProductos();
    }

    handleAnticipoChange = (anticipo) => {
        this.setState({
            ...this.state, anticipo
        });
    }

    handleTipoVentaChange = (tipoVenta) => {
        this.setState({
            ...this.state, tipoVenta
        });
    }

    handleTipoVentaOptions = () => {
        optTiposVenta = [];
        this.props.ventasCat.rows.map((venta) =>
            optTiposVenta.push({ value: venta.id, label: venta.nombre })
        );
        return optTiposVenta;
    }

    handleTipoCreditoChange = (tipoCredito) => {
        this.setState({
            ...this.state, tipoCredito
        });
    }

    handleTipoCreditoOptions = () => {
        optTiposCredito = [];
        this.props.creditos.rows.map((credito) =>
            optTiposCredito.push({ value: credito.id, label: credito.nombre })
        );
        return optTiposCredito;
    }

    handleVendedoresChange = (vendedor) => {
        this.setState({
            ...this.state, vendedor
        });
    }

    handleVendedoresOptions = () => {
        optVendedores = [];
        this.props.usuarios.rows.map((usuario) => {
            if (usuario.tipo.id === 3) {
                optVendedores.push({ value: usuario.id, label: usuario.nombre + ' ' + usuario.apellidoP + ' ' + usuario.apellidoM });
            }
        });
        return optVendedores;
    }

    handleClientesChange = (cliente) => {
        this.setState({
            ...this.state, cliente
        });
    }

    handleClientesOptions = () => {
        optClientes = [];
        this.props.usuarios.rows.map((usuario) => {
            if (usuario.tipo.id === 6) {
                optClientes.push({ value: usuario.id, label: usuario.nombre + ' ' + usuario.apellidoP + ' ' + usuario.apellidoM });
            }
        }
        );
        return optClientes;
    }

    handleProductosChange = (producto) => {
        this.setState({
            ...this.state, producto
        });
    }

    handleProductosOptions = () => {
        optProductos = [];
        this.props.productos.rows.map((producto) =>
            optProductos.push({ value: producto.id, label: producto.nombre })
        );
        return optProductos;
    }

    buscarLista = () => {
        const { size, anticipo, estado, tipoVenta, tipoCredito, vendedor, cliente, producto, buscar } = this.state;
        const { getVentas } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            limit: size,
            offset: '0',
            orderBy: 'desc',
        }

        if (anticipo) {
            obj.anticipoBool = anticipo.value;
        }
        if (tipoVenta) {
            obj.tipoVenta = tipoVenta.value;
        }
        if (tipoCredito) {
            obj.tipoCredito = tipoCredito.value;
        }
        if (vendedor) {
            obj.vendedor = vendedor.value;
        }
        if (cliente) {
            obj.cliente = cliente.value;
        }
        if (producto) {
            obj.producto = producto.value;
        }
        if (buscar.trim() !== '') {
            obj.text = buscar;
        }
        getVentas(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, buscar } = this.state;
        const { getVentas } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        if (buscar.trim() !== '') {
            obj.text = buscar;
        }

        getVentas(obj);
    }

    multipleProds = (prods) => {
        let productos = '';
        prods.map((v, i) => {
            if (i !== 0) {
                productos += ', ' + v.nombre;
            }
            else {
                productos += v.nombre;
            }
        }
        )
        return productos;
    }

    handleTxtChange = (e) => {
        const { value } = e.target.value;
        this.setState({ buscar: value });
    }

    render() {

        const { buscar, activePage, size, tipoVenta, tipoCredito, vendedor, cliente, producto, anticipo } = this.state;
        const { ventas } = this.props;

        return (
            <div className="col-md-12">

                <div className="row">
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={this.handleTipoVentaOptions()} value={tipoVenta} placeholder="Tipo de venta" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleTipoVentaChange} isClearable />
                    </div>
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={this.handleTipoCreditoOptions()} value={tipoCredito} placeholder="Tipo de crédito" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleTipoCreditoChange} isClearable />
                    </div>
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={this.handleVendedoresOptions()} value={vendedor} placeholder="Vendedor" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleVendedoresChange} isClearable />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={this.handleClientesOptions()} value={cliente} placeholder="Cliente" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleClientesChange} isClearable />
                    </div>
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={this.handleProductosOptions()} value={producto} placeholder="Producto" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleProductosChange} isClearable />
                    </div>
                    <div className="col-md-4 col-sm-12 input-margin">
                        <Select options={optAnticipo} value={anticipo} placeholder="Anticipo" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleAnticipoChange} isClearable />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 w-50"
                                type="text"
                                placeholder="Buscar nombre/numeral/código barras"
                                aria-label="Buscar nombre/numeral/código barras"
                                onChange={this.handleTxtChange}
                                value={buscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscarLista}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de ventas</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Cliente</th>
                            <th>Tipo de venta</th>
                            <th>Productos</th>
                            <th>Vendedor</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {
                            ventas.total > 0 ?
                                ventas.rows.map((v, i) =>
                                    <tr key={i}>
                                        <td>{v.cliente.nombre + ' ' + v.cliente.apellidoP + ' ' + v.cliente.apellidoM}</td>
                                        <td>{v.tipo === 2 ? v.tipo.nombre : v.tipo.nombre + ' - ' + v.tipoCredito.nombre}</td>
                                        <td>{v.tipo === 1 ? v.productos[0].nombre : this.multipleProds(v.productos)}</td>
                                        <td>{v.vendedor.nombre + ' ' + v.vendedor.apellidoP + ' ' + v.vendedor.apellidoM}</td>
                                        <td>
                                            <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesVenta/${v.id}`}>Ver más</Link>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr><td colSpan="5">No se encontraron registros para mostrar.</td></tr>
                        }
                        {/* <tr>
                            <td>Cliente prueba </td>
                            <td>Cobrador prueba</td>
                            <td>$250.00</td>
                            <td>$1,000.00</td>
                            <td className="font-bien">Cumplido</td>
                            <td>
                                <Link className="btn btn-primary Ripple-parent btn-mas" to={'/'}>Ver más</Link>
                            </td>
                       </tr>*/}
                    </TableBody>
                </Table>
                <Paginacion total={ventas.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    ventas: state.ventas.ventas,
    usuarios: state.catalogos.usuariosCat,
    ventasCat: state.catalogos.ventas,
    creditos: state.catalogos.creditos,
    productos: state.productos.productos

}), {
        getVentas: fetchGetVentas,
        getUsuarios: fetchGetCatUsuarios,
        getCreditos: fetchGetCatCredito,
        getVentasCat: fetchGetCatVentas,
        getProductos: fetchGetProductos
    })(ListaVentas);