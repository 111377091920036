import React, { Component } from 'react';
import Content from './Components/Shared/Content';
import 'moment/locale/es';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://1ccbdcb87ed4426d9c7d5a2751d336e5@sentry.io/1437898',
  });

class App extends Component {

     componentDidCatch(error, errorInfo) {
          Sentry.withScope(scope => {
              scope.setExtras(errorInfo);
              Sentry.captureException(error);
          });
      }
  
    render() {
        const { children } = this.props;
        return (
            <div className="App">
                <Content body={children} />
            </div>
        );

    }
}
export default App;
