import {
    apiGetIngresos,
    apiGetComisiones,
} from '../Api/apiReportes';

import { handleLoading } from './general';

const initialState = {
    ingresos: {
        rows: [],
        total: 0,
    },
    comisiones: {
        rows: [],
        total: 0,
    },
};

const GET_INGRESOS = "GET_INGRESOS";
const GET_COMISIONES = "GET_COMISIONES";

const getIngresos = ingresos => ({ type: GET_INGRESOS, payload: ingresos });
const getComisiones = comisiones => ({ type: GET_COMISIONES, payload: comisiones });

export const fetchGetIngresos = (obj) => {
    return dispatch => {
        /* dispatch(handleLoading());
         apiGetIngresos(obj)
             .then((ingresos) => {
                 dispatch(getIngresos(ingresos));
                 dispatch(handleLoading());
             })
             .catch((error) => {
                 dispatch(handleLoading());
                 console.log("errorGetReportesIngresos", error);
                 dispatch(getIngresos(initialState.ingresos));
             });*/
    };
}

export const fetchGetComisiones = (obj) => {
    return dispatch => {
        /* dispatch(handleLoading());
         apiGetIngresos(obj)
             .then((comisiones) => {
                 dispatch(getComisiones(comisiones));
                 dispatch(handleLoading());
             })
             .catch((error) => {
                 dispatch(handleLoading());
                 console.log("errorGetReportesComisiones", error);
                 dispatch(getComisiones(initialState.comisiones));
             });*/
    };
}

const reportesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INGRESOS:
            return { ...state, ingresos: action.payload };
        case GET_COMISIONES:
            return { ...state, comisiones: action.payload };
        default:
            return { ...state };
    }
};

export default reportesReducer;