import React from "react";
import { Row, Col, Input, Tooltip, Button } from "mdbreact";
import Productos from './lista-productos';
import ListaClientes from './lista-clientes';
import DetallesVenta from './frm-detalles';
import ResumenVenta from './resumen-venta';

import '../../Css/stepper.css';


class StepperExample extends React.Component {
  state = {
    formActivePanel: 1,
  }

  swapFormActive = (a) => (param) => (e) => {
    const { changeTab } = this.props;
    this.setState({
      'formActivePanel': param,
    });
    changeTab(param);
  }

  handleNextPrevClick = (a) => (param) => (e) => {
    const { changeTab } = this.props;
    this.setState({
      'formActivePanel': param,
    });
    changeTab(param);
  }

  changePanel = () => {
    const { changeTab } = this.props;
    this.setState({
      'formActivePanel': 2,
    });
    changeTab(2);
  }

  render() {
    const { selProducto, selCliente, selDetalles, infoDetalles, infoCliente, infoProducto, guardarVenta, infoFechas } = this.props;
    const { formActivePanel } = this.state;

    if (formActivePanel === 3 && infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 2) {
      this.changePanel();
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="steps-form-2">
            <div className="steps-row-2 setup-panel-2 d-flex justify-content-between">
              <Tooltip placement="top" className="steps-step-2" tag="div" tooltipContent="Clientes" ><Button className={`btn btn-rounded btn-circle-2 waves-effect ml-0 ${formActivePanel === 1 ? "stepper-active" : ''}`} onClick={this.swapFormActive(1)(1)}><i className="far fa-id-card fa-lg" aria-hidden="true"></i></Button></Tooltip>
              <Tooltip placement="top" className="steps-step-2" tag="div" tooltipContent="Productos" ><Button className={`btn btn-rounded btn-circle-2 waves-effect ml-0 ${formActivePanel === 2 ? "stepper-active" : ''}`} onClick={this.swapFormActive(1)(2)}><i className="fas fa-boxes fa-lg" aria-hidden="true"></i></Button></Tooltip>
              {infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 1 && <Tooltip placement="top" className="steps-step-2" tag="div" tooltipContent="Detalles" ><Button className={`btn btn-rounded btn-circle-2 waves-effect ml-0 ${formActivePanel === 3 ? "stepper-active" : ''}`} onClick={this.swapFormActive(1)(3)}><i className="fas fa-money-check-alt fa-lg" aria-hidden="true"></i></Button></Tooltip>}
              <Tooltip placement="top" className="steps-step-2" tag="div" tooltipContent="Finalizar venta" ><Button className={`btn btn-rounded btn-circle-2 waves-effect mr-0 ${formActivePanel === 4 ? "stepper-active" : ''}`} onClick={this.swapFormActive(1)(4)}><i className="fa fa-check fa-lg" aria-hidden="true"></i></Button></Tooltip>
            </div>
          </div>
          <br />
          <div>
            {/*<form onSubmit={this.handleSubmission} autoComplete="off">*/}
            <Row>
              {formActivePanel === 1 &&
                <div className="col-md-12">
                  <ListaClientes clickCliente={selCliente} cliente={infoCliente} />
                  <Button rounded className="float-right btn-stepper" onClick={this.handleNextPrevClick(1)(2)}>Productos <i className="fas fa-arrow-circle-right"></i></Button>
                </div>
              }

              {formActivePanel === 2 &&
                <div className="col-md-12">
                  <Productos clickProducto={selProducto} producto={infoProducto} tipoVenta={infoDetalles.selOptionPago.value} />
                  <Button rounded className="float-left btn-stepper" onClick={this.handleNextPrevClick(1)(1)}><i className="fas fa-arrow-circle-left"></i> Clientes</Button>
                  {infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 1 && <Button rounded className="float-right btn-stepper" onClick={this.handleNextPrevClick(1)(3)}>Detalles <i className="fas fa-arrow-circle-right"></i></Button>}
                  {infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 2 && <Button rounded className="float-right btn-stepper" onClick={this.handleNextPrevClick(1)(4)}>Finalizar <i className="fas fa-arrow-circle-right"></i></Button>}
                </div>
              }

              {formActivePanel === 3 &&
                (<Col md="12">
                  <h3 className="font-weight-bold pl-0 my-4"><strong>Detalles de venta</strong></h3>
                  <DetallesVenta clickDetalles={selDetalles} detalles={infoDetalles} precioProducto={infoProducto.detalles.precioCredito} />
                  <Button rounded className="float-left btn-stepper" onClick={this.handleNextPrevClick(1)(2)}><i className="fas fa-arrow-circle-left"></i> Productos</Button>
                  <Button rounded className="float-right btn-stepper" onClick={this.handleNextPrevClick(1)(4)}>Finalizar <i className="fas fa-arrow-circle-right"></i></Button>
                </Col>)}

              {formActivePanel === 4 &&
                (<Col md="12">
                  <h3 className="font-weight-bold pl-0 my-4"><strong>Resumen de venta</strong></h3>
                  <ResumenVenta cliente={infoCliente} producto={infoProducto} detalles={infoDetalles} fechasPagos={infoFechas} />
                  {infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 1 && <Button rounded className="float-left btn-stepper" onClick={this.handleNextPrevClick(1)(3)}><i className="fas fa-arrow-circle-left"></i> Detalles</Button>}
                  {infoDetalles.selOptionPago && infoDetalles.selOptionPago.value === 2 && <Button rounded className="float-left btn-stepper" onClick={this.handleNextPrevClick(1)(2)}><i className="fas fa-arrow-circle-left"></i> Productos</Button>}
                  <Button color="success" rounded className="float-right" onClick={() => guardarVenta()}><i className="fas fa-save"></i> Guardar</Button>
                </Col>)}
            </Row>
            {/* </form>*/}
          </div>
        </div>
      </div>
    );
  };
}

export default StepperExample;