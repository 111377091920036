import React, { Component } from 'react';
import { Modal, ModalBody, Container } from 'mdbreact';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

class ModalSupervisor extends Component {

    render() {

        const { handleModal, showModalSupervisor, supervisorAsignado, handleSupervisoresOptions, selSupervisor, handleChange, loadSupervisores, agregarSupervisor } = this.props;

        return (
            <Modal isOpen={showModalSupervisor} centered showModal={() => loadSupervisores()}>
                <div className="modal-header header-recibir">
                    <p className="modal-title">Asignar supervisor</p>
                    <button type="button" className="close" aria-label="Close" onClick={() => handleModal()}><span aria-hidden="true">×</span></button>
                </div>
                <ModalBody>
                    <Container fluid >
                        <form autoComplete="off" onSubmit={(e) => agregarSupervisor(e)}>
                            <div className="row">
                                <div className="col-md-12 input-margin">
                                    <p>Seleccionar supervisor:</p>
                                    <Select options={handleSupervisoresOptions()} value={selSupervisor} placeholder="- Seleccione -" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={handleChange} isClearable />
                                </div>
                            </div>
                            <br />
                            <div className="text-center">
                                <button type="submit" className="btn btn-modal btn-verde" >Guardar supervisor </button>
                            </div>
                        </form>
                    </Container>
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalSupervisor;