import { baseUrl, GET, PUT, CreateRequest, makeUrlGET } from './general';

export const apiHandleAuth = async (username, password, method) => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let request = {
        headers,
        method
    }
    request.body = JSON.stringify({
        "username": username,
        "password": password
    });
    let url = baseUrl + 'auth/login/';
    return fetch(url, request).then(res => res.json());
};

export const apiGetMe = async () =>{
    var request = await CreateRequest(GET);
    let url = baseUrl + 'usuarios/me/' ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};

export const apiUpdateMe = async (body) =>{
    var request = await CreateRequest(PUT, body);
    let url = baseUrl + 'usuarios/me/' ;
    return fetch(url, request).then(res => res.json().then(data => ({status: res.status, body:data})));
};