import React, { Component } from 'react';
import { Card, CardBody, Button } from "mdbreact";
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import EditarProducto from './editar';
import InfoProducto from './info';

import { fetchGetSelectedProduct, fetchSeleccionarProducto } from '../../Reducers/productos';
import { fetchEditViewProductos } from '../../Reducers/productos';

import '../../Css/detalles.css';

class DetallesProducto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idProducto: -1
        }
    }

    componentDidMount() {
        const { getProducto } = this.props;
        const { uuid } = this.props.match.params;
        getProducto(uuid);
    }

    componentWillUnmount() {
        const { editar, editarProducto, resetProducto } = this.props;
        if (editar) {
            editarProducto();
        }
        resetProducto(-1);
    }

    render() {
        const { editar, editarProducto, infoProducto } = this.props;
        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        <Card >
                            <CardBody >
                                {
                                    editar
                                        ?
                                        <EditarProducto fnCancelar={() => editarProducto()} vista='Detalles' />
                                        :
                                        <InfoProducto fnEditar={() => editarProducto()} producto={infoProducto} />
                                }
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(state => ({
    editar: state.productos.editViewProducto,
    infoProducto: state.productos.productoSeleccionado
}), {
        getProducto: fetchGetSelectedProduct,
        editarProducto: fetchEditViewProductos,
        resetProducto: fetchSeleccionarProducto
    })(DetallesProducto));
