import React, { Component } from 'react';
import { Modal, ModalBody, Container } from 'mdbreact';

class ModalNotas extends Component {

    render() {

        const { handleModal, showModalNotas, agregarNota, handleChange, nota } = this.props;

        return (
            <Modal isOpen={showModalNotas} centered >
                <div className="modal-header header-recibir">
                    <p className="modal-title">Agregar nota</p>
                    <button type="button" className="close" aria-label="Close" onClick={() => handleModal()}><span aria-hidden="true">×</span></button>
                </div>
                <ModalBody>
                    <Container fluid >
                        <form autoComplete="off" onSubmit={(e) => agregarNota(e)}>
                            <div className="row">
                                <div className="col-md-12"><b>Nota:</b></div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 descripcion">
                                    <textarea className="form-control textarea-modal" rows="3" id="nota" name="nota" value={nota} onChange={handleChange} cols="4" maxLength="255" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                            </div>
                            <br />
                            <div className="text-center">
                                <button type="submit" className="btn btn-modal btn-verde" >Guardar </button>
                            </div>
                        </form>
                    </Container>
                </ModalBody>
            </Modal>
        )
    }
}

export default ModalNotas;