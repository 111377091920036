import React, { Component } from 'react';
import { Input, Button } from "mdbreact";

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import Search from '../SearchBar/Search';

let optionsAlmacenistas = [];

class AltaAlmacen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      almacenista: null,
      direccion: [],
      mapsReady: false
    }
    this.onImageChange = this.onImageChange.bind(this);
    this.clearFileInput = this.clearFileInput.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.handleSelectOptions = this.handleSelectOptions.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.childBusqueda = React.createRef();
  }

  componentDidMount() {
    const isLoaded = document.getElementById("maps-url");
    if (!isLoaded) {
      const script = document.createElement('script');
      script.onload = () => {
        this.setState({ mapsReady: true });
      };
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxEwucH4e-Hs6d2waD9gReIJH9VgBSpGI&libraries=places';
      script.id = 'maps-url';

      document.body.appendChild(script);
    }
    else {
      this.setState({ mapsReady: true });
    }
  }

  handleSelectChange = (almacenista) => {
    this.setState({


      ...this.state, almacenista
    });
  }

  handleSelectOptions() {
    optionsAlmacenistas = [];
    /* this.props.vehiculos.rows.map((vehiculo) =>
       optionsAlmacenistas.push({ value: vehiculo.id, label: vehiculo.nombre })
     );*/
    return optionsAlmacenistas;
  }

  onPriceChange(e) {
    const { name, value } = e.target;
    const filteredInput = value.replace(/[^0-9\.]+/g, '');
    this.setState({ [name]: filteredInput });
  }

  onImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: [e.target.result] })
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  submit(e) {
    e.preventDefault();

  }

  clearFileInput() {
    this.fileInput.value = "";
    this.setState({ image: [] });
  }

  render() {

    const { nombre, almacenista, direccion, mapsReady } = this.state;

    return (

      <div className="col-md-12">
        <form autoComplete="off">
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ color: "#4F4F4F" }}><b>Información de almacén</b></h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Nombre de almacén" />
            </div>
            <div className="col-md-4 col-sm-12">
              <Select className="select-margin" options={this.handleSelectOptions()} value={almacenista} placeholder="Asignar almacenista" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectChange} isClearable />
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-inline md-form mr-auto m-0 form-direccion">
                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} ><i className="fas fa-map-marked-alt"></i></Button>
                {mapsReady && <Search ref={this.childBusqueda} />}

              </div>
            </div>
          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-crear" onClick={this.submit}>Crear almacén</button>
          </div>
        </form>
      </div>
    );
  }
}

export default AltaAlmacen;