import React, { Component } from 'react';
import { Input, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';

import '../../Css/custom-chbx.css';

import { NotificationManager } from 'react-notifications';

import Search from '../SearchBar/Search';

import { connect } from 'react-redux';
import { fetchAddPrecliente, fetchAgregarPreventa } from '../../Reducers/solicitudes';

class AltaPreCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePerfil: [],
            perfilImg: null,
            imageINE1: [],
            INEimg1: null,
            imageINE2: [],
            INEimg2: null,
            imageDom: [],
            Domimg: null,
            nombre: '',
            apellidoP: '',
            apellidoM: '',
            telefono: {
                value: '',
                valid: false,
                touched: false
            },
            domicilio: {
                direccion: '',
                lat: 0,
                lng: 0,
                valid: false
            },
            correo: {
                value: '',
                valid: true,
            },
            usuario: {
                value: '',
                valid: true
            },
            contrasena: {
                value: '',
                valid: true,
            },
            repetirContrasena: '',
            aval1: {
                nombre: '',
                telefono: {
                    value: '',
                    valid: true,
                },
                domicilio: {
                    direccion: '',
                    lat: 0,
                    lng: 0,
                    valid: false
                }
            },
            aval2: {
                nombre: '',
                telefono: {
                    value: '',
                    valid: true,
                },
                domicilio: {
                    direccion: '',
                    lat: 0,
                    lng: 0,
                    valid: true
                },
            },
            trabajo: {
                nombre: '',
                telefono: {
                    value: '',
                    valid: true
                },
                domicilio: {
                    direccion: '',
                    lat: 0,
                    lng: 0,
                    valid: false
                }
            },
            mapsReady: false
        }

    }

    componentDidMount() {
        const isLoaded = document.getElementById("maps-url");
        if (!isLoaded) {
            const script = document.createElement('script');
            script.onload = () => {
                this.setState({ mapsReady: true });
            };
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxEwucH4e-Hs6d2waD9gReIJH9VgBSpGI&libraries=places';
            script.id = 'maps-url';

            document.body.appendChild(script);

        }
        else {
            this.setState({ mapsReady: true });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value.trim() });
    }

    onImageChange = (event) => {
        event.persist();
        const { files, id } = event.target;
        if (files && files[0]) {
            if (id === 'imageDom') {
                this.setState({ Domimg: files[0] });
            }
            if (id === 'imageINE1') {
                this.setState({ INEimg1: files[0] });
            }
            if (id === 'imageINE2') {
                this.setState({ INEimg2: files[0] });
            }
            if (id === 'imagePerfil') {
                this.setState({ perfilImg: files[0] });
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ [id]: [e.target.result] })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    addressChange = (name, info) => {

        this.geocoder = new window.google.maps.Geocoder;
        let lat = 0;
        let lng = 0;
        let domicilio = "";
        if (info.id !== -1) {
            this.geocoder.geocode({ 'placeId': info.id }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    domicilio = info.nombre;

                    switch (name) {
                        case 'busquedaCliente':
                            this.setState({
                                domicilio: {
                                    direccion: domicilio,
                                    lat: lat,
                                    lng: lng,
                                    valid: true
                                }
                            });
                            break;
                        case 'busquedaAval1':
                            this.setState({
                                aval1: {
                                    ...this.state.aval1,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            })
                            break;
                        case 'busquedaAval2':
                            this.setState({
                                aval2: {
                                    ...this.state.aval2,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            })
                            break;
                        case 'dirTrabajo':
                            this.setState({
                                trabajo: {
                                    ...this.state.trabajo,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            });
                            break;
                    }
                }
            });
        }
        else {
            switch (name) {
                case 'busquedaCliente':
                    this.setState({
                        domicilio: {
                            direccion: domicilio,
                            lat: lat,
                            lng: lng,
                            valid: false
                        }
                    });
                    break;
                case 'busquedaAval1':
                    this.setState({
                        aval1: {
                            ...this.state.aval1,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    })
                    break;
                case 'busquedaAval2':
                    this.setState({
                        aval2: {
                            ...this.state.aval2,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    })
                    break;
                case 'dirTrabajo':
                    this.setState({
                        trabajo: {
                            ...this.state.trabajo,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    });
                    break;
            }
        }

    }

    handleChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case 'correo':
                let filteredEmail = value.replace(/[^a-zA-z._0-9@-]+/g, '').toLowerCase().trim();
                this.setState({
                    [id]: {
                        value: filteredEmail,
                        valid: filteredEmail === '' ? true : RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(filteredEmail),
                    }
                });
                break;
            case 'telefono':
                let filteredPhone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    [id]: {
                        value: filteredPhone,
                        valid: RegExp(/^.{10,}$/).test(filteredPhone),
                        touched: true
                    }
                });
                break;
            case 'nombreAval1':
                this.setState({ aval1: { ...this.state.aval1, nombre: value } });
                break;
            case 'telAval1':
                let aval1Phone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    aval1: {
                        ...this.state.aval1,
                        telefono: {
                            value: aval1Phone,
                            valid: RegExp(/^.{10,}$/).test(aval1Phone),
                        }
                    }
                });
                break;
            case 'nombreAval2':
                this.setState({ aval2: { ...this.state.aval2, nombre: value } });
                break;
            case 'telAval2':
                let aval2Phone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    aval2: {
                        ...this.state.aval2,
                        telefono: {
                            value: aval2Phone,
                            valid: RegExp(/^.{10,}$/).test(aval2Phone),
                        }
                    }
                });
                break;
            case 'nombreLaboral':
                this.setState({ trabajo: { ...this.state.trabajo, nombre: value } });
                break;
            case 'telLaboral':
                let laboralPhone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    trabajo: {
                        ...this.state.trabajo,
                        telefono: {
                            value: laboralPhone,
                            valid: RegExp(/^.{10,}$/).test(laboralPhone),
                        }
                    }
                });
                break;
            default:
                this.setState({ [id]: value });
                break;
        }
    }

    submit = (e) => {
        e.preventDefault();
        const { save } = this.props;
        const { INEimg1, INEimg2, Domimg, perfilImg, nombre, apellidoP, apellidoM, telefono, domicilio, correo, usuario, contrasena,
            aval1, aval2, repetirContrasena, trabajo } = this.state;

        if (contrasena.value !== repetirContrasena) {
            NotificationManager.warning('Las contraseñas ingresadas no coinciden', 'Error en contraseña');
        }
        else if (!INEimg1 || !INEimg2 || !Domimg || !perfilImg) {
            NotificationManager.warning('Las imágenes de  INE/IFE, la imagen de perfil y el comprobante de domicilio son obligatorias.', 'Información incompleta');
        }
        else if (!domicilio.valid || !aval1.domicilio.valid || !trabajo.domicilio.valid) {
            NotificationManager.warning('El domicilio del cliente, del la información laboral y del aval 1 es obligatorio.', 'Domicilio vacío');
        }
        else if (!telefono.valid || !correo.valid || !usuario.valid || !contrasena.valid) {
            NotificationManager.warning('La información del formulario contiene errores.', 'Información con errores');
        }
        else {
            const datos = {
                'ineImg': INEimg1,
                'perfilImg': perfilImg,
                'ineBImg': INEimg2,
                'domImg': Domimg,
                'nombre': nombre,
                'apellidoP': apellidoP,
                'apellidoM': apellidoM,
                'direccion': domicilio.direccion,
                'lat': domicilio.lat,
                'lng': domicilio.lng,
                'telefono': telefono.value,
                'referenciaAval1': JSON.stringify({ 'nombre': aval1.nombre, 'telefono': aval1.telefono.value, 'direccion': aval1.domicilio.direccion, 'lat': aval1.domicilio.lat, 'lng': aval1.domicilio.lng }),
                'referenciaAval2': JSON.stringify({ 'nombre': aval2.nombre, 'telefono': aval2.telefono.value, 'direccion': aval2.domicilio.direccion, 'lat': aval2.domicilio.lat, 'lng': aval2.domicilio.lng }),
                'referenciaLaboral': JSON.stringify({ 'nombre': trabajo.nombre, 'telefono': trabajo.telefono.value, 'direccion': trabajo.domicilio.direccion, 'lat': trabajo.domicilio.lat, 'lng': trabajo.domicilio.lng }),
            }

            if (correo.value !== '') {
                datos.email = correo.value;
            }

            save(datos, this.addPreventa);
        }
    }

    addPreventa = () => {
        const { agregarPreventa } = this.props;
        agregarPreventa();
    }

    clearFileInput = (id) => {
        if (id === 1) {
            this.fileInputINE1.value = "";
            this.setState({ imageINE1: [], INEimg1: null });
        }
        else if (id === 2) {
            this.fileInputINE2.value = "";
            this.setState({ imageINE2: [], INEimg2: null });
        }
        else if (id === 3) {
            this.fileInputDom.value = "";
            this.setState({ imageDom: [], Domimg: null });
        }
        else if (id === 4) {
            this.fileInputPerfil.value = "";
            this.setState({ imagePerfil: [], perfilImg: null });
        }
        else {

        }
    }

    render() {

        const { mapsReady, imageINE1, imageINE2, imageDom, imagePerfil, nombre, apellidoP, apellidoM, telefono, correo,
            aval1, aval2, trabajo } = this.state;

        return (

            <div className="col-md-12">
                <form autoComplete="off" onSubmit={(e) => this.submit(e)}>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 ><b>Información de cliente</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Input label="Nombre(s)" value={nombre} type="text" name="nombre" onChange={this.handleChange} id="nombre" required pattern=".*[^ ].*" minLength="2" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Input label="Primer apellido" value={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} id="apellidoP" required pattern=".*[^ ].*" minLength="2" />
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <Input label="Segundo apellido" value={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} id="apellidoM" required pattern=".*[^ ].*" minLength="2" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Input label="Teléfono" value={telefono.value} type="text" name="telefono" onChange={this.handleChange} id="telefono" minLength="10" required pattern=".*[^ ].*" />
                            {!telefono.valid && telefono.touched && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <Input label="Correo electrónico" value={correo.value} type="email" name="correo" onChange={this.handleChange} id="correo" />
                            {!correo.valid && <p className="form-alert">*Formato de correo inválido</p>}
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12 col-sm-12">
                            <div className="form-inline md-form mr-auto m-0 form-direccion">
                                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                {mapsReady && <Search id="txt-dir-cliente" handleChange={this.addressChange} name='busquedaCliente' />}
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-6 col-12 col-sm-12">
                            <div className="row">
                                <div className="col-12 input-margin">
                                    <p className="div-imagen"><b>IFE/INE (lado a):</b></p>
                                    <div className="file-field">
                                        <div className="btn btn-gris">
                                            <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageINE1" name="imageINE1" ref={ref => this.fileInputINE1 = ref} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 input-margin">
                                    {
                                        typeof imageINE1 !== 'undefined' && imageINE1.length > 0 ?
                                            <div className="container-img">
                                                <img id="thumb_ine1" alt="" src={imageINE1} style={{ maxHeight: "130px" }} />
                                                <button className="btn" onClick={() => this.clearFileInput(1)}><i className="fas fa-times fa-lg"></i></button>
                                            </div>
                                            :
                                            <img id="thumb_ine1" alt="" src={imagen_thumbnail} style={{ maxHeight: "130px" }} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 col-sm-12">
                            <div className="row">
                                <div className="col-12 input-margin">
                                    <p className="div-imagen"><b>IFE/INE (lado b):</b></p>
                                    <div className="file-field">
                                        <div className="btn btn-gris">
                                            <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageINE2" name="imageINE2" ref={ref => this.fileInputINE2 = ref} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 input-margin">
                                    {
                                        typeof imageINE2 !== 'undefined' && imageINE2.length > 0 ?
                                            <div className="container-img">
                                                <img id="thumb_ine2" alt="" src={imageINE2} style={{ maxHeight: "130px" }} />
                                                <button className="btn" onClick={() => this.clearFileInput(2)}><i className="fas fa-times fa-lg"></i></button>
                                            </div>
                                            :
                                            <img id="thumb_ine2" alt="" src={imagen_thumbnail} style={{ maxHeight: "130px" }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-6 col-12 col-sm-12">
                            <div className="row">
                                <div className="col-12 input-margin">
                                    <p className="div-imagen"><b>Imagen cliente:</b></p>
                                    <div className="file-field">
                                        <div className="btn btn-gris">
                                            <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imagePerfil" name="imagePerfil" ref={ref => this.fileInputPerfil = ref} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 input-margin">
                                    {
                                        typeof imagePerfil !== 'undefined' && imagePerfil.length > 0 ?
                                            <div className="container-img">
                                                <img id="thumb_perfil" alt="" src={imagePerfil} style={{ maxHeight: "130px" }} />
                                                <button className="btn" onClick={() => this.clearFileInput(4)}><i className="fas fa-times fa-lg"></i></button>
                                            </div>
                                            :
                                            <img id="thumb_perfil" alt="" src={imagen_thumbnail} style={{ maxHeight: "130px" }} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-sm-12">
                            <div className="row">
                                <div className="col-12 input-margin">
                                    <p className="div-imagen"><b>Comprobante de domicilio:</b></p>
                                    <div className="file-field">
                                        <div className="btn btn-gris">
                                            <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageDom" name="imageDom" ref={ref => this.fileInputDom = ref} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 input-margin">
                                    {
                                        typeof imageDom !== 'undefined' && imageDom.length > 0 ?
                                            <div className="container-img">
                                                <img id="thumb_comprobante" alt="" src={imageDom} style={{ maxHeight: "130px" }} />
                                                <button className="btn" onClick={() => this.clearFileInput(3)}><i className="fas fa-times fa-lg"></i></button>
                                            </div>
                                            :
                                            <img id="thumb_comprobante" alt="" src={imagen_thumbnail} style={{ maxHeight: "130px" }} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-12">
                            <h5 ><b>Información laboral</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Input label="Nombre" value={trabajo.lugar} type="text" name="nombreLaboral" onChange={this.handleChange} id="nombreLaboral" required pattern=".*[^ ].*" minLength="2" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Input label="Teléfono" value={trabajo.telefono.value} type="text" name="telLaboral" onChange={this.handleChange} id="telLaboral" minLength="10" required pattern=".*[^ ].*" />
                            {!trabajo.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="form-inline md-form mr-auto m-0 form-direccion">
                                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                {mapsReady && <Search id="txt-dir-laboral" handleChange={this.addressChange} name='dirTrabajo' />}
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-12">
                            <h5 ><b>Información de avales</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Input label="Nombre de aval 1" value={aval1.nombre} type="text" name="nombreAval1" onChange={this.handleChange} id="nombreAval1" required pattern=".*[^ ].*" minLength="2" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Input label="Teléfono" value={aval1.telefono.value} type="text" name="telAval1" onChange={this.handleChange} id="telAval1" minLength="10" required pattern=".*[^ ].*" />
                            {!aval1.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="form-inline md-form mr-auto m-0 form-direccion">
                                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                {mapsReady && <Search id="txt-dir-aval1" handleChange={this.addressChange} name='busquedaAval1' />}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <Input label="Nombre de aval 2" value={aval2.nombre} type="text" name="nombreAval2" onChange={this.handleChange} id="nombreAval2" minLength="2" />
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <Input label="Teléfono" value={aval2.telefono.value} type="text" name="telAval2" onChange={this.handleChange} id="telAval2" minLength="10" />
                            {!aval2.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="form-inline md-form mr-auto m-0 form-direccion">
                                <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                {mapsReady && <Search id="txt-dir-aval2" handleChange={this.addressChange} name='busquedaAval2' />}
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="text-center">
                        <button type="submit" className="btn btn-crear">Crear cliente</button>
                    </div>
                </form>
            </div >
        );
    }
}


export default connect(state => ({}), {
    save: fetchAddPrecliente,
    agregarPreventa: fetchAgregarPreventa
})(AltaPreCliente);