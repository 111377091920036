import React, { Component } from 'react';
import { Card, CardBody, Button, Modal, ModalBody, Container, Alert } from "mdbreact";
import { Link } from 'react-router-dom';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchDeleteUsuario, fetchActivateUsuario } from '../../Reducers/usuarios';
import { fetchShowModalEstatus, fetchChangeStatusCliente, fetchShowModalCobradorCliente, fetchChangeCobradorCliente } from '../../Reducers/clientes';
import { fetchGetCatUsuariosMultiple } from '../../Reducers/catalogos';
import { connect } from 'react-redux';
import { baseUrl } from '../../Api/general';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import jwtDecode from "jwt-decode";
import { NotificationManager } from 'react-notifications';

let optEstatus = [
    { value: 1, label: 'Bueno' },
    { value: 2, label: 'Regular' },
    { value: 3, label: 'Malo' }
];

let optCobrador = [];

class DatosCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            idCliente: -1,
            selEstatusCliente: null,
            selCobradorAsignado: null,
            cobradorSeleccionado: {}
        }
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCliente: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar cliente',
            text: '¿Desea desactivar el cliente seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteCliente,
            showCancel: true
        });
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCliente: -1 });
        hideMessage();
    }

    deleteCliente = () => {
        const { hideMessage, delCliente } = this.props;
        const { idCliente } = this.state;
        hideMessage();
        delCliente(idCliente, 'detalles');
    }

    handleActivarAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCliente: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar cliente',
            text: '¿Desea activar el cliente seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activarCliente,
            showCancel: true
        });
    }

    activarCliente = () => {
        const { hideMessage, activarCliente } = this.props;
        const { idCliente } = this.state;
        hideMessage();
        activarCliente(idCliente, 'detalles');
    }

    handleStatusChange = (selEstatusCliente) => {
        this.setState({
            ...this.state, selEstatusCliente
        });
    }

    cambiarEstatusCliente = (e) => {
        e.preventDefault();
        const { changeStatus, cliente } = this.props;
        const { selEstatusCliente } = this.state;
        let statusValue = '';

        if (cliente && cliente.infoCliente) {
            if (cliente.infoCliente.estado) {
                statusValue = cliente.infoCliente.estado;
            }
        }
        if (selEstatusCliente) {
            if (selEstatusCliente.value !== statusValue) {
                changeStatus(cliente.id, { "estado": parseInt(selEstatusCliente.value) });
            }
            else {
                NotificationManager.warning('El estado no ha sido modificado.', 'Información no modificada');
            }
        }
        else {
            NotificationManager.warning('Se debe seleccionar un estado para poder continuar.', 'Estado no seleccionado');
        }
    }

    handleModal = () => {
        const { cliente, handleModalEstatus } = this.props;
        if (cliente && cliente.infoCliente) {
            if (cliente.infoCliente.estado) {
                let statusValue = cliente.infoCliente.estado;
                let estatusSel = '';
                switch (statusValue) {
                    case 1:
                        estatusSel = 'Bueno';
                        break;
                    case 2:
                        estatusSel = 'Regular';
                        break;
                    case 3:
                        estatusSel = 'Malo';
                        break;
                }
                this.setState({ selEstatusCliente: { value: statusValue, label: estatusSel } });
            }
        }
        handleModalEstatus();
    }

    handleModalCobradorCliente = () => {
        const { cobradorAsignado, handleModalCC, getCobradores, showModalCC } = this.props;
        if (!showModalCC) {
            if (cobradorAsignado) {
                this.setState({ selCobradorAsignado: { value: cobradorAsignado.id, label: cobradorAsignado.nombre + ' ' + cobradorAsignado.apellidoP + ' ' + cobradorAsignado.apellidoM } });
            }
            let obj = {
                tipo: 4,
                activo: 'true'
            };
            getCobradores(obj);
        }
        handleModalCC();
    }

    handleOptsCobrador = () => {
        const { cobradores } = this.props;
        optCobrador = [];
        cobradores.rows.map((user) =>
            optCobrador.push({ value: user.id, label: user.nombre + ' ' + user.apellidoP + ' ' + user.apellidoM })
        );
        return optCobrador;
    }

    handleCobradorChange = (selCobradorAsignado) => {
        const { cobradores } = this.props;
        if (selCobradorAsignado) {
            let cobrador = cobradores.rows.find(cobrador => cobrador.id === selCobradorAsignado.value);
            this.setState({
                ...this.state, selCobradorAsignado,
                cobradorSeleccionado: cobrador
            });
        }
        else {
            this.setState({
                ...this.state, selCobradorAsignado,
                cobradorSeleccionado: {}
            });
        }
    }

    cambiarCobrador = (e) => {
        e.preventDefault();
        const { changeCobrador, cobradorAsignado, cliente } = this.props;
        const { selCobradorAsignado, cobradorSeleccionado } = this.state;

        if (selCobradorAsignado) {
            if (selCobradorAsignado.value === cobradorAsignado.id) {
                NotificationManager.warning('La información no ha sido modificada.', 'Información sin cambios');
            }
            else {
                let cambio = {
                    "CobradorId": selCobradorAsignado.value,
                    "clientes": [cliente.id]
                };
                changeCobrador(cambio, cobradorSeleccionado);
            }
        }
        else {
            NotificationManager.warning('Debe seleccionar un cobrador para continuar.', 'Información incompleta');
        }
    }

    getOptions = () => {
        const { fnEditar, cliente } = this.props;
        const decoded = jwtDecode(localStorage.token);
        if (decoded.tipo !== 3) {
            if (cliente.activo) {
                return (
                    <>
                        <Button className="btn-dinero btn-detalles" onClick={this.handleModal}><i className="fas fa-info-circle"></i>Cambiar estado</Button>
                        <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                        <Button className="btn-eliminar btn-detalles" onClick={() => this.handleDeleteAlert(cliente.id)}><i className="far fa-trash-alt"></i>Desactivar</Button>
                    </>
                )
            }
            else {
                return (
                    <Button className="btn-editar btn-detalles" onClick={() => this.handleActivarAlert(cliente.id)}><i className="fas fa-user-check"></i>Activar</Button>
                )
            }
        }
        else {
            return;
        }
    }

    render() {

        const { cliente, cobradorAsignado, showModal, showModalCC, hasCobrador } = this.props;
        const { selEstatusCliente, selCobradorAsignado } = this.state;
        const decoded = jwtDecode(localStorage.token);

        let urlINE1 = '';
        let urlINE2 = '';
        let urlDom = '';
        let perfil = '';
        let aval1 = { nombre: '', telefono: '', direccion: '' };
        let aval2 = { nombre: '', telefono: '', direccion: '' };
        let laboral = { nombre: '', telefono: '', direccion: '' };
        let cobrador = { nombre: '', telefono: '' };
        let estatus = '';
        let showIncompleteAlert = false;

        if (cliente && cliente.imagenes && cliente.imagenes.length > 0) {
            perfil = baseUrl + cliente.imagenes.find(image => image.tipo.id === 1).url;
            urlINE1 = baseUrl + cliente.imagenes.find(image => image.tipo.id === 2).url;
            urlDom = baseUrl + cliente.imagenes.find(image => image.tipo.id === 3).url;
            urlINE2 = baseUrl + cliente.imagenes.find(image => image.tipo.id === 5).url;
        }
        if (cliente && cliente.infoCliente) {
            if (!cliente.infoCliente.lat || !cliente.infoCliente.lat) {
                showIncompleteAlert = true;
            }
            if (cliente.infoCliente.referenciaAval1) {
                aval1 = JSON.parse(cliente.infoCliente.referenciaAval1);
            }
            if (cliente.infoCliente.referenciaAval2) {
                aval2 = JSON.parse(cliente.infoCliente.referenciaAval2);
            }
            if (cliente.infoCliente.referenciaLaboral) {
                laboral = JSON.parse(cliente.infoCliente.referenciaLaboral);
            }
            if (cliente.infoCliente.estado) {
                switch (cliente.infoCliente.estado) {
                    case 1:
                        estatus = <span className="font-bien">Bueno <i className="far fa-laugh-wink fa-lg"></i></span>
                        break;
                    case 2:
                        estatus = <span className="font-reg">Regular <i className="far fa-meh fa-lg"></i></span>
                        break;
                    case 3:
                        estatus = <span className="font-malo">Malo <i className="far fa-frown fa-lg"></i> </span>
                        break;
                }
            }
        }
        if (Object.entries(cobradorAsignado).length !== 0) {
            cobrador.nombre = cobradorAsignado.nombre + ' ' + cobradorAsignado.apellidoP + ' ' + cobradorAsignado.apellidoM;
            cobrador.telefono = cobradorAsignado.telefono;
        }

        return (

            <div className="col-md-12 col-sm-12">
                <Card className="card-css">
                    <CardBody >
                        <div className="row">
                            <div className="col-md-1 col-sm-2" style={{ paddingTop: "5px" }}>
                                <Link className="btn-back" to={'/Clientes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                            </div>
                            <div className="col-md-5 col-sm-10">
                                <h4 style={{ color: "#4F4F4F" }}>{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</h4>
                            </div>
                            <div className="col-md-6 col-sm-12 text-right-md">
                                {this.getOptions()}
                            </div>
                        </div>
                        <hr className="gral-hr" />
                        <div className="body-generales" >
                            {showIncompleteAlert && <Alert color="warning" className="text-center">Usuario cargado de sistema anterior, por favor complete la información.</Alert>}
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Número de cliente</b></div>
                                {
                                    cliente.infoCliente ?
                                        cliente.infoCliente.numeroCliente ?
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">
                                                <i class="fas fa-circle red-text fa-xs"></i> {cliente.infoCliente.numeroCliente}
                                            </div>
                                            :
                                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">
                                                <i class="fas fa-circle green-text fa-xs"></i> {cliente.id}
                                            </div>
                                        :
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"></div>
                                }
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cliente.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-10 col-sm-12 descripcion input-margin">{cliente.direccion}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado a)</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE1} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado b)</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE2} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Cliente</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={perfil} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Comprobante</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlDom} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Estado</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{estatus}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información laboral</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{laboral.direccion}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de avales</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 1</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval1.direccion}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 2</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval2.direccion}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de usuario web</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cliente.email || ''}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Usuario</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cliente.username || ''}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <h5><b>Información de cobrador</b></h5>
                                </div>
                                <div className="col-md-6 col-sm-6 text-right">
                                    {decoded.tipo !== 3 && <Button className="btn-detalles transferir" onClick={() => this.handleModalCobradorCliente()}><i className="fas fa-motorcycle"></i>Cambiar cobrador</Button>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cobrador.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cobrador.telefono}</div>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Modal isOpen={showModal} className="modal-dinero" centered>
                    <div className="modal-header header-recibir">
                        <p className="modal-title">Estatus cliente</p>
                        <button type="button" className="close" aria-label="Close" onClick={this.handleModal}><span aria-hidden="true">×</span></button>
                    </div>
                    <ModalBody>
                        <Container fluid >
                            <form autoComplete="off" onSubmit={(e) => this.cambiarEstatusCliente(e)}>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 input-margin">
                                        <Select options={optEstatus} value={selEstatusCliente} placeholder="Seleccionar estado" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleStatusChange} isClearable />
                                    </div>
                                </div>
                                <br />
                                <div className="text-center">
                                    <button type="submit" className="btn btn-crear" >Confirmar</button>
                                </div>
                            </form>
                        </Container>
                    </ModalBody>
                </Modal>

                <Modal isOpen={showModalCC} className="modal-dinero" centered>
                    <div className="modal-header header-recibir">
                        <p className="modal-title">Cobrador asignado</p>
                        <button type="button" className="close" aria-label="Close" onClick={() => this.handleModalCobradorCliente()}><span aria-hidden="true">×</span></button>
                    </div>
                    <ModalBody>
                        <Container fluid >
                            <form autoComplete="off" onSubmit={(e) => this.cambiarCobrador(e)}>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 input-margin">
                                        <Select options={this.handleOptsCobrador()} value={selCobradorAsignado} placeholder="Seleccionar cobrador" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleCobradorChange} isClearable />
                                    </div>
                                </div>
                                <br />
                                <div className="text-center">
                                    <button type="submit" className="btn btn-crear" >Confirmar</button>
                                </div>
                            </form>
                        </Container>
                    </ModalBody>
                </Modal>
            </div >
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta,
    cliente: state.usuarios.usuarioSeleccionado,
    cobradorAsignado: state.clientes.cobradorAsignado,
    showModal: state.clientes.showModalEstatus,
    showModalCC: state.clientes.showModalCC,
    cobradores: state.catalogos.cobradoresCat,
    hasCobrador: state.clientes.isCobradorAsignado
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        delCliente: fetchDeleteUsuario,
        activarCliente: fetchActivateUsuario,
        handleModalEstatus: fetchShowModalEstatus,
        changeStatus: fetchChangeStatusCliente,
        changeCobrador: fetchChangeCobradorCliente,
        handleModalCC: fetchShowModalCobradorCliente,
        getCobradores: fetchGetCatUsuariosMultiple
    })(DatosCliente);
