import {
    apiAddVendedor,
    apiUpdateVendedor,
} from '../Api/apiVendedores';

import { addUsuario, updateUsuarioLista, updateUsuarioDetalles } from './usuarios';

import { NotificationManager } from 'react-notifications';

import { handleLoading } from './general';

const initialState = {
    editViewVendedor:false
};

const SHOW_VENDEDOR_FORM = "SHOW_VENDEDOR_FORM";
const verFormEdVendedor = () => ({ type: SHOW_VENDEDOR_FORM });

export const fetchAddVendedor = (body) => {
    return dispatch => {
        dispatch(handleLoading());
        apiAddVendedor(body)
            .then((res) => {
                if (res.status === 201) {
                    dispatch(addUsuario(res.body));
                    NotificationManager.success('Vendedor guardado correctamente', '¡Éxito!');
                    setTimeout(() => {
                        window.location.href = `/DetallesVendedor/${res.body.id}`;
                    }, 3000);                   
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                dispatch(handleLoading());
                console.log("errorAddVendedor", error);
            });
    };
}

export const fetchUpdateVendedor = (id, body, vista) => {
    return dispatch => {
        dispatch(handleLoading());
        apiUpdateVendedor(id, body)
            .then((res) => {
                if (res.status === 200) {
                    if (vista === 'lista') {
                        dispatch(updateUsuarioLista(res.body));
                    }
                    else {
                        dispatch(updateUsuarioDetalles(res.body));
                    }
                    dispatch(verFormEdVendedor());
                    NotificationManager.success('Vendedor guardado correctamente', '¡Éxito!');
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            }).catch((error) => {
                console.log("errorUpdtaeVendedor", error);
                dispatch(handleLoading());
            });
    };
}

export const fetchEditViewVendedor = () => {
    return dispatch => {
        dispatch(verFormEdVendedor());
    }
}

const vendedoresReducer = (state = initialState, action) => {
    switch (action.type) {
       case SHOW_VENDEDOR_FORM:
            return { ...state, editViewVendedor: !state.editViewVendedor };
        default:
            return { ...state };
    }
};

export default vendedoresReducer;