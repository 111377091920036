import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Button, ListGroupItem } from "mdbreact";
import moment from 'moment';
import Notas from './notas';
import MultipleSeleccion from '../Ventas/productos-seleccionados';

class Preventa extends Component {

    calcPendiente = () => {
        const { preVenta } = this.props;
        let pendiente = '';
        let costoCredito = 0;
        let anticipo = 0;
        if (preVenta.tipo.id === 1) {
            costoCredito = parseFloat(preVenta.productos[0].precioCredito);
            anticipo = parseFloat(preVenta.anticipo);
            pendiente = '$' + (costoCredito - anticipo).toLocaleString();
        }
        else {
            pendiente = '$0.00';
        }
        return pendiente;
    }

    calcAbonos = () => {
        const { preVenta } = this.props;
        let abonos = '';
        let pagos = 0;
        let monto = 0;
        let pendiente = 0;

        if (preVenta.tipo.id === 1) {
            pendiente = parseFloat(preVenta.productos[0].precioCredito) - parseFloat(preVenta.anticipo);
            pagos = preVenta.tipoCredito.pagos;
            monto = pendiente / pagos;
            abonos = pagos + ' pagos de $' + monto.toLocaleString();
        }
        return abonos;
    }

    returnFechas = () => {
        const { fechas } = this.props;
        let fechasPagos = '';
        if (fechas.total > 0) {
            fechasPagos = fechas.rows.map((fecha, k) =>
                <ListGroupItem className="list-fechas" key={k}>
                    <p className="mb-1">{moment(fecha.fecha).format('DD/MM/YYYY')}</p>
                </ListGroupItem>
            );
        }
        return fechasPagos;
    }

    render() {

        const { preVenta, usuario, handleAcceptAlert, handleDeleteAlert, notasPreventa, notasPrecliente,
            handleModalNota, handleModalSupervisor, fechas } = this.props;

        return (
            <div>
                <Card className="card-css">
                    <CardBody>
                        <div className="row">
                            <div className="col-md-1 col-sm-2" style={{ paddingTop: "5px" }}>
                                <Link className="btn-back" to={'/Solicitudes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                            </div>
                            {(usuario.tipo.id === 1 || usuario.tipo.id === 2) &&
                                <div className="col-md-11 col-sm-12 text-right-md">
                                    {!preVenta.supervisor && <Button className="btn-detalles supervisor" onClick={() => handleModalSupervisor()}><i className="fas fa-user"></i>Cambiar supervisor</Button>}
                                    <Button className="btn-detalles aceptar" onClick={() => handleAcceptAlert()}><i className="fas fa-check"></i>Aceptar</Button>
                                    <Button className="btn-detalles eliminar" onClick={() => handleDeleteAlert()}><i className="far fa-trash-alt"></i>Eliminar</Button>
                                </div>}
                        </div>
                        <hr className="gral-hr" />
                        <div className="body-generales" >
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Tipo solicitud</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">Nueva venta</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Fecha y hora</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{moment(preVenta.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Supervisor</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.supervisor ? preVenta.supervisor.nombre + ' ' + preVenta.supervisor.apellidoP + ' ' + preVenta.supervisor.apellidoM : ''}</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de cliente</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.cliente.nombre} {preVenta.cliente.apellidoP} {preVenta.cliente.apellidoM}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.cliente.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin">{preVenta.cliente.email}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin">{preVenta.cliente.direccion}</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de producto</b></h5>
                                </div>
                            </div>
                            {preVenta.tipo.id === 1 &&
                                <>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de producto</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].nombre}</div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Código de barras</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].codigoBarras}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Precio de contado</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioContado.toLocaleString()}</div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Código numeral</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.productos[0].numeralEditable}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Precio a crédito</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioCredito.toLocaleString()}</div>
                                    </div>
                                </>
                            }
                            {preVenta.tipo.id === 2 && <MultipleSeleccion vista={3} objEditar={preVenta.productos} />}
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de pago</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Tipo de pago </b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.tipo.nombre}</div>
                                {preVenta.tipo.id === 1 &&
                                    <>
                                        <div className="col-md-3 col-sm-12 input-margin" ><b>Frecuencia</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.tipoCredito.nombre}</div>
                                    </>
                                }
                            </div>
                            {preVenta.tipo.id === 1 &&
                                <>
                                    {/*<div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Días de cobro</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"></div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Hora sugerida de cobro</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md"></div>
                            </div>*/}
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Precio crédito</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.productos[0].precioCredito.toLocaleString()}</div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Anticipo</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">${preVenta.anticipo.toLocaleString()}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Pendiente</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{this.calcPendiente()}</div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Abono</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{this.calcAbonos()}</div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Fecha de cobro</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('DD/MM/YYYY') : ''}</div>
                                        <div className="col-md-3 col-sm-12 input-margin"><b>Hora sugerida de cobro</b></div>
                                        <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{fechas.total > 0 ? moment(fechas.rows[0].fecha).format('HH:mm') : ''}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 input-margin"> <b>Fechas de pagos:</b></div>
                                        <div className="col-md-12 col-sm-12 input-margin">
                                            <ul className="list-inline">
                                                {this.returnFechas()}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de vendedor</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.vendedor.nombre} {preVenta.vendedor.apellidoP} {preVenta.vendedor.apellidoM}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preVenta.vendedor.telefono}</div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <h5><b>Notas</b></h5>
                                </div>
                                {(usuario.tipo.id === 1 || usuario.tipo.id === 2) && <div className="col-md-6 col-sm-6 text-right">
                                    <Button className="btn-detalles transferir" onClick={() => handleModalNota()}><i className="fas fa-plus"></i>Agregar nota</Button>
                                </div>}
                            </div>
                            <br />
                            <div className="row">
                                <Notas notasPrecliente={notasPrecliente} notasPreventa={notasPreventa} />
                            </div>
                            <br />
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default Preventa;