import React, { Component } from 'react';
import { Badge, Fa, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import moment from 'moment';

import { fetchDeleteAlertasSolicitudes, fetchGetAlertasSolicitudes } from '../../Reducers/notificaciones';

/*const solicitudes = {
    total: 6,
    rows: [
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Cliente', 'accion': 'Solicitud de nueva venta.' },
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Nombre Vendedor', 'accion': 'Solicitud de nuevo cliente y nueva venta.' },
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Cliente', 'accion': 'Solicitud de nueva venta.' },
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Nombre Vendedor', 'accion': 'Solicitud de nuevo cliente y nueva venta.' },
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Nombre Vendedor', 'accion': 'Solicitud de nuevo cliente' },
        { 'timestamp': '01/01/2019  00:00', 'nombre': 'Cliente', 'accion': 'Solicitud de nueva venta.' },
    ]
}*/

class DropdownSolicitudes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.startInterval();
    }

    componentWillUnmount() {
        this.stopInterval();
    }

    startInterval() {
        const { getAlertas } = this.props;
        getAlertas();
        try {
            this.interval = setInterval(async () => {
                getAlertas();
            }, 30000);
        } catch (e) {
            console.log(e);
        }
    }

    stopInterval() {
        clearInterval(this.interval);
    }

    tipoAlerta(id) {
        let alerta = ''
        switch (id) {
            case 1: alerta = ' nueva solicitud de cliente'
                break;
            case 2: alerta = 'Nueva nota en solicitud de cliente'
                break;
            case 3: alerta = ' nueva solicitud de preventa'
                break;
            case 4: alerta = 'Nueva nota en solicitud de preventa'
                break;
        }
        return alerta;
    }

    handleDropdownClick = () => {
        const { show } = this.state;
        const { deleteAlertas } = this.props;
        if (show) {
            deleteAlertas();
        }
        this.setState({ show: !show });
    }

    render() {
        const { alertas } = this.props;
        const { show } = this.state;

        return (
            <div className="dropdown dd-info">
                <a aria-haspopup="true" href="#" className="nav-link" onClick={this.handleDropdownClick}>
                    <div className="d-md-inline div-usr">
                        <i className="fa fa-user-plus fa-lg icon-verde"></i>
                        <span className="texto-hide">Solicitudes</span>
                        <span className="badge default badge-default badgeInfo">{alertas.total}</span>
                    </div>
                </a>
                <div tabIndex="-1" role="menu" aria-hidden="true" className={`dropdown-menu ${show ? 'show' : ''}`} style={{ right: "0", left: "auto" }}>
                    <div className="arrow-up"></div>
                    {alertas.total > 0 ?
                        alertas.rows.map((item, index) =>
                            <React.Fragment key={index}>
                                <button type="button" tabIndex="0" className="dropdown-item">
                                    <div className="alerta-fecha">{moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</div>
                                    <div className="alerta-descripcion">{item.tipo !== 4 ? item.isPreCliente || item.preCliente ? item.preCliente.nombre + ' ' + item.preCliente.apellidoP + ' ' + item.preCliente.apellidoM + ':' : item.cliente.nombre + ' ' + item.cliente.apellidoP + ' ' + item.cliente.apellidoM + ':' : ''}  {this.tipoAlerta(item.tipo)} </div>
                                </button>
                                <div tabIndex="-1" className="dropdown-divider" key={index + alertas.total}></div>
                            </React.Fragment>
                        )
                        :
                        <>
                            <button type="button" tabIndex="0" className="dropdown-item" key={1}>
                                <div className="alerta-descripcion">No se encontraron solicitudes nuevas</div>
                            </button>
                            <div tabIndex="-1" className="dropdown-divider" key={2}></div>
                        </>
                    }
                    <a className="dropdown-item ver_todo text-center" href="/Solicitudes">Ver todo</a>
                </div>
            </div>
            /*<Dropdown className="dd-info" >
                <a aria-haspopup="true" href="#" className="nav-link" onClick={this.handleDropdownClick}>
                    <div className="d-none d-md-inline div-usr">
                        <i className="fa fa-user-plus fa-lg icon-verde"></i>
                        Solicitudes
                            <span className="badge default badge-default badgeInfo">{alertas.total}</span>
                    </div>
                </a>
                <DropdownMenu className={show ? 'show' : ''} style={{ right: "0", left: "auto" }}>
                    <div className="arrow-up"></div>
                    {alertas.total > 0 ?
                        alertas.rows.map((item, index) =>
                            [<DropdownItem key={index}>
                                <div className="list-group-item-text">{moment(item.timestamp).format('DD/MM/YYYY HH:mm')}</div>
                                {

                                }
                                <div className="list-group-item-heading"><b>{item.nombre}</b> {this.tipoAlerta(item.tipo)} </div>
                            </DropdownItem>,
                            <DropdownItem divider key={index + alertas.total} />
                            ]
                        )
                        :
                        [
                            <DropdownItem key={1}>
                                <div className="list-group-item-heading"><b>No se encontraron solicitudes nuevas</b></div>
                            </DropdownItem>,
                            <DropdownItem divider key={2} />
                        ]
                    }
                    <Link className="dropdown-item ver_todo text-center" to={{ pathname: '/Solicitudes' }}>Ver todo</Link>
                </DropdownMenu>
            </Dropdown >*/
        );
    }
}

export default connect(state => ({
    alertas: state.notificaciones.solicitudes
}), {
        getAlertas: fetchGetAlertasSolicitudes,
        deleteAlertas: fetchDeleteAlertasSolicitudes
    })(DropdownSolicitudes);