import React, { Component } from 'react';
import General from './general';
import EditarAdministrador from '../Administradores/editar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchGetSelected, fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditView } from '../../Reducers/general';

class DetallesAdministrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idAdministrador: this.props.match.params.uuid
        }
    }

    componentDidMount() {
        const { getAdministrador } = this.props;
        const { uuid } = this.props.match.params;
        getAdministrador(uuid);
    }

    componentWillUnmount() {
        const { editar, editarAdministrador, resetAdministrador } = this.props;
        if (editar) {
            editarAdministrador();
        }
        resetAdministrador(-1);
    }

    render() {
        const { editar, editarAdministrador } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    {editar
                        ?
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <EditarAdministrador fnCancelar={() => editarAdministrador()} vista='Detalles' />
                                </div>
                            </div>
                        </div>

                        :
                        <General fnEditar={() => editarAdministrador()} />
                    }
                </div>
                <br />
            </div>
        )
    }
}

export default withRouter(connect(state => ({
    editar: state.general.editView,
}), {
        getAdministrador: fetchGetSelected,
        editarAdministrador: fetchEditView,
        resetAdministrador: fetchSeleccionarUsuario
    })(DetallesAdministrador));
