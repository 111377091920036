import React, { Component } from 'react';
import { Card, CardBody } from "mdbreact";
import { Link } from 'react-router-dom';
import { baseUrl } from '../../Api/general';

class DatosCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCliente: -1,
        }
    }

    render() {

        const { cliente } = this.props;

        let urlINE1 = '';
        let urlINE2 = '';
        let urlDom = '';
        let perfil = '';
        let aval1 = { nombre: '', telefono: '', direccion: '' };
        let aval2 = { nombre: '', telefono: '', direccion: '' };
        let laboral = { nombre: '', telefono: '', direccion: '' };

        if (cliente && cliente.imagenes && cliente.imagenes.length > 0) {
            perfil = baseUrl + cliente.imagenes.find(image => image.tipo.id === 1).url;
            urlINE1 = baseUrl + cliente.imagenes.find(image => image.tipo.id === 2).url;
            urlDom = baseUrl + cliente.imagenes.find(image => image.tipo.id === 3).url;
            urlINE2 = baseUrl + cliente.imagenes.find(image => image.tipo.id === 5).url;
        }
        if (cliente && cliente.infoCliente) {
            if (cliente.infoCliente.referenciaAval1) {
                aval1 = JSON.parse(cliente.infoCliente.referenciaAval1);
            }
            if (cliente.infoCliente.referenciaAval2) {
                aval2 = JSON.parse(cliente.infoCliente.referenciaAval2);
            }
            if (cliente.infoCliente.referenciaLaboral) {
                laboral = JSON.parse(cliente.infoCliente.referenciaLaboral);
            }
        }

        return (
            <div className="col-md-12 col-sm-12">
                <Card className="card-css">
                    <CardBody >
                        <div className="row">
                            <div className="col-md-1 col-sm-2" style={{ paddingTop: "5px" }}>
                                <Link className="btn-back" to={'/Clientes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                            </div>
                            <div className="col-md-5 col-sm-10">
                                <h4 style={{ color: "#4F4F4F" }}>{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</h4>
                            </div>

                        </div>
                        <hr className="gral-hr" />
                        <div className="body-generales" >
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cliente.email || ''}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{cliente.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin">{cliente.direccion}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado a)</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE1} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado b)</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE2} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Cliente</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={perfil} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Comprobante</b></div>
                                <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlDom} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información laboral</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{laboral.direccion}</div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5><b>Información de avales</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 1</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval1.direccion}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 2</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.nombre}</div>
                                <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                                <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.telefono}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                                <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval2.direccion}</div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default DatosCliente;