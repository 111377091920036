import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaIngresos from './lista';
import { connect } from 'react-redux';

import '../../Css/detalles.css';
import '../../Css/ingresos.css';

class Ingresos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            columna: 'Vendedor'
        }
    }

    handleTabChange = (id, nombre) => {
        this.setState({ tab: id, columna: nombre });
    }

    render() {
        const { tab, columna } = this.state;
        return (
            <Card className="card-css">
                <CardHeader>
                    <Nav header>
                        <NavItem>
                            <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1, 'Vendedor')}>Vendedores</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2, 'Cobrador')}>Cobradores</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 3 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(3, 'Nombre')}>Por corte</NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                            <ListaIngresos nombreColumna={columna} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default connect(state => ({}), {})(Ingresos);