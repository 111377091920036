import React, { Component } from 'react';
import { Card, CardBody, Button} from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { connect } from 'react-redux';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchDeleteUsuario, fetchActivateUsuario } from '../../Reducers/usuarios';
import { Link } from "react-router-dom";

import { baseUrl } from '../../Api/general';

class GeneralesAdministrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idAdministrador: -1,
        }
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idAdministrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar administrador',
            text: '¿Desea desactivar el administrador seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteAdministrador,
            showCancel: true
        });
    }

    handleActivarAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idAdministrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar administrador',
            text: '¿Desea activar el administrador seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activateAdministrador,
            showCancel: true
        });
    }

    activateAdministrador = () => {
        const { hideMessage, activarAdministrador } = this.props;
        const { idAdministrador } = this.state;
        hideMessage();
        activarAdministrador(idAdministrador, 'detalles');
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idAdministrador: -1 });
        hideMessage();
    }

    deleteAdministrador = () => {
        const { hideMessage, delAdministrador } = this.props;
        const { idAdministrador } = this.state;
        hideMessage();
        delAdministrador(idAdministrador, 'detalles');
    }

    renderImage = () => {
        const { administrador } = this.props;
        if (Object.entries(administrador).length === 0 && administrador.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (administrador.imagenes.length > 0) {
                imageUrl = baseUrl + administrador.imagenes[administrador.imagenes.length - 1].url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    render() {

        const { fnEditar, administrador } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-1 col-sm-2 col-2">
                                        <Link className="btn-back" to={'/Administradores'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                                    </div>
                                    <div className="col-md-5 col-sm-10 col-10">
                                        <h4><b>{administrador.nombre} {administrador.apellidoP} {administrador.apellidoM}</b></h4>
                                    </div>
                                    {
                                        administrador.activo
                                            ?
                                            <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                                                <Button className="btn-eliminar btn-detalles" onClick={() => this.handleDeleteAlert(administrador.id)}><i className="far fa-trash-alt"></i>Desactivar</Button>
                                            </div>
                                            :
                                            <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                <Button className="btn-editar btn-detalles" onClick={() => this.handleActivarAlert(administrador.id)}><i className="fas fa-user-check"></i>Activar</Button>
                                            </div>
                                    }
                                </div>
                                <hr className="gral-hr" />
                                <div className="body-generales" >
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{administrador.telefono}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Domicilio</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{administrador.direccion}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Correo electrónico</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{administrador.email}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Usuario</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{administrador.username}</div>
                                    </div>
                                   {/* <div className="row">
                                        <div className="col-md-12 col-12 col-sm-12 col-12 input-margin">
                                            <b>Imagen de perfil</b>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12 input-margin">
                                            {
                                                this.renderImage()
                                            }
                                        </div>
                                        </div>*/}
                                    <br />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    administrador: state.usuarios.usuarioSeleccionado,
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        delAdministrador: fetchDeleteUsuario,
        activarAdministrador: fetchActivateUsuario,
    })(GeneralesAdministrador);