export const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      border: 0,
      height: "unset",
      background: "transparent",
      borderBottom: "1px solid rgba(0, 0, 0, 0.26)",
      "&:hover": {
        boxShadow: "none",
      }
    }),
    menu: () => ({
      backgroundColor: "white",
      boxShadow: "1px 2px 6px #888888",
      position: "absolute",
      left: 0,
      top: `calc(100% + 1px)`,
      width: "100%",
      zIndex: 2,
    }),
    menuList: () => ({
      overflowY: "auto",
      maxHeight: "150px"
    }),
    indicatorSeparator: base => ({ 
      ...base,
      display:"none"
    })
  };