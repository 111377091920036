import {
    apiGetAlertasSolicitudes,
    apiDeleteAlertasSolicitudes,
    apiGetAlertasNotificaciones,
    apiDeleteAlertasNotificaciones,
    apiGetAlertasNotificacionesHistorico
} from '../Api/apiNotificaciones';

import { handleLoading } from './general';
import { NotificationManager } from 'react-notifications';

const initState = {
    solicitudes: {
        total: 0,
        rows: []
    },
    notificaciones: {
        total: 0,
        rows: []
    },
    historico: {
        total: 0,
        rows: []
    }
}

const GET_ALERTAS_SOLICITUDES = 'GET_ALERTAS_SOLICITUDES';
const GET_NOTIFICACIONES = 'GET_NOTIFICACIONES';
const GET_NOTIFICACIONES_HISTORICO = 'GET_NOTIFICACIONES_HISTORICO';

const getAlertasSolicitudes = alertas => ({ type: GET_ALERTAS_SOLICITUDES, payload: alertas });
const getNotificaciones = notificaciones => ({ type: GET_NOTIFICACIONES, payload: notificaciones });
const getNotificacionesHistorico = notificaciones => ({ type: GET_NOTIFICACIONES_HISTORICO, payload: notificaciones });

export const fetchGetAlertasSolicitudes = () => {
    return dispatch => {
        apiGetAlertasSolicitudes()
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getAlertasSolicitudes(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
            })
            .catch((error) => {
                console.log("errorGetAlertaSolicitudes", error);
                dispatch(getAlertasSolicitudes(initState.solicitudes));
            });
    };
}

export const fetchDeleteAlertasSolicitudes = () => {
    return dispatch => {
        apiDeleteAlertasSolicitudes()
            .then((res) => {
                if (res === 204) {
                    dispatch(getAlertasSolicitudes(initState.solicitudes));
                }
            })
            .catch((error) => {
                console.log("errorDeleteAlertaSolicitudes", error);
                dispatch(getAlertasSolicitudes(initState.solicitudes));
            });
    };
}

export const fetchGetNotificaciones = () => {
    return dispatch => {
        apiGetAlertasNotificaciones()
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getNotificaciones(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
            })
            .catch((error) => {
                console.log("errorGetAlertaNotificaciones", error);
                dispatch(getNotificaciones(initState.notificaciones));
            });
    };
}

export const fetchDeleteAlertasNotificaciones = () => {
    return dispatch => {
        apiDeleteAlertasNotificaciones()
            .then((res) => {
                if (res === 204) {
                    dispatch(getNotificaciones(initState.notificaciones));
                }
            })
            .catch((error) => {
                console.log("errorDeleteAlertaNotificaciones", error);
                dispatch(getNotificaciones(initState.notificaciones));
            });
    };
}

export const fetchGetNotificacionesHistorico = (obj) => {
    return dispatch => {
        dispatch(handleLoading());
        apiGetAlertasNotificacionesHistorico(obj)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getNotificacionesHistorico(res.body));
                }
                else {
                    if (res.body.message) {
                        NotificationManager.error(res.body.message, 'Error');
                    }
                    else {
                        let mensaje = '';
                        res.body.map(v => {
                            mensaje += v.message + ', ';
                        });
                        NotificationManager.error(mensaje, 'Error');
                    }
                }
                dispatch(handleLoading());
            })
            .catch((error) => {
                console.log("errorGetAlertaNotificacionesHistorico", error);
                dispatch(getNotificacionesHistorico(initState.historico));
                dispatch(handleLoading());
            });
    };
}

const notificacionesReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_ALERTAS_SOLICITUDES:
            return { ...state, solicitudes: action.payload };
        case GET_NOTIFICACIONES:
            return { ...state, notificaciones: action.payload };
        case GET_NOTIFICACIONES_HISTORICO:
            return { ...state, historico: action.payload };
        default:
            return state;
    }
};

export default notificacionesReducer;