import React from 'react';
import PropTypes from 'prop-types';

const Paginacion = ({ total, activePage, size, paginacionEvent }) => {
    let pages = 1;
    let total_mostrados = activePage * size;

    function generatePagination() {
        pages = Math.ceil(total / size);
        const helper = [];
        for (let i = (activePage - 3); i <= (activePage + 3); i++) {
            if (i > 0 && i <= pages) {
                helper.push(i);
            }
        }
        return helper.map((btn, index) => (
            <li key={index} className={(btn === activePage) ? 'page-item active' : 'page-item'}>
                <button type="button" className="page-link" onClick={() => { paginacionEvent('page', btn); }}>{btn}</button>
            </li>
        ));
    }

    return (

        <div className="pagination-wrapper">
            <p className="description">
                Mostrando registros
              del {(activePage - 1) * size + 1} al {`${total_mostrados <= total ? total_mostrados : total}`} de {`${total} `}
                registros.
            </p>
            <nav>
                <ul className="pagination">
                    <li className="page-item">
                        <button type="button" className="page-link" disabled={activePage === 1}
                            onClick={() => { paginacionEvent('page', 'prev'); }}>
                            Anterior
                  </button>
                    </li>
                    {generatePagination()}
                    <li className="page-item">
                        <button type="button" className="page-link" disabled={activePage === pages}
                            onClick={() => { paginacionEvent('page', 'next'); }}>
                            Siguiente
                  </button>
                    </li>
                </ul>
            </nav>
        </div>

    );
};

Paginacion.propTypes = {    
    total: PropTypes.number,
    activePage: PropTypes.number,
    size: PropTypes.number,    
    paginacionEvent: PropTypes.func,
};

Paginacion.defaultProps = {  
    total: 0,
    activePage: 1,
    size: 10,
    paginacionEvent: () => { },
};

export default Paginacion;
