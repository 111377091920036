import React, { Component } from 'react';
import { Card, CardBody, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { connect } from 'react-redux';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchDeleteUsuario, fetchActivateUsuario } from '../../Reducers/usuarios';
import { Link } from "react-router-dom";

import { baseUrl } from '../../Api/general';

class GeneralesVendedor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idVendedor: -1,
        }
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idVendedor: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar vendedor',
            text: '¿Desea desactivar el vendedor seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteVendedor,
            showCancel: true
        });
    }

    handleActivarAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idVendedor: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar vendedor',
            text: '¿Desea activar el vendedor seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activateVendedor,
            showCancel: true
        });
    }

    activateVendedor = () => {
        const { hideMessage, activarVendedor } = this.props;
        const { idVendedor } = this.state;
        hideMessage();
        activarVendedor(idVendedor, 'detalles');
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idVendedor: -1 });
        hideMessage();
    }

    deleteVendedor = () => {
        const { hideMessage, delVendedor } = this.props;
        const { idVendedor } = this.state;
        hideMessage();
        delVendedor(idVendedor, 'detalles');
    }

    renderImage = () => {
        const { vendedor } = this.props;
        if (Object.entries(vendedor).length === 0 && vendedor.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (vendedor.imagenes.length > 0) {
                imageUrl = baseUrl + vendedor.imagenes[vendedor.imagenes.length - 1].url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    render() {

        const { fnEditar, vendedor } = this.props;

        if (Object.entries(vendedor).length === 0 && vendedor.constructor === Object) {
            return (<div></div>);
        }
        else {
            return (
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <Card >
                                <CardBody >
                                    <div className="row">
                                        <div className="col-md-1 col-sm-2 col-2">
                                            <Link className="btn-back" to={'/Vendedores'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                                        </div>
                                        <div className="col-md-5 col-sm-10 col-10">
                                            <h4><b>{vendedor.nombre} {vendedor.apellidoP} {vendedor.apellidoM}</b></h4>
                                        </div>
                                        {
                                            vendedor.activo
                                                ?
                                                <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                    <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                                                    <Button className="btn-eliminar btn-detalles" onClick={() => this.handleDeleteAlert(vendedor.id)}><i className="far fa-trash-alt"></i>Desactivar</Button>
                                                </div>
                                                :
                                                <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                    <Button className="btn-editar btn-detalles" onClick={() => this.handleActivarAlert(vendedor.id)}><i className="fas fa-user-check"></i>Activar</Button>
                                                </div>
                                        }
                                    </div>
                                    <hr className="gral-hr" />
                                    <div className="body-generales" >
                                        <div className="row">
                                            <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                            <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{vendedor.telefono}</div>
                                            <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Domicilio</b></div>
                                            <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{vendedor.direccion}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Correo electrónico</b></div>
                                            <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{vendedor.email}</div>
                                            <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Usuario</b></div>
                                            <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{vendedor.username}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Comisión</b></div>
                                            <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{vendedor.infoVendedor.comision} %</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12 col-sm-12 col-12 input-margin">
                                                <b>Imagen de perfil</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-12 input-margin">
                                                {
                                                    this.renderImage()
                                                }
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default connect(state => ({
    vendedor: state.usuarios.usuarioSeleccionado,
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        delVendedor: fetchDeleteUsuario,
        activarVendedor: fetchActivateUsuario,
    })(GeneralesVendedor);