
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchDeleteUsuario, fetchActivateUsuario/*, fetchResetUsers*/ } from '../../Reducers/usuarios';
import { fetchGetAcumuladosMonitor } from '../../Reducers/monitor';
import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    idCobrador: -1,
};

class ListaCobradores extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getCobradores } = this.props;

        let obj = {
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getCobradores(obj);
    }

    /*componentWillUnmount() {
        const { resetUsers } = this.props;
        resetUsers();
    }*/


    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar cobrador',
            text: '¿Desea descativar el cobrador seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteCobrador,
            showCancel: true
        });
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }

    deleteCobrador = () => {
        const { hideMessage, delCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        delCobrador(idCobrador, 'monitor');
    }

    handleActivateAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar cobrador',
            text: '¿Desea activar el cobrador seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activarCobrador,
            showCancel: true
        });
    }

    activarCobrador = () => {
        const { hideMessage, activarCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        activarCobrador(idCobrador, 'monitor');
    }


    buscar = () => {
        const { size } = this.state;
        const { getCobradores } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }

        getCobradores(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size } = this.state;
        const { getCobradores } = this.props;
        let pagina;

        let obj = {
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getCobradores(obj);
    }

    render() {

        const { activePage, size } = this.state;
        const { fnEditar, cobradores, txtBuscar } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        {/* <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 w-70"
                                type="text"
                                placeholder="Buscar username/nombre/apellido/teléfono/correo"
                                aria-label="Buscar cobrador"
                                onChange={this.changeBuscar}
                                value={txtBuscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
        </FormInline>*/}
                    </div>
                    <div className="col-md-6 col-sm-12  input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de cobradores</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>E-mail</th>
                            <th>Acumulado</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {cobradores.total > 0 ?
                            cobradores.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.cobrador.nombre} {v.cobrador.apellidoP} {v.cobrador.apellidoM}</td>
                                    <td>{v.cobrador.telefono}</td>
                                    <td>{v.cobrador.email}</td>
                                    <td>${v.total.toFixed(2).toLocaleString()}</td>
                                    <td>
                                        <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCobrador/${v.cobrador.id}`}>Ver más</Link>
                                        {/*<Button className={"btn-opciones"} color="success" size="sm" onClick={() => fnEditar(v.cobrador.id)} disabled={!v.cobrador.activo}><Fa icon="edit" /></Button>
                                        {v.cobrador.activo ?
                                            <Button className="btn-opciones" color="danger" size="sm" onClick={() => this.handleDeleteAlert(v.cobrador.id)}><Fa icon="trash" /></Button>
                                            :
                                            <Button className="btn-opciones" color="success" size="sm" onClick={() => this.handleActivateAlert(v.cobrador.id)}><i className="fas fa-user-check"></i></Button>
                                        }*/}
                                    </td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={cobradores.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    cobradores: state.monitor.acumuladosMonitor
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        getCobradores: fetchGetAcumuladosMonitor,
        delCobrador: fetchDeleteUsuario,
        activarCobrador: fetchActivateUsuario,
        // resetUsers: fetchResetUsers
    })(ListaCobradores);