import React, { Component } from 'react';
import { Card, CardBody, Button, Modal, ModalBody, ModalHeader, Container, Input } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { connect } from 'react-redux';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchDeleteUsuario, fetchActivateUsuario } from '../../Reducers/usuarios';
import { fetchAddIngresosCobrador, fetchShowModalIngresos } from '../../Reducers/cobradores';
import { Link } from "react-router-dom";

import { NotificationManager } from 'react-notifications';

import { baseUrl } from '../../Api/general';

class GeneralesCobrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCobrador: -1,
            recibir: '0.0',
            gastos: '0.0',
            notas: '',
            acumulado: '',
        }
    }

    onPriceChange = (e) => {
        const { name, value } = e.target;
        const filteredInput = value.replace(/[^0-9\.]+/g, '');
        this.setState({ [name]: filteredInput });
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar cobrador',
            text: '¿Desea desactivar el cobrador seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteCobrador,
            showCancel: true
        });
    }

    handleActivarAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar cobrador',
            text: '¿Desea activar el cobrador seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activateCobrador,
            showCancel: true
        });
    }

    activateCobrador = () => {
        const { hideMessage, activarCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        activarCobrador(idCobrador, 'detalles');
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }

    deleteCobrador = () => {
        const { hideMessage, delCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        delCobrador(idCobrador, 'detalles');
    }

    renderImage = () => {
        const { cobrador } = this.props;
        if (Object.entries(cobrador).length === 0 && cobrador.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (cobrador.imagenes.length > 0) {
                imageUrl = baseUrl + cobrador.imagenes[cobrador.imagenes.length - 1].url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleModal = () => {
        const { acumulado, handleModalIngresos, cobrador } = this.props;
        this.setState({
            recibir: acumulado.total > 0 ? acumulado.rows[0].total : '000.00',
            acumulado: acumulado.total > 0 ? acumulado.rows[0].total : 0,
            idCobrador: cobrador.id
        });
        handleModalIngresos();
    }

    agregarIngreso = (e) => {
        e.preventDefault();
        const { agregarIngreso } = this.props;
        const { idCobrador, recibir, gastos, notas, acumulado } = this.state;
        let suma = parseFloat(gastos) + parseFloat(recibir);
        if (suma > parseFloat(acumulado)) {
            NotificationManager.warning('La cantidad a recibir más los gatos es mayor al acumulado.', 'Cantidad errónea');
        }
        else {
            const datos = {
                "cobrador": idCobrador,
                "total": parseFloat(recibir),
                "gasto": parseFloat(gastos)
            }
            if (notas !== '') {
                datos.nota = notas;
            }
            agregarIngreso(datos);
        }
    }

    render() {
        const { recibir, gastos, notas } = this.state;
        const { fnEditar, cobrador, showModal } = this.props;
        let referencia = { nombre: '', telefono: '' };
        let zona = '';
        let imei = '';
        if (cobrador.infoCobrador) {
            referencia = JSON.parse(cobrador.infoCobrador.referencia);
            zona = cobrador.infoCobrador.zona;
            imei = cobrador.infoCobrador.imei;
        }

        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <Card >
                            <CardBody >
                                <div className="row">
                                    <div className="col-md-1 col-sm-2 col-2">
                                        <Link className="btn-back" to={'/Cobradores'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                                    </div>
                                    <div className="col-md-5 col-sm-10 col-10">
                                        <h4><b>{cobrador.nombre} {cobrador.apellidoP} {cobrador.apellidoM}</b></h4>
                                    </div>
                                    {
                                        cobrador.activo
                                            ?
                                            <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                <Button className="btn-dinero btn-detalles" onClick={this.handleModal}><i className="fas fa-dollar-sign"></i>Recibir dinero</Button>
                                                <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                                                <Button className="btn-eliminar btn-detalles" onClick={() => this.handleDeleteAlert(cobrador.id)}><i className="far fa-trash-alt"></i>Desactivar</Button>
                                            </div>
                                            :
                                            <div className="col-md-6 col-sm-12 col-12 text-right-md">
                                                <Button className="btn-editar btn-detalles" onClick={() => this.handleActivarAlert(cobrador.id)}><i className="fas fa-user-check"></i>Activar</Button>
                                            </div>
                                    }
                                </div>
                                <hr className="gral-hr" />
                                <div className="body-generales" >
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin  text-right-md">{cobrador.telefono}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Domicilio</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{cobrador.direccion}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Correo electrónico</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{cobrador.email}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Usuario</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{cobrador.username}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Zona de operación</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{zona}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>IMEI</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion  input-margin text-right-md">{imei}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-12 col-sm-12 col-12 input-margin">
                                            <b>Imagen de perfil</b>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12 input-margin">
                                            {
                                                this.renderImage()
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5><b>Información de referencia</b></h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="mb-2 text-muted">En caso de algún accidente o emergencia contactaremos a la persona en esta referencia</span>
                                        </div>
                                    </div>
                                    <div className="row ">
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Nombre</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion input-margin">{referencia ? referencia.nombre : ''}</div>
                                        <div className="col-md-2 col-sm-12 col-12 input-margin"><b>Teléfono</b></div>
                                        <div className="col-md-4 col-sm-12 col-12 descripcion input-margin">{referencia ? referencia.telefono : ''}</div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>

                <Modal isOpen={showModal} showModal={() => this.inputSearch.focus()} className="modal-dinero" centered>
                    <div className="modal-header header-recibir">
                        <p className="modal-title">Recibir dinero</p>
                        <button type="button" className="close" aria-label="Close" onClick={this.handleModal}><span aria-hidden="true">×</span></button>
                    </div>
                    <ModalBody>
                        <Container fluid >
                            <form autoComplete="off" onSubmit={(e) => this.agregarIngreso(e)}>
                                <div className="row">
                                    <div className="col-md-6">Cantidad a recibir: </div>
                                    <div className="col-md-6">
                                        <i className="fas fa-dollar-sign prefijo-input"></i>
                                        <input placeholder="0000.00" type="text" name="recibir" className="form-control input-modal" ref={(input) => { this.inputSearch = input; }} value={recibir} onChange={this.onPriceChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">Gastos: </div>
                                    <div className="col-md-6">
                                        <i className="fas fa-dollar-sign prefijo-input"></i>
                                        <input placeholder="0000.00" type="text" name="gastos" className="form-control input-modal" value={gastos} onChange={this.onPriceChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">Notas: </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 descripcion">
                                        <textarea className="form-control textarea-modal" rows="3" name="notas" value={notas} onChange={this.handleChange}></textarea>
                                    </div>
                                </div>
                                <hr className="gral-hr" />
                                <div className="text-center">
                                    <button type="submit" className="btn btn-crear" >Confirmar</button>
                                </div>
                            </form>
                        </Container>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta,
    cobrador: state.usuarios.usuarioSeleccionado,
    showModal: state.cobradores.showModalIngreso,
    acumulado: state.cobradores.acumulados
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        delCobrador: fetchDeleteUsuario,
        activarCobrador: fetchActivateUsuario,
        agregarIngreso: fetchAddIngresosCobrador,
        handleModalIngresos: fetchShowModalIngresos,
    })(GeneralesCobrador);