import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
    resultados: {
        count: 10,
        results: []
    },
    selectedOption: null
};

let options = [];

class ListaProductos extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
        this.paginationHandler = this.paginationHandler.bind(this);
        this.mapArray = this.mapArray.bind(this);
        this.handleSelectOptions = this.handleSelectOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            ...this.state, selectedOption
        });
    }

    handleSelectOptions() {
        options = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           options.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return options;
    }


    buscar(texto) {
        const { size } = this.state;
        const { getClientes } = this.props;

        this.setState({ activePage: 1, buscar: texto.trim() });

        let obj = {
            limit: size,
            offset: '0',
            query: texto.trim()
        }
        //getClientes(obj);
    }

    paginationHandler(event, value) {
        const { activePage, size, buscar } = this.state;
        //const { getClientes } = this.props;
        let pagina;

        let obj = {
            query: buscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        // getClientes(obj);
    }


    mapArray(data) {
        let dataTable = data.map((elm) => {
            return {
                nombre: elm.nombre + ' ' + elm.apellido,
                zona: elm.engomado[0].codigo,
                dinero: elm.celular,
                recaudacion: elm.correo,
            }
        });
        return dataTable;
    }


    render() {

        const { activePage, size, resultados, selectedOption } = this.state;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        <FormInline className="md-form m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 w-50"
                                type="text"
                                placeholder="Buscar producto"
                                aria-label="Buscar producto"
                            />
                            <Button className="btn-refresh"><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12  input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de productos</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Producto</th>
                            <th>Precio contado</th>
                            <th>Precio crédito</th>
                            <th>Cantidad</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        <tr>
                            <td>Mueble Fancy</td>
                            <td>$1000.00</td>
                            <td>$1000.00</td>
                            <td> 20 </td>
                        </tr>

                    </TableBody>
                </Table>
                <Paginacion total={resultados.count} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({}), {})(ListaProductos);