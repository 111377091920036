import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiAddSupervisor = async (body) => {
    var request = await CreateRequestImagen(POST, body);
    let url = baseUrl + `usuarios/supervisor/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};

export const apiUpdateSupervisor = async (id, body) => {
    var request = await CreateRequestImagen(PUT, body);
    let url = baseUrl + `usuarios/${id}/supervisor/`;
    return fetch(url, request).then(res => res.json().then(data => ({ status: res.status, body: data })));
};