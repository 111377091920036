import React, { Component } from 'react';
import {
    Card, CardBody, CardHeader, Nav, NavItem, NavLink,
} from "mdbreact";
import ListaAlmacenes from './lista';
import AltaAlmacen from './alta';

class Cobradores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1
        }
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(id) {
        this.setState({ tab: id });
    }

    render() {
        const { tab } = this.state;
        return (
            <Card className="card-css">
                <CardHeader>
                    <Nav header>
                        <NavItem>
                            <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Lista de almacenes</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Nuevo almacén</NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <div className="row">
                            {tab === 1 && <ListaAlmacenes />}
                            {tab === 2 && <AltaAlmacen/>}
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Cobradores;