import React, { Component } from 'react';
import { Card, CardBody, Button, CardHeader, Nav, NavItem, NavLink } from "mdbreact";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Pagos from './pagos';
import Historial from './historial';
import EditarCliente from '../Clientes/editar';
import GeneralRechazado from './cliente-rechazado';
import General from './generales';
import TimelinePagos from './timeline-pagos';
import { fetchGetSelected, fetchSeleccionarUsuario } from '../../Reducers/usuarios';
import { fetchEditViewCliente, fetchGetCobradorAsignado, fetchResetCobradorAsignado } from '../../Reducers/clientes';
import '../../Css/clientes.css';
import jwtDecode from "jwt-decode";

class DetallesCliente extends Component {
    constructor(props) {
        super(props);
        const decoded = jwtDecode(localStorage.token);
        this.state = {
            tab: decoded.tipo !== 3 ? 1 : 2,
            idCliente: -1,
            editarCliente: false,
            timeline: false,
            idCompra: -1
        }
    }

    componentDidMount() {
        const { getCliente, getCobradorAsignado } = this.props;
        const { uuid } = this.props.match.params;
        this.setState({ idCliente: uuid });
        getCliente(uuid);
        getCobradorAsignado(uuid);
    }

    componentWillUnmount() {
        const { editarCliente, editar, resetCliente, resetCobradorAsignado } = this.props;
        if (editar) {
            editarCliente();
        }
        resetCliente(-1);
        resetCobradorAsignado();
    }

    handleTabChange = (id) => {
        this.setState({ tab: id, timeline: false });
    }

    verPagosMueble = (id) => {
        const { timeline } = this.state;
        this.setState({
            timeline: !timeline,
            idCompra: id
        });
    }

    render() {
        const { tab, timeline, idCliente, idCompra } = this.state;
        const { editarCliente, editar, cliente } = this.props;
        const decoded = jwtDecode(localStorage.token);

        if (Object.entries(cliente).length === 0) {
            return (<div></div>);
        }
        else if (cliente.rechazado) {
            return (
                <div className="col-md-12">
                    <div className="row">
                        <GeneralRechazado cliente={cliente} />
                    </div>
                </div>
            );
        }
        else if (cliente.TipoUsuarioId && cliente.TipoUsuarioId !== 6) {
            return (<div></div>);
        }
        else {
            return (
                <div className="col-md-12">
                    <div className="row">
                        {editar
                            ?
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 col-sm-12">
                                        <EditarCliente fnCancelar={() => editarCliente()} vista='Detalles' />
                                    </div>
                                </div>
                            </div>
                            :
                            <General fnEditar={() => editarCliente()} />
                        }
                    </div>

                    <br />

                    <div className="row">
                        <div className="col-md-12">
                            <Card className="card-css">
                                <CardHeader>
                                    <Nav header>
                                        <NavItem>
                                            {decoded.tipo !== 3 && <NavLink className={tab === 1 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(1)}>Pagos</NavLink>}
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={tab === 2 ? 'tabs-css active' : 'tabs-css'} to="#" onClick={() => this.handleTabChange(2)}>Historial de compras</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <div className="col-md-12">
                                        {tab === 1 && idCliente !== -1 && <Pagos idCliente={idCliente} />}
                                        {tab === 2 && !timeline && idCliente !== -1 && <Historial fnVerPagos={this.verPagosMueble} idCliente={idCliente} />}
                                        {tab === 2 && timeline && idCliente !== -1 && <TimelinePagos fnVerPagos={this.verPagosMueble} venta={idCompra} />}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            )

        }
    }
}

export default withRouter(connect(state => ({
    cliente: state.usuarios.usuarioSeleccionado,
    editar: state.clientes.editViewCliente,
}), {
        getCliente: fetchGetSelected,
        editarCliente: fetchEditViewCliente,
        resetCliente: fetchSeleccionarUsuario,
        getCobradorAsignado: fetchGetCobradorAsignado,
        resetCobradorAsignado: fetchResetCobradorAsignado
    })(DetallesCliente));
