import React, { Component } from 'react';
import { Input } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';

import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import { connect } from 'react-redux';
import { fetchAddProducto, fetchGetProductos } from '../../Reducers/productos';
import { fetchGetCatLineas, fetchGetCatSublineas, fetchResetSublineas } from '../../Reducers/catalogos';

import { baseUrl, GET, CreateRequest } from '../../Api/general';

import { NotificationManager } from 'react-notifications';

let optionsLinea = [];
let optionsSublinea = [];

class AltaProducto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: [],
      productImg: null,
      imageValid: true,
      nombre: '',
      barras: '',
      numeral: '',
      numeralEditable: '',
      contado: '',
      credito: '',
      minimo: '',
      foraneo: '',
      descripcion: '',
      optLineaSelected: null,
      optSublineaSelected: null,
    }

    this.handleSublineaChange = this.handleSublineaChange.bind(this);
  }

  componentDidMount() {
    const { getLineas, resetSublinea, productos } = this.props;
    resetSublinea();
    getLineas();
    let numSiguiente = parseInt(productos.total) + 1;
    if (numSiguiente < 100) {
      this.setState({ numeral: ('0' + numSiguiente).slice(-2).toString() })
    }
    else {
      this.setState({ numeral: numSiguiente.toString() })
    }
  }

  handleLineaChange = (optLineaSelected) => {
    const { getSublineas, resetSublinea, linea } = this.props;

    this.setState({
      ...this.state, optLineaSelected
    });

    if (optLineaSelected) {
      let codigoLinea = linea.rows.find(elem => elem.id === optLineaSelected.value);
      this.setState({ barras: codigoLinea.numeral + '-', optSublineaSelected: null })
      let datosSublinea = {
        linea: optLineaSelected.value
      };
      getSublineas(datosSublinea);

    }
    else {
      this.setState({ optSublineaSelected: null, barras: '' });
      resetSublinea();
    }

  }

  handleLineaOptions = () => {
    optionsLinea = [];
    this.props.linea.rows.map((elem) =>
      optionsLinea.push({ value: elem.id, label: elem.numeral + ' - ' + elem.nombre })
    );
    return optionsLinea;
  }

  async handleSublineaChange(optSublineaSelected) {
    const { barras } = this.state;

    this.setState({
      ...this.state, optSublineaSelected
    });
    if (optSublineaSelected) {
      var request = await CreateRequest(GET);
      let url = baseUrl + `productos/siguiente-numeral/${optSublineaSelected.value}/`;
      const res = await fetch(url, request).then(res => res.json());
      this.setState({ barras: res.codigoBarras });
    }
    else {
      let resetBarras = barras.substring(0, 2);
      this.setState({ barras: resetBarras });
    }
  }

  handleSublineaOptions = () => {
    optionsSublinea = [];
    this.props.sublineas.rows.map((elem) =>
      optionsSublinea.push({ value: elem.id, label: elem.numeral + ' - ' + elem.nombre })
    );
    return optionsSublinea;
  }

  onImageChange = (event) => {
    event.persist();
    const { files, id } = event.target;

    if (files && files[0]) {
      if (files[0].size > 5000000) {
        NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
        this.setState({ productImg: files[0], imageValid: false });
      }
      else {
        this.setState({ productImg: files[0], imageValid: true });
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({ image: [e.target.result] })
      };
      reader.readAsDataURL(files[0]);
    }
  }

  handleChange = (event) => {

    const { id, value } = event.target;
    switch (id) {
      case 'contado':
      case 'credito':
      case 'foraneo':
      case 'minimo':
        let filteredPrice = value.replace(/[^0-9.]+/g, '');
        this.setState({ [id]: filteredPrice });
        break;
      case 'barras':
      case 'numeral':
      case 'numeralEditable':
        let filteredId = value.replace(/[^0-9]+/g, '');
        this.setState({ [id]: filteredId });
        break;
      default:
        this.setState({ [id]: value });
        break;
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { save } = this.props;
    const { productImg, imageValid, nombre, barras, foraneo, numeral, numeralEditable, contado, credito, minimo, descripcion, optSublineaSelected } = this.state;

    if (!productImg) {
      NotificationManager.warning('La imagen del producto es obligatoria.', 'Imagen de producto');
    }
    else if (contado.trim() === '' || credito.trim() === '', minimo.trim() === '') {
      NotificationManager.warning('Los precios de contado, crédito y mínimo son obligatorios.', 'Información incompleta');
    }
    else if (numeralEditable.trim() === '') {
      NotificationManager.warning('El código numeral es obligatorio.', 'Información incompleta');
    }
    else if (!optSublineaSelected) {
      NotificationManager.warning('La línea y sublínea son obligatorias.', 'Información incompleta');
    }
    else if (!imageValid) {
      NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
    }
    else {
      const datos = {
        'productoImg': productImg,
        'nombre': nombre,
        'barras': barras,
        'precioContado': contado,
        'precioCredito': credito,
        'precioMinimo': minimo,
        'subLinea': optSublineaSelected.value,
        'numeralEditable': numeralEditable
      }
      if (descripcion.trim() !== '') {
        datos.descripcion = descripcion;
      }
      if (foraneo.trim() !== '') {
        datos.precioForaneo = foraneo;
      }
      save(datos);
    }
  }

  clearFileInput = () => {
    this.fileInput.value = "";
    this.setState({ image: [], productImg: null });
  }

  render() {

    const { image, barras, nombre, foraneo, numeral, numeralEditable, descripcion, contado, credito, minimo, optLineaSelected, optSublineaSelected } = this.state;

    return (

      <div className="col-md-12">
        <form autoComplete="off" onSubmit={(e) => this.submit(e)}>
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ color: "#4F4F4F" }}><b>Información de producto</b></h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Select className="select-margin" options={this.handleLineaOptions()} value={optLineaSelected} placeholder="Línea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleLineaChange} isClearable />
            </div>
            <div className="col-md-4 col-sm-12">
              <Select className="select-margin" options={this.handleSublineaOptions()} value={optSublineaSelected} placeholder="Sublínea" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSublineaChange} isClearable />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Código de barras" id="barras" name="barras" value={barras} onChange={this.handleChange} disabled />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <Input label="Folio" id="numeral" name="numeral" value={numeral} onChange={this.handleChange} disabled />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Código numeral" id="numeralEditable" name="numeralEditable" value={numeralEditable} onChange={this.handleChange} />
            </div>
            <div className="col-md-4 col-sm-12">
              <Input label="Nombre de producto" id="nombre" name="nombre" value={nombre} onChange={this.handleChange} required pattern=".*[^ ].*" minLength="2" maxLength="255" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 inline-margin">
              <div className="inline-input">Precio de contado:</div>
              <div className="inline-input">
                <i className="fas fa-dollar-sign prefijo-input"></i>
                <input placeholder="0000.00" id="contado" name="contado" type="text" className="form-control input-modal" value={contado} onChange={this.handleChange} required pattern=".*[^ ].*" />
              </div>
            </div>
            <div className="col-md-4 col-sm-12 inline-margin">
              <div className="inline-input">Precio a crédito:</div>
              <div className="inline-input">
                <i className="fas fa-dollar-sign prefijo-input"></i>
                <input placeholder="0000.00" type="text" className="form-control input-modal" id="credito" name="credito" value={credito} onChange={this.handleChange} required pattern=".*[^ ].*" />
              </div>
            </div>
            <div className="col-md-4 col-sm-12 inline-margin">
              <div className="inline-input">Precio mínimo:</div>
              <div className="inline-input">
                <i className="fas fa-dollar-sign prefijo-input"></i>
                <input placeholder="0000.00" type="text" className="form-control input-modal" id="minimo" name="minimo" value={minimo} onChange={this.handleChange} required pattern=".*[^ ].*" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 inline-margin">
              <div className="inline-input">Precio foráneo:</div>
              <div className="inline-input">
                <i className="fas fa-dollar-sign prefijo-input"></i>
                <input placeholder="0000.00" id="foraneo" name="foraneo" type="text" className="form-control input-modal" value={foraneo} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12 col-12 col-sm-12 ">
              <div className="row">
                <div className="col-md-12">
                  <p className="div-imagen"><b>Elegir imagen de producto:</b></p>
                  <div className="file-field">
                    <div className="btn btn-gris">
                      <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="group_image" ref={ref => this.fileInput = ref} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {
                    typeof image !== 'undefined' && image.length > 0 ?
                      <div className="container-img">
                        <img alt="" id="thumb_img" src={image} style={{ maxHeight: "130px" }} />
                        <button className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
                      </div>
                      :
                      <img alt="" id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} />
                  }
                </div>
              </div>
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-md-12"><b>Descripción:</b></div>
          </div>
          <div className="row">
            <div className="col-md-12 descripcion">
              <textarea className="form-control textarea-modal" rows="3" id="descripcion" name="descripcion" value={descripcion} onChange={this.handleChange} cols="4" maxLength="255" />
            </div>
          </div>
          <br />
          <hr className="hr-producto" />
          <div className="text-center">
            <button type="submit" className="btn btn-crear" >Crear producto</button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(state => ({
  linea: state.catalogos.lineas,
  sublineas: state.catalogos.sublineas,
  productos: state.productos.productos
}), {
    save: fetchAddProducto,
    getLineas: fetchGetCatLineas,
    getSublineas: fetchGetCatSublineas,
    resetSublinea: fetchResetSublineas,
    getProductos: fetchGetProductos,
  })(AltaProducto);
