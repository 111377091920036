import React, { Component } from 'react';
import { Input, Card, CardBody, CardHeader, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';

import { baseUrl } from '../../Api/general';

import { connect } from 'react-redux';
import { fetchUpdateProducto } from '../../Reducers/productos';

import { NotificationManager } from 'react-notifications';

class EditarProducto extends Component {
  constructor(props) {
    super(props);

    const { producto } = this.props;

    this.state = {
      image: [],
      productImg: null,
      imageValid: true,
      nombre: producto.nombre || '',
      barras: producto.codigoBarras || '',
      numeral: producto.numeral || '',
      numeralEditable: producto.numeralEditable || '',
      contado: producto.precioContado || '',
      credito: producto.precioCredito || '',
      minimo: producto.precioMinimo || '',
      foraneo: producto.precioForaneo || '',
      descripcion: producto.descripcion || '',
      linea: producto.linea.nombre || '',
      sublinea: producto.subLinea.nombre || '',
      loadUrl: true,
    }
  }

  onImageChange = (event) => {
    event.persist();
    const { files } = event.target;


    if (files && files[0]) {
      if (files[0].size > 5000000) {
        NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
        this.setState({ productImg: files[0], imageValid: false });
      }
      else {
        this.setState({ productImg: files[0], imageValid: true });
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          image: [e.target.result],
          loadUrl: false
        })
      };
      reader.readAsDataURL(files[0]);
    }
  }

  handleChange = (event) => {

    const { id, value } = event.target;
    switch (id) {
      case 'contado':
      case 'credito':
      case 'foraneo':
      case 'minimo':
        let filteredPrice = value.replace(/[^0-9.]+/g, '');
        this.setState({ [id]: filteredPrice });
        break;
      case 'numeralEditable':
        let filteredId = value.replace(/[^0-9]+/g, '');
        this.setState({ [id]: filteredId });
        break;
      default:
        this.setState({ [id]: value });
        break;
    }
  }

  submit = (e) => {
    e.preventDefault();
    const { update, producto, vista } = this.props;
    const { productImg, nombre, numeralEditable, contado, credito, minimo, foraneo, descripcion, loadUrl, imageValid } = this.state;

    if (!loadUrl && !productImg) {
      NotificationManager.warning('La imagen del producto es obligatoria.', 'Campos obligatorios');
    }
    else if (contado.toString().trim() === '' || credito.toString().trim() === '', minimo.toString().trim() === '') {
      NotificationManager.warning('Los precios de contado, crédito y mínimo son obligatorios.', 'Información incompleta');
    }
    else if (nombre.trim() === '') {
      NotificationManager.warning('El nombre del producto no puede estar vacío.', 'Información incompleta');
    }
    else if (numeralEditable.trim() === '') {
      NotificationManager.warning('El código numeral es obligatorio.', 'Información incompleta');
    }
    else if (!imageValid) {
      NotificationManager.warning('La imagen debe ser menor a 5MB.', 'Imagen muy grande');
    }
    else {
      const datos = {}
      if (nombre !== producto.nombre) {
        datos.nombre = nombre;
      }
      if ((descripcion !== producto.descripcion && descripcion !== '') || (producto.descripcion === null && descripcion !== '')) {
        datos.descripcion = descripcion;
      }
      if (contado !== producto.precioContado) {
        datos.precioContado = contado;
      }
      if (credito !== producto.precioCredito) {
        datos.precioCredito = credito;
      }
      if (minimo !== producto.precioMinimo) {
        datos.precioMinimo = minimo;
      }
      if (numeralEditable !== producto.numeralEditable) {
        datos.numeralEditable = numeralEditable;
      }
      if (productImg) {
        datos.productoImg = productImg;
      }
      if (foraneo.toString().trim() !== '' && foraneo !== producto.precioForaneo) {
        datos.precioForaneo = foraneo;
      }

      if (Object.entries(datos).length === 0 && datos.constructor === Object) {
        NotificationManager.warning('La información del producto no ha sido modificada.', 'Producto no editado');
      }
      else {
        update(producto.id, datos, vista);
      }
    }
  }

  clearFileInput = () => {
    this.fileInput.value = "";
    this.setState({ image: [], loadUrl: false, productImg: null });
  }

  renderImage = () => {
    const { image, loadUrl } = this.state;
    const { producto } = this.props;
    let imageUrl = null;

    if (Object.entries(producto).length === 0 && producto.constructor === Object) {
      /*console.log('producto sin imagen');*/
    }
    else if (producto.imagenes.length > 0) {
      imageUrl = baseUrl + producto.imagenes[producto.imagenes.length - 1].url;
    }
    else {
     /* console.log('producto sin imagen');*/
    }

    if (typeof image !== 'undefined' && image.length > 0) {
      return (
        <div className="container-img">
          <img id="thumb_img" src={image} style={{ maxHeight: "130px" }} alt='' />
          <button type="button" className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
        </div>
      )
    }
    else if (imageUrl && loadUrl) {
      return (
        <div className="container-img">
          <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
          <button type="button" className="btn" onClick={() => this.clearFileInput()}><i className="fas fa-times fa-lg"></i></button>
        </div>
      )
    }
    else {
      return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
    }
  }

  render() {

    const { barras, nombre, numeral, numeralEditable, descripcion, contado, credito, minimo, foraneo, linea, sublinea } = this.state;
    const { fnCancelar } = this.props;

    return (
      <Card className="card-css">
        <CardHeader>

        </CardHeader>
        <CardBody>
          <div className="col-md-12">
            <form autoComplete="off" onSubmit={(e) => this.submit(e)}>
              <div className="row">
                <div className="col-md-12">
                  <h5 style={{ color: "#4F4F4F" }}><b>Información de producto</b></h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <Input label="Línea" id="linea" name="linea" value={linea} onChange={this.handleChange} disabled />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Input label="Sublínea" id="sublinea" name="sublinea" value={sublinea} onChange={this.handleChange} disabled />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Input label="Código de barras" id="barras" name="barras" value={barras} onChange={this.handleChange} disabled />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <Input label="Folio" id="numeral" name="numeral" value={numeral} onChange={this.handleChange} disabled />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Input label="Código numeral" id="numeralEditable" name="numeralEditable" value={numeralEditable} onChange={this.handleChange} />
                </div>
                <div className="col-md-4 col-sm-12">
                  <Input label="Nombre de producto" id="nombre" name="nombre" value={nombre} onChange={this.handleChange} pattern=".*[^ ].*" minLength="4" maxLength="255" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 inline-margin">
                  <div className="inline-input">Precio de contado:</div>
                  <div className="inline-input">
                    <i className="fas fa-dollar-sign prefijo-input"></i>
                    <input placeholder="0000.00" id="contado" name="contado" type="text" className="form-control input-modal" value={contado} onChange={this.handleChange} pattern=".*[^ ].*" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 inline-margin">
                  <div className="inline-input">Precio a crédito:</div>
                  <div className="inline-input">
                    <i className="fas fa-dollar-sign prefijo-input"></i>
                    <input placeholder="0000.00" type="text" className="form-control input-modal" id="credito" name="credito" value={credito} onChange={this.handleChange} pattern=".*[^ ].*" />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 inline-margin">
                  <div className="inline-input">Precio mínimo:</div>
                  <div className="inline-input">
                    <i className="fas fa-dollar-sign prefijo-input"></i>
                    <input placeholder="0000.00" type="text" className="form-control input-modal" id="minimo" name="minimo" value={minimo} onChange={this.handleChange} pattern=".*[^ ].*" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 inline-margin">
                  <div className="inline-input">Precio foráneo:</div>
                  <div className="inline-input">
                    <i className="fas fa-dollar-sign prefijo-input"></i>
                    <input placeholder="0000.00" id="foraneo" name="foraneo" type="text" className="form-control input-modal" value={foraneo} onChange={this.handleChange} />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12 col-12 col-sm-12 ">
                  <div className="row">
                    <div className="col-md-12">
                      <p className="div-imagen"><b>Elegir imagen de producto:</b></p>
                      <div className="file-field">
                        <div className="btn btn-gris">
                          <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="group_image" ref={ref => this.fileInput = ref} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {
                        this.renderImage()
                      }
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div className="row">
                <div className="col-md-12"><b>Descripción:</b></div>
              </div>
              <div className="row">
                <div className="col-md-12 descripcion">
                  <textarea className="form-control textarea-modal" rows="3" id="descripcion" name="descripcion" value={descripcion} onChange={this.handleChange} maxLength="255" minLength="4" pattern=".*[^ ].*" />
                </div>
              </div>
              <br />
              <hr className="hr-producto" />
              <div className="text-center">
                <button type="submit" className="btn btn-crear">Actualizar </button>
                <button type="button" className="btn btn-danger btn-crear" onClick={(e) => fnCancelar(e)}>Cancelar</button>
              </div>
            </form>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default connect(state => ({
  producto: state.productos.productoSeleccionado
}), {
    update: fetchUpdateProducto,
  })(EditarProducto);
