import React, { Component } from 'react';
import { Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import { Link } from "react-router-dom";

import { baseUrl } from '../../Api/general';

class InfoProducto extends Component {

    renderImage = () => {
        const { producto } = this.props;
        if (Object.entries(producto).length === 0 && producto.constructor === Object) {
            return (<img id="thumb_img" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
        else {
            let imageUrl = imagen_thumbnail;
            if (producto.imagenes.length > 0) {
                imageUrl = baseUrl + producto.imagenes[producto.imagenes.length - 1].url;
            }
            return (
                <img id="thumb_img" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
            )
        }
    }

    render() {
        const { fnEditar, producto } = this.props;
        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-1 col-sm-2">
                        <Link className="btn-back" to={'/Productos'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                    </div>
                    <div className="col-md-5 col-sm-10">
                        <h4><b>{producto.nombre}</b></h4>
                    </div>
                    <div className="col-md-6 col-sm-12 text-right-md">
                        <Button className="btn-editar btn-detalles" onClick={() => fnEditar()}><i className="fas fa-edit"></i>Editar</Button>
                    </div>
                </div>
                <hr className="gral-hr" />
                <div className="body-generales" >
                    <div className="row">
                        <div className="col-md-2 col-sm-12 input-margin"><b>Código de barras</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">{producto.codigoBarras}</div>
                        <div className="col-md-2 col-sm-12 input-margin"><b>Folio</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">{producto.numeral}</div>
                    </div>
                    <div className="row">
                    <div className="col-md-2 col-sm-12 input-margin"><b>Código numeral</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">{producto.numeralEditable}</div>
                        <div className="col-md-2 col-sm-12 input-margin"><b>Precio de contado</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">${producto.precioContado ? producto.precioContado.toLocaleString() : ''}</div>                        
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-12 input-margin"><b>Precio a crédito</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">${producto.precioCredito ? producto.precioCredito.toLocaleString() : ''}</div>
                        <div className="col-md-2 col-sm-12 input-margin"><b>Precio mínimo</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">${producto.precioMinimo ? producto.precioMinimo.toLocaleString() : ''}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-12 input-margin"><b>Precio foráneo</b></div>
                        <div className="col-md-4 col-sm-12 input-margin descripcion text-right-md">${producto.precioForaneo ? producto.precioForaneo.toLocaleString() : ''}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12 col-sm-12 input-margin">
                            <b>Imagen de producto</b>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 input-margin">
                            {
                                this.renderImage()
                            }
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-12">
                            <h5><b>Descripción de producto</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <span className="mb-2 text-justify descripcion">{producto.descripcion}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InfoProducto;
