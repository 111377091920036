import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import jwtDecode from "jwt-decode";
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';

import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchGetUsuarios, fetchDeleteUsuario, fetchActivateUsuario, fetchResetUsers } from '../../Reducers/usuarios';

import '../../Css/clientes.css';

import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    idCliente: -1,
    selCobradorOption: null,
    selEdoOption: null,
};

let optionsCobrador = [];
let optionsEstado = [
    { value: 1, label: 'Rechazado' },
    { value: 2, label: 'Aceptado' },
    { value: 3, label: 'Activo' },
    { value: 4, label: 'Inactivo' },
];

class ListaClientes extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getClientes } = this.props;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getClientes(obj);
    }

    componentWillUnmount() {
        const { resetUsers } = this.props;
        resetUsers();
    }

    handleCobradorChange = (selCobradorOption) => {
        this.setState({
            ...this.state, selCobradorOption
        });
    }

    handleCobradorOptions = () => {
        optionsCobrador = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           options.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return optionsCobrador;
    }

    handleEstadoChange = (selEdoOption) => {
        this.setState({
            ...this.state, selEdoOption
        });
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCliente: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar cliente',
            text: '¿Desea desactivar el cliente seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteCliente,
            showCancel: true
        });
    }

    hideDeleteAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCliente: -1 });
        hideMessage();
    }

    deleteCliente = () => {
        const { hideMessage, delCliente } = this.props;
        const { idCliente } = this.state;
        hideMessage();
        delCliente(idCliente, 'lista');
    }

    handleActivateAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCliente: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar cliente',
            text: '¿Desea activar el cliente seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activarCliente,
            showCancel: true
        });
    }

    activarCliente = () => {
        const { hideMessage, activarCliente } = this.props;
        const { idCliente } = this.state;
        hideMessage();
        activarCliente(idCliente, 'lista');
    }

    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar, selEdoOption } = this.state;
        const { getClientes } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: '0',
            text: txtBuscar
        }
        if (selEdoOption) {
            switch (selEdoOption.value) {
                //rechazado
                case 1:
                    obj.rechazado = 'true';
                    break;
                //aceptado
                case 2:
                    obj.rechazado = 'false';
                    break;
                //activo
                case 3:
                    obj.activo = 'true';
                    break;
                //inactivo
                case 4:
                    obj.activo = 'false';
                    obj.rechazado = 'false';
                    break;
            }
        }
        getClientes(obj);
    }


    paginationHandler = (event, value) => {
        const { activePage, size, txtBuscar } = this.state;
        const { getClientes } = this.props;
        let pagina;

        let obj = {
            tipo: 6,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            text: txtBuscar
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getClientes(obj);
    }

    getStatus = (id, rechazado, activo) => {
        let estatus = '';
        if (!rechazado) {
            if (activo) {
                switch (id) {
                    case 1:
                        estatus = <div className="font-bien">Bueno <i className="far fa-laugh-wink fa-lg"></i></div>
                        break;
                    case 2:
                        estatus = <div className="font-reg">Regular <i className="far fa-meh fa-lg"></i></div>
                        break;
                    case 3:
                        estatus = <div className="font-malo">Malo <i className="far fa-frown fa-lg"></i> </div>
                        break;
                }
            }
            else {
                estatus = <div className="font-eliminado">Inactivo <i className="far fa-times-circle fa-lg"></i></div>
            }
        }
        else {
            estatus = <div className="font-rechazado">Rechazado <i className="far fa-thumbs-down fa-lg"></i></div>
        }

        return estatus;

    }

    getOptions = (cliente) => {
        const { fnEditar } = this.props;
        const decoded = jwtDecode(localStorage.token);
        if (decoded.tipo === 3) {
            return (
                <tr key={cliente.id}>
                    <td></td>
                    <td>{cliente.infoCliente ? cliente.infoCliente.numeroCliente ? cliente.infoCliente.numeroCliente : '' : ''}</td>
                    <td>{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</td>
                    <td>{cliente.telefono}</td>
                    <td>{cliente.direccion}</td>
                    <td>{cliente.infoCliente ? this.getStatus(cliente.infoCliente.estado, cliente.rechazado, cliente.activo) : ''}</td>
                    <td>
                        <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCliente/${cliente.id}`}>Ver más</Link>
                    </td>
                </tr>
            )
        }
        else {
            if (cliente.rechazado) {
                return (
                    <tr key={cliente.id}>
                        <td></td>
                        <td></td>
                        <td>{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</td>
                        <td>{cliente.telefono}</td>
                        <td>{cliente.direccion}</td>
                        <td>{cliente.infoCliente ? this.getStatus(cliente.infoCliente.estado, cliente.rechazado, cliente.activo) : ''}</td>
                        <td>
                            <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCliente/${cliente.id}`}>Ver más</Link>
                            <Button className="btn-opciones" color="success" size="sm" disabled><Fa icon="edit" /></Button>
                            <Button className="btn-opciones" color="success" size="sm" disabled><i className="fas fa-user-check"></i></Button>
                        </td>
                    </tr>
                )
            }
            else {
                return (
                    <tr key={cliente.id}>
                        <td>{cliente.infoCliente ? !cliente.infoCliente.lat || !cliente.infoCliente.lng ? <i className="fas fa-exclamation-triangle fa-lg amber-text" title="La información del cliente está incompleta."></i> : '' : ''}</td>
                        {
                            cliente.infoCliente ?
                                cliente.infoCliente.numeroCliente ?
                                    <td style={{ whiteSpace: "nowrap" }}><i class="fas fa-circle red-text fa-xs"></i> {cliente.infoCliente.numeroCliente}</td>
                                    :
                                    <td style={{ whiteSpace: "nowrap" }}><i class="fas fa-circle green-text fa-xs"></i> {cliente.id}</td>
                                :
                                <td></td>
                        }
                        <td>{cliente.nombre} {cliente.apellidoP} {cliente.apellidoM}</td>
                        <td>{cliente.telefono}</td>
                        <td>{cliente.direccion}</td>
                        <td>{cliente.infoCliente ? this.getStatus(cliente.infoCliente.estado, cliente.rechazado, cliente.activo) : ''}</td>
                        <td>
                            <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCliente/${cliente.id}`}>Ver más</Link>
                            <Button className="btn-opciones" color="success" size="sm" onClick={() => fnEditar(cliente.id)} disabled={!cliente.activo}><Fa icon="edit" /></Button>
                            {cliente.activo
                                ?
                                <Button className="btn-opciones" color="danger" size="sm" onClick={() => this.handleDeleteAlert(cliente.id)}><Fa icon="trash" /></Button>
                                :
                                <Button className="btn-opciones" color="success" size="sm" onClick={() => this.handleActivateAlert(cliente.id)}><i className="fas fa-user-check"></i></Button>
                            }
                        </td>
                    </tr>)
            }
        }
    }

    render() {

        const { activePage, size, selEdoOption, txtBuscar } = this.state;
        const { clientes } = this.props;

        return (

            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 search-cliente"
                                type="text"
                                placeholder="Buscar username/nombre/apellido/teléfono/correo"
                                aria-label="Buscar cliente"
                                onChange={this.changeBuscar}
                                value={txtBuscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de clientes</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    { /*<div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleCobradorOptions()} value={selCobradorOption} placeholder="Filtrar por cobrador" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleCobradorChange} isClearable />
    </div>*/}
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={optionsEstado} value={selEdoOption} placeholder="Filtrar por estado" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleEstadoChange} isClearable />
                    </div>
                </div>
                <br />

                <Table className="tbl-css tbl-clientes" responsive>
                    <TableHead>
                        <tr>
                            <th></th>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Dirección</th>
                            <th>Estatus</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {clientes.total > 0 ?
                            clientes.rows.map(v =>
                                this.getOptions(v)
                            )
                            :
                            <tr><td colSpan="7">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={clientes.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div >
        )
    }
}

export default connect(state => ({
    alerta: state.general.alerta,
    clientes: state.usuarios.usuarios
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        getClientes: fetchGetUsuarios,
        delCliente: fetchDeleteUsuario,
        activarCliente: fetchActivateUsuario,
        resetUsers: fetchResetUsers
    })(ListaClientes);