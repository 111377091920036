
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import { fetchHandleAlert, fetchHideAlert } from '../../Reducers/general';
import { fetchGetUsuarios, fetchDeleteUsuario, fetchActivateUsuario, fetchResetUsers } from '../../Reducers/usuarios';
import { fetchResetTokenCobrador } from '../../Reducers/cobradores';

import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    txtBuscar: '',
    idCobrador: -1,
    selectedOption: null,
    selEdoOption: null,
};

let options = [];

let optionsEstado = [
    { value: 1, label: 'Activo' },
    { value: 2, label: 'Inactivo' },
];

class ListaCobradores extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getCobradores } = this.props;

        let obj = {
            tipo: 4,
            orderBy: 'desc',
            limit: size,
            offset: '0',
        }
        getCobradores(obj);
    }

    componentWillUnmount() {
        const { resetUsers } = this.props;
        resetUsers();
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            ...this.state, selectedOption
        });
    }

    handleSelectOptions = () => {
        options = [];
        /* this.props.vehiculos.rows.map((vehiculo) =>
           options.push({ value: vehiculo.id, label: vehiculo.nombre })
         );*/
        return options;
    }

    handleEstadoChange = (selEdoOption) => {
        this.setState({
            ...this.state, selEdoOption
        });
    }


    handleResetMobileAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Reiniciar acceso',
            text: '¿Desea reiniciar el acceso del cobrador seleccionado?',
            confirmButtonText: 'Reiniciar',
            onConfirm: this.resetMobileToken,
            showCancel: true
        });
    }

    resetMobileToken = () => {
        const { hideMessage, resetToken } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        resetToken(idCobrador);
    }

    handleDeleteAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Desactivar cobrador',
            text: '¿Desea descativar el cobrador seleccionado?',
            confirmButtonText: 'Desactivar',
            onConfirm: this.deleteCobrador,
            showCancel: true
        });
    }

    hideAlert = () => {
        const { hideMessage } = this.props;
        this.setState({ idCobrador: -1 });
        hideMessage();
    }

    deleteCobrador = () => {
        const { hideMessage, delCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        delCobrador(idCobrador, 'lista');
    }

    handleActivateAlert = (id) => {
        const { showMessage } = this.props;
        this.setState({ idCobrador: id });
        showMessage({
            show: true,
            type: 'warning',
            title: 'Activar cobrador',
            text: '¿Desea activar el cobrador seleccionado?',
            confirmButtonText: 'Activar',
            onConfirm: this.activarCobrador,
            showCancel: true
        });
    }

    activarCobrador = () => {
        const { hideMessage, activarCobrador } = this.props;
        const { idCobrador } = this.state;
        hideMessage();
        activarCobrador(idCobrador, 'lista');
    }

    changeBuscar = (e) => {
        const { value } = e.target;
        this.setState({ txtBuscar: value });
    }

    buscar = () => {
        const { size, txtBuscar, selEdoOption } = this.state;
        const { getCobradores } = this.props;

        this.setState({ activePage: 1 });

        let obj = {
            tipo: 4,
            orderBy: 'desc',
            limit: size,
            offset: '0',
            text: txtBuscar
        }

        if (selEdoOption) {
            switch (selEdoOption.value) {
                //activo
                case 1:
                    obj.activo = 'true';
                    break;
                //inactivo
                case 2:
                    obj.activo = 'false';
                    break;
            }
        }
        getCobradores(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, txtBuscar } = this.state;
        const { getCobradores } = this.props;
        let pagina;

        let obj = {
            tipo: 4,
            orderBy: 'desc',
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            text: txtBuscar
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getCobradores(obj);
    }

    render() {

        const { activePage, size, selectedOption, selEdoOption } = this.state;
        const { alerta, fnEditar, cobradores, txtBuscar } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12  input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 w-70"
                                type="text"
                                placeholder="Buscar username/nombre/apellido/teléfono/correo"
                                aria-label="Buscar cobrador"
                                onChange={this.changeBuscar}
                                value={txtBuscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12  input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de cobradores</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={optionsEstado} value={selEdoOption} placeholder="Filtrar por estado" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleEstadoChange} isClearable />
                    </div>
                </div>

                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>E-mail</th>
                            <th>Zona</th>
                            <th>Opciones</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {cobradores.total > 0 ?
                            cobradores.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{v.nombre} {v.apellidoP} {v.apellidoM}</td>
                                    <td>{v.telefono}</td>
                                    <td>{v.email}</td>
                                    <td>{v.infoCobrador ? v.infoCobrador.zona : ''}</td>
                                    <td>
                                        <Link className="btn btn-primary Ripple-parent btn-mas" to={`/DetallesCobrador/${v.id}`}>Ver más</Link>
                                        <Button className={"btn-opciones"} color="success" size="sm" onClick={() => fnEditar(v.id)} disabled={!v.activo}><Fa icon="edit" /></Button>
                                        <Button className="btn-opciones" color="warning" size="sm" onClick={() => this.handleResetMobileAlert(v.id)} disabled={!v.activo}><i className="fas fa-mobile-alt"></i></Button>
                                        {v.activo ?
                                            <Button className="btn-opciones" color="danger" size="sm" onClick={() => this.handleDeleteAlert(v.id)}><Fa icon="trash" /></Button>
                                            :
                                            <Button className="btn-opciones" color="success" size="sm" onClick={() => this.handleActivateAlert(v.id)}><i className="fas fa-user-check"></i></Button>
                                        }
                                    </td>
                                </tr>
                            )
                            :
                            <tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={cobradores.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    //alerta: state.general.alerta,
    cobradores: state.usuarios.usuarios
}), {
        showMessage: fetchHandleAlert,
        hideMessage: fetchHideAlert,
        getCobradores: fetchGetUsuarios,
        delCobrador: fetchDeleteUsuario,
        activarCobrador: fetchActivateUsuario,
        resetToken: fetchResetTokenCobrador,
        resetUsers: fetchResetUsers
    })(ListaCobradores);