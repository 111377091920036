import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../Css/solicitudes.css';
import { Card, CardBody, Button } from "mdbreact";
import { baseUrl } from '../../Api/general';
import moment from 'moment';
import Notas from './notas';

class Precliente extends Component {

    render() {

        const { preCliente, usuario, handleAcceptAlert, handleDeleteAlert, notasPreventa, notasPrecliente, handleModalNota, handleModalSupervisor } = this.props;

        let urlINE1 = '';
        let urlINE2 = '';
        let urlDom = '';
        let perfil = '';
        let aval1 = { nombre: '', telefono: '', direccion: '' };
        let aval2 = { nombre: '', telefono: '', direccion: '' };
        let laboral = { nombre: '', telefono: '', direccion: '' };

        if (preCliente.imagenes && preCliente.imagenes.length > 0) {
            perfil = baseUrl + preCliente.imagenes.find(image => image.tipo.id === 1).url;
            urlINE1 = baseUrl + preCliente.imagenes.find(image => image.tipo.id === 2).url;
            urlDom = baseUrl + preCliente.imagenes.find(image => image.tipo.id === 3).url;
            urlINE2 = baseUrl + preCliente.imagenes.find(image => image.tipo.id === 5).url;
        }

        if (preCliente.referenciaAval1) {
            aval1 = JSON.parse(preCliente.referenciaAval1);
        }
        if (preCliente.referenciaAval2) {
            aval2 = JSON.parse(preCliente.referenciaAval2);
        }
        if (preCliente.referenciaLaboral) {
            laboral = JSON.parse(preCliente.referenciaLaboral);
        }

        return (
            <Card className="card-css">
                <CardBody>
                    <div className="row">
                        <div className="col-md-1 col-sm-2" style={{ paddingTop: "5px" }}>
                            <Link className="btn-back" to={'/Solicitudes'}><i className="fas fa-arrow-left fa-2x"></i></Link>
                        </div>
                        {(usuario.tipo.id === 1 || usuario.tipo.id === 2) &&
                            <div className="col-md-11 col-sm-12 text-right-md">
                                {!preCliente.supervisor && <Button className="btn-detalles supervisor" onClick={() => handleModalSupervisor()}><i className="fas fa-user"></i>Asignar supervisor</Button>}
                                <Button className="btn-detalles aceptar" onClick={() => handleAcceptAlert()} ><i className="fas fa-check"></i>Aceptar</Button>
                                <Button className="btn-detalles eliminar" onClick={() => handleDeleteAlert()} ><i className="far fa-trash-alt"></i>Eliminar</Button>
                            </div>}
                    </div>

                    <hr className="gral-hr" />
                    <div className="body-generales" >
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Tipo solicitud</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">Nuevo cliente</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Fecha y hora</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{moment(preCliente.createdAt).format('DD/MM/YYYY HH:mm')}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Supervisor</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preCliente.supervisor ? preCliente.supervisor.nombre + ' ' + preCliente.supervisor.apellidoP + ' ' + preCliente.supervisor.apellidoM : ''}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de cliente</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preCliente.nombre} {preCliente.apellidoP} {preCliente.apellidoM}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{preCliente.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Correo electrónico</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin">{preCliente.email}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin">{preCliente.direccion}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado a)</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE1} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>IFE / INE (lado b)</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlINE2} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Cliente</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={perfil} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Comprobante</b></div>
                            <div className="col-md-3 col-sm-12 input-margin text-right-md"><a href={urlDom} target="_blank" className="link-archivo">Archivo.jpg</a></div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información laboral</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{laboral.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{laboral.direccion}</div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <h5><b>Información de avales</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 1</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval1.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval1.direccion}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Nombre de aval 2</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.nombre}</div>
                            <div className="col-md-3 col-sm-12 input-margin"><b>Teléfono</b></div>
                            <div className="col-md-3 col-sm-12 descripcion input-margin text-right-md">{aval2.telefono}</div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12 input-margin"><b>Dirección</b></div>
                            <div className="col-md-9 col-sm-12 descripcion input-margin text-left-md">{aval2.direccion}</div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <h5><b>Notas</b></h5>
                            </div>
                            {(usuario.tipo.id === 1 || usuario.tipo.id === 2) && <div className="col-md-6 col-sm-6 text-right">
                                <Button className="btn-detalles transferir" onClick={() => handleModalNota()}><i className="fas fa-plus"></i>Agregar nota</Button>
                            </div>}
                        </div>
                        <br />
                        <div className="row">
                            <Notas notasPrecliente={notasPrecliente} notasPreventa={notasPreventa} />
                        </div>
                        <br />
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Precliente;