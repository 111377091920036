import { baseUrl, GET, POST, PUT, DELETE, CreateRequest, CreateRequestImagen, makeUrlGET } from './general';

export const apiGetVentas = async (objs) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + 'ventas/?' + makeUrlGET(objs);
    return fetch(url, request).then(res => res.json());
};

export const apiGetVentaSeleccionada = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `ventas/${id}/`;
    return fetch(url, request).then(res => res.json());
};

export const apiGetNotasVenta = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `ventas/${id}/notas/`;
    return fetch(url, request).then(res => res.json());
};

export const apiGetFechasVenta = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `ventas/${id}/fechas/`;
    return fetch(url, request).then(res => res.json());
};

export const apiGetAbonosVenta = async (id) => {
    var request = await CreateRequest(GET);
    let url = baseUrl + `ventas/${id}/abonos/`;
    return fetch(url, request).then(res => res.json());
};
