
import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, FormInline, Button } from 'mdbreact';
import { connect } from 'react-redux';
import { fetchGetVentas } from '../../Reducers/ventas';
import moment from 'moment';
import Paginacion from '../Shared/pagination';

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    buscar: '',
};

class HistorialCompras extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size } = this.state;
        const { getVentas, idCliente } = this.props;

        let obj = {
            limit: size,
            offset: '0',
            cliente: idCliente
        }
        getVentas(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, buscar } = this.state;
        const { getVentas, idCliente } = this.props;
        let pagina;

        let obj = {
            limit: size,
            offset: ((activePage - 1) * size).toString(),
            cliente: idCliente,
        }
        if (buscar.trim() !== '') {
            obj.text = buscar;
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        getVentas(obj);
    }

    buscarLista = () => {
        const { size, buscar } = this.state;
        const { getVentas } = this.props;

        this.setState({ activePage: 1 });
        let obj = {
            limit: size,
            offset: '0',
            orderBy: 'desc',
        }
        if (buscar.trim() !== '') {
            obj.text = buscar;
        }
        getVentas(obj);
    }

    handleTxtChange = (e) => {
        const { value } = e.target.value;
        this.setState({ buscar: value });
    }

    multipleProds = (prods) => {
        let productos = '';
        prods.map((v, i) => {
            if (i !== 0) {
                productos += ', ' + v.nombre;
            }
            else {
                productos += v.nombre;
            }
        }
        )
        return productos;
    }

    render() {

        const { buscar, activePage, size } = this.state;
        const { fnVerPagos, ventas } = this.props;

        return (
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 col-sm-12 input-margin">
                        <FormInline className="md-form mr-auto m-0">
                            <Fa icon="search" />
                            <input
                                className="form-control form-control-sm ml-3 w-50"
                                type="text"
                                placeholder="Buscar producto"
                                aria-label="Buscar producto"
                                onChange={this.handleTxtChange}
                                value={buscar}
                            />
                            <Button className="btn-refresh" onClick={this.buscarLista}><Fa icon="redo" />  Actualizar</Button>
                        </FormInline>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de productos</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Fecha</th>
                            <th>Productos</th>
                            <th>Tipo venta</th>
                            <th>Vendedor</th>
                            {/*<th>Opciones</th>*/}
                        </tr>
                    </TableHead>
                    <TableBody>
                        {ventas.total > 0 ?
                            ventas.rows.map((v, i) =>
                                <tr key={i}>
                                    <td>{moment(v.createdAt).format('DD/MM/YYYY  HH:mm')}</td>
                                    <td>{v.tipo === 1 ? v.productos[0].nombre : this.multipleProds(v.productos)}</td>
                                    <td>{v.tipo === 2 ? v.tipo.nombre : v.tipo.nombre + ' - ' + v.tipoCredito.nombre}</td>
                                    <td>{v.vendedor.nombre + ' ' + v.vendedor.apellidoP + ' ' + v.vendedor.apellidoM}</td>
                                    <td>
                                        <Button className="btn-primary btn-mas" size="sm" onClick={() => fnVerPagos(v.id)}>Ver más</Button>
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan="4">No se encontraron registros para mostrar</td>
                            </tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={ventas.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    ventas: state.ventas.ventas
}), {
        getVentas: fetchGetVentas
    })(HistorialCompras);