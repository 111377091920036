import React, { Component } from 'react';
import { Input, Card, CardBody, CardHeader, Button } from "mdbreact";
import imagen_thumbnail from '../../Img/placeholder-square.jpg';
import '../../Css/custom-chbx.css';
import { baseUrl } from '../../Api/general';
import Search from '../SearchBar/Search';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchUpdateCliente } from '../../Reducers/clientes';

class EditarCliente extends Component {
    constructor(props) {
        super(props);
        const { cliente } = this.props;

        let referenciaAval1 = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };
        let referenciaAval2 = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };
        let referenciaLaboral = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };
        let lat = 0;
        let lng = 0;

        if (cliente && cliente.infoCliente) {
            if (cliente.infoCliente.referenciaAval1) {
                referenciaAval1 = JSON.parse(cliente.infoCliente.referenciaAval1);
            }
            if (cliente.infoCliente.referenciaAval2) {
                referenciaAval2 = JSON.parse(cliente.infoCliente.referenciaAval2);
            }
            if (cliente.infoCliente.referenciaLaboral) {
                referenciaLaboral = JSON.parse(cliente.infoCliente.referenciaLaboral);
            }
            if (cliente.infoCliente.lat) {
                lat = cliente.infoCliente.lat;
                console.log('lat:' + lat);
            }
            if (cliente.infoCliente.lng) {
                lng = cliente.infoCliente.lng;
                console.log('lng:' + lng);
            }
        }

        this.state = {
            imageINE1: [],
            INEimg1: null,
            imageINE2: [],
            INEimg2: null,
            imageDom: [],
            Domimg: null,
            imagePerfil: [],
            PerfilImg: null,
            nombre: cliente.nombre || '',
            apellidoP: cliente.apellidoP || '',
            apellidoM: cliente.apellidoM || '',
            telefono: {
                value: cliente.telefono || '',
                valid: true,
            },
            domicilio: {
                direccion: cliente.direccion || '',
                lat: lat,
                lng: lng,
                valid: true
            },
            correo: {
                value: cliente.email || '',
                valid: true,
            },
            usuario: {
                value: cliente.username || '',
                valid: true
            },
            contrasena: {
                value: '',
                valid: true,
            },
            repetirContrasena: '',
            aval1: {
                nombre: referenciaAval1.nombre,
                telefono: {
                    value: referenciaAval1.telefono,
                    valid: true,
                },
                domicilio: {
                    direccion: referenciaAval1.direccion,
                    lat: referenciaAval1.lat,
                    lng: referenciaAval1.lng,
                    valid: true
                }
            },
            aval2: {
                nombre: referenciaAval2.nombre,
                telefono: {
                    value: referenciaAval2.telefono,
                    valid: true,
                },
                domicilio: {
                    direccion: referenciaAval2.direccion,
                    lat: referenciaAval2.lat,
                    lng: referenciaAval2.lng,
                    valid: true
                },
            },
            trabajo: {
                nombre: referenciaLaboral.nombre,
                telefono: {
                    value: referenciaLaboral.telefono,
                    valid: true
                },
                domicilio: {
                    direccion: referenciaLaboral.direccion,
                    lat: referenciaLaboral.lat,
                    lng: referenciaLaboral.lng,
                    valid: true
                }
            },
            mapsReady: false,
            asignar: cliente.username ? true : false,
            loadUrlINE1: true,
            loadUrlINE2: true,
            loadUrlDom: true,
            loadUrlPerfil: true,
        }
    }

    componentDidMount() {
        const isLoaded = document.getElementById("maps-url");
        if (!isLoaded) {
            const script = document.createElement('script');
            script.onload = () => {
                this.setState({ mapsReady: true });
            };
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCxEwucH4e-Hs6d2waD9gReIJH9VgBSpGI&libraries=places';
            script.id = 'maps-url';

            document.body.appendChild(script);

        }
        else {
            this.setState({ mapsReady: true });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.id]: event.target.value.trim() });
    }

    onImageChange = (event) => {
        event.persist();
        const { files, id } = event.target;
        if (files && files[0]) {
            if (id === 'imageDom') {
                this.setState({ Domimg: files[0] });
            }
            if (id === 'imageINE1') {
                this.setState({ INEimg1: files[0] });
            }
            if (id === 'imageINE2') {
                this.setState({ INEimg2: files[0] });
            }
            if (id === 'imagePerfil') {
                this.setState({ PerfilImg: files[0] });
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ [id]: [e.target.result] })
            };
            reader.readAsDataURL(files[0]);
        }
    }

    addressChange = (name, info) => {

        this.geocoder = new window.google.maps.Geocoder;
        let lat = 0;
        let lng = 0;
        let domicilio = "";
        if (info.id !== -1) {
            this.geocoder.geocode({ 'placeId': info.id }, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    domicilio = info.nombre;

                    switch (name) {
                        case 'busquedaCliente':
                            this.setState({
                                domicilio: {
                                    direccion: domicilio,
                                    lat: lat,
                                    lng: lng,
                                    valid: true
                                }
                            });
                            break;
                        case 'busquedaAval1':
                            this.setState({
                                aval1: {
                                    ...this.state.aval1,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            })
                            break;
                        case 'busquedaAval2':
                            this.setState({
                                aval2: {
                                    ...this.state.aval2,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            })
                            break;
                        case 'dirTrabajo':
                            this.setState({
                                trabajo: {
                                    ...this.state.trabajo,
                                    domicilio: {
                                        direccion: domicilio,
                                        lat: lat,
                                        lng: lng,
                                        valid: true
                                    }
                                }
                            });
                            break;
                    }
                }
            });
        }
        else {
            switch (name) {
                case 'busquedaCliente':
                    this.setState({
                        domicilio: {
                            direccion: domicilio,
                            lat: lat,
                            lng: lng,
                            valid: false
                        }
                    });
                    break;
                case 'busquedaAval1':
                    this.setState({
                        aval1: {
                            ...this.state.aval1,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    })
                    break;
                case 'busquedaAval2':
                    this.setState({
                        aval2: {
                            ...this.state.aval2,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    })
                    break;
                case 'dirTrabajo':
                    this.setState({
                        trabajo: {
                            ...this.state.trabajo,
                            domicilio: {
                                direccion: domicilio,
                                lat: lat,
                                lng: lng,
                                valid: false
                            }
                        }
                    });
                    break;
            }
        }

    }

    handleChange = (event) => {
        const { asignar } = this.state;
        const { id, value } = event.target;
        switch (id) {
            case 'usuario':
                let filteredUsername = value.replace(/[^a-zA-z._0-9]+/g, '');
                this.setState({
                    [id]: {
                        value: filteredUsername.toLowerCase().trim(),
                        valid: filteredUsername === '' && !asignar ? true : RegExp(/^(?=.{4,}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+[a-zA-Z0-9]+$/).test(filteredUsername),
                    }
                });
                break;
            case 'correo':
                let filteredEmail = value.replace(/[^a-zA-z._0-9@-]+/g, '').toLowerCase().trim();
                this.setState({
                    [id]: {
                        value: filteredEmail,
                        valid: filteredEmail === '' && !asignar ? true : RegExp(/^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}(.[a-z]{2,3})?$/).test(filteredEmail),
                    }
                });
                break;
            case 'contrasena':
                this.setState({
                    [id]: {
                        value: value,
                        valid: value === '' && !asignar ? true : RegExp(/^.{6,}$/).test(value),
                    }
                });
                break;
            case 'telefono':
                let filteredPhone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    [id]: {
                        value: filteredPhone,
                        valid: RegExp(/^.{10,}$/).test(filteredPhone),
                        touched: true
                    }
                });
                break;
            case 'asignar':
                this.setState({
                    [id]: !asignar,
                });
                break;
            case 'nombreAval1':
                this.setState({ aval1: { ...this.state.aval1, nombre: value } });
                break;
            case 'telAval1':
                let aval1Phone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    aval1: {
                        ...this.state.aval1,
                        telefono: {
                            value: aval1Phone,
                            valid: RegExp(/^.{10,}$/).test(aval1Phone),
                        }
                    }
                });
                break;
            case 'nombreAval2':
                this.setState({ aval2: { ...this.state.aval2, nombre: value } });
                break;
            case 'telAval2':
                let aval2Phone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    aval2: {
                        ...this.state.aval2,
                        telefono: {
                            value: aval2Phone,
                            valid: RegExp(/^.{10,}$/).test(aval2Phone),
                        }
                    }
                });
                break;
            case 'nombreLaboral':
                this.setState({ trabajo: { ...this.state.trabajo, nombre: value } });
                break;
            case 'telLaboral':
                let laboralPhone = value.replace(/[^0-9]+/g, '');
                this.setState({
                    trabajo: {
                        ...this.state.trabajo,
                        telefono: {
                            value: laboralPhone,
                            valid: RegExp(/^.{10,}$/).test(laboralPhone),
                        }
                    }
                });
                break;
            default:
                this.setState({ [id]: value });
                break;
        }
    }

    submit = (e) => {
        e.preventDefault();
        const { cliente, update, vista } = this.props;
        const { loadUrlINE1, loadUrlINE2, loadUrlDom, INEimg1, INEimg2, Domimg, PerfilImg, nombre, apellidoP, apellidoM,
            telefono, domicilio, correo, usuario, contrasena, repetirContrasena, aval1, aval2,
            trabajo, asignar } = this.state;

        let referenciaAval1 = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };
        let referenciaAval2 = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };
        let referenciaLaboral = { nombre: '', telefono: '', direccion: '', lat: 0, lng: 0 };

        if (cliente && cliente.infoCliente) {
            if (cliente.infoCliente.referenciaAval1) {
                referenciaAval1 = JSON.parse(cliente.infoCliente.referenciaAval1);
            }
            if (cliente.infoCliente.referenciaAval2) {
                referenciaAval2 = JSON.parse(cliente.infoCliente.referenciaAval2);
            }
            if (cliente.infoCliente.referenciaLaboral) {
                referenciaLaboral = JSON.parse(cliente.infoCliente.referenciaLaboral);
            }
        }

        if (contrasena.value !== repetirContrasena) {
            NotificationManager.warning('Las contraseñas ingresadas no coinciden', 'Error en contraseña');
        }
        else if (domicilio.lat === 0 || domicilio.lng === 0) {
            NotificationManager.warning('Por favor ingrese la dirección nuevamente', 'Error en dirección');
        }
        else if (!telefono.valid || !domicilio.valid || !correo.valid || !usuario.valid || !contrasena.valid
            || !aval1.telefono.valid || !aval1.domicilio.valid || !aval2.telefono.valid || !aval2.domicilio.valid
            || !trabajo.telefono.valid || !trabajo.domicilio.direccion) {
            NotificationManager.warning('La información del formulario contiene errores.', 'Información con errores');
        }
        else {
            const datos = {};
            if (nombre !== cliente.nombre) {
                datos.nombre = nombre;
            }
            if (apellidoP !== cliente.apellidoP) {
                datos.apellidoP = apellidoP;
            }
            if (apellidoM !== cliente.apellidoM) {
                datos.apellidoM = apellidoM;
            }
            if (telefono.value !== cliente.telefono && telefono.value !== '') {
                datos.telefono = telefono.value;
            }
            if (domicilio.direccion !== cliente.direccion && domicilio.direccion.trim() !== '') {
                datos.direccion = domicilio.direccion;
                datos.lat = domicilio.lat;
                datos.lng = domicilio.lng;
            }
            if (correo.value !== cliente.email && correo.value !== '') {
                datos.email = correo.value;
            }
            if (asignar) {
                if (usuario.value !== cliente.username) {
                    datos.username = usuario.value;
                }
                if (contrasena.value !== '') {
                    datos.password = contrasena.value;
                }
            }
            if (aval1.nombre !== referenciaAval1.nombre || aval1.telefono.value !== referenciaAval1.telefono || aval1.domicilio.direccion !== referenciaAval1.direccion) {
                let nombreAval1 = aval1.nombre !== referenciaAval1.nombre ? aval1.nombre : referenciaAval1.nombre;
                let telAval1 = aval1.telefono.value !== referenciaAval1.telefono ? aval1.telefono.value : referenciaAval1.telefono;
                let domAval1 = {
                    direccion: aval1.domicilio.direccion !== referenciaAval1.direccion ? aval1.domicilio.direccion : referenciaAval1.direccion,
                    lat: aval1.domicilio.lat !== referenciaAval1.lat ? aval1.domicilio.lat : referenciaAval1.lat,
                    lng: aval1.domicilio.lng !== referenciaAval1.lng ? aval1.domicilio.lng : referenciaAval1.lng
                }
                datos.referenciaAval1 = JSON.stringify({ 'nombre': nombreAval1, 'telefono': telAval1, 'direccion': domAval1.direccion, 'lat': domAval1.lat, 'lng': domAval1.lng });
            }
            if (aval2.nombre !== referenciaAval2.nombre || aval2.telefono.value !== referenciaAval2.telefono || aval2.domicilio.direccion !== referenciaAval2.direccion) {
                let nombreAval2 = aval2.nombre !== referenciaAval2.nombre ? aval2.nombre : referenciaAval2.nombre;
                let telAval2 = aval2.telefono.value !== referenciaAval2.telefono ? aval2.telefono.value : referenciaAval2.telefono;
                let domAval2 = {
                    direccion: aval2.domicilio.direccion !== referenciaAval2.direccion ? aval2.domicilio.direccion : referenciaAval2.direccion,
                    lat: aval2.domicilio.lat !== referenciaAval2.lat ? aval2.domicilio.lat : referenciaAval2.lat,
                    lng: aval2.domicilio.lng !== referenciaAval2.lng ? aval2.domicilio.lng : referenciaAval2.lng
                }
                datos.referenciaAval2 = JSON.stringify({ 'nombre': nombreAval2, 'telefono': telAval2, 'direccion': domAval2.direccion, 'lat': domAval2.lat, 'lng': domAval2.lng });
            }
            if (trabajo.nombre !== referenciaLaboral.nombre || trabajo.telefono.value !== referenciaLaboral.telefono || trabajo.domicilio.direccion !== referenciaLaboral.direccion) {
                let nombreLaboral = trabajo.nombre !== referenciaLaboral.nombre ? trabajo.nombre : referenciaLaboral.nombre;
                let telLaboral = trabajo.telefono.value !== referenciaLaboral.telefono ? trabajo.telefono.value : referenciaLaboral.telefono;
                let domLaboral = {
                    direccion: trabajo.domicilio.direccion !== referenciaLaboral.direccion ? trabajo.domicilio.direccion : referenciaLaboral.direccion,
                    lat: trabajo.domicilio.lat !== referenciaLaboral.lat ? trabajo.domicilio.lat : referenciaLaboral.lat,
                    lng: trabajo.domicilio.lng !== referenciaLaboral.lng ? trabajo.domicilio.lng : referenciaLaboral.lng
                }
                datos.referenciaLaboral = JSON.stringify({ 'nombre': nombreLaboral, 'telefono': telLaboral, 'direccion': domLaboral.direccion, 'lat': domLaboral.lat, 'lng': domLaboral.lng });
            }
            if (INEimg1) {
                datos.ineImg = INEimg1;
            }
            if (INEimg2) {
                datos.ineBImg = INEimg2;
            }
            if (Domimg) {
                datos.domImg = Domimg;
            }
            if (Domimg) {
                datos.domImg = Domimg;
            }
            if (PerfilImg) {
                datos.perfilImg = PerfilImg;
            }

            if (Object.entries(datos).length === 0 && datos.constructor === Object) {
                NotificationManager.warning('La información del cobrador no ha sido modificada.', 'Cobrador no editado');
            }
            else {
                update(cliente.id, datos, vista);
            }
        }
    }

    clearFileInput = (id) => {
        if (id === 1) {
            this.fileInputINE1.value = "";
            this.setState({ loadUrlINE1: false, imageINE1: [], INEimg1: null });
        }
        else if (id === 2) {
            this.fileInputINE2.value = "";
            this.setState({ loadUrlINE2: false, imageINE2: [], INEimg2: null });
        }
        else if (id === 3) {
            this.fileInputDom.value = "";
            this.setState({ loadUrlDom: false, imageDom: [], Domimg: null });
        }
        else if (id === 4) {
            this.fileInputPerfil.value = "";
            this.setState({ loadUrlPerfil: false, imagePerfil: [], PerfilImg: null });
        }
        else {

        }
    }

    renderImageINE1 = () => {
        const { imageINE1, loadUrlINE1 } = this.state;
        const { cliente } = this.props;
        let imageUrl = null;

        if (Object.entries(cliente).length === 0 && cliente.constructor === Object) {
            /*console.log('usuario sin INE 1');*/
        }
        else if (cliente.imagenes.length > 0) {
            let imagenINE1 = cliente.imagenes.find(image => image.tipo.id === 2);
            imageUrl = baseUrl + imagenINE1.url;
        }
        else {
            /*console.log('usuario sin INE 1');*/
        }

        if (typeof imageINE1 !== 'undefined' && imageINE1.length > 0) {
            return (
                <div className="container-img">
                    <img id="thumb_img_INE1" src={imageINE1} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(1)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else if (imageUrl && loadUrlINE1) {
            return (
                <div className="container-img">
                    <img id="thumb_img_INE1" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(1)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else {
            return (<img id="thumb_img_INE1" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
    }

    renderImageINE2 = () => {
        const { imageINE2, loadUrlINE2 } = this.state;
        const { cliente } = this.props;
        let imageUrl = null;

        if (Object.entries(cliente).length === 0 && cliente.constructor === Object) {
            /*console.log('usuario sin INE 2');*/
        }
        else if (cliente.imagenes.length > 0) {
            let imagenINE2 = cliente.imagenes.find(image => image.tipo.id === 5);
            imageUrl = baseUrl + imagenINE2.url;
        }
        else {
            /*console.log('usuario sin INE 2');*/
        }

        if (typeof imageINE2 !== 'undefined' && imageINE2.length > 0) {
            return (
                <div className="container-img">
                    <img id="thumb_img_INE2" src={imageINE2} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(2)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else if (imageUrl && loadUrlINE2) {
            return (
                <div className="container-img">
                    <img id="thumb_img_INE2" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(2)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else {
            return (<img id="thumb_img_INE2" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
    }

    renderImageDomicilio = () => {
        const { imageDom, loadUrlDom } = this.state;
        const { cliente } = this.props;
        let imageUrl = null;

        if (Object.entries(cliente).length === 0 && cliente.constructor === Object) {
            /*console.log('usuario sin Comprobante de domicilio');*/
        }
        else if (cliente.imagenes.length > 0) {
            let imagenComprobante = cliente.imagenes.find(image => image.tipo.id === 3);
            imageUrl = baseUrl + imagenComprobante.url;
        }
        else {
            /* console.log('usuario sin Comprobante de domicilio');*/
        }

        if (typeof imageDom !== 'undefined' && imageDom.length > 0) {
            return (
                <div className="container-img">
                    <img id="thumb_img_comprobante" src={imageDom} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(3)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else if (imageUrl && loadUrlDom) {
            return (
                <div className="container-img">
                    <img id="thumb_img_comprobante" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(3)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else {
            return (<img id="thumb_img_comprobante" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
    }

    renderImagePerfil = () => {
        const { imagePerfil, loadUrlPerfil } = this.state;
        const { cliente } = this.props;
        let imageUrl = null;

        if (Object.entries(cliente).length === 0 && cliente.constructor === Object) {
            /*console.log('usuario sin imagen de perfil');*/
        }
        else if (cliente.imagenes.length > 0) {
            let imagenPerfil = cliente.imagenes.find(image => image.tipo.id === 1);
            imageUrl = baseUrl + imagenPerfil.url;
        }
        else {
            /*console.log('usuario sin imagen de perfil');*/
        }

        if (typeof imagePerfil !== 'undefined' && imagePerfil.length > 0) {
            return (
                <div className="container-img">
                    <img id="thumb_img_perfil" src={imagePerfil} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(4)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else if (imageUrl && loadUrlPerfil) {
            return (
                <div className="container-img">
                    <img id="thumb_img_perfil" src={imageUrl} style={{ maxHeight: "130px" }} alt='' />
                    <button type="button" className="btn" onClick={() => this.clearFileInput(4)}><i className="fas fa-times fa-lg"></i></button>
                </div>
            )
        }
        else {
            return (<img id="thumb_img_comprobante" src={imagen_thumbnail} style={{ maxHeight: "130px" }} alt='' />);
        }
    }

    render() {

        const { mapsReady, imageINE1, imageINE2, imageDom, nombre, apellidoP, apellidoM, telefono, correo, usuario, contrasena, repetirContrasena,
            aval1, aval2, asignar, trabajo, domicilio } = this.state;
        const { fnCancelar } = this.props;

        console.log(domicilio)

        return (
            <Card className="card-css">
                <CardHeader>

                </CardHeader>
                <CardBody>
                    <div className="col-md-12">
                        <form autoComplete="off" onSubmit={(e) => this.submit(e)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 ><b>Información de cliente</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Nombre(s)" value={nombre} type="text" name="nombre" onChange={this.handleChange} id="nombre" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Primer apellido" value={apellidoP} type="text" name="apellidoP" onChange={this.handleChange} id="apellidoP" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Segundo apellido" value={apellidoM} type="text" name="apellidoM" onChange={this.handleChange} id="apellidoM" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <Input label="Teléfono" value={telefono.value} type="text" name="telefono" onChange={this.handleChange} id="telefono" minLength="10" required pattern=".*[^ ].*" />
                                    {!telefono.valid && telefono.touched && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <Input label="Correo electrónico" value={correo.value} type="email" name="correo" onChange={this.handleChange} id="correo" />
                                    {!correo.valid && <p className="form-alert">*Formato de correo inválido</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-inline md-form mr-auto m-0 form-direccion">
                                        <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                        {mapsReady && <Search id="txt-dir-cliente" handleChange={this.addressChange} name='busquedaCliente' inputValue={domicilio.direccion} />}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            <p className="div-imagen"><b>IFE/INE (lado a):</b></p>
                                            <div className="file-field">
                                                <div className="btn btn-gris">
                                                    <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageINE1" name="imageINE1" ref={ref => this.fileInputINE1 = ref} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            {
                                                this.renderImageINE1()
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            <p className="div-imagen"><b>IFE/INE (lado b):</b></p>
                                            <div className="file-field">
                                                <div className="btn btn-gris">
                                                    <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageINE2" name="imageINE2" ref={ref => this.fileInputINE2 = ref} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            {
                                                this.renderImageINE2()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6 col-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            <p className="div-imagen"><b>Imagen Cliente:</b></p>
                                            <div className="file-field">
                                                <div className="btn btn-gris">
                                                    <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imagePerfil" name="imagePerfil" ref={ref => this.fileInputPerfil = ref} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            {
                                                this.renderImagePerfil()
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            <p className="div-imagen"><b>Comprobante de domicilio:</b></p>
                                            <div className="file-field">
                                                <div className="btn btn-gris">
                                                    <span>Elegir archivo</span> <input type="file" onChange={this.onImageChange} className="filetype" id="imageDom" name="imageDom" ref={ref => this.fileInputDom = ref} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 input-margin">
                                            {
                                                this.renderImageDomicilio()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col-md-12">
                                    <h5 ><b>Información laboral</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Nombre" value={trabajo.nombre} type="text" name="nombreLaboral" onChange={this.handleChange} id="nombreLaboral" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <Input label="Teléfono" value={trabajo.telefono.value} type="text" name="telLaboral" onChange={this.handleChange} id="telLaboral" minLength="10" required pattern=".*[^ ].*" />
                                    {!trabajo.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="form-inline md-form mr-auto m-0 form-direccion">
                                        <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                        {mapsReady && <Search id="txt-dir-laboral" handleChange={this.addressChange} name='dirTrabajo' inputValue={trabajo.domicilio.direccion} />}
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="row">
                                <div className="col-md-12">
                                    <h5 ><b>Información de avales</b></h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Nombre de aval 1" value={aval1.nombre} type="text" name="nombreAval1" onChange={this.handleChange} id="nombreAval1" required pattern=".*[^ ].*" minLength="2" />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <Input label="Teléfono" value={aval1.telefono.value} type="text" name="telAval1" onChange={this.handleChange} id="telAval1" minLength="10" required pattern=".*[^ ].*" />
                                    {!aval1.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="form-inline md-form mr-auto m-0 form-direccion">
                                        <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                        {mapsReady && <Search id="txt-dir-aval1" handleChange={this.addressChange} name='busquedaAval1' inputValue={aval1.domicilio.direccion} />}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Input label="Nombre de aval 2" value={aval2.nombre} type="text" name="nombreAval2" onChange={this.handleChange} id="nombreAval2" minLength="2" />
                                </div>
                                <div className="col-md-3 col-sm-12">
                                    <Input label="Teléfono" value={aval2.telefono.value} type="text" name="telAval2" onChange={this.handleChange} id="telAval2" minLength="10" />
                                    {!aval2.telefono.valid && <p className="form-alert">*El teléfono tiene que ser un número telefónico válido</p>}
                                </div>
                                <div className="col-md-5 col-sm-12">
                                    <div className="form-inline md-form mr-auto m-0 form-direccion">
                                        <Button className="btn-prefix" onClick={(e) => e.preventDefault()} disabled><i className="fas fa-map-marked-alt"></i></Button>
                                        {mapsReady && <Search id="txt-dir-aval2" handleChange={this.addressChange} name='busquedaAval2' inputValue={aval2.domicilio.direccion} />}
                                    </div>
                                </div>
                            </div>

                            <br />

                            <div className="row input-margin">
                                <div className="col-md-12 col-xs-12">
                                    <div className="custom-control custom-checkbox">
                                        <input value={asignar} defaultChecked={asignar} name="asignar" id="asignar" onChange={this.handleChange} type="checkbox" className="custom-control-input" />
                                        <label className="custom-control-label" htmlFor="asignar"><b>Asignar usuario para consultas en línea</b></label>
                                    </div>
                                </div>
                            </div>

                            <br />

                            {asignar &&
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5><b>Información de usuario web</b></h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <Input label="Usuario" value={usuario.value} type="text" name="usuario" onChange={this.handleChange} id="usuario" pattern=".*[^ ].*" minLength="4" />
                                            {!usuario.valid && <p className="form-alert">*Formato de usuario inválido</p>}
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <Input label="Contraseña" value={contrasena.value} type="password" name="contrasena" onChange={this.handleChange} id="contrasena" pattern=".*[^ ].*" minLength="6" />
                                            {!contrasena.valid && <p className="form-alert">*La contraseña debe de contener mínimo 6 caracteres</p>}
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <Input label="Repetir contraseña" value={repetirContrasena} type="password" name="repetirContrasena" onChange={this.handleChange} id="repetirContrasena" pattern=".*[^ ].*" minLength="6" />
                                        </div>
                                    </div>
                                </>
                            }
                            <br />
                            <div className="text-center">
                                <button type="submit" className="btn btn-crear">Actualizar </button>
                                <button type="button" className="btn btn-danger btn-crear" onClick={(e) => fnCancelar(e)}>Cancelar</button>
                            </div>
                        </form>
                    </div >
                </CardBody>
            </Card>
        );
    }
}

export default connect(state => ({
    cliente: state.usuarios.usuarioSeleccionado
}), {
        update: fetchUpdateCliente
    })(EditarCliente);