import React, { Component } from 'react';
import { Table, TableBody, TableHead, Fa, Button } from 'mdbreact';
import Paginacion from '../Shared/pagination';
import moment from 'moment';
import DateTime from 'react-datetime';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { fetchGetIngresosCobrador } from '../../Reducers/cobradores';
import { fetchGetCatUsuarios } from '../../Reducers/catalogos';
import Select from 'react-select';
import { customStyles } from '../Shared/selectStyle';
import 'react-datetime/css/react-datetime.css';
import '../../Css/ingresos.css';

let options = [];

let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

const initialState = {
    total: 10,
    activePage: 1,
    size: 10,
    offset: '0',
    selectedOption: null,
    dpStart: moment(start),
    dpEnd: moment(end),
    startDate: moment(start).toISOString(),
    endDate: moment(end).toISOString(),
};

class IngresosCobrador extends Component {
    constructor(props) {
        super(props)
        this.state = initialState;
    }

    componentDidMount() {
        const { size, startDate, endDate } = this.state;
        const { getIngresos, cobrador, getUsuarios } = this.props;

        let objUsuarios = {
            limit: 1000,
            offset: '0',
            tipo: '1,2,3'
        }
        getUsuarios(objUsuarios);

        let objIngresos = {
            cobrador: cobrador,
            fechaInicio: startDate,
            fechaFin: endDate,
            limit: size,
            offset: '0',
            orderBy: 'desc'
        }

        getIngresos(objIngresos);
    }

    handleSelectChange = (selectedOption) => {
        this.setState({
            ...this.state, selectedOption
        });
    }

    handleSelectOptions = () => {
        const { usuarios } = this.props;
        options = [];
        usuarios.rows.map((user) =>
            options.push({ value: user.id, label: user.nombre + ' ' + user.apellidoP + ' ' + user.apellidoM })
        );
        return options;
    }

    buscar = () => {
        const { size, startDate, endDate, selectedOption } = this.state;
        const { getIngresos, cobrador } = this.props;

        let obj = {
            cobrador: cobrador,
            fechaInicio: startDate,
            fechaFin: endDate,
            limit: size,
            offset: '0',
            orderBy: 'desc'
        }
        if (selectedOption) {
            obj.usuario = selectedOption.value;
        }

        getIngresos(obj);
    }

    paginationHandler = (event, value) => {
        const { activePage, size, buscar } = this.state;
        //const { getClientes } = this.props;
        let pagina;

        let obj = {
            query: buscar,
            limit: size,
            offset: ((activePage - 1) * size).toString()
        }

        switch (event) {
            case 'page':
                if (value === 'prev') {
                    pagina = (activePage - 1);
                } else if (value === 'next') {
                    pagina = (activePage + 1);
                }
                else {
                    pagina = value;
                }
                this.setState({ activePage: pagina });
                obj.offset = ((pagina - 1) * size).toString();
                break;
            case 'size':
                this.setState({ size: parseInt(value, 10), activePage: 1 });
                obj.limit = parseInt(value, 10);
                obj.offset = '0';
                break;
            default:
                break;
        }
        // getClientes(obj);
    }

    handleChangeDTP = (name, value) => {
        const { dpStart } = this.state;
        switch (name) {
            case 'fromDate':
                this.setState({ dpStart: value, startDate: moment(value).toISOString(), });
                break;
            case 'toDate':
                if (!((value).isBefore(dpStart))) {
                    this.setState({ dpEnd: value, endDate: moment(value).toISOString() });
                }
                else {
                    NotificationManager.error('La fecha de fin no puede ser menor a la fecha de inicio.', 'Error en fechas');
                }
                break;
            default:
                break;
        }
    }

    render() {

        const { activePage, size, selectedOption, dpStart, dpEnd } = this.state;
        const { ingresos } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">Del </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input"></i>
                            <DateTime name="fromDate" locale="es" inputProps={{ className: "dtpicker_center form-control input-modal" }} onChange={moment => this.handleChangeDTP("fromDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" value={dpStart} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <div className="inline-input-add-on">al </div>
                        <div className="inline-input-dp">
                            <i className="far fa-calendar-alt prefijo-input"></i>
                            <DateTime name="toDate" inputProps={{ className: "dtpicker_center form-control input-modal" }} locale="es" onChange={moment => this.handleChangeDTP("toDate", moment)} closeOnSelect={true} dateFormat="DD/MM/YYYY" placeholder="Fecha final" value={dpEnd} timeFormat={false} />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 input-margin">
                        <div className="form-inline float-md-right">
                            <div className="form-group">
                                <label htmlFor="size">Número de registros</label>&nbsp;
                                <select id="size" className="select-num" value={size}
                                    onChange={(event) => { this.paginationHandler('size', event.target.value); }}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Select options={this.handleSelectOptions()} value={selectedOption} placeholder="Quién recibió" noOptionsMessage={() => "No se encontraron coincidencias"} styles={customStyles} onChange={this.handleSelectChange} isClearable />
                    </div>
                    <div className="col-md-3 col-sm-12 input-margin">
                        <Button className="btn-refresh" onClick={this.buscar}><Fa icon="redo" />  Actualizar</Button>
                    </div>
                </div>

                <br />

                <Table className="tbl-css" responsive>
                    <TableHead>
                        <tr>
                            <th>Fecha</th>
                            <th>Monto ingresado</th>
                            <th>Gastos</th>
                            <th>Comentarios</th>
                            <th>Quién recibió</th>
                        </tr>
                    </TableHead>
                    <TableBody>
                        {
                            ingresos.total > 0 ?
                                ingresos.rows.map((v, i) =>
                                    <tr key={i}>
                                        <td>{moment(v.createdAt).format('DD/MM/YYYY  HH:mm')}</td>
                                        <td>${v.total.toFixed(2).toLocaleString()}</td>
                                        <td>${v.gasto.toFixed(2).toLocaleString()}</td>
                                        <td>{v.nota}</td>
                                        <td>{v.recolector.nombre} {v.recolector.apellidoP} {v.recolector.apellidoM}</td>
                                    </tr>
                                )
                                :
                                <tr><td colSpan="5">No se encontraron registros para mostrar</td></tr>
                        }
                    </TableBody>
                </Table>
                <Paginacion total={ingresos.total} activePage={activePage} size={size} paginacionEvent={this.paginationHandler} />
            </div>
        )
    }
}

export default connect(state => ({
    ingresos: state.cobradores.ingresos,
    usuarios: state.catalogos.usuariosCat
}), {
        getIngresos: fetchGetIngresosCobrador,
        getUsuarios: fetchGetCatUsuarios
    })(IngresosCobrador);